import React from "react";
import axios from "axios";
import { RAZORPAY_API_KEY } from "../../../core/constants";
import { FaRegCreditCard } from "react-icons/fa";
import { config } from "../../../actions/config";

const PaymentButton = ({paybleAmount , onPaymentDone, isDisabled, buttonClass="razorpay-btn", buttonText="Pay"})=> {
    let disableButton=false;
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        disableButton=true;
        const userString = localStorage.getItem('user')
        const user = userString? JSON.parse(userString):null
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const result = await axios.post(config.BASE_URL+"/payment/getOrder",{amount: paybleAmount*100}).catch(e =>{});

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        const { amount, id: order_id, currency } = result.data.data;

        const options = {
            key: RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
            amount: (amount).toString(),
            currency: currency,
            name: user?.userName,
            description: "Payment",
            image: process.env.PUBLIC_URL+'images/logo_1.png',
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    "userId": user._id,
                    "amount": amount/100,
                    "transactionId": response.razorpay_order_id,
                    "status": 'approved'
                };

                // const result = await axios.post(config.BASE_URL+"/hire/makepayment", data).catch(e =>{});
                onPaymentDone(data)
                disableButton=false
                // alert(result.data.msg);
            },
            prefill: {
                name: user.userName,
                email: user.emailId,
                contact: user.phoneNumber,
            },
            notes: {
                address: user.address,
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
                <button className={buttonClass} disabled={isDisabled || disableButton} src={process.env.PUBLIC_URL+'icon/razorpay.png'} height={50} alt="logo" style={{cursor:'pointer'}} onClick={displayRazorpay} >
                      {buttonText}
                </button>
    );
}

export default PaymentButton;