import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import AuditionAndRole from "./AuditionAndRole";
import PersonalTraits from "./PersonalTraits";
import ShootAndLocations from "./shootAndLocation";
import OtherRequirements from "./otherRequirements";
import OrganiserDetails from "./organiserDetails";

const AuditionForm = ({ step, ...props }) => {

  return (
    <>
        <div className="col-md-3 col-sm-4 right_space sidebar_part">
        <ul className="nav nav-tabs" style={{background:"linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 95%)"}}>
            <li className={step === 1 ? 'active1' : ''} >
              <a href="javascript:void" onClick={() => props.nextStep(1)} style={{color: step === 1 ? 'black' : ''}}> Audition and Role</a>
            </li>
            <li className={step === 2 ? 'active1' : ''}>
              <a href="javascript:void" onClick={() => props.nextStep(2)} style={{color: step === 2 ? 'black' : ''}}>Personal Traits</a>
            </li>
            <li className={step === 3 ? 'active1' : ''}>
              <a href="javascript:void" onClick={() => props.nextStep(3)} style={{color: step === 3 ? 'black' : ''}}>Shoot Locations</a>
            </li>
            <li className={step === 4 ? 'active1' : ''}>
              <a href="javascript:void" onClick={() => props.nextStep(4)} style={{color: step === 4 ? 'black' : ''}}>Organiser Details</a>{" "}
            </li>
            <li className={step === 5 ? 'active1' : ''}>
              <a href="javascript:void" onClick={() => props.nextStep(5)} style={{color: step === 5 ? 'black' : ''}}>Role Brief</a>{" "}
            </li>
          </ul>
        </div>
        <div className="col-md-9 col-sm-8">
          <div className="tab-content shadow_bg">
            {
              step === 1 ? <AuditionAndRole {...props} /> :
                step === 2 ? <PersonalTraits {...props} /> :
                  step === 3 ? <ShootAndLocations {...props} /> :
                    step === 4 ? <OrganiserDetails {...props}  /> :
                      <OtherRequirements {...props}/>
            }
          </div>
        </div>
    </>
  );
};

export default AuditionForm;
