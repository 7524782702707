import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ForgotPassOTP({ submitOtp, handleChangeSingle, submitForm, onChangeOtp, otp }) {
  const { t } = useTranslation();
  let newOtp = otp.split('');
  console.log(newOtp, otp, "ppp");
  return (
    <>
      <div className="logo"> <a href="index.php"><img src="images/logo_1.png" alt="img" /></a> </div>
      <form onSubmit={submitOtp} className="form-space">
        <div className="form_head_part">
          <h4>{t("ForgotPassPageTitle")}</h4>
          <p>Enter OTP and reset your password</p>
        </div>
        <div className="form-group otp_field">
          <label>OTP</label>
          <input type="text"
            className="form-control"
            name="otp"
            value={newOtp[0]}
            id="0"
            onChange={e => onChangeOtp((e.target.value ?.trim() ?.[0]), 0)}
          />
          <input type="text"
            className="form-control"
            name="otp"
            id="1"
            value={newOtp[1]}
            onChange={e => onChangeOtp((e.target.value ?.trim() ?.[0]), 1)}
          />
          <input type="text"
            className="form-control"
            name="otp"
            id="2"
            value={newOtp[2]}
            onChange={e => onChangeOtp((e.target.value ?.trim() ?.[0]), 2)}
          />
          <input type="text"
            className="form-control"
            value={newOtp[3]}
            id="3"
            onChange={e => onChangeOtp((e.target.value ?.trim() ?.[0]), 3)}
          />
          <input type="text"
            className="form-control"
            name="otp"
            id="4"
            value={newOtp[4]}
            onChange={e => onChangeOtp((e.target.value ?.trim() ?.[0]), 4)}
          />
          <input type="text"
            className="form-control"
            name="otp"
            id="5"
            value={newOtp[5]}
            onChange={e => onChangeOtp((e.target.value ?.trim() ?.[0]), 5)}
          />
          <a onClick={submitForm}>Resend OTP</a>
        </div>
        <div className="form-group">
          <label>New Password</label>
          <input type="password"
            className="form-control"
            name="password"
            onChange={handleChangeSingle}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <input type="password"
            className="form-control"
            name="confirmPassword"
            onChange={handleChangeSingle}
            required
          />
        </div>
        <div className="clearfix">
          <button type="submit" className="blue_btn">{t("SubmitBtn")}</button>
        </div>
      </form>
    </>
  )

}

export default ForgotPassOTP;