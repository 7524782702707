import React, { Component, useEffect, useState } from 'react';
import {
  FaMapMarker, FaStarHalf, FaStar,
  FaRegEnvelope,
  FaPhoneAlt,
  FaUser,
  // FaSuitcase,
  // FaUsers,
  // FaLocationArrow,
  // FaFilm,
} from 'react-icons/fa';
// import { Link } from "react-router-dom";
import { config } from '../../actions/config';
import { getMeritScore } from '../../functions/meritCalculation';
import UserProfileSidebar from "../../components/UserProfile/UserProfileSidebar";

const UserProfileHeader = ({ user, loginUser, handleSubmitReview, handleChangeSingle, ...restProps }) => {
  const [meritScore, setMeritScore] = useState(0);
  useEffect(() => {
    if (user && user._id) {
      const MF = getMeritScore(user)
      const score = MF.map(e => e.progress).reduce((a, b) => a + b) / MF.length
      setMeritScore(score.toFixed(0))
    }
  }, [user])
  return (
    <div className="clearfix people_detail_banner your_page user_side_area">

      <div className="row">
      
        <div className="col-md-12 people_center_info">
          <div className="row">        
            <div className="add_list col-md-12">            
              <div className="clearfix people_header">
              <div className="applicant_img">
            <img src={user.profilePic ? config.FILE_URL + user.profilePic : 'images/fill_user.png'} alt="img" />
            {/* <span className="online"><div className="circle_icon"></div> </span> */}
          </div>
                <h3>{user.userName}
                  <div className="rating">
                    <div className="star">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStarHalf />
                    </div>

                  </div> </h3>
                <br />
                <small style={{ marginBottom: 10 }}>{user?.profession}</small>                
                {/* <small>Profile Summary : {user.languagesSpeak ?.join(', ')}</small> */}

                
                  <div className="bottom_desc">
                    <div className="progress default_progress">
                      <div className="progress-bar" role="progressbar" aria-valuenow="70"
                        aria-valuemin="0" aria-valuemax="100" style={{ width: meritScore + "%", background: 'green' }}>
                        <span>{meritScore}% Merit Score</span>
                      </div>
                    </div>
                  </div>
                  
                  {loginUser?.emailId !== user?.emailId ?
                <div className='text-center' style={{ paddingTop: 10 }}>
                  <button className="btn btn-primary nextBtn btn-lg stroke_btn" data-toggle="modal" data-target="#review">Write Review</button>

                </div>
                : null}
              </div>
            </div>
          </div>
        </div>
        <UserProfileSidebar user={user} />
        <div className="col-md-12">
          <div className="dark_bg">
          {/* <small><FaMapMarker /> {user.city}</small> */}
                {/* <p>Address: {user.address}</p> */}
                {/* <small>Languages Speak : {user.languagesSpeak?.join(', ')}</small> */}
            <p>{user.emailId}</p>
            <p>{user.phoneNumber} </p>
            <small>Manager Details</small>
            {
              user.managerName && <p>{user.managerName}  </p>
            }
            {
              user.managerNumber && <p>{user.managerNumber} </p>
            }


            {/* <p>20 connects available <FaFileAlt /> </p> */}
          </div>

        </div>

      </div>
      <ReviewModal user={user} loginUser={loginUser} handleSubmitReview={handleSubmitReview} handleChangeSingle={handleChangeSingle} />
    </div>
  )
}
const ReviewModal = ({ user, loginUser, handleSubmitReview, handleChangeSingle }) => {
  return (
    <>
      <div className="modal fade" id="review" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <h4 className="modal-title">Write Your Review</h4>
            </div>
            <div className="modal-body terms_bx">
              <div className='skills_part'>
                {/* <label><strong>Message</strong> </label>    
          <p>Write a detailed review about </p>        */}

                <textarea
                  className="form-control"
                  rows={7}
                  placeholder="Write your honest review..."
                  name="userReview"
                  // value={user?.userReview?.reviewMsg}
                  onChange={(e) => handleChangeSingle({ target: { name: 'userReview', value: { reviewMsg: e.target.value, reviewerName: loginUser.userName, reviewerId: loginUser._id } } })}
                ></textarea>
              </div>
            </div>
            <div className="modal-footer application_btn">
              <button type="submit" className="blue_btn" data-dismiss="modal" onClick={handleSubmitReview}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UserProfileHeader;