import React from "react";
import Select from "react-select";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { SeeJob } from "./jobPostConst";

const Visibility = ({
    handleChangeDropDown,
    handleChangeSingle,
    prevStep,
    nextStep,
    PostJobSubmit,
    ...props
}) => {

    return (
        <>
            <div >
                <div className="tab_head">
                    <h4>Visibility</h4>
                    <hr />
                </div>
                <div class="row">
                    <div className="form-group col-sm-6">
                        <label>Who can see your job</label>
                        <Select
                            options={SeeJob}
                            value={{
                                value: props.visibility,
                                label: props.visibility,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "visibility",
                                    value: e.label,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Total No. of Vacancy</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="talentCount"
                            value={props.talentCount}
                            onChange={(e) => {
                                vlidateNumberField(e) && handleChangeSingle(e);
                            }}
                        />
                    </div>

                    <div className="col-sm-12">
                        <hr />
                        <br />
                        <button className="blue_btn" onClick={prevStep}>Back</button>
                        <button style={{ marginLeft: "15px" }}
                            type="submit"
                            className="blue_btn"
                            onClick={(e) => PostJobSubmit(e, false)}
                        >
                            {" "}
                            Save{" "}
                        </button>
                        <button className="blue_btn pull-right" onClick={() => nextStep()} >Continue</button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Visibility;