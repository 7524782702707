import React, { useEffect, useState } from 'react'
import Header from '../../components/shared/header/Header'
import SubHeader from '../../components/shared/header/SubHeader'
import Footer from "../../components/shared/footer/Footer";
import { FaCreditCard } from "react-icons/fa";
import { getUserFunds, sendSubcriptionPlan } from '../../actions';
import axios from 'axios';
import { config } from '../../actions/config';
import swal from 'sweetalert';

const Subscriptionpayment = () => {

    const [plan, setPlan] = useState({});
    const [user, setUser] = useState({});
    const [payableAmount, setPayableAmount] = useState(0);
    const [transactionId, setTransactionId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [userFunds, setUserFunds] = useState()


    useEffect(() => {
        // Fetch wallet funds
        getMyFunds();

        // Retrieve and set payableAmount
        const storedAmount = localStorage.getItem("payableAmount");
        if (storedAmount) {
            setPayableAmount(parseFloat(storedAmount));
        }

        // Retrieve and parse selectedPlan
        const storedPlan = localStorage.getItem("selectedPlan");
        if (storedPlan) {
            try {
                const parsedPlan = JSON.parse(storedPlan);
                setPlan({ ...parsedPlan }); // This will trigger re-render with updated state
                console.log('Parsed plan:', parsedPlan);
            } catch (error) {
                console.error("Error parsing storedPlan:", error);
            }


        }
        const user = localStorage.getItem("user");
        if (user) {
            try {
                const parseduser = JSON.parse(user);
                setUser({ ...parseduser }); // This will trigger re-render with updated state
                console.log('Parsed User:', parseduser);
            } catch (error) {
                console.error("Error parsing storedPlan:", error);
            }


        }
    }, []);
    useEffect(() => {
        console.log('UserId', user._id);
        console.log('Plan', plan);
    }, [plan, user]);


    const tnxId = (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2);

    const onPayment = async () => {
        if (!transactionId.trim()) { // Check if transactionId is empty or only contains whitespace
            swal(`Transaction ID is required.`, { buttons: false, timer: 2000, icon: 'error' });
            // Set the error message
            return; // Exit the function to prevent further processing
        }
        try {
            const paymentData = {
                userId: user._id,
                subscriptionPlanId: plan._id,
                subscriptionPlan: plan.plan,
                amount: payableAmount,
                amountWithoutTax: plan.amount || 0, // Handle case where plan.amount might be undefined
                paymentMethod: "UPI",
                // transactionId: "sdcce174332563t17fgy", // Replace with actual transactionId if dynamic
                transactionId: transactionId,
            };

            console.log("Sending payment data:", paymentData);

            // Call the API to post the data
            const result = await postSubscriptionPayment(paymentData);
            console.log("Payment successful:", result);
            swal({
                title: "Welcome to BiG!",
                icon: "success",
                content: {
                  element: "div",
                  attributes: {
                    innerHTML: `
                      <p style="text-align: center; font-size: 18px; margin-bottom: 20px;">
                        You’ve unlocked exclusive access to auditions, jobs, and premium features. Let your journey to stardom begin!
                        <br/>
                        <span style="font-weight: bold;">Will share confirmation email soon</span>
                      </p>
                      <div style="text-align: center;">
                        <button id="exploreNowBtn" style="
                          background-color: #d62027; 
                          color: white; 
                          border: none; 
                          padding: 10px 20px; 
                          margin: 10px; 
                          font-size: 16px; 
                          border-radius: 5px; 
                          cursor: pointer;">
                          Explore Now
                        </button>
                      </div>
                    `,
                  },
                },
                buttons: false, // Hide the default swal buttons
              });
              
              setTimeout(() => {
                const modal = document.querySelector(".swal-modal");
              
                // Add a custom close button
                const closeButton = document.createElement("button");
                closeButton.innerHTML = "&times;";
                closeButton.style.position = "absolute";
                closeButton.style.top = "5px";
                closeButton.style.right = "5px";
                closeButton.style.background = "transparent";
                closeButton.style.border = "none";
                closeButton.style.fontSize = "30px";
                closeButton.style.cursor = "pointer";
                modal.appendChild(closeButton);
              
                // Close the popup when clicking the custom close button
                closeButton.addEventListener("click", () => swal.close());
              
                // Add click functionality to the Explore Now button
                const exploreNowBtn = document.getElementById("exploreNowBtn");
                exploreNowBtn.addEventListener("click", () => {
                  swal.close(); // Close the popup
                  window.location.href = "#/home"; // Navigate to the homepage
                });
              }, 0);
              window.location.href = "#/home"; // Navigate to the homepage
              
        } catch (error) {
            console.error("Payment failed:", error);
            // alert("Payment failed. Please try again."); 
            swal(`Please enter valid Transaction id.`, { buttons: false, timer: 2000, icon: 'error' });

        }
    };

    const onPaymentBigWallet = async () => {
        console.log("User Funds:", userFunds, "Payable Amount:", payableAmount);
    
        // Validate amounts
        if (isNaN(userFunds) || isNaN(payableAmount)) {
            swal("Error: Invalid amount values.", { buttons: false, timer: 3000, icon: "error" });
            return;
        }
    
        if (userFunds < payableAmount) {
            swal("Big Wallet Balance Insufficient.", { buttons: false, timer: 3000, icon: "error" });
            return;
        }
    
        try {
            const paymentData = {
                userId: user._id,
                subscriptionPlanId: plan._id,
                subscriptionPlan: plan.plan,
                amount: payableAmount,
                amountWithoutTax: plan.amount,
                paymentMethod: "bigWallet",
                transactionId: "bw" + tnxId,
            };
    
            console.log("Sending payment data:", paymentData);
    
            const result = await postSubscriptionPayment(paymentData);
            console.log("Payment successful:", result);
    
            // Success swal
            swal({
                title: "Welcome to BiG!",
                icon: "success",
                content: {
                  element: "div",
                  attributes: {
                    innerHTML: `
                      <p style="text-align: center; font-size: 18px; margin-bottom: 20px;">
                        You’ve unlocked exclusive access to auditions, jobs, and premium features. Let your journey to stardom begin!
                        <br/>
                        <span style="font-weight: bold;">Will share confirmation email soon</span>
                      </p>
                      <div style="text-align: center;">
                        <button id="exploreNowBtn" style="
                          background-color: #d62027; 
                          color: white; 
                          border: none; 
                          padding: 10px 20px; 
                          margin: 10px; 
                          font-size: 16px; 
                          border-radius: 5px; 
                          cursor: pointer;">
                          Explore Now
                        </button>
                      </div>
                    `,
                  },
                },
                buttons: false, // Hide the default swal buttons
              });
              
              setTimeout(() => {
                const modal = document.querySelector(".swal-modal");
              
                // Add a custom close button
                const closeButton = document.createElement("button");
                closeButton.innerHTML = "&times;";
                closeButton.style.position = "absolute";
                closeButton.style.top = "5px";
                closeButton.style.right = "5px";
                closeButton.style.background = "transparent";
                closeButton.style.border = "none";
                closeButton.style.fontSize = "30px";
                closeButton.style.cursor = "pointer";
                modal.appendChild(closeButton);
              
                // Close the popup when clicking the custom close button
                closeButton.addEventListener("click", () => swal.close());
              
                // Add click functionality to the Explore Now button
                const exploreNowBtn = document.getElementById("exploreNowBtn");
                exploreNowBtn.addEventListener("click", () => {
                  swal.close(); // Close the popup
                  window.location.href = "#/home"; // Navigate to the homepage
                });
              }, 0);
              window.location.href = "#/home"; // Navigate to the homepage
              
        } catch (error) {
            console.error("Payment failed:", error);
            swal("Error.", { buttons: false, timer: 2000, icon: "error" });
        }
    };
    


    const postSubscriptionPayment = async (data) => {
        try {
            const response = await sendSubcriptionPlan(data);
            console.log("Payment response:", response);
            return response;
        } catch (error) {
            console.error("Error posting subscription payment:", error.response || error.message);
            throw error;
        }
    };

    const getMyFunds = () => {
        const userString = localStorage.getItem('user')
        const user = userString ? JSON.parse(userString) : null
        getUserFunds(user?._id).then(res => {
            setUserFunds(res?.data?.data.amount)
        }).catch(e => {
            console.log(e)
        })
    }


    

    const handleChange = (e) => {
        const { value } = e.target;
        setTransactionId(value); // Directly set the transactionId as a string
        if (value.trim()) {
            setErrorMessage(''); // Clear the error message if the input is valid
        }
    };

    return (
        <><div className="top_slide inner_header">
            <Header />
            <SubHeader />

            <section id="user_payment" className="mid_info inner_banner">
                <div className="container side_space">
                    <div className="row">
                        <div className="col-md-12 inner_banner_text text-center">
                            <h2>Make the payment for Subscription!</h2>
                        </div>
                    </div>
                </div>
            </section>
        </div>
            <div id="payment_mode" className="default_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="payment_area">
                                <ul class="nav nav-tabs" role="tablist" id="myTab">
                                    <li class="active"><a href="#upipay" role="tab" data-toggle="tab"><FaCreditCard /> UPI Payment</a></li>
                                    <li><a href="#cardpayment" role="tab" data-toggle="tab"><FaCreditCard /> Big Pocket Payment</a></li>
                                    {/* <li><a href="#currentIssue" role="tab" data-toggle="tab"><FaCreditCard /> Bank Payment</a></li> */}
                                </ul>
                                <div class="tab-content">

                                    <div class="tab-pane active" id="upipay">
                                        <div className="form_outer">
                                            <div className="row">
                                                <div className="col-md-12 payment_qr">
                                                    <div className="qr_inside">
                                                        <h6>Kalibroida Tech..</h6>
                                                        {/* <p>Scan thw QR with any Bharat QR/UPI enable app</p> */}
                                                        <p> <strong>Note :</strong>  Scan this QR or use UPI ID to make payment of <strong style={{ color: "#0548b3", fontWeight: "bold" }}> Rs {payableAmount.toFixed(2)}</strong> and submit transaction id.</p>
                                                    </div>
                                                    <img src="images/payment_qr.jpg" />
                                                </div>

                                                <div className="col-md-12 form-group">
                                                    <hr />
                                                    <p><strong>Or use UPI ID to add the fund and submit transaction id</strong></p>
                                                    <br />
                                                    <input type="radio" value="1" name="visa" id="visa" />
                                                    <label for="visa"><img src="images/phonepay.png" alt="" /></label>
                                                    <input type="radio" value="1" name="master" id="master" />
                                                    <label for="master"><img src="images/upi.png" alt="" /></label>
                                                    <input type="radio" value="1" name="master" id="master" />
                                                    <label for="master"><img src="images/paytm.png" alt="" /></label>
                                                    <input type="radio" value="1" name="master" id="master" />
                                                    <label for="master"><img src="images/gpay.png" alt="" /></label>
                                                </div>
                                                {/* <div className="col-md-12 form-group">
                <label>UPI id</label>
                <input type="text" className="form-control" />
              </div> */}
                                                <div className="col-md-12 form-group">
                                                    <label>Amount</label>
                                                    <input disabled value={payableAmount.toFixed(2)} className="form-control" />
                                                </div>
                                                {/* <div style={{ marginTop: "5px" }}>
                                                            <strong style={{ paddingRight: "8px" }}>Plan :</strong>{plan.plan}
                                                        </div> */}
                                                <div className="col-md-12 form-group">
                                                    <label>Transation id</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errorMessage ? 'is-invalid' : ''}`} // Add a class if there's an error
                                                        name="transactionId"
                                                        onChange={handleChange}
                                                    />
                                                    {errorMessage && <div className="text-danger">{errorMessage}</div>}
                                                </div>
                                                {/* <div className="col-md-12 form-group">
                                                    <label>Upload Transaction Image</label>
                                                    <input
                                                        type="file"
                                                        accept="image/*" 
                                                
                                                    />
                                                </div> */}
                                                
                                                <div className="col-md-12 form-group">
                                                    <button type="submit" onClick={onPayment} className="blue_btn">Make Payment</button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="tab-pane" id="cardpayment">
                                        <div className="form_outer">
                                            <h4> <small>Available Balance In Bigpocket is <strong>RS {Number(userFunds || 0).toFixed(2)}</strong></small> </h4>
                                            <p> <strong>Note :</strong>  Make payment of <strong style={{ color: "#0548b3", fontWeight: "bold" }}> Rs {payableAmount.toFixed(2)}</strong> From Big Pocket</p>
                                            <hr />
                                            {/* <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <input type="radio" value="1" name="visa" id="visa" />
                                                    <label for="visa"><img src="images/visa.png" alt="" /></label>

                                                    <input type="radio" value="1" name="master" id="master" />
                                                    <label for="master"><img src="images/master.png" alt="" /></label>
                                                </div>

                                                <div className="col-md-12 form-group">
                                                    <label>Cardholder name</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label>Card number</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <label>Expiraton</label>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control" placeholder="MM" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control" placeholder="YY" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-4 form-group">
                                                    <label>CVV</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label>Amount</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <button type="submit" className="blue_btn">Make Payment</button>
                                                </div>
                                            </div> */}

                                            <div className="col-md-12 form-group">
                                                <button type="submit" onClick={onPaymentBigWallet} className="blue_btn">Make Payment</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer /></>
    )
}

export default Subscriptionpayment