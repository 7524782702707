import React from "react";
import Header from "../shared/header/Header";
import { Link } from 'react-router-dom';
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../shared/footer/Footer";
import MakeFilmHeader from "./MakeFilmHeader";
import MakeFilmJobCat from "./MakeFilmJobCat";
import TeamList from "./TeamList";
import NoListMsg from "./NoListMsg";
import MakeFilmPopup from "./MakeFilmPopup";

const MakeFilmPage = props => (
  <>
    <Header {...props} />
    <SubHeader />
       {/* CTA section start*/}
       <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/hire.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>Need to hire talent for your next project?</h2>
            <p>Access the largest network of Producers, Directors, Writers, Artists, models, freelancers, and crew members to find the perfect creative talent for your next project.</p>
            </div>
            </div>
            </div>
          </div>
      </section>
      {/* CTA section ends */}
    <section id="make_film" className="dark_clr">
      <div className="container">
        <MakeFilmHeader {...props} />
        <MakeFilmJobCat {...props} />
        <TeamList {...props} />
        {/* <NoListMsg /> */}
      </div>
    </section>
    <Footer />
    <MakeFilmPopup {...props} />
  </>
);
export default MakeFilmPage;
