import React from "react";
import { Link } from "react-router-dom"
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import UserProfileHeader from "../../components/UserProfile/UserProfileHeader";
import UserProfileDetail from "../../components/UserProfile/UserProfileDetail";

const UserProfilePage = ({ step, ...props }) => {
  return (
    <>
    <div className="top_slide inner_header">
      <Header {...props} />
      <SubHeader />
      <section id="user_pro_banner" className="mid_info inner_banner">
          <div className="container side_space">         
            <div className="row">   
            <div className="col-md-6 inner_banner_text">
           
            </div>
            
            <img src="images/profile_bg.png" alt="img" />   
           
            </div>            
          </div>
      </section>
      </div>
      <section id="people_page" className="default_bg">
        <div className="container side_space">
        <div className="row">
        <div className="col-md-3">
        <UserProfileHeader {...props} />
        </div>
        <div className="col-md-9">
        <UserProfileDetail {...props} />
        </div>
        </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default UserProfilePage;
