import React from "react";
import { components } from "react-select";

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class" style={{ fontSize: 14 }}>Type to search ...</span>
    </components.NoOptionsMessage>
  );
};

export const Placeholder = props => {
  return <components.Placeholder {...props} />;
};

export default NoOptionsMessage;