import React from 'react';
import { Link } from 'react-router-dom';
import { FaHeart, FaSearch, FaAngleLeft, FaAngleRight, FaLocationArrow, FaMoneyBill, FaClock, } from 'react-icons/fa';
import _ from 'lodash';

const AllApplicationTab = ({ jobPostDetail, ...props }) => {
    console.log(jobPostDetail, 'hgdhgd')
    return (
        <div className="tab-pane" id="list_tab2">
            <div className="listing_filter">
                <div class="form-group search_field">
                    <input type="search" placeholder="Search" class="form-control" />
                    <button type="button" class="default_btn"><FaSearch /></button></div>
            </div>
            <ul>


                {
                    _.map(jobPostDetail.invites, val => (
                        <li>
                            <div className="row">
                                <div className="people_img col-md-2">
                                    <img src="images/akshay.jpg" alt="img" />
                                    <span className="online"><div className="circle_icon"></div> </span>
                                </div>
                                <div className="people_list col-md-10">
                                    <div className="people_list_info clearfix">
                                        <span className="name_title">{val.userName}</span>
                                        <h6>{val.workAndAwards ?.roleDetails}</h6>
                                        <div className="people_short_info">
                                            <span><FaLocationArrow /><strong>{val.workAndAwards ?.countryName}</strong></span>
                                            <span><FaClock /><strong>Rs 2500</strong> / hr</span>
                                            <span><FaMoneyBill /><strong>Rs 80k+</strong> earned</span>
                                        </div>
                                        <small> Earned Rs 0 on abc projects </small>
                                        <div className="skills">
                                            <p>9 relevant skills</p>

                                            {
                                                _.map(val.eduction ?.skills , val1 => (
                                                    <span> {val1}</span>
                                                ))
                                            }
                                            {
                                                _.map(val.eduction ?.specialization , val1 => (
                                                    <span> {val1}</span>
                                                ))
                                            }
                                            <Link to="/">3 More</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </li>
                    ))
                }

            </ul>
            <div class="pagination_part clearfix text-right">
                <br />
                <ul class="pagination">
                    <li><Link to="#"><FaAngleLeft /> Prev</Link></li>
                    <li><Link to="#">1</Link></li>
                    <li><Link to="#">2</Link></li>
                    <li><Link to="#">3</Link></li>
                    <li><Link to="#">4</Link></li>
                    <li><Link to="#">5</Link></li>
                    <li><Link to="#"> Next <FaAngleRight /></Link></li>
                </ul>
            </div>
        </div>
    );
}
export default AllApplicationTab;