import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { passingYear, postGraduation, graduation } from "./registerConst";
import Select from "react-select";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import filmCourseOptions from "./filmCoursesConst";
import { specialization } from "./registerConst";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import { loadOptionsResolveLocation } from "../../Constants.js/districtConst";
import collegeWiseCourse from './collegeWiseCourse';
import NoOptionsMessage from '../../commons/ReactSelectNoOptionsMessage';

const EducationInfo = ({
  handleChangeDropDown,
  submitForm,
  handleChange,
  nextStep,
  prevStep,
  handleChangeNestedValue,
  othersActivated,
  ...props
}) => {
  const { t } = useTranslation();
  const college = (props.education ?.[0] ?.collegeUniversity) ?.toLowerCase().replaceAll(' ', '_').includes('national_school_of_drama') ? 'national_school_of_drama' : (props.education ?.[0] ?.collegeUniversity) ?.toLowerCase().replaceAll(' ', '_').includes('film_and_television_institute') ? 'film_and_television_institute' : ''
  let optionsCourses = collegeWiseCourse[college];
  console.log(optionsCourses, college, props, "ccjd")
  return (
    <div>
      <div className="setup-content row" id="step-2">
        <div className="form-group col-md-12">
          <label className="control-label">
            Film Relevant Institute
          </label>
          <CreatableSelect
            isClearable
            options={filmCourseOptions}
            value={{
              label: (!othersActivated && props.education ?.[0] ?.collegeUniversity),
              value: (!othersActivated && props.education ?.[0] ?.collegeUniversity)
            }}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "collegeUniversity",
                value: e ?.label,
                key: "education",
                drop: true,
                index: 0,
              })
            }

          />

          {/* <Select
            options={filmCourseOptions}
            //isMulti
            value={{
              label:
                (!othersActivated && props.education?.[0]?.collegeUniversity) ||
                "Others",
              value:
                (!othersActivated && props.education?.[0]?.collegeUniversity) ||
                "Others",
            }}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "collegeUniversity",
                value: e.label,
                key: "education",
                drop: true,
                index: 0,
              })
            }
          /> */}
          {/* <span style={{ color: "red" }}>
            {this.state.errors["highestQualification"]}
          </span> */}
        </div>

        {/* {othersActivated ? (
          <div className="form-group col-md-6">
            <label>Others</label>
            <input
              maxLength={100}
              type="text"
              className="form-control"
              name="other"
              placeholder="Other"
              value={props.education?.[0]?.collegeUniversity}
              onChange={(e) =>
                handleChangeNestedValue({
                  name: "collegeUniversity",
                  value: e.target.value,
                  key: "education",
                  index: 0,
                })
              }
            />
          </div>
        ) : null} */}

        <div className="form-group col-md-6">
          <label>Location</label>
          <AsyncSelect
            components={{ NoOptionsMessage }}
            cacheOptions
            defaultOptions={[]}
            loadOptions={loadOptionsResolveLocation}
            value={{
              label: props.education ?.[0] ?.location,
              value: props.education ?.[0] ?.location,
            }}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "location",
                value: e.label,
                key: "education",
                index: 0,
              })
            }
          />
        </div>

        <div className="form-group col-md-6">
          <label>Specialized Course</label>
          <CreatableSelect
            options={optionsCourses || specialization}
            //isMulti
            value={{
              label: props.education ?.[0] ?.course,
              value: props.education ?.[0] ?.course,
            }}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "course",
                value: e.label,
                key: "education",
                index: 0,
              })
            }
          />
        </div>

        <div className="form-group col-md-6">
          <label className="control-label">Course Duration</label>
          <input
            type="text"
            name="duration"
            className="form-control"
            placeholder="Enter duration in months"
            value={props.education ?.[0] ?.courseDuration || ""}
            onChange={(e) =>
              vlidateNumberField(e) &&
              handleChangeNestedValue({
                name: "courseDuration",
                value: e.target.value,
                key: "education",
                index: 0,
              })
            }
            required
          />
          {/* <span style={{ color: "red" }}>
            {this.state.errors["collegeUniversity"]}
          </span> */}
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">{t("SignUpPassingYrLabel")}</label>
          <Select
            options={passingYear}
            placeholder="Select year"
            value={{
              label: props.education ?.[0] ?.passingYear,
              value: props.education ?.[0] ?.passingYear,
            }}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "passingYear",
                value: e.label,
                key: "education",
                index: 0,
              })
            }
          />
        </div>

        <div className="form-group col-md-12">
          <label className="control-label">{t("SignUpSkillsLabel")}</label>
          <input
            type="text"
            name="skills"
            className="form-control"
            placeholder="Enter your skills"
            value={props.education ?.[0] ?.skills}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "skills",
                value: e.target.value,
                key: "education",
                index: 0,
              })
            }
            required
          />
        </div>

        <div
          className="panel-group col-md-12"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div className="panel panel-default">
            <div className="panel-heading active" role="tab" id="headingOne">
              <h6 className="panel-title">
                <Link
                  to="#collapseOne"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Post Graduation
                </Link>
              </h6>
            </div>
            <div
              id="collapseOne"
              className="panel-collapse collapse in"
              role="tabpanel"
              aria-labelledby="headingOne"
            >
              <div className="row">
                {/* <div className="form-group col-md-6">
                  <label className="control-label">Post Graducation</label>
                  <Select
                    options={HighestQualification}
                    value={props.HighestQualification}
                    onChange={(e) =>
                      handleChangeDropDown({
                        name: "HighestQualification",
                        value: e,
                      })
                    }
                  />
                </div> */}
                <div className="form-group col-md-6">
                  <label className="control-label">Course</label>
                  <Select
                    options={postGraduation}
                    value={{
                      label: props.education ?.[1] ?.course,
                      value: props.education ?.[1] ?.course,
                    }}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "course",
                        value: e.label,
                        key: "education",
                        index: 1,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">University / Colleges</label>
                  <input
                    type="text"
                    name="specialisation"
                    className="form-control"
                    placeholder="Enter University / Colleges"
                    value={props.education ?.[1] ?.collegeUniversity}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "collegeUniversity",
                        value: e.target.value,
                        key: "education",
                        index: 1,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">Location</label>
                  <AsyncSelect
                    components={{ NoOptionsMessage }}
                    //value={{ value: location, label: location }}

                    cacheOptions
                    defaultOptions={[]}
                    loadOptions={loadOptionsResolveLocation}
                    value={{
                      label: props.education ?.[1] ?.location,
                      value: props.education ?.[1] ?.location,
                    }}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "location",
                        value: e.label,
                        key: "education",
                        index: 1,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">
                    {t("SignUpPassingYrLabel")}
                  </label>
                  <Select
                    options={passingYear}
                    value={{
                      label: props.education ?.[1] ?.passingYear,
                      value: props.education ?.[1] ?.passingYear,
                    }}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "passingYear",
                        value: e.label,
                        key: "education",
                        index: 1,
                      })
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="control-label">Course Duration</label>
                  <input
                    type="text"
                    name="duration"
                    className="form-control"
                    placeholder="Enter duration in months"
                    value={props.education ?.[1] ?.courseDuration || ""}
                    onChange={(e) =>
                      vlidateNumberField(e) &&
                      handleChangeNestedValue({
                        name: "courseDuration",
                        value: e.target.value,
                        key: "education",
                        index: 1,
                      })
                    }
                    required
                  />
                </div>

              </div>
            </div>
          </div>

          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingtwo">
              <h6 className="panel-title">
                <Link
                  to="#collapsetwo"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="true"
                  aria-controls="collapsetwo"
                >
                  Graduation
                </Link>
              </h6>
            </div>
            <div
              id="collapsetwo"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingtwo"
            >
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="control-label">Course</label>
                  <Select
                    options={graduation}
                    value={{
                      label: props.education ?.[2] ?.course,
                      value: props.education ?.[2] ?.course,
                    }}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "course",
                        value: e.label,
                        key: "education",
                        index: 2,
                      })
                    }
                  />
                </div>
                {/* <div className="form-group col-md-6">
                  <label className="control-label">Specialization</label>
                  <input
                    type="text"
                    name="specialisation"
                    className="form-control"
                    placeholder="Enter specialisation"
                    value={props.education?.[2]?.course}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "course",
                        value: e.target.value,
                        key: "education",
                        index: 2,
                      })
                    }
                    required
                  />
                  </div> */}
                <div className="form-group col-md-6">
                  <label className="control-label">University / Colleges</label>
                  <input
                    type="text"
                    name="specialisation"
                    className="form-control"
                    placeholder="Enter University / Colleges"
                    value={props.education ?.[2] ?.collegeUniversity}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "collegeUniversity",
                        value: e.target.value,
                        key: "education",
                        index: 2,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">Location</label>
                  <AsyncSelect
                    components={{ NoOptionsMessage }}
                    //value={{ value: location, label: location }}

                    cacheOptions
                    defaultOptions={[]}
                    loadOptions={loadOptionsResolveLocation}
                    value={{
                      label: props.education ?.[2] ?.location,
                      value: props.education ?.[2] ?.location,
                    }}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "location",
                        value: e.label,
                        key: "education",
                        index: 2,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">
                    {t("SignUpPassingYrLabel")}
                  </label>
                  <Select
                    options={passingYear}
                    placeholder="Select passing year"
                    value={{
                      label: props.education ?.[2] ?.passingYear,
                      value: props.education ?.[2] ?.passingYear,
                    }}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "passingYear",
                        value: e.label,
                        key: "education",
                        index: 2,
                      })
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="control-label">Course Duration</label>
                  <input
                    type="text"
                    name="duration"
                    className="form-control"
                    placeholder="Enter duration in months"
                    value={props.education ?.[2] ?.courseDuration || ""}
                    onChange={(e) =>
                      vlidateNumberField(e) &&
                      handleChangeNestedValue({
                        name: "courseDuration",
                        value: e.target.value,
                        key: "education",
                        index: 2,
                      })
                    }
                    required
                  />
                  {/* <span style={{ color: "red" }}>
            {this.state.errors["collegeUniversity"]}
          </span> */}
                </div>

              </div>
            </div>
          </div>

          <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="headingthree">
              <h6 className="panel-title">
                <Link
                  to="#collapsethree"
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="true"
                  aria-controls="collapsethree"
                >
                  SSC/+2
                </Link>
              </h6>
            </div>
            <div
              id="collapsethree"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingthree"
            >
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="control-label">Course</label>
                  <input
                    type="text"
                    name="specialisation"
                    className="form-control"
                    placeholder="Enter course name"
                    value={props.education ?.[3] ?.course}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "course",
                        value: e.target.value,
                        key: "education",
                        index: 3,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">School / Colleges</label>
                  <input
                    type="text"
                    name="specialisation"
                    className="form-control"
                    placeholder="Enter School / Colleges"
                    value={props.education ?.[3] ?.collegeUniversity}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "collegeUniversity",
                        value: e.target.value,
                        key: "education",
                        index: 3,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">Location</label>
                  <AsyncSelect
                    components={{ NoOptionsMessage }}
                    //value={{ value: location, label: location }}

                    cacheOptions
                    defaultOptions={[]}
                    loadOptions={loadOptionsResolveLocation}
                    value={{
                      label: props.education ?.[3] ?.location,
                      value: props.education ?.[3] ?.location,
                    }}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "location",
                        value: e.label,
                        key: "education",
                        index: 3,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="control-label">
                    {t("SignUpPassingYrLabel")}
                  </label>
                  <Select
                    options={passingYear}
                    value={{
                      label: props.education ?.[3] ?.passingYear,
                      value: props.education ?.[3] ?.passingYear,
                    }}
                    onChange={(e) =>
                      handleChangeNestedValue({
                        name: "passingYear",
                        value: e.label,
                        key: "education",
                        index: 3,
                      })
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <label className="control-label">Course Duration</label>
                  <input
                    type="text"
                    name="duration"
                    className="form-control"
                    placeholder="Enter duration in months"
                    value={props.education ?.[3] ?.courseDuration || ""}
                    onChange={(e) =>
                      vlidateNumberField(e) &&
                      handleChangeNestedValue({
                        name: "courseDuration",
                        value: e.target.value,
                        key: "education",
                        index: 3,
                      })
                    }
                    required
                  />

                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <button
            className="btn btn-primary nextBtn btn-lg stroke_btn"
            onClick={prevStep}
          >
            {t("PrevBtn")}
          </button>
          <button
            className="btn btn-primary nextBtn btn-lg blue_btn  pull-right"
            onClick={nextStep}
          >
            {t("ContinueBtn")}
          </button>
          <button
            className="btn btn-primary nextBtn btn-lg blue_btn pull-right" style={{ background: "#1683f3", marginRight: "20px" }} onClick={submitForm}>
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EducationInfo;
