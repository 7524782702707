import React from 'react';
import {  PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// import {AggregatorInvoice} from '../../core/services/invoiceData';
import Invoice from '../../components/AggregatorInvoiceToMM/Invoice';
import { FaFileInvoiceDollar } from 'react-icons/fa';



const AggregatorInvoiceToMMPage = ({invoice,transactionId}) => {
  console.log(invoice)
  const invoiceData = invoice
  return(
  <div className=''>
  {/* <PDFViewer showToolbar={false} width="800" height="1110" className="app" >
                <Invoice invoice={invoiceData}/>
            </PDFViewer> */}
            <PDFDownloadLink document={ <Invoice invoice={invoiceData}/>} fileName={"Service Invoice for Agency.pdf"}>
      {({ blob, url, loading, error }) =>
        loading ?  <button style={{width:'100%', padding:'6px'}}>Loading...</button> : <button style={{width:'100%', padding:'6px'}}><FaFileInvoiceDollar/> Service Invoice for Agency</button>
      }
    </PDFDownloadLink>
  </div>
)};
export default AggregatorInvoiceToMMPage;
