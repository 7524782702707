import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class BollywoodCelebrity extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>Bollywood Celebrity Management</h1>
                  <p align="justify"> We handles Store launch, celebrity promotional events for a particular film or product launch, award shows, ribbon-cutting ceremony, campaigning, celebrity parties or weddings, brand endorsement, international shows where celebrities are the centre of attraction for their fans. </p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/bollywood_img.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/bollywood_stars.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>About Bollywood Artist Management</h2>
          </div>
          <p align="justify">If we talk about today’s era then Bollywood celebrities are the most loved ones and the trusted ones in the country. Therefore, managing bollywood celebrity is a vital task and a very huge one. The events, launch, shows, parties, weddings, endorsement and many more, there are endless management things that are required for bollywood celebrity.</p>
          <br/>
          <p align="justify">Recently, <strong>bollywood celebrity endorsement</strong> has reached another level and has become a major part of marketing. Even in weddings and shows in different cities are too much in trend. Rightly said by someone “with fame comes responsibility and pressure” now to manage this, every celebrity needs a helping hand or we can say a “manager”. There are plenty of bollywood celebrity management companies, but as this is a work that needs to be handed over in responsible hands therefore, a need for a renowned and reputed company is a must, like BollywoodisGood.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Our Role</h2>
          </div>
          <p align="justify">We know that fact that with every move of a bollywood celebrity is being noticed and can either add stars to the reputation or ruin it; therefore, we have hired professional managers. They have years of experience and knows very well how to handle every activities of bollywood celebrities. BiG is known as a bollywod celebrity management company and has management very huge projects as well as worked for some renowned bollywood celebrities. We provide proficiency based solution and Bollywood Celebrity Management Services with perfection.</p>
          <br />
          <p align="justify">Our team of celebrity management handles Store launch, celebrity promotional events for a particular film or product launch, award shows, ribbon-cutting ceremony, campaigning, celebrity parties or weddings, brand endorsement, international shows where celebrities are the centre of attraction for their fans. We have years of experience of managing such a big events with professionalism from decades. We are extremely skilled in arranging such demanding events in an efficient and competent manner.</p>
       
          </div>
          <div class="col-sm-5">
          <img src="images/our_role_img.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>          

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
          <h2>Bollywood Celebrity Management Services</h2>
          <p>At BiG, we can organize top bollywood stars for events, brand endorsement &amp; promotions, appearance and opening ceremony. Besides arranging celebrity for you we also take care of following requirements like:</p>
          </div>
          <div className="clearfix">
          <ul>
          <li>
          <div class="service_bx text-center">
          <img src="images/dates.webp" alt="BollywoodisGood is the leading bollywood celebrity management services in Mumbai." />
          <h6>Their Dates</h6>
          </div>
          </li>
          <li>
          <div class="service_bx text-center">
          <img src="images/travel1.webp" alt="BollywoodisGood is the best Bollywood celebrity endorsement company in Mumbai." />
          <h6>Travelling &amp; accommodation needs</h6>
          </div>
          </li>
          <li>
          <div class="service_bx text-center">
          <img src="images/negociate.webp" alt="img" />
          <h6>Negotiations in the rate packages</h6>
          </div>
          </li>
          <li>
          <div class="service_bx text-center">
          <img src="images/bookings.webp" alt="img" />
          <h6>Bookings on Priority</h6>
          </div>
          </li>
          <li>
          <div class="service_bx text-center">
          <img src="images/comfort.webp" alt="img" />
          <h6>Comfort of the celebrity</h6>
          </div>
          </li>
          </ul>
          <p>We even take care of the reputation of the bollywood celebrity and then book any event or endorsement for them. In this industry it’s hard to maintain the reputation but easy to destroy it. Therefore, our managers first study every pros and cons before taking any decision for bollywood celebrity. The demand of Bollywood celebrities for Store launch, celebrity promotional events for a particular film or product launch, award shows, ribbon-cutting ceremony, campaigning, celebrity parties or weddings, brand endorsement etc has risen so much that it becomes a fully loaded and very responsible task for our managers to perform. Hence, we have only professionals with us, who knows nitty-gritty of the area and how to fulfill every task easily.</p>
          </div>
          </div>
          </section>

          
        <section id="last_info">
        <div class="container">
        <div class="row">
        <div class="col-xs-12">
        <blockquote>
        <p align="justify">We feel happy that we as one of the best Bollywood celebrity management company we have worked with numerous popular faces of the industry and when they give positive feedback to us, that becomes the moment of pride for us. We have very good clientele as every celebrity who has hired us has experienced complete satisfaction regarding the services we offer. Therefore, with the best marketing strategy “word of mouth” we have gained a reputation and returning clients. Our aim is to maintain long term relationship with the client and make sure that they feel safe and comfortable with us. Our loyalty towards our every client has made us one of the reliable companies in the industry. So, get in touch with us now and experience the unbeatable satisfaction.</p>
        </blockquote>
        </div>
        </div>
        </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default BollywoodCelebrity;
