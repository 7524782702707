import React from "react";
import Select from "react-select";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import cityStateConst, { countries } from "../CreateUserProfile/cityStateConst";
const StateOptions = _.map(cityStateConst, (val) => ({
    label: val.state,
    value: val.state,
}));

const JobLocation = ({
    handleChangeDropDown,
    handleChangeSingle,
    prevStep,
    nextStep,
    PostJobSubmit,
    ...props
}) => {

    const districtArray = _.find(cityStateConst, {
        state: props.currentState,
    }) ?.districts;

    const districtOptions = _.map(districtArray, (val) => ({
        label: val,
        value: val,
    }));

    return (
        <>
            <div >
                <div className="tab_head">
                    <h4>Job Location </h4>
                    <hr />
                </div>
                <div class="row">
                    <div className="col-sm-6 form-group">
                    <label>Country</label>
                        <Select
                            options={countries}
                            value={{
                                value: props.currentCountry,
                                label: props.currentCountry,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "currentCountry",
                                    value: e.label,
                                })
                            }
                        />
                        
                    </div>

                    <div className="col-sm-6 form-group">
                    <label>State</label>
                        <Select
                            options={StateOptions}
                            value={{
                                value: props.currentState,
                                label: props.currentState,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "currentState",
                                    value: e.label,
                                })
                            }
                        />
                        

                    </div>

                    <div class="col-sm-6">
                    <label>City</label>
                        <Select
                            options={districtOptions}
                            value={{
                                value: props.currentCity,
                                label: props.currentCity,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "currentCity",
                                    value: e.label,
                                })
                            }
                        />
                       
                    </div>
                    <div className="col-sm-6 form-group">
                        <label>Zip code</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="currentZipCode"
                            value={props.currentZipCode || ""}
                            onChange={(e) => {
                                vlidateNumberField(e) && handleChangeSingle(e);
                            }}
                        />
                    </div>

                    {props.category === 'Artist or Actor or Talent' && (
                        <>
                            <div className="col-sm-12 form-group radio_btn inline_feild">
                                <br />
                                <p>
                                    {" "}
                                    <strong>
                                        Do you want to conduct audition for this job ?
                      </strong>
                                </p>
                                <label className="check_bx">
                                    <input
                                        type="radio"
                                        value="checkbx1"
                                        name="conductAuditions"
                                        checked={props.conductAuditions}
                                        onClick={() =>
                                            handleChangeSingle({
                                                target: { value: true, name: "conductAuditions" },
                                            })
                                        }
                                    />
                                    Yes
                      <span className="checkmark"></span>
                                </label>
                                <label className="check_bx">
                                    <input
                                        type="radio"
                                        name="conductAuditions"
                                        checked={!props.conductAuditions}
                                        onClick={() =>
                                            handleChangeSingle({
                                                target: { value: false, name: "conductAuditions" },
                                            })
                                        }
                                    />
                                    No
                      <span className="checkmark"></span>
                                </label>
                            </div>
                        </>
                    )}

                    <div className="col-sm-12">
                        <hr />
                        <br />
                        <button className="blue_btn" onClick={prevStep}>Back</button>
                        <button style={{ marginLeft: "15px" }}
                            type="submit"
                            className="blue_btn"
                            onClick={(e) => PostJobSubmit(e, false)}
                        >
                            {" "}
                            Save{" "}
                        </button>
                        <button className="blue_btn pull-right" onClick={() => nextStep()} >Continue</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobLocation;