import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import converter from 'number-to-words';

const borderColor = '#555'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 11,
    },
    description: {
        width: '60%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
        height:24,
        // fontStyle: 'bold',
        fontFamily: "Helvetica-Bold"
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        flexDirection:'column',
        // paddingTop: 10,
        height: 24,
        fontWeight:300
    },
    totalLast: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
        flexDirection:'column',
        height: 24,
        fontWeight:300
    },
    totalInWord:{
        width: '65%',
        textAlign: 'left',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        flexDirection:'column',
        height: 24,
        fontWeight:300,
        textTransform:'capitalize'
    },
    bottomTable:{
        border:1,

    },

  });


const InvoiceTableFooter = ({items,payee,payer}) => {
    const total = items.map(item =>  item.netAmount)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0);
    // const totalNetAmount = items.map(item => (item.netAmount ))
    //     .reduce((accumulator, currentValue) => accumulator + currentValue , 0);
    // const totalTaxPercentage = items.map(item => (item.gstPercent ))
    //     .reduce((accumulator, currentValue) => accumulator + currentValue , 0);
    const totalTax = items.map(item => (payer?.state === payee?.state  ? (item.netAmount * item.taxRate.igst) / 100 : ((item.netAmount * item.taxRate.cgst)/100) +( (item.netAmount * item.taxRate.sgst)/100)))
    .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    return(    
       <>
        <View style={styles.row}>
            <Text style={{...styles.total,width:'5%'}}></Text>
            <Text style={styles.description}>Total Amount</Text>
           
            <Text style={styles.total}></Text>
            <Text style={{...styles.totalLast,fontFamily: "Helvetica-Bold"}}>{ Number.parseFloat(total+totalTax).toFixed(2)}</Text>
        </View>
        <View>
        {/* <View style={{...styles.row,borderBottomWidth:0,fontSize:12}}>
            <Text style={{paddingLeft:10, marginTop:60, height:50,}}>Total Amounts (in words) : <Text style={{fontSize:10,textTransform:'capitalize'}}>{converter.toWords(total+totalTax)}</Text></Text>
        </View> */}
             <View style={{ ...styles.row, borderBottomWidth: 0 }}>
          <Text
            style={{
              ...styles.description,
              width: "35%",
              borderBottomWidth: 0,
            }}>
            Amounts in words
          </Text>
          <Text style={{ ...styles.totalInWord, borderBottomWidth: 1 }}> {converter.toWords(total+totalTax)}</Text>
          {/* <Text
            style={{
              ...styles.totalLast,
              width: "14%",
              borderBottomWidth: 1,
            }}></Text> */}
        </View>
        <View style={{paddingLeft:10,marginTop:25}}>
            <Text style={{textAlign:'center'}}>
            Note : Whether the tax is payable on reverse charges :- No
            </Text>
        </View>
        
        </View>
       </>
    )
};
  
  export default InvoiceTableFooter