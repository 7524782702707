const currency =[
    {
        country:'India',
        currency: 'INR'
    },
    {
        country:'Other',
        currency: 'USD'
    }
]
export default currency;