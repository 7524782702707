import React, { Component } from 'react';
import JobListPage from '../../pages/JobList/JobListPage';
import { getJobList, deleteJobPost, postJob } from '../../actions/index';
import _ from 'lodash';
let timer = "";

class JobListPageContainer extends Component {
    state = { filters: [{ type: 'visibility', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }], search: '' };

    resetAllFilters = () => {
        this.setState({ filters: [{ type: 'visibility', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }], search: '' }, () => this.getJobList(""));

    }
    onChangeSearch = search => {
        this.setState({ search });
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.getJobList(search);
        }, 300);
    }

    componentDidMount() {
        this.getJobList("");
        const userString = localStorage.getItem('user');
        const user = userString ? JSON.parse(userString) : null;
        this.setState({user:user})
    }

    getJobList = search => {
        getJobList({ filters: JSON.stringify(_.filter(this.state.filters, val => val.value != 'All')), search: search,sort:JSON.stringify(this.state.sort) })
            .then(res => {
                this.setState({ data: res.data ?.data[0] ?.search });
            })
    };

    handleChangeDropdown = (e) => {
        this.setState({ [e.name]: e.value })
    }

    removePost = (id) => {
        deleteJobPost(id)
            .then(res => this.getJobList(this.state.search));
    }

    onChangeFilter = e => {

        let { filters, search } = this.state;
        const alreadyPresent = _.find(filters, { ...e });
        if (_.isEmpty(alreadyPresent)) {
            this.setState({ filters: _.uniqBy([e, ...filters], 'type') }, () => this.getJobList(search));
            console.log(e, filters, 'gsfdfd')
        }
        else {
            this.setState({ filters: _.filter(filters, val => val.type != e.type) }, () => this.getJobList(search));
            console.log(e, filters, 'dfded')
        }
    }

    publishPost = (id) => {
        postJob({ _id: id, published: true })
            .then(res => {
                this.getJobList(this.state.search);
            })
    };
    onChangeSort = (e)=>{
let { search } = this.state;
        this.setState({ sort:e}, () => this.getJobList(search));
    }
    defaultProps = {
        placeholder: "Search Languages"
    }

    render() {
        return <JobListPage
            {...this.state}
            defaultProps={this.defaultProps}
            handleChangeDropdown={this.handleChangeDropdown}
            resetAllFilters={this.resetAllFilters}
            onChangeFilter={this.onChangeFilter}
            onChangeSearch={this.onChangeSearch}
            removePost={this.removePost}
            publishPost={this.publishPost}
            onChangeSort={this.onChangeSort}
        />
    }
}

export default JobListPageContainer;