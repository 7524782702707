import React, { useState } from "react";
import _ from "lodash";
import { config } from "../../actions/config";
import FileInput from "../FileCropper/FileInput";
import ImageCropper from "../FileCropper/FileCropper";

const ApplicationForm = ({ handleChangeValues, jobPostDetail,fileSelectedHandler,...props }) => {
  const [image, setImage] = useState("");
    const [imageName, setImageName] = useState("");
    const [currentPage, setCurrentPage] = useState("choose-img");
  
    // Invoked when new image file is selected
    const onImageSelected = (selectedImg,name) => {
      setImage(selectedImg);
      setCurrentPage("crop-img");
      setImageName(name)
    };
    // Handle Cancel Button Click
    const onCropCancel = () => {
      setCurrentPage("choose-img");
      setImage("");
    };
    const onCropDone = (img) => {
      fileSelectedHandler({target:{name: imageName, files:[img]}})
      setCurrentPage("choose-img");
    }
  return(
  <div className="shadow_bg info_bx clearfix application_from">
    <h5 style={{background:"#c62537"}}>
      <strong>Introduce Yourself</strong>
    </h5>
    <p>What makes you a strong candidate for this job?</p>
    <hr />
    <div className="form-group">
      <label>
        <strong>Message to Client</strong>
        <p>
          Describe some of your experiences that make you a great candidate for
          this job. Include any questions you may have about this job.
        </p>
      </label>

      <textarea
        onChange={(e) =>
          handleChangeValues({ name: "messegeToClient", value: e.target.value })
        }
        className="form-control"
      ></textarea>
    </div>

    {
      _.map(jobPostDetail.screeningQuestions, (val, index) => (
        <div className="form-group">
          <label>
            <strong>{val}</strong>
          </label>
          <textarea
            onChange={(e) =>
              handleChangeValues({
                name: "answers",
                index: index,
                value: e.target.value,
              })
            }
            className="form-control"
            placeholder=""
          ></textarea>
        </div>
      ))
    }

    <div className="form-group">
            <label>Video URL</label>
            <input type="text" className="form-control" placeholder="Enter url" name='videoUrl' onChange={handleChangeValues} />
          </div>
          <div className="form-group file_input">
          <p>
            <strong>Attach File</strong>
          </p>
          <div className="row">
            <div className="col-md-4">
              {/* <input
                type="file"
                className="form-control"
                placeholder=""
                id="userImage1"
                name="userImage1"
                accept="image/*"
                onChange={(e)=>{fileSelectedHandler(e)}}
              /> */}
              <label for="userImage1">
              {currentPage === "choose-img" ? (
                <FileInput
                  setImage={setImage}
                  onImageSelected={onImageSelected}
                  label={" Upload Photo1"}
                  name={"userImage1"}
                />
              ) : currentPage === "crop-img" ? (
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                  
                />
              ) : (
                ""
              )}
                </label>
              {props.userImage1 &&  <>
                            <img src={config.FILE_URL+ props.userImage1} style={{height:100,borderRadius:10}}/><br/>
                        </> }
            </div>
            <div className="col-md-4">
              {/* <input
                type="file"
                className="form-control"
                placeholder=""
                id="userImage2"
                name="userImage2"
                accept="image/*"
                onChange={(e)=>{fileSelectedHandler(e)}}
              /> */}
              <label for="userImage2">
              {currentPage === "choose-img" ? (
                <FileInput
                  setImage={setImage}
                  onImageSelected={onImageSelected}
                  label={" Upload Photo2"}
                  name={"userImage2"}
                />
              ) : currentPage === "crop-img" ? (
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                  
                />
              ) : (
                ""
              )}
              </label>
              {props.userImage2 &&  <>
                            <img src={config.FILE_URL+ props.userImage2} style={{height:100,borderRadius:10}}/><br/>
                        </> }
            </div>
            <div className="col-md-4">
              {/* <input
                type="file"
                className="form-control"
                placeholder=""
                id="userImage3"
                name="userImage3"
                accept="image/*"
                onChange={(e)=>{fileSelectedHandler(e)}}
              /> */}
              <label for="userImage3">
              {currentPage === "choose-img" ? (
                <FileInput
                  setImage={setImage}
                  onImageSelected={onImageSelected}
                  label={" Upload Photo3"}
                  name={"userImage3"}
                />
              ) : currentPage === "crop-img" ? (
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                  
                />
              ) : (
                ""
              )}
              </label>
              {props.userImage3 &&  <>
                            <img src={config.FILE_URL+ props.userImage3} style={{height:100,borderRadius:10}}/><br/>
                        </> }
            </div>
          </div>
          <p>Upload your 3 different images as per character brief</p>
        </div>
  </div>
)};

export default ApplicationForm;
