import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class SportsCelebrityManagementCompany extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>Sports Celebrity Management Company</h1>
                  <p align="justify">Once an unknown concept sports celebrity management has now become a buzz. Managing sports celebrity is a task, because the brand should match with the personality who is endorsing it. As sports of every country are very close to its citizens hence, the sports players are loved by them.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/sports-celebrity-management-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/sports-celebrity-marketing.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Sports Celebrity Management Services</h2>
          </div>
          <p align="justify">Now to manage these sports celebrity is a very serious job, as the reputation of the player and brand depends on how the company manages the both. It has been seen with the time that the trust and inclination people have towards sports celebrities is remarkable. Therefore, when they endorse any brand, the people even start rusting that brand just because of the sports person endorsing it.</p>
          <br/>
          <p align="justify">It has been noticed that people who are not very much into sports, start liking a sports celebrity through the advertisement they do for any brand, therefore, with the sport celebrity endorsement not only the brand gets the benefit but the sport celebrity also gets the fame. There are so many individuals who don’t like to watch sports but when they look any sports celebrity in ads, they certainly ask for him/her and start following the celebrity which again helps in increasing the value of the celebrity.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Sports Celebrity Event Management</h2>
          </div>
          <p align="justify">When any sports celebrity endorses any brand, the value of the sport celebrity gets linked with the brand that helps the brand to grow. It’s very important to for any sport celebrity to endorse a brand that not only enhances the value of brand and the celebrity also and managing this is a very serious job. There are many companies that provide services of sports celebrity management but it is very important to choose the one that has the good reputation and name in the industry, otherwise not only brand but the investment of the brand suffers a lot.</p>
          <br />
          <p align="justify">Therefore, opting for any good company is a must like BollywoodisGood. BiG has a team of professional managers who understands the value and time of celebrities as well as the brand. We have provided our valuable services to many sports celebrities and have managed their stardom. After going through all the details of the profile and researching the value of the celebrity, we make sure that every endorsement they make or every event they visit always enhances their value.</p>
         
          </div>
          <div class="col-sm-5">
          <img src="images/sports-celebrity-event-management-agency.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
        <h2>Best Sports Celebrity Management Agency</h2>
        </div>
          <p align="justify">The BollywoodisGood is created with an aim to offer best services and gain long term relationship with every celebrity. We not only manage but strategically plan every move of the sport celebrity. As they are considered one of the most trusted part of the country, therefore there management needs to be super strong and we at BollywoodisGood work on that. Many top sports celebrities have hired us to manage their profile, events, endorsements and many other related activities and we have always fulfilled the requirements satisfactorily. We feel proud that the sports celebrities who have availed the services with us are giving positive feedbacks and recommendations to other fellow celebrities and this is not only enhancing our business but also giving us the confidence to work harder.</p>
        <br />
         <p align="justify">Many top sports celebrities have hired us to manage their profile, events, endorsements and many other related activities and we have always fulfilled the requirements satisfactorily. We feel proud that the sports celebrities who have availed the services with us are giving positive feedbacks and recommendations to other fellow celebrities and this is not only enhancing our business but also giving us the confidence to work harder.</p>
          </div>
          </section>   

        <section id="last_info">
        <div class="container">
        <div class="row">
        <div class="col-xs-12">
        <blockquote>
        <p align="justify">These feedbacks give us the idea that whatever ways we are using in Sports celebrity management is appropriate and is helping them in enhancing their value. Therefore, get in touch with us when you or any of your friends needs Sports celebrity management services. We are here to assist you and give you the best services. Experience the trust, professionalism, connections, links with good brands and ultimate services by availing the services with BollywoodisGood. You can leave your query at info@bollywoodisgood.com and we will connect with you within 48 hours.</p>
        </blockquote>
        </div>
        </div>
        </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default SportsCelebrityManagementCompany;
