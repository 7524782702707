import React from 'react';

const ClientInviteMsg = () => {
    return(
        <>
  <div className="shadow_bg clearfix message_part">
            <h5><strong>Original message from client</strong></h5>
            <hr />
            <p>Hello!</p>
            <br />
            <p>I'd like to invite you to take a look at the job I've posted. Please submit a proposal if you're available and interested.
            </p>
            <br />
            <p>Abhishek Gupta</p>
            </div>
        </>
    );
}

export default ClientInviteMsg;