import React, { useEffect, useState } from 'react'
import Header from '../../components/shared/header/Header'
import SubHeader from '../../components/shared/header/SubHeader'
import Footer from "../../components/shared/footer/Footer";
import { FaCreditCard } from "react-icons/fa";

const SubscriptionDetails = () => {

    const [plan, setPlan] = useState(null);
    const [gstDetails] = useState({
        igst: 18, // IGST percentage
        cgst: 0, // CGST percentage
        sgst: 0, // SGST percentage
    });
    // const [commissionRate] = useState(2); // Commission percentage
    const [payableAmount, setPayableAmount] = useState(0);

    useEffect(() => {
        const storedPlan = localStorage.getItem("selectedPlan");
        if (storedPlan) {
            const parsedPlan = JSON.parse(storedPlan);
            setPlan(parsedPlan);

            // Calculate amounts
            const amount = parsedPlan.amount;
            // const commission = (amount * commissionRate) / 100;
            const igst = (amount * gstDetails.igst) / 100;
            const cgst = (amount * gstDetails.cgst) / 100;
            const sgst = (amount * gstDetails.sgst) / 100;
            // const gstOnCommission =
            //     gstDetails.igst > 0
            //         ? (commission * gstDetails.igst) / 100
            //         : (commission * gstDetails.cgst) / 100 +
            //         (commission * gstDetails.sgst) / 100;

            const totalPayable = amount + igst + cgst + sgst ;
            setPayableAmount(totalPayable);
        }
    }, [gstDetails]);

    if (!plan) {
        return <p>Loading plan details...</p>;
    }

    return (
        <><div className="top_slide inner_header">
            <Header />
            <SubHeader />

            <section id="user_payment" className="mid_info inner_banner">
                <div className="container side_space">
                    <div className="row">
                        <div className="col-md-12 inner_banner_text text-center">
                            <h2>Make the payment for Subscription!</h2>
                        </div>
                    </div>
                </div>
            </section>
        </div>
            <div id="payment_mode" className="default_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="payment_area">
                                <ul class="nav nav-tabs" role="tablist" id="myTab">
                                    {/* <li class="active"><a href="#upipay" role="tab" data-toggle="tab"><FaCreditCard /> UPI Payment</a></li>
                                    <li><a href="#cardpayment" role="tab" data-toggle="tab"><FaCreditCard /> Big Wallet Payment</a></li> */}
                                    {/* <li><a href="#currentIssue" role="tab" data-toggle="tab"><FaCreditCard /> Bank Payment</a></li> */}
                                </ul>
                                <div class="tab-content">

                                    <div class="tab-pane active" id="upipay">
                                        <div className="form_outer">
                                            <div className="row">
                                                

                                                <div>
                                                    <h3 style={{ color: "#0548b3", fontWeight: "bold" }}>
                                                        Plan Details
                                                    </h3>
                                                    <hr />
                                                    <ul style={{ marginTop: "20px" }}>
                                                    <li style={{ marginTop: "5px" }}>
                                                    Plan : <strong style={{ paddingRight: "8px" }}>{plan.plan}</strong>
                                                        </li>
                                                        <li style={{ marginTop: "5px" }}>
                                                        Amount : <strong style={{ paddingRight: "8px" }}>₹ {plan.amount}</strong>
                                                        </li>
                                                        <li style={{ marginTop: "5px" }}>
                                                        Validity : <strong style={{ paddingRight: "8px" }}>{plan.validityInDays} Days</strong> 
                                                        </li>
                                                        <li style={{ marginTop: "5px" }}>
                                                        Eligible to apply Auditions : <strong style={{ paddingRight: "8px" }}>{plan.auditions}</strong> 
                                                        </li>
                                                        <li style={{ marginTop: "5px" }}>
                                                        Eligible to Hire new Talent : <strong style={{ paddingRight: "8px" }}>{plan.hireTalent}</strong> 
                                                        </li>
                                                        <li style={{ marginTop: "5px" }}>
                                                        Eligible to apply Job Applications : <strong style={{ paddingRight: "8px" }}> {plan.jobApplications}</strong>
                                                        </li>
                                                    </ul>




                                                </div>
                                                <hr />
                                                <div className="skills_part">
                                                    <h6>Payment Details</h6>
                                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                                                        <div className="item-label">Base Amount</div>
                                                        <div>₹ {plan.amount} </div>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className="item-label">IGST ({gstDetails.igst}%)</div>
                                                        <div>₹ {(plan.amount * gstDetails.igst) / 100}</div>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className="item-label">CGST ({gstDetails.cgst}%)</div>
                                                        <div>₹ {(plan.amount * gstDetails.cgst) / 100}</div>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className="item-label">SGST ({gstDetails.sgst}%)</div>
                                                        <div>₹ {(plan.amount * gstDetails.sgst) / 100}</div>
                                                    </div>
                                                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className="item-label">Commission ({commissionRate}%)</div>
                                                        <div>{(plan.amount * commissionRate) / 100}</div>
                                                    </div> */}
                                                    <hr />
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div style={{ fontWeight: "bold" }} className="item-label">Total Amount</div>
                                                        <div style={{ color: "#0548b3", fontWeight: "bold" }}>₹ {payableAmount.toFixed(2)}</div>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: "20px" }} className="col-md-12 form-group">
                                                    <button
                                                        type="submit"
                                                        className="blue_btn"
                                                        onClick={() => {
                                                            localStorage.setItem("payableAmount", payableAmount);
                                                            window.location.href = "#/Subscriptionpayment";
                                                        }}
                                                    >
                                                        Buy Now
                                                    </button>
                                                </div>

                                            </div>


                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer /></>
    )
}

export default SubscriptionDetails