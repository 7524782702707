import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#555";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#555",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
    height: "250px",
  },
  leftCell: {
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    flexDirection:'column',
    paddingTop: 3,
    paddingBottom:10,
    height:'100%'
  },
  rightCell: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
    flexDirection:'column',
    paddingTop: 3,
    paddingBottom:10,
    height:'100%'
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
    flexDirection:'column',
    paddingTop: 3 ,
    height:'100%'
  },
  normalFont:{
    fontFamily:'Helvetica',
    fontSize: '8px'
  }
});

const InvoiceTableRow = ({ items,payee,payer }) => {
  const rows = items.map((item, i) => (
    <View style={styles.row} key={i}>
        <View style={{ ...styles.rightCell, width: "5%" }}>
        <Text>{i + 1}</Text>
      </View>
     
      <View style={{ ...styles.leftCell, width: "45%" }}>
        <Text>{item.description}</Text>
        <Text style={{...styles.normalFont,marginTop:10}}>{"CGST"} {payer?.state !== payee?.state  && item.taxRate.cgst ? item.taxRate.cgst+'%' : "0%"}</Text>
        <Text style={styles.normalFont}>{"SGST"} <Text>{payer?.state !== payee?.state  && item.taxRate.sgst ? item.taxRate.sgst+'%' : "0%"}</Text></Text>
        <Text style={styles.normalFont}>{"IGST"} <Text>{payer?.state === payee?.state  && item.taxRate.igst ? item.taxRate.igst+'%' : "0%"}</Text></Text>
      </View>
      <View style={{ ...styles.rightCell, width: "15%" }}>
        <Text>{item.hsnCode}</Text>
      </View>
      <View
        style={{
          ...styles.rightCell,
          width: "15%",
          
        }}>
          <Text>{(item.netAmount).toFixed(2)}</Text>
        {/* <Text>{payer?.state !== payee?.state  && item.taxRate.cgst ? item.taxRate.cgst+'%' : "0%"}</Text>
        <Text>{payer?.state !== payee?.state  && item.taxRate.sgst ? item.taxRate.sgst+'%' : "0%"}</Text>
        <Text>{payer?.state === payee?.state  && item.taxRate.igst ? item.taxRate.igst+'%' : "0%"}</Text> */}
      </View>
      <View style={{ ...styles.amount, width: "20%",justifyContent: "flex-start",paddingBottom:10, }}>
        <Text>
          {(item.netAmount).toFixed(
            2
          )}
        </Text>
        <Text style={styles.normalFont}>{payer?.state !== payee?.state ? ((item.netAmount * (item.taxRate.cgst || 0)) / 100).toFixed(2):0}</Text>
          <Text style={styles.normalFont}>{payer?.state !== payee?.state ?((item.netAmount * (item.taxRate.sgst || 0)) / 100).toFixed(2):0}</Text>
          <Text style={styles.normalFont}>{payer?.state === payee?.state ? ((item.netAmount * (item.taxRate.igst || 0)) / 100).toFixed(2):0}</Text>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
