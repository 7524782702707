import React from "react";
import _ from "lodash";
import moment from 'moment';

const ShootAndLocations = ({ handleChangeDropDown, handleChangeSingle, prevStep, nextStep, PostAuditionSubmit, ...props }) => {

    return (
      <>
        <div>
          <div className="tab_head">
            <h4>Shoot and Locations</h4>
            <hr />
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label>Shoot Dates</label>

              <div className="row">
                {!props.discloseLaterShootLocations && (
                  <>
                    <div className="col-sm-4 col-xs-6">
                      <input
                        type="date"
                        className={`form-control ${
                          props.shootStartDate ? "" : "date-time-picker"
                        }`}
                        name="shootStartDate"
                        value={
                          moment(props.shootStartDate).format("YYYY-MM-DD") ||
                          " "
                        }
                        onChange={handleChangeSingle}
                      />
                    </div>
                    <div className="col-sm-4 col-xs-6">
                      <input
                        type="date"
                        className={`form-control ${
                          props.shootEndDate ? "" : "date-time-picker"
                        }`}
                        name="shootEndDate"
                        value={
                          moment(props.shootEndDate).format("YYYY-MM-DD") || " "
                        }
                        min={moment(props.shootStartDate).format("YYYY-MM-DD")}
                        onChange={handleChangeSingle}
                      />
                    </div>
                  </>
                )}
                <div className="col-sm-4 col-xs-12">
                  <label className="check_bx">
                    <input
                      type="checkbox"
                      value="checkbx"
                      name="checkField"
                      onClick={() =>
                        handleChangeSingle({
                          target: {
                            value: !props.discloseLaterShootLocations,
                            name: "discloseLaterShootLocations",
                          },
                        })
                      }
                    />
                    Disclose Latter
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group col-md-6 col-xs-12">
              <label className="control-label">Specific Role Criteria</label>
              <input
                type="text"
                name="specificRoleCriteria"
                className="form-control"
                placeholder="Ex. Swimming or Football player or Dance"
                value={props.specificRoleCriteria}
                onChange={handleChangeSingle}
              />
            </div>
            {/* 
                    <div className="form-group col-md-6">
                        <label className="control-label">
                            Competitor Brands (if any)
                  </label>
                        <input
                            type="text"
                            name="competitorBrands"
                            className="form-control"
                            placeholder="Enter brand"
                            value={props.brand}
                            onChange={handleChangeSingle}
                        />
                    </div> */}

            {/* <div className="form-group col-md-6">
              <label className="control-label">Shoot Locations</label>
              <input
                type="text"
                name="shootLocations"
                className="form-control"
                placeholder="Mumbai, Jaipur, Newyork, Washington...etc"
                value={props.shootLocations}
                onChange={handleChangeSingle}
              />
            </div> */}

            <div className="col-sm-6 col-xs-12 form-group radio_btn inline_feild">
              <p>
                {" "}
                <strong>Passport </strong>
              </p>
              <label
                for="yes"
                onClick={() =>
                  handleChangeSingle({
                    target: { name: "passportRequired", value: true },
                  })
                }>
                Yes
                <div className="switch">
                  {props.uploadImageNeeded ? (
                    <input
                      type="radio"
                      id="yes"
                      value="hr"
                      name="btns"
                      checked
                    />
                  ) : (
                    <input type="radio" id="yes" value="hr" name="btns"   checked={props?.passportRequired}/>
                  )}
                  <span class="slider_check round"></span>
                </div>
              </label>
              <label
                for="no"
                onClick={() =>
                  handleChangeSingle({
                    target: { name: "passportRequired", value: false },
                  })
                }>
                No
                <div className="switch">
                  <input type="radio" id="no" value="fixed" name="btns" checked={!props?.passportRequired}/>
                  <span class="slider_check round"></span>
                </div>
              </label>
            </div>
            <div className="col-sm-12 col-xs-12">
              <hr />
              <button className="blue_btn" onClick={prevStep}>
                Back
              </button>
              <button
                className="blue_btn pull-right"
                onClick={() => nextStep()}>
                Next
              </button>
              <button
                className="blue_btn pull-right"
                style={{ marginRight: "10px" }}
                onClick={(e) => PostAuditionSubmit(e, false)}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default ShootAndLocations;