import React, { useState } from "react";
import axios from "axios";
import { FaRegCreditCard } from "react-icons/fa";
import { config } from "../../actions/config";
import { Modal } from "react-bootstrap";

const WalletPayButton = ({contractId,description,payeeId,paybleAmount , onPaymentDone, isDisabled, amountWithouTax,handleShowModal,showModal, handleClose, walletBalance, disablePaymentOption})=> {
  
    const [disableButton, setDisableButton] = useState(false);

const makepayment = async ()=>{
    setDisableButton(true);
    const userString = localStorage.getItem('user')
    const user = userString? JSON.parse(userString):null

    const tnxId = (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2);
    const data = {
        "payer": user._id,
        "payee": payeeId,
        "amount": paybleAmount,
        "transactionId": 'bw'+tnxId,
        "contractId":contractId,
        "status": true,
        "description": description,
        "amountWithouTax":amountWithouTax,
        "paymentMethod" : 'bigWallet'
    };

    try {
        const result = await axios.post(config.BASE_URL+"/hire/makepayment", data).catch(e =>{});
        onPaymentDone(result?.data?.data)
         setDisableButton(false);
    } catch (error) {
          setDisableButton(false);
    }
}
  
    return (
        <>
        <button disabled={disablePaymentOption}
          onClick={handleShowModal}
          className={`milestone-status blue_btn `}>
          {"Pay using Big Pocket"}
        </button>
        <Modal show={showModal} onHide={handleClose} animation={false} backdrop={false} className="alert_box">
        <div>
        <Modal.Header closeButton style={{background:"#c62537"}}>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="skills_part" style={{display: 'flex', justifyContent: 'center'}}>
            
            <h4>Available Balance In My Pocket ({walletBalance || 0 }) <small>INR</small> </h4>
            <br />
            {walletBalance < paybleAmount  && <p className="text-danger">Not have enough balance in BIG Wallet</p>}
            <br />
  
          </div>
        </Modal.Body>
        <Modal.Footer>
       {walletBalance >= paybleAmount ? <button disabled={isDisabled || disableButton} src={process.env.PUBLIC_URL+'icon/razorpay.png'} height={50} className="razorpay-btn" alt="logo" style={{cursor:'pointer'}} onClick={makepayment} >
                    <FaRegCreditCard /> <span style={{marginLeft: 8}}> Make payment</span>
                </button>
                : 
                <button disabled={true} src={process.env.PUBLIC_URL+'icon/razorpay.png'} height={50} className="razorpay-btn" alt="logo" style={{cursor:'pointer'}}  >
                    <FaRegCreditCard /> <span style={{marginLeft: 8}}> Make payment</span>
                </button>}
        </Modal.Footer>
        </div>
      </Modal>
        </>

    );
}

export default WalletPayButton;