import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchPeople from './SearchPeople';
import AuditionInvitedPeople from './AuditionInvitedPeople';
import AudiSavedPeople from './AudiSavedPeople';
import { FaSearch } from 'react-icons/fa';

const InviteforAudition = ({ onUserSearch, ...props }) => {
     return (
          <div className="tab-pane" id="tab_default_2">
               <div className="row">
                    <div className="col-md-6 col-sm-7">
                         <ul className="nav nav-tabs">
                              <li className="active"><Link to="#list_tab1" data-toggle="tab">Search</Link></li>
                              <li><Link to="#list_tab2" data-toggle="tab">Invited Peoples ({props ?.AuditionDetail ?.invites ?.length})</Link></li>
                              {/* <li><Link to="#list_tab3" data-toggle="tab">Saved Peoples ({props ?.AuditionDetail ?.savedUsers ?.length})</Link></li> */}
                         </ul>
                    </div>
                    <div className="col-md-6 col-sm-5">
                         <div class="form-group search_field dark_search text-right">

                              <input onChange={(e) => onUserSearch(e.target.value)} type="search" placeholder="Search" class="form-control" />
                              <button type="button" class="default_btn"><FaSearch style={{color:"#474646"}} /></button>
                         </div>
                    </div>
               </div>

               <div className="tab-content">
                    <SearchPeople {...props} />
                    <AuditionInvitedPeople {...props} />
                    <AudiSavedPeople {...props} />
               </div>

          </div>

     );
}
export default InviteforAudition;