import React, { Component } from "react";
import ApplyAuditionPage from "../../pages/ApplyAudition/ApplyAuditionPage";
import { getAuditionDetails, createAuditionApplication, uploadFiles } from '../../actions/index';
import swal from 'sweetalert';
import Popup from "./Popup";

class ApplyAuditionContainer extends Component {
  state = {
    step: 1,
    auditionDetails: {},
    introductionVideoUrl: "",
    scriptVideoUrl: "",
    askQueries: "",
    auditionId: this.props.match?.params?.id,
    showPopup: false, // State to control the Popup visibility
  };

  handleChangeValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    if (this.props.match?.params?.id) {
      getAuditionDetails(this.props.match.params.id)
        .then((res) => this.setState({ auditionDetails: res.data?.data }));
    }
  }

  handleSubmit = (e, published) => {
    console.log(e, "sdwwd");
    e.preventDefault();

    createAuditionApplication({ ...this.state, published })
      .then((res) => {
        this.props.history.push('/SearchAudition'); // Navigate on success
        swal(`Audition Applied Successfully`, { buttons: false, timer: 2000, icon: 'success' });
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          const errorMessage = error.response.data.Error;
          if (errorMessage) {
            swal({
              title: "Access Exclusive Features!",
              // icon: "error",

              content: {
                element: "div",
                attributes: {
                  innerHTML: `
                   
                  <p style="text-align: center;font-size:18px">Subscribe now to unlock premium opportunities.
                     Or refer a friend and earn credits to access BiG for free!</p>`,
                  // Center the text❌😟⚠️🚫 ${errorMessage} <div style="font-size: 40px; margin-bottom: 25px;">❌</div>
                  // Subscribe now to unlock premium opportunities.Or refer a friend and earn credits to access BiG for free!
                },
              },
              buttons: {
                aaaa: {
                  text: "Refer a Friend",
                  value: "referal",
                  visible: true,
                  className: "blue_btn",
                },
                bbbb: {
                  text: "Subscribe Now",
                  value: "renew",
                  visible: true,
                  className: "blue_btn",
                },
              },
            }).then((value) => {
              if (value === "referal") {
                window.location.href = "#/Referal";
              }
              if (value === "renew") {
                // Show the Popup component instead of navigating
                this.setState({ showPopup: true });
              }
            });
            setTimeout(() => {
              const modal = document.querySelector(".swal-modal");
              const closeButton = document.createElement("button");
              closeButton.innerHTML = "&times;";
              closeButton.style.position = "absolute";
              closeButton.style.top = "5px";
              closeButton.style.right = "5px";
              closeButton.style.background = "transparent";
              closeButton.style.border = "none";
              // closeButton.style.borderRadius = "50%";
              closeButton.style.fontSize = "30px";
              closeButton.style.cursor = "pointer";
              modal.appendChild(closeButton);
              closeButton.addEventListener("click", () => swal.close());
            }, 0);
          }
        }
      });
  };

  closePopup = () => {
    this.setState({ showPopup: false }); // Close the Popup
  };

  fileSelectedHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const configs = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const targetName = e.target.name;
    try {
      const res = await uploadFiles(formData, configs);
      if (res && res.data && res.data.data) {
        this.setState({ [targetName]: res.data.data.filename });
      }
    } catch (ex) {
      console.log('upload error', ex);
    }
  };

  render() {
    return (
      <>
        <ApplyAuditionPage
          {...this.state}
          {...this.props}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChangeValues={this.handleChangeValues}
          handleSubmit={this.handleSubmit}
          fileSelectedHandler={this.fileSelectedHandler}
        />
        {/* Conditionally render Popup */}
        {this.state.showPopup && <Popup
          closePopup={this.closePopup} />}
      </>
    );
  }
}

export default ApplyAuditionContainer;
