import React from 'react';
import {Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import logo from '../../images/logo_1.png'

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        backgroundColor:'#3275b8',
        height: 20,
        fontWeight:'bold',
        display:'flex',
        position:'relative',
        border: '1px sloid #3275b8'
    },
    titleContainer0:{
        position:'absolute',
        top:50,
        width:'100%',
        height: 20,
        display:'flex',
        justifyContent:'center',
        alignItems:'flex-end' 
    },
    reportTitle:{
        color: '#FFF',
        fontSize: 12,
        textAlign: 'center',
    }
  });


  const InvoiceTitle = ({title}) => (
      <>
    <View style={{...styles.titleContainer,height: 52,}}>
        {/* <Text style={styles.reportTitle}>Bollywood is Good</Text> */}
        <Image src={logo} style={{height: 50}}/>
    </View>
    <View style={{...styles.titleContainer,...styles.titleContainer0}}>
       <Text style={{color:'#fff' }}>Tax Invoice</Text>
    </View>
      </>
    
  );
  
  export default InvoiceTitle