import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class AudioBooksInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>BOOKS & AUDIOBOOKS</h1>
                  <p align="justify">Books and audible audiobooks are gaining popularity in the market. The love for books and the demand for them will never lose its existence. Hence if you are a brand/business that deals in books and audible audiobooks then you need to market your brand very thoughtfully. The world is filled with readers and they are always searching for a new book to read, therefore choosing the influencer marketing tool will benefit you tremendously.</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/books-audio-books-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/book-brand-promotions.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Product Branding</h2>
          </div>
          <p align="justify">BollywoodisGood is one of the most renowned and trusted book influencer agencies that will help you throughout the process from finding the influencer, choosing the right influencer to create the best campaign for you. Approximately 600,000 and 1,000,000 publish every year, so your brand/business needs to stand out then only you will be able to grab the attention of the audience and make your brand popular.</p>
          <br />
          <p align="justify">Our team of experts find the right influencer for you, get in touch with them, coordinate and collaborate and make sure that the influencer has plenty of followers and engagement before assigning him/her the project. On the other hand, our team will create a successful campaign for your brand/business that will enhance your online presence and will hit the target audience. In return, you will see an increase in your ROI, sales, and popularity.</p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="last_info">
            <div class="container">
            <div class="row">
            <div class="col-xs-12">
            <blockquote>
            <p align="justify">BollywoodisGood team will share a detailed report that will show you the exact benefit your brand has received from the campaign as well as from the influencer. After getting the details like the aim of the brand, what are you offering, who is your target audience, and other related details, we take a step forward and design the influencer campaign for your brand/business. By joining hand with us your brand/business will get unmatched benefits as well as the potential audience, online presence, profits, and great brand value.</p>
            </blockquote>
            </div>
            </div>
            </div>
            </section>
           
        <Footer />
      </>
    );
  }
}

export default AudioBooksInfluencer;
