import React from "react";
import { Link } from 'react-router-dom';
import { FaSuitcase, } from 'react-icons/fa';
import { JobCategory } from '../JobPost/jobPostConst';
import Select from "react-select";
import _ from 'lodash';
import { loadOptionsResolveLocation } from '../../Constants.js/districtConst';
import AsyncSelect from 'react-select/async';
import { Placeholder } from '../../commons/ReactSelectNoOptionsMessage';
import { locationData } from "../../Constants.js/districtConst";
const cityOptions = []

export const FilmType = [
  { label: "Choose Film Type", value: "Choose Film Type" },
  { label: "Movie", value: "Movie" },
  { label: "Web Series", value: "Series" },
  { label: "Short Film", value: "Short Film" },
  { label: "TV Show", value: "TV Show" },
  { label: "Animated Film", value: "Animated Film" },
]



const MakeFilmJobCat = ({ onChangeValues, location, onAddNewUser, ...props }) => {

  return (
    <div className="row">      
      <div className="col-md-12">
      <Select
        components={{ Placeholder }}
        placeholder={FilmType[0].value}
        options={FilmType}
        onChange={e => onChangeValues({ name: 'filmType', value: e.label })}
        // value={{ label: props.filmType, value: props.filmType }}

      />
        <div className="form_light_bg clearfix">
          <div className="form-group col-md-3 no_padding">
            <input
              type="text"
              placeholder="Type Project Name"
              className="form-control"
              value={props.title}
              onChange={e => onChangeValues({ name: 'title', value: e.target.value })} />
          </div>
          {/* <div className="form-group col-md-3 no_padding"> */}

          {/* </div> */}
          <div className="form-group col-md-3 no_padding">
            <Select
              components={{ Placeholder }}
              placeholder={'Select Location'}
              options={locationData}
              onChange={e => onChangeValues({ name: 'location', value: e.value })}
            // value={{ label: props.location, value: props.location }}

            />
          </div>


          <div class="dropdown film_cat_menu col-md-3 no_padding">
            <Link to="#" role="button" data-toggle="dropdown" class="blue_btn" data-target="#">
              Job Category <FaSuitcase />
            </Link>
            <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">

              {
                _.map(JobCategory, val => (
                  <li><Link onClick={() => props.getUsersOnHover(val.label)} data-toggle="modal" data-target="#job_cat">{val.label}</Link></li>
                ))
              }


            </ul>
          </div>

        </div>
      </div>
    </div>
  );
}

export default MakeFilmJobCat;