import React, { Component, useEffect, useState } from 'react';
import {
  FaMapMarker, FaStarHalf, FaStar,
  FaRegEnvelope,
  FaPhoneAlt,
  FaUser,
  FaSuitcase,
  FaUsers,
  FaLocationArrow,
  FaFilm,
} from 'react-icons/fa';
import { Link } from "react-router-dom";
import { config } from '../../actions/config';
import { getMeritScore } from '../../functions/meritCalculation';

const HireTalentHeader = ({ user,loginUser }) => {
  const [meritScore, setMeritScore] = useState(0);
  useEffect(() => {
    if(user && user._id){
     const MF =  getMeritScore(user)
     const score = MF.map(e=>e.progress).reduce((a,b)=> a+b)/MF.length
     setMeritScore(score.toFixed(0))
    }
  },[user])
  return (
    <div className="clearfix people_detail_banner your_page">
      <div className="container">
        <div className="row">
          <div className="col-md-2 col-sm-3">
            <div className="applicant_img">
              <img src={user.profilePic ? config.FILE_URL+ user.profilePic : 'images/fill_user.png'} alt="img" /><span class="online"><div class="circle_icon"></div> </span>
            </div>
          </div>
          <div className="col-md-7 col-sm-9 people_center_info">
            <div className="row">
              <div className="add_list col-md-12">
                <div className="clearfix people_header">
                  <h3>{user.userName}
                    <div className="rating">
                      <div className="star">
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStarHalf />
                      </div>

                    </div> </h3>
                  <br />
                  <small style={{marginBottom:10}}>{user ?.profession}</small>
                  <small><FaMapMarker /> {user.city}</small>
                  <p>Address: {user.address}</p>
                  <small>Languages Speak : {user.languagesSpeak ?.join(', ')}</small>
                  <div style={{paddingTop:10}}>
                 {/* <small>Rate : {user?.currency} {user ?.additionalInfo ?.currentCompensationAmount}/{user ?.additionalInfo ?.currentCompensationType}</small> */}
                  </div>
                  <div className="bottom_desc">
                  <div className="progress default_progress">
                      <div  className="progress-bar" role="progressbar" aria-valuenow="70"
                        aria-valuemin="0" aria-valuemax="100" style={{ width: meritScore+"%" ,background: 'green'}}>
                        <span>{meritScore}% Merit Score</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HireTalentHeader;