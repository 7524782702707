import React from 'react';
import { Link } from 'react-router-dom';
import { FaPaperPlane, FaPaperclip, } from 'react-icons/fa';
import _ from 'lodash';

const ClientsideApplicationDetail = ({ jobApplication }) => {
  console.log(jobApplication, "dwidihodeij");
  return (
    <>
      <div className="col-md-12 profile_tab">
        <div className="row">
          <div className="col-md-8">
            <div className="postjob_tab">
              <ul className="nav nav-tabs">
                <li className="active"><Link to="#tab_default_1" data-toggle="tab">Application Details</Link></li>
                {/* <li><Link to="#tab_default_2" data-toggle="tab">Chat Messages</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="bid_info text-center">
              <p>Proposed Bid</p>
              <strong>{jobApplication ?.fixedBidRate}</strong>
            </div>
          </div>
        </div>
        <div className="shadow_bg clearfix">
          <div className="tab-content">
            <div className="tab-pane active" id="tab_default_1">
              {/* <h5><strong>Cover letter</strong></h5> */}
              <hr />


              {/* <p><strong>My skills includes</strong></p>
              <ul className="detail_list">
                <li>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non  quaerat voluptatem.</li>
                <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</li>
                <li>Dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam elore magnam .</li>
                <li>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adore magnam aliquam quaerat voluptatem.</li>
                <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</li>
              </ul> */}

              <p><strong>Work Samples</strong></p>
              <br />
              <div className="work_link">
                <Link to=""><FaPaperclip /> perspiciatis/omnis.jpg</Link>
                <Link to=""><FaPaperclip /> http://localhost:3000/big#/UserApplication</Link>
              </div>

              <p><strong>Messege</strong></p>
              <p>{jobApplication.messegeToClient}</p>
              <br />
              {
                jobApplication ?.answers ?.[0] && <p><strong>Screening questions and their answers</strong></p>
              }

              {
                _.map(jobApplication ?.jobId ?.screeningQuestions , (val, index) => (
                  <>
                    <p><strong>{val}</strong></p>
                    <p>{jobApplication ?.answers ?.[index]}</p>
                  </>
                ))
              }


              <br />
              <p>Thanks</p>
              <p>{jobApplication ?.userId ?.userName}</p>

            </div>


            <div className="tab-pane" id="tab_default_2">
              <h5><strong>Chat with Abhishek Gupta </strong></h5>
              <hr />
              <div className="messaging">
                <div className="msg_topic">
                  <h6>Message Title</h6>
                  <p>Job Short Description</p>
                  <Link to="">View Application</Link>
                </div>
                <div className="outline_bx clearfix">
                  <div className="incoming_msg">
                    <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                    <div className="received_msg">
                      <h6>Abhishek Gupta</h6>
                      <div className="received_withd_msg">
                        <p>Hello!</p>
                        <p>I'd like to invite you to take a look at the job I've posted. Please submit a proposal if you're available and interested.
            </p>

                        <p>Abhishek Gupta</p>
                        <span className="time_date"> 11:01 AM    |    June 9</span></div>
                    </div>
                  </div>
                  <div className="outgoing_msg">
                    <div className="outgoing_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                    <h6>Parul Yadav</h6>
                    <div className="sent_msg">
                      <p>Hello Abhishek</p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                      <p>Parul Yadav</p>
                      <span className="time_date"> 11:01 AM    |    June 9</span> </div>
                  </div>
                  <div className="type_msg">
                    <div className="input_msg_write">
                      <input type="text" className="form-control" placeholder="Type a message" />
                      <label for="attach">
                        <FaPaperclip />
                        <input type="file" id="attach"></input>
                      </label>
                      <button className="msg_send_btn" type="button"><FaPaperPlane /></button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default ClientsideApplicationDetail;