export default ({
    step: 1,
    fullName: "",
    emailId: "",
    password: "",
    mobileNumber: "",
    currentLocation: "",
    outsideIndia: "",
    profession: "",
    totalWorkExperience: "",
    countryWorks: "",
    countryName: "",
    currentProject: "",
    workingFromDate: "",
    workingToDate: "",
    projectDuration: "",
    filmIndustry: "",
    otherFilmIndustry: "",
    functionalArea: "",
    otherFunctionalArea: "",
    role: "",
    roleType: "",
    roleDetails: "",
    vedioLink: "",
    awardName: "",
    awardFor: "",
    awardNominateFor: "",
    awardYear: "",
    awardImage: "",
    awardVedio: "",
    highestQualification: "",
    otherHigherQualification: "",
    courses: "",
    otherCourses: "",
    sepcialization: "",
    otherSepcialization: "",
    universityOrCollegeName: "",
    passingYear: "",
    skills: "",
    uploadResume: "",
    agreeTermsAndCondition: "",
    gender: "",
    education: [{}, {}, {}, {}],
    awards: [{}],
    workAndAwards: [{}],
    physicalTraits: {},
    additionalInfo: {},
})
