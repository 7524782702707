import React from "react";
import { Link } from "react-router-dom"
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import HireTalentHeader from "../../components/HireTalent/HireTalentHeader";
import HireTalentDetail from "../../components/HireTalent/HireTalentDetail";

const HireTalentPage = ({...props }) => {
  return (
    <>
      <Header {...props} />
      <SubHeader />
      <section id="people_page" className="default_bg">
        <img src="images/make_film_bg.png" alt="img" className="bg_img" />
        <HireTalentHeader {...props} />
        <HireTalentDetail {...props} />
      </section>
      <Footer />
    </>
  );
};

export default HireTalentPage;
