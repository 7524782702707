import React, { Component } from 'react';

class footerScript extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
                <link href="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></link>
                <script src="js/bootstrap.min.js"></script>
                <script src="js/owl.carousel.min.js"></script>
                <script src="https://use.fontawesome.com/c7b464bfb3.js"></script>
                <script src="js/jquery.jConveyorTicker.min.js"></script>
            </div>
        );
    }
}

export default footerScript;