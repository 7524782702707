import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        padding:3
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-BoldOblique',
        fontSize:12
    },
    blankSpace:{
        height:10
    }
  });


  const BillTo = ({ invoice }) => {
    const payee = invoice.payer
    console.log('payee',payee)
    return(
      <View style={styles.headerContainer}>
      <Text style={styles.billTo}>To:</Text>
      <Text>{payee.name}</Text>
      <Text>{payee.address}</Text>

      <Text>GST : {payee.gstNo}</Text>
      <Text>PAN : {payee.pan}</Text>
      {/* <Text>Place of Supply : {invoice.placeOfSupply}</Text> */}
      <Text style={styles.blankSpace}></Text>
      {/* <Text>Contract Date : {invoice.contractDate}</Text>
      <Text>Contract No:{invoice.contractNo} </Text> */}
    </View>
  )};
  
  export default BillTo