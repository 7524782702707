import React from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, } from 'react-icons/fa';
import _ from 'lodash';
import '../../scss/findTalent.scss'
// import moment from 'moment';
import { config } from '../../actions/config';
import { getMeritScore } from '../../functions/meritCalculation';
import Pagination from '../Pagination/Pagination';
import BigLoader from '../../commons/Loader';


const FindTalentList = ({ data, onChangeSearch, onChangeFilter, setToggleFilter, totalCount, pageIndex, pageChangeHandler, loading }) => {
  const getMyMeritScore = (user) => {
    if (user && user._id) {
      const MF = getMeritScore(user)
      const score = MF.map(e => e.progress).reduce((a, b) => a + b) / MF.length
      return (score ? score.toFixed(0) : 0)
    }
  }


  return (
    <>
      {loading && <BigLoader />}
      <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 search_job_list" onClick={() => { setToggleFilter(true) }}>
        <div className='flex_bx'>
          <div className="form-group">

            <input
              type="search"
              placeholder="Search for Talent"
              className="form-control"
              onChange={(e) => onChangeSearch(e.target.value)}
            />
          </div>
          <div className="filter_points">
            <div id="group-2" className="list-group collapse in">
              <p className="list-group-item full_field"><input onChange={e => onChangeFilter({ type: 'city', value: e.target.value })} type="search" placeholder="Search client location" className="form-control" /> </p>
            </div>
          </div>
          <button type="button" className="blue_btn">
            <FaSearch /> Search
          </button>
        </div>
        <div className="row">
          {_.map(data, (val, i) => (
            <div key={'talent' + i} className="col-lg-4 col-md-6 col-sm-6 col-xs-6 full">
              <div className="celebrity_detail text-center clearfix" style={{ height: '100%' }}>
                <div className="people_img">
                  <img
                    src={
                      val.profilePic
                        ? config.FILE_URL + val.profilePic
                        : "images/blank_img.jpg"
                    }
                    alt="img"
                  />
                  {/* <span className="online">
                    <div className="circle_icon"></div>
                  </span> */}
                </div>
                <div className="people_list">
                  <div className="people_list_info clearfix" style={{ paddingBottom: 36 }}>
                    <h6 style={{ textTransform: 'capitalize' }} className="username-ellipsis">
                      {val.userName} <small>{val.gender}</small>
                    </h6>
                    <div className="people_short_info">
                      {/* <span>{val.location}</span> */}
                      <span></span>
                      <span>{val.profession}</span>
                    </div>

                    <div className="talent_list">
                      <p style={{ justifyContent: "center" }}>
                        <span className="people_short_info"> Work Experience :</span>
                        <span style={{ marginLeft: 14 }}>
                          {val.totalrelevantexp}
                          {val.totalrelevantexp ? " Years" : "0 "}
                        </span>
                      </p>
                    </div>
                    {/* {val?.additionalInfo?.currentCompensationAmount && (
                      <span className="cele_price">
                        Rate :
                        {val?.currency === "INR" ? (
                          <span> &#x20b9; </span>
                        ) : (
                          <span>$</span>
                        )}
                        {val?.additionalInfo?.currentCompensationAmount}/
                        {val?.additionalInfo?.currentCompensationType}
                      </span>
                    )} */}

                    <div className="bottom_desc">
                      <div className="progress default_progress">
                        <div className="progress-bar" role="progressbar" aria-valuenow="70"
                          aria-valuemin="0" aria-valuemax="100" style={{ width: getMyMeritScore(val) + "%", background: 'green' }}>
                          <span>{getMyMeritScore(val)}% Merit Score</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="end_info" style={{ position: 'absolute', width: '100%', bottom: 0 }}>
                    <Link to={`/UserProfile/${val._id}`} style={{ cursor: "pointer" }} className="stroke_btn">
                      View Profile
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Pagination totalCount={totalCount || 0} currentPage={pageIndex} onPageChange={(p) => { pageChangeHandler(p) }} pageSize={12} key={'P1' + Date.now.toString()} />
      </div>
    </>
  );
}
export default FindTalentList;
