import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaStarHalf,
  FaAngleRight,
  FaAngleLeft,
  FaFilter,
  FaFacebook,
  FaInstagram,
  FaMapMarkerAlt,
  FaTwitter,
  FaLinkedin,
  FaAddressCard,
  FaAngleDown,
  FaUser,
  FaInfo,
  FaStar,
  FaTrophy,
  FaYoutube,
} from "react-icons/fa";
import _, { isArray } from "lodash";
import UserProfileSidebar from "../../components/UserProfile/UserProfileSidebar";
import { config } from "../../actions/config";
import moment from "moment";
import { MeritFactor } from "../../Constants.js/meritFactor";
import { getMeritScore } from "../../functions/meritCalculation";
import MyContracts from "./MyContracts";
import Spinner from 'react-bootstrap/Spinner';
import { Modal } from "react-bootstrap";
import { getCurrentSubscriptionPlan } from "../../actions";

const containerStyle = {
  backgroundImage: 'url("images/tag.png")',
  backgroundSize: 'contain', // Adjust as needed
  backgroundRepeat: 'no-repeat', // Adjust as needed
  width: '175px',
  height: '45px', // Set a desired height
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const UserProfileDetail = ({ user, onHireClick, loginUser, inviteForJob, undoInviteHandler, usersRoleDetails, eligibleForHiring, ...rest }) => {
  // console.log(rest)
  const activeTab = rest.activeTab;
  const [meritFactor, setMeritFactor] = useState([])
  const [loader, setLoader] = useState(true)
  const [subscriptionPlan, setSubscriptionPlan] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user && user._id) {
      const MF = getMeritScore(user)
      setMeritFactor(MF)
      setLoader(false)
    }
  }, [user]);
  // useEffect(() => {
  //   setMeritFactor(MeritFactor)
  // }, [MeritFactor])


  useEffect(() => {

    const storedSubscriptionPlan = JSON.parse(localStorage.getItem("subscriptionPlan"));
    if (storedSubscriptionPlan) {

      console.log("subscription plannn:", storedSubscriptionPlan);
      setSubscriptionPlan(storedSubscriptionPlan);

    };


  }, []);

  return (
    <section id="people_other_info" style={{ position: 'relative' }}>
      {loader && <div style={{ position: 'absolute', left: '50%', top: '50%', zIndex: 99 }}></div>}
      <div className="row">
        <div className="col-md-12">
          <div className="postjob_tab">
            <ul className="nav nav-tabs">
              <li className={activeTab === 1 ? 'active' : ''}>
                <Link to="#tab_default_2" data-toggle="tab">
                  About
                </Link>
              </li>
              <li className={activeTab === 2 ? 'active' : ''}>
                <Link to="#tab_default_3" data-toggle="tab">
                  Work History{" "}
                </Link>{" "}
              </li>
              <li className={activeTab === 3 ? 'active' : ''}>
                <Link to="#tab_default_4" data-toggle="tab">
                  Skill & Expertise
                </Link>
              </li>

              <li className={activeTab === 4 ? 'active' : ''}>
                <Link to="#tab_default_5" data-toggle="tab">
                  Invoice Info
                </Link>
              </li>
              {/* <li className={activeTab === 5 ? 'active':''}>
                  <Link to="#tab_default_6" data-toggle="tab">
                  Merit Score
                  </Link>
                </li> */}
              <li className={activeTab === 6 ? 'active' : ''}>
                <Link to="#tab_default_7" data-toggle="tab">
                  My Contracts{" "}
                </Link>
              </li>
              {loginUser?._id === user?._id ? (
                <li className={activeTab === 8 ? 'active' : ''}>
                  <Link to="#tab_default_8" data-toggle="tab">
                    Subscription
                  </Link>
                </li>
              ) : <li className={activeTab === 5 ? 'active' : ''}>
                <Link to="#tab_default_6" data-toggle="tab">
                  Merit Score
                </Link>
              </li>}
              {/* <li className={activeTab === 7 ? 'active':''}>
                  <Link to="#tab_default_7" data-toggle="tab">
                    Subscription{" "}
                  </Link>
                </li> */}
              {/* <li className={activeTab === 6 ? 'active':''}>
                  <Link to="#tab_default_7" data-toggle="tab">
                    Certifications
                  </Link>
                </li> */}
            </ul>
          </div>
        </div>
        <div className="profile_area col-md-12 no_padding">
          <div className="col-md-12">
            <div className="shadow_bg clearfix" style={{ position: 'relative' }}>
              {loginUser?.emailId !== user?.emailId ?
                <div className='text-right pt-3' >
                  {eligibleForHiring ?
                    <button className='secondary-button ml-auto' onClick={onHireClick} > Hire</button>

                    : <button className='secondary-button ml-auto' onClick={() => { setShow(true) }}> Hire</button>}
                  <a href="javascript:void">
                    {user?.jobInvites?.includes(loginUser._id) ? <button className='blue_btn ml-auto' style={{ borderRadius: 20 }} onClick={undoInviteHandler}> Invited</button>
                      : <button className='blue_btn ml-auto' style={{ borderRadius: 20 }} onClick={inviteForJob}> Invite For job</button>}
                  </a>

                </div> : null}
              <div className="tab-content">
                <div className={`tab-pane about_info ${activeTab === 1 ? ' active' : ''}`} id="tab_default_2">
                  <div className="tab_head">
                    <div className="tab_title portfolio-sec">
                      <div className="tag_text">

                        <p>{user?.bio}</p>
                      </div>
                      <div style={containerStyle} className="tag_part">
                        <h5 className="portfolio-id">{user?.portfolioId || 'portfolioId'}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="tab_info">
                    <h5>{user?.physicalTraits?.jobType}</h5>
                    <p>{user?.additionalInfo?.desiredRole}</p>
                    <br />

                    <div className="tab_info">
                      <h5>Total Experience</h5>
                      <p>{user?.physicalTraits?.JobDescription}</p>
                    </div>

                    <div className="job_list clearfix">
                      {
                        user?.additionalInfo?.specificIndividual &&

                        <ul>
                          <li>
                            <span>Desired Role You Are Looking For : </span>{" "}
                            {user?.additionalInfo?.desiredRole}
                          </li>
                        </ul>
                      }

                    </div>

                    <div className="job_list clearfix">
                      {
                        user?.additionalInfo?.specificIndividual &&

                        <ul>
                          <li>
                            <span>Looking for to work with any specific individuals :</span>{" "}
                            {user?.additionalInfo?.specificIndividual}
                          </li>
                        </ul>
                      }

                    </div>

                    <div className="educational_info job_list clearfix">
                      <br />
                      <h5><div className="inside_icon"><FaAddressCard /> </div> Education</h5>
                      <hr />
                      <div className="row">
                        {_.map(user?.education, (val) => (
                          <>

                            <div className="col-md-6">
                              <div className="flex_bx">
                                <div class="work_his_icon"><img src="images/education.png" alt="img" /></div>
                                <div className="edu_info">
                                  <p>
                                    <strong>{val.collegeUniversity}  </strong>
                                  </p>
                                  <p>{val.course}</p>
                                  {val.courseDuration &&
                                    < p > {val.courseDuration} Months</p>
                                  }
                                  <p><small>{val.location}</small>
                                    <small>{val.passingYear}</small></p>

                                  <br />
                                </div>
                              </div>
                            </div>
                          </>

                        ))}
                      </div>
                    </div>

                    {user?.education?.length && user?.education?.[0]?.skills ? (
                      <ul className="detail_list clearfix">
                        <p>
                          <strong>My skills includes</strong>
                        </p>
                        <li>{user?.education?.[0]?.skills}</li>
                        {/* <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</li>
           <li>Dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam elore magnam .</li>
           <li>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adore magnam aliquam quaerat voluptatem.</li>
           <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</li> */}
                      </ul>
                    ) : ''}

                    <br />

                    {user.profession === 'Artist or Actor or Talent' && <div className="job_list job_list_info clearfix">
                      <h5><div className="inside_icon"><FaUser /> </div>  Physical Information</h5>

                      <ul>
                        {user?.physicalTraits?.height?.from?.feet && (
                          <li>
                            <span>Height:</span>
                            {user?.physicalTraits?.height?.from?.feet} ft{" "}
                            {user?.physicalTraits?.height?.from?.inch} in to{" "}
                            {user?.physicalTraits?.height?.to?.feet} ft{" "}
                            {user?.physicalTraits?.height?.to?.inch} in
                          </li>
                        )}

                        {user?.physicalTraits?.weight?.min && (
                          <li>
                            <span>Weight:</span>{" "}
                            {user?.physicalTraits?.weight?.min} kg to{" "}
                            {user?.physicalTraits?.weight?.max} kg
                          </li>
                        )}

                        {user?.physicalTraits?.physique && (
                          <li>
                            <span>Physique:</span>
                            {user?.physicalTraits?.physique}
                          </li>
                        )}

                        {user?.physicalTraits?.eyeColor && (
                          <li>
                            <span>Eye Color:</span>{" "}
                            {user?.physicalTraits?.eyeColor}
                          </li>
                        )}

                        {user?.physicalTraits?.hairLength && (
                          <li>
                            <span>Hair color:</span>{" "}
                            {user?.physicalTraits?.hairColor}
                          </li>
                        )}

                        {user?.physicalTraits?.hairLength && (
                          <li>
                            <span>Hair Length:</span>{" "}
                            {user?.physicalTraits?.hairLength}
                          </li>
                        )}

                        {user?.physicalTraits?.voiceType && (
                          <li>
                            <span>Voice Type:</span>{" "}
                            {user?.physicalTraits?.voiceType}
                          </li>
                        )}

                        {user?.physicalTraits?.uniqueTraits && (
                          <li>
                            <span>Unique Traits:</span>
                            {user?.physicalTraits?.uniqueTraits}
                          </li>
                        )}
                        {user?.physicalTraits?.appearance && (
                          <li>
                            <span>Appearance or D/C:</span>
                            <small>{user?.physicalTraits?.appearance}</small>
                          </li>
                        )}
                      </ul>
                    </div>}
                    {user?._id === loginUser?._id || user?.awards && user?.awards.length > 0 ? <div className="job_list clearfix">
                      <br />
                      <h5><div className="inside_icon"><FaTrophy /> </div>  Awards</h5>

                      <div className="row">
                        {user?.awards && user?.awards.length > 0 ? _.map(user?.awards, (val) => (
                          <>
                            <div className="col-md-6">
                              <div className="award_bx">
                                <div className="award_pic">
                                  <a href={config.FILE_URL + val.awardImage} target="_blank" rel="noopener noreferrer">
                                    <img src={config.FILE_URL + val.awardImage} alt="img" />
                                  </a>
                                </div>
                                <div className="award_detail">
                                  <span>{val.awardYear}</span>
                                  <p>
                                    <strong>{val.awardName}</strong>
                                  </p>
                                  <small>{val.awardFor}</small>
                                  <small>
                                    Nominated for {val.nominatedFor ? val.nominatedFor : ':  Yet to Submit'}
                                  </small>
                                  <small> Appearance Timing : {val.appearanceTime}</small>
                                  <small><a href={val.awardVedioLink} target="_blank">Award Url</a></small>

                                </div>
                              </div>
                            </div>
                          </>
                        )) : user?._id === loginUser?._id ?
                          <div className="col-md-6">
                            <div className="award_bx">
                              <div className="award_detail"><p>
                                {"No Awards Added, Needs to update by user!"}
                              </p>
                              </div>
                            </div>
                          </div> : null
                        }
                      </div>
                    </div> : null}
                  </div>
                  <br />
                  <div className="job_list addi_info clearfix">
                    <h5><div className="inside_icon"><FaInfo /> </div> Additional Info</h5>
                    <ul>
                      {user.dateOfBirth && (
                        <li>
                          <span>D.O.B:</span> <em>{moment(user.dateOfBirth).format('DD MMM yyyy')}</em>
                        </li>
                      )}

                      {user?.additionalInfo?.location && (
                        <li>
                          <span>Preferred Location:</span>{" "}
                          <em> {user?.additionalInfo?.location}</em>
                        </li>
                      )}

                      {user?.additionalInfo?.currentCompensationAmount && (
                        <li>
                          <span>Current Payout:</span>{" "}
                          <em> {user?.country == "India" ? 'INR' : 'USD'} {user?.additionalInfo?.currentCompensationAmount}</em>
                        </li>
                      )}

                      {
                        user?.additionalInfo?.expectedCompensationAmount &&
                        <li>
                          <span>Expected Payout:</span>
                          <em> {user?.country == "India" ? 'INR' : 'USD'} {user?.additionalInfo?.expectedCompensationAmount}</em>
                        </li>
                      }

                      {
                        user?.additionalInfo?.specificIndividual &&
                        <li>
                          <span>Looking for to work with :</span>{" "}
                          <em>{user?.additionalInfo?.specificIndividual}</em>
                        </li>
                      }

                      <li>
                        <span>Address:</span> {user.address}
                      </li>
                      <li>
                        <span> Social Profile:</span>
                        <div className="flex just">
                          {user?.additionalInfo?.fbProfile && <FaFacebook size={'2em'} title="Facebook" color="#3b5998" style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => window.open(user?.additionalInfo?.fbProfile)} />}
                          {user?.additionalInfo?.instagramProfile && <FaInstagram size={'2em'} color="#8a3ab9" style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => window.open(user?.additionalInfo?.instagramProfile)} />}
                          {user?.additionalInfo?.twitterProfile && <FaTwitter size={'2em'} color=" #00acee" style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => window.open(user?.additionalInfo?.twitterProfile)} />}
                          {user?.additionalInfo?.linkedinProfile && <FaLinkedin size={'2em'} color="#0A66C2" style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => window.open(user?.additionalInfo?.linkedinProfile)} />}
                          {user?.additionalInfo?.youtubeProfile && <FaYoutube size={'2em'} color="#c4302b" style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => window.open(user?.additionalInfo?.youtubeProfile)} />}
                        </div>
                      </li>
                      <li>
                        <span>Language :</span>
                        <em>
                          {user?.languagesSpeak && <><span>Speak: </span> {user?.languagesSpeak?.length ? user?.languagesSpeak?.join(', ') : ' Yet to Submit'} <br /></>}
                          {user?.languagesRead && <><span>Read: </span> {user?.languagesRead.length ? user?.languagesRead?.join(', ') : ' Yet to Submit'} <br /></>}
                          {user?.languagesWrite && <><span>Write: </span> {user?.languagesWrite.length ? user?.languagesWrite?.join(', ') : ' Yet to Submit'} <br /></>}
                        </em>
                      </li>
                    </ul>

                    {/* <div className="col-sm-6">
                    <h5><div className="inside_icon"><FaMapMarkerAlt /> </div> Social Media Profiles</h5>
                    <div className="flex just">
                   {user?.additionalInfo?.fbProfile && <FaFacebook size={'2em'} title="Facebook" color="#3b5998" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.fbProfile)}/>}
                   {user?.additionalInfo?.instagramProfile && <FaInstagram size={'2em'} color="#8a3ab9" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.instagramProfile)}/>}
                   {user?.additionalInfo?.twitterProfile && <FaTwitter size={'2em'} color=" #00acee" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.twitterProfile)}/>}
                   {user?.additionalInfo?.linkedinProfile && <FaLinkedin size={'2em'} color="#0A66C2" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.linkedinProfile)}/>}
                    {user?.additionalInfo?.youtubeProfile && <FaYoutube size={'2em'} color="#c4302b" style={{marginRight:10, cursor:'pointer'}} onClick={()=> window.open(user?.additionalInfo?.youtubeProfile)}/>}
                    </div>
                    </div> */}
                    <div className="">
                      <br />
                      <h5><div className="inside_icon"><FaStar /> </div> Profile Reviews</h5>
                      <div className="row">
                        {user?.userReview && isArray(user?.userReview) && user?.userReview?.map((review) => (
                          <div className="col-md-4 review_bx">
                            <div className="profile_review">
                              <p>{review.reviewMsg}</p>
                              <div> <cite><i> {review.reviewerName}</i></cite></div>
                            </div>  </div>))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 2 ? ' active' : ''}`} id="tab_default_3">
                  <div className="tab_head">
                    <div className="tab_title">
                      <h5>Work History</h5>
                    </div>
                    {/* <div className="tab_links">
                        <li className="dropdown">
                          <a
                            href="#lala"
                            className="dropdown-toggle circle_btn"
                            data-toggle="dropdown"
                          >
                            {" "}
                            <FaFilter />{" "}
                          </a>
                          <ul className="dropdown-menu" role="listbox">
                            <li>
                              <Link to="/" role="option">
                                Newest first
                              </Link>
                            </li>
                            <li>
                              <Link to="/" role="option">
                                Highest rated
                              </Link>
                            </li>
                            <li>
                              <Link to="/" role="option">
                                Lowest rated
                              </Link>
                            </li>
                            <li>
                              <Link to="/" role="option">
                                Largest projects
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </div> */}
                  </div>
                  <div className="tab_info client_his">
                    <ul>
                      {_.map(user.workAndAwards, (val) => (
                        <>
                          <li>
                            <div className="work_head">
                              <a data-toggle="collapse" className="drop_btn" data-parent="#accordion" href="#collapseOne"><FaAngleDown /></a>
                              <div className="work_his_icon">
                                <img src="images/work.png" alt="img" />
                              </div>
                              <div>
                                <h5>{val.profession}</h5>
                                <h6><strong>Company</strong> : {val.projectCompanyName}</h6>
                                <p>
                                  <small>
                                    <strong>Role</strong> : {val.roleType}
                                  </small>
                                  <small>
                                    <strong>Film Type</strong> : {val.filmType}
                                  </small>
                                  <small>
                                    <strong>Industry</strong> : {val.filmIndustry}
                                  </small>
                                  <small>
                                    <strong>Genre</strong> : {val.genre}
                                  </small>
                                </p>
                              </div>
                            </div>

                            <div id="collapseOne" class="panel-collapse collapse">
                              <p>{val.roleDetail}</p>
                              <p>
                                <strong>Work Video: </strong>{" "}
                                <Link to="">{val.videoLink}</Link>
                              </p>
                              <p>
                                <small>
                                  <strong>Loc.</strong> {val.location}
                                </small>
                                <small>
                                  <strong>Exp.</strong>
                                  {val.workExperience == "NaN Year" ? "" : val.workExperience}
                                </small>
                                <small>
                                  <strong>Duration</strong> {val.duration}
                                </small>
                                <small>
                                  <strong> Working Since</strong> {val.fromDate} to {val.toDate}
                                </small>
                              </p>
                            </div>
                            {/* <div className="project_shotinfo">
                                <div className="applicant_rating">
                                  <p>
                                    <div className="rating">
                                      <div className="star">
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStarHalf />
                                        <strong>4.5</strong>
                                      </div>
                                    </div>
                                  </p>
                                </div>                             
                              </div> */}
                          </li>

                          {/* <hr /> */}
                        </>
                      ))}
                    </ul>
                    {/* <hr /> */}
                    {/* <div className="pagination_part clearfix text-right">
                        <ul className="pagination">
                          <li>
                            <Link to="#">
                              <FaAngleLeft />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">1</Link>
                          </li>
                          <li>
                            <Link to="#">2</Link>
                          </li>
                          <li>
                            <Link to="#">3</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <FaAngleRight />
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 3 ? ' active' : ''}`} id="tab_default_4">
                  <div className="tab_head">
                    <div className="tab_title">
                      <h5>Skills & Expertise</h5>
                    </div>
                  </div>
                  <div className="tab_info skills_part">
                    {/* <h5>Age</h5>
                          <span>Young Adult</span><span>Senior</span><span>Child</span> <span>Teenage</span> */}
                    {/* <h5>Voice Talent Deliverables</h5>
                          <span>Characters/Video Games</span><span>eLearning</span><span>Advertisement</span> <span>Trailer</span>
                          <span>Phone System</span><span>Audiobook</span>
                          <h5>Audio Editing Software</h5>
                          <span>Audacity</span><span>Ableton Live</span><span>Adobe Audition</span> */}
                    <h5>Video & Photo</h5>
                    {user?.workAndAwards?.map(d => {
                      return (
                        <a href={d.videoLink} target="_blank" rel="noopner noreferrer">{d.videoLink}</a>
                      )
                    })}
                    {/* <h5>Accents</h5>
                          <span>English - American</span><span>English (British)</span><span>English - Australian</span> */}
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 4 ? ' active' : ''}`} id="tab_default_5">
                  <div className="tab_head">
                    <div className="tab_title">
                      <h5>Invoice Related Informations</h5>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="tab_info">
                      </div> */}
                    <div className="job_list col-md-6">
                      <h5><div className="inside_icon"><FaMapMarkerAlt /> </div> Address Details</h5>
                      <ul>
                        <li>
                          <span>Address : </span>{" "}
                          <div>{user?.invoiceInfo?.address || ' Yet to Submit'}</div>
                        </li>
                        <li>
                          <span>Country :</span>{" "}
                          <div>  {user?.invoiceInfo?.country || ' Yet to Submit'}</div>
                        </li>
                        <li>
                          <span>State :</span>
                          <div> {user?.invoiceInfo?.state || ' Yet to Submit'}</div>
                        </li>
                        <li>
                          <span>City :</span>
                          <div> {user?.invoiceInfo?.city || ' Yet to Submit'}</div>
                        </li>
                      </ul>
                    </div>
                    <div className="job_list col-md-6">
                      <h5><div className="inside_icon"><FaMapMarkerAlt /> </div> GST Details</h5>
                      <ul>
                        <li>
                          <span>GST:</span>
                          <div>{user?.GST || ' Yet to Submit'}</div>
                        </li>
                        <li>
                          <span>PAN:</span>{" "}
                          <div>  {user?.PAN || ' Yet to Submit'} </div>
                        </li>
                        <li>
                          <span>Name on PAN:</span>{" "}
                          <div> {user?.nameOnPan || ' Yet to Submit'} </div>
                        </li>
                      </ul>
                    </div>
                    <div className="job_list col-md-6">
                      <h5><div className="inside_icon"><FaMapMarkerAlt /> </div> BANK Details</h5>
                      <ul>
                        <li>
                          <span>Beneficiary Name in Bank:</span>
                          <div>{user?.invoiceInfo?.accountHolderName || ' Yet to Submit'}</div>
                        </li>
                        <li>
                          <span>Account Number:</span>
                          <div> {user?.invoiceInfo?.accountNo || ' Yet to Submit'}</div>
                        </li>
                        <li>
                          <span>IFSC:</span>{" "}
                          <div> {user?.invoiceInfo?.ifsc || ' Yet to Submit'} </div>
                        </li>
                        <li>
                          <span>Branch Name:</span>{" "}
                          <div> {user?.invoiceInfo?.branchName || ' Yet to Submit'} </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <br />

                </div>
                <div className={`tab-pane ${activeTab === 5 ? ' active' : ''}`} id="tab_default_6">
                  <div className="tab_head">
                    <div className="tab_title">
                      <h5>Profile Status</h5>
                    </div>
                  </div>
                  <div className="row">

                    {meritFactor.map(merit => {
                      return (
                        <div className="col-md-3">
                          <div className="score-container" >
                            <h5>{merit.title}</h5>
                            <img src={merit.image} />

                            <div className="progress merit-score">
                              <div className="merit-score-bar" role="progressbar" aria-valuenow="70"
                                aria-valuemin="0" aria-valuemax="100" style={{ width: merit.progress + "%" }}>
                                {merit.progress >= 50 && <span>{merit.progress}% Completed</span>}

                              </div>
                              {merit.progress < 50 && <span >{merit.progress}% Completed</span>}
                            </div>
                            {loginUser?.emailId === user?.emailId ? <span className="edit-merit" onClick={() => localStorage.setItem('profileStep', merit.profileStep)}>
                              <Link to="/CreateUserProfile" >Edit</Link>
                            </span> : null}
                          </div>
                        </div>
                      )
                    })}


                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 6 ? ' active' : ''}`} id="tab_default_7">
                  <div className="tab_head">
                    <div className="tab_title">
                      <h5>My Contracts</h5>
                    </div>
                  </div>
                  {loginUser && <MyContracts currentUser={loginUser} />}
                </div>
                <div className={`tab-pane ${activeTab === 7 ? ' active' : ''}`} id="tab_default_7">
                  <div className="tab_head">
                    <div className="tab_title">
                      <h5>Certifications</h5>
                    </div>
                  </div>
                  <div className="tab_info certificate_info">
                    <ul>
                      <li>
                        <img src="images/title_detail_icon.png" alt="icon" />
                        <div className="course_info">
                          <h5>Diploms in Voice Acting</h5>
                          <p>Lorem ipsum institude, Mumbai</p>
                          <span>Issued December 2019</span>
                        </div>
                      </li>
                      <li>
                        <img src="images/title_detail_icon.png" alt="icon" />
                        <div className="course_info">
                          <h5>Diploms in Voice Acting</h5>
                          <p>Lorem ipsum institude, Mumbai</p>
                          <span>Issued December 2019</span>
                        </div>
                      </li>
                      <li>
                        <img src="images/title_detail_icon.png" alt="icon" />
                        <div className="course_info">
                          <h5>Diploms in Voice Acting</h5>
                          <p>Lorem ipsum institude, Mumbai</p>
                          <span>Issued December 2019</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 8 ? ' active' : ''}`} id="tab_default_8">
                  <div className="tab_head">
                    <div className="tab_title">
                      <h5>Subscription Details</h5>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="tab_info">
                      </div> */}
                    <div className="job_list">
                      <h5>Current Plan Details</h5>
                      <ul className="col-md-10">
                        <li>
                          <span>Plan Name : </span>{" "}
                          <div>{subscriptionPlan.subscriptionPlan}</div>
                        </li>
                        <li>
                          <span>Amount :</span>
                          <div>
                            {parseFloat(subscriptionPlan.amount).toFixed(2)}
                          </div>
                        </li>
                        <li>
                          <span>Validity :</span>{" "}
                          <div>{subscriptionPlan.planValidity} days</div>
                        </li>
                        <li>
                          <span>Eligible to Hire New Talents :</span>
                          <div>{subscriptionPlan.maxHireTalent}</div>
                        </li>
                        <li>
                          <span>Eligible to apply Maximum Jobs :</span>
                          <div>{subscriptionPlan.maxJobApplications}</div>
                        </li>
                        <li>
                          <span>Eligible to apply Maximum Auditions :</span>
                          <div>{subscriptionPlan.maxAuditions}</div>
                        </li>
                      </ul>
                    </div>


                  </div>
                  <br />

                </div>
              </div>
            </div>
          </div>
          {/* <UserProfileSidebar user={user} /> */}
        </div>
      </div>
      {loginUser?.emailId === user?.emailId ? <div class="all_contact_link btn_wraper">
        <Link class="blue_btn" to="/CreateUserProfile">
          Edit Profile
        </Link>
      </div> : null}

      <Modal show={show} onHide={handleClose} animation={false} backdrop={false}>
        <div style={{ backgroundColor: '#ffff' }}>
          <Modal.Header closeButton style={{ backgroundColor: "#c62537" }}>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="skills_part">

              <h4>You are not eligible to hire right now ! </h4>
              <br />
              <p>Please contact to Admin for granting acsess</p>
              <br />

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="blue_btn"
              data-dismiss="modal"
              onClick={() => {
                // if(isCompletedSelected){

                // }else{
                rest.requestHiringAccess();
                // }
                handleClose();
              }}>
              Request Permission
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </section>
  );
};

export default UserProfileDetail;
