import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import PostAuditionInfo from "../../components/AuditionDetail/PostAuditionInfo";

const AuditionDetailPage = (props) => (
  <>
    <div className="top_slide inner_header">

      <Header />
      <SubHeader />
    </div>
    <PostAuditionInfo {...props} />
    <Footer />
  </>
);

export default AuditionDetailPage;
