import React, { Component } from 'react';
import MakeFilmPage from "../../components/MakeFilm/MakeFilmPage";
import { getUsersForFilm, addUserToFilm, sendInvitationLink, getFilm } from '../../actions/index';
import _ from 'lodash';
import swal from 'sweetalert';
let timer = '';
class MakeFilmPageContainer extends Component {

    state = { users: [], title: '', filmType: '', location: '', filmData: {} };


    componentDidMount() {
        if (this.props.location ?.state ?.id) {
            getFilm(this.props.location ?.state ?.id)
                .then(res => this.setState({ filmData: res.data.data, ...res.data.data }));

        }
    }

    getUsersOnHover = category => {
        this.setState({ category });
        getUsersForFilm({ category, location: this.state.location })
            .then(res => {
                this.setState({ users: res.data.data });
            })
    }

    onAddNewUser = (userId) => {
        addUserToFilm({ ...this.state, _id: this.state.filmData._id, userId })
            .then(res => this.setState({ filmData: res.data.data }));
    }

    onAddRemoveUser = (userId) => {
        addUserToFilm({ ...this.state, _id: this.state.filmData._id, userId, type: 'delete' })
            .then(res => this.setState({ filmData: res.data.data }));

    }

    onsendSingleInvitation = userId => {
        sendInvitationLink({ userIds: [userId], _id: this.state.filmData._id })
            .then(res => this.setState({ filmData: res.data.data }));
    }

    onsendMultipleInvitation = () => {
        sendInvitationLink({ userIds: _.map(this.state.filmData.team, val => val._id), _id: this.state.filmData._id })
            .then(res => this.setState({ filmData: res.data.data }));
        swal("Invitations Sent to All", { buttons: false, timer: 2000, icon: "success" })
    }


    onChangeValues = e => this.setState({ [e.name]: e.value });

    searchUser = searchText => {
        const { category, location } = this.state;
        this.setState({ searchText });
        clearTimeout(timer);
        setTimeout(() => {
            getUsersForFilm({ category, location: location, searchText })
                .then(res => {
                    this.setState({ users: res.data.data });
                })
        }, 300)
    }


    resetSearch = () => this.setState({ searchText: '' });

    render() {
        console.log(this.state, _.groupBy(this.state.filmData ?.categoryWiseUsers , 'category'), "state data for make my film")
        return <MakeFilmPage
            {...this.props}
            {...this.state}
            getUsersOnHover={this.getUsersOnHover}
            onChangeValues={this.onChangeValues}
            onAddNewUser={this.onAddNewUser}
            onAddRemoveUser={this.onAddRemoveUser}
            onsendSingleInvitation={this.onsendSingleInvitation}
            onsendMultipleInvitation={this.onsendMultipleInvitation}
            searchUser={this.searchUser}
            resetSearch={this.resetSearch}
        />
    }

}

export default MakeFilmPageContainer;