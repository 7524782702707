import React, { Component, useState } from "react";
import { Link } from 'react-router-dom';
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import SearchJobFilter from "../../components/SearchJob/SearchJobFilter";
import SearchJobList from "../../components/SearchJob/SearchJobList";
import { FaSlidersH } from "react-icons/fa";

const SearchJobPage = (props) => {
  const [showFilter, setToggleFilter] = useState(true);

  return (
    <div>
      <div className="top_slide inner_header">
        <Header />
        <SubHeader />

        <section id="audition_banner" className="mid_info inner_banner">
          <div className="container side_space">
            <div className="row">
              <div className="col-md-6 inner_banner_text">
                <h2>Ready to embark on your dream career in the film industry?</h2>
                <p>Explore the endless possibilities and find your perfect job on Bollywood is Good!</p>
                <button className="blue_btn" onClick={() => window.history.back()}>Back</button>
                <Link to="/PostJob" className="blue_btn">Post Job</Link>
              </div>
              <div className="col-md-6 inner_img">
                <img src="images/slide5.png" alt="img" />
              </div>
            </div>
          </div>
        </section>
      </div>

        {/* CTA section start*/}
        {/* <section id="cta" className="mid_info inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/audition_banner.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-8 col-sm-8 col-xs-8 cta_info full">
            <h2>Ready to embark on your dream career in the film industry?</h2>
            <p>Explore the endless possibilities and find your perfect job on Bollywood is Good!</p>
                 </div>
            </div>
            </div>
          </div>
      </section> */}
        {/* CTA section ends */}
        {/* <section id="breakdown_list" className="default_bg">     
      <div className="container">
      <div className="heading header_btn">
        <h2>Discover Thrilling Career Opportunities</h2>
        <button className="blue_btn" onClick={()=> window.history.back()}>Back</button>
        </div>
        <div className="row">
        <div className="filter-toggler" onClick={()=>{setToggleFilter(!showFilter)}}><button className="blue_btn"><FaSlidersH /> Filters </button></div>
          <SearchJobFilter {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}} />
          <SearchJobList {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}} />
        </div>
      </div>
    </section> */}

        <section id="breakdown_list" className="audition_list">
          <div className="container">
            <div className="row">
              <div className="filter-toggler" onClick={() => { setToggleFilter(!showFilter) }}><button className="blue_btn"><FaSlidersH /> Filters </button></div>
              <SearchJobFilter {...props} {...{ showFilter: showFilter, setToggleFilter: setToggleFilter }} />
              <SearchJobList {...props} {...{ showFilter: showFilter, setToggleFilter: setToggleFilter }} />
            </div>
          </div>
        </section>
        <Footer />
    </div>
  )
};
export default SearchJobPage;
