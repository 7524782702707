import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InvitePeopleSearchTab from './InvitePeopleSearchTab';
import InvitedPeopleTab from './InvitedPeopleTab';
import SavedPeopleTab from './SavedPeopleTab';
import HirePeopleTab from './HirePeopleTab';

const InvitePeopleTab = props => {
    return (
        <div className="tab-pane" id="tab_default_2">
            <div className="shadow_bg clearfix">
                <div className="inside_listing_tab">
                    <ul className="nav nav-tabs">
                        <li className="active"><Link to="#list_tab1" data-toggle="tab">Search</Link></li>
                        <li><Link to="#list_tab2" data-toggle="tab">Invited Peoples ({Number(props.jobPostDetail ?.invites ?.length) || 0})</Link></li>
                        <li><Link to="#list_tab3" data-toggle="tab">My Hires  ({Number(props.currentUser ?.hiredUsers ?.length) || 0})<span></span> </Link></li>
                        <li><Link to="#list_tab4" data-toggle="tab">Saved  ({Number(props.jobPostDetail ?.savedUsers ?.length) || 0})<span></span> </Link> </li>
                    </ul>

                    <div className="tab-content">
                        <InvitePeopleSearchTab {...props} />
                        <InvitedPeopleTab {...props} />
                        <SavedPeopleTab {...props} />
                        <HirePeopleTab {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default InvitePeopleTab;