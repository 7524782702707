import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class AdAgency extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>CREATIVE AD AGENCY</h1>
                  <p align="justify"> Rightly said that “Everything you imagine is real” and we make <strong>creative Ads</strong> as per the demand of the clients and covert their thoughts into creative ad. With the amalgamation of creative heads we handle any size of projects. </p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/film-making-services-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/commercial-film-makers.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Creative Ad Services we offer are: ENTERTAINMENT</h2>
          </div>
          <p align="justify">With highly complicated and rising market its tough business and identifying brand matters the most. We render your product or service with much required brushing and makeup, because what looks good sells best. The fresh and innovative ideas makes is what makes us survive in this market. By using our services the company can generate leads. We create advertising for marketing which is developed and generated by art directors, creative directors and copywriters in <strong>creative advertising agency.</strong></p>
         <br />
          <p align="justify"><strong>Motion Design : </strong>
Our team of motion designers assist the users from on-air promos to animations for Instagram take your vision to life from pre-visualization to final animation with 2D and 3D tools.
<br /> <br />
<strong>Editing </strong>
Our editors are ready to handle for any project with eight state-of-the-art bays. To bring stories of any size to life we offer editorial services from short form to long form.
</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
            <ul>
              <li><p align="justify"><strong>Audio </strong> : Our audio team provides high-mixing and sound design for every broadcast and digital mediums. BollywoodisGood considered as one of the most trusted audio production companies in the industry. We offer services like original music composition, music supervision, and VO casting.</p> </li>
              <br />
              <li><p align="justify"><strong>Writing </strong> : If you are looking for a script, a tagline or a complete-360 campaign - Our expert team of production writing provides mutual partnership that raises your brand over the competition.</p> </li>
              <br />
              <li><p align="justify"><strong>Production</strong> : To make your video visible to the right audience go for a flexible resources and crews instead of selecting a video production company. With BollywoodisGood you get dedicated creative heads that are able to capture pictures for promos, commercials and social extremes as well as are a complete-service agency.</p></li>
            </ul>
            <br />
          <div class="inner_head">
          <h2>DESIGN + CONTENT</h2>
          </div>
          <p align="justify"><strong>Copywriting </strong>: The copywriters of BollywoodisGood get the deep understanding of your brand and the demand of your customers. We provide copywriting services to fascinate and inspire audiences from generating concept to final content.</p>
          </div>
          <div class="col-sm-5">
          <img src="images/commercial-film-makers.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <p align="justify"><strong>Brand Identity :</strong>
We believe that telling a story with the use of words, sounds, or interaction would attract the customers and make them easily understand the brand. We make sure that the brand identity design is appropriate and from the look and feel to the logos and typefaces everything is attracting the customers.
<br /> <br />
<strong>Campaign Development :</strong>
Our team of experts who works on creating campaign for companies knows every “ifs and buts” related to it. We know the marketing and development of the campaign, hence we develop the result oriented campaign for your brand.
<br /> <br />
<strong>App Design :</strong>
You can get any app design company in the market but are you sure that they have the best developers who are highly skilled. Therefore, it better to go for a company that has best developers like BollywoodisGood. We blend the current trends with unique tools and top marketers those can channel your brand, products or services to the masses.
<br /><br />
<strong>Front End Development :</strong>
Front end development is more than just what to scroll and what to click. The user-friendly feel and the comfort of the customer is what the best chance a brand can make its impact.
<br /> <br />
<strong>UI / User Interface :</strong>
Looking good is not that important then is your customers are able to use it properly is vital. Our team aims on user interface design that combines intuition with information, and offers ways by which your user can search your brand’s best attributes.
<br /><br />
<strong>UX / User Experience :</strong>
For every project the foundation is UX design skills. By knowing audiences, behaviour and personas we make certain all experience is constructed to expect the users’ requirements.
</p>
          </div>
          </section>     

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>BRANDING</h2>
          </div>
          <p align="justify">BollywoodisGood feels proud of the brands that our team helped in building and brands building that people can relate to and depend on, is what we’re best known for.</p>
          <br />
          <ul>
          <li>Brand Strategy</li>
          <li>New Brand Launches</li>
          <li>Rebranding programs</li>
          <li>Brand Custodian Programs</li>
          <li>Repositioning Exercises</li>
          <li>Packaging Design</li>
          <li>Strategic Brand Properties</li>
          </ul>
          <br />
          <p> So , If  you are looking for <strong>Creative Ad Agency</strong> then BollywoodisGood is the best option for you.</p>
          </div>
          
          <div class="col-sm-5">
          <img src="images/commercial-film-makers.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services" className="dark_list">
          <div class="container">
            <div className="row">
              <div className="col-md-4">
              <div class="inner_head">
            <h3>CREATIVE & GRAPHIC</h3>
            </div>
            <ul>
            <li>Brand Identity Creation</li>
            <li>Brochure Design</li>
            <li>Logo Design</li>
            <li>Catalogue Design</li>
            <li>Print Ad Design</li>
            <li>Print Material Design</li>
            <li>Packaging Design</li>
            <li>Website Design & Development</li>
            <li>Graphic Design</li>
            <li>Photoshoot</li>
            <li>TVC Ad</li>
            <li>Radio Jingle Creation</li>
            </ul>
              </div>
              <div className="col-md-4">
              <div class="inner_head">
          <h3>PROMOTION MEDIUMS</h3>
          </div>
          <ul>
            <li>TV</li>
            <li>Radio</li>
            <li>OOH (Out Of Home)</li>
            <li>Airport</li>
            <li>Inflight</li>
            <li>Transit Media</li>
            <li>Railways</li>
            <li>Print Media</li>
          </ul>
              </div>
              <div className="col-md-4">
              <div class="inner_head">
          <h3>BTL EVENTS / ACTIVATIONS</h3>
          </div>
          <ul>
            <li>Mall Activation</li>
            <li>Exhibitions</li>
            <li>Ground Activation</li>
          </ul>
              </div>
            </div>
          
          
          
          </div>
          </section>     
        <Footer />
      </>
    );
  }
}

export default AdAgency;
