import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaAngleLeft, FaAngleRight, FaAngleDown, FaAngleUp, FaFileInvoice, } from 'react-icons/fa';
import _ from 'lodash';
import moment from 'moment';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Button,Dropdown,DropdownButton,Modal } from 'react-bootstrap';
import ArtistInvoiceContainer from '../../Container/ArtistInvoice/ArtistInvoiceContainer';
import AggregatorInvoiceToMMContainer from '../../Container/AggregatorInvoiceToMM/AggregatorInvoiceToMMContainer';
import AggregatorInvoiceToArtistContainer from '../../Container/AggregatorInvoiceToArtist/AggregatorInvoiceToArtistContainer';
import { getAllContracts } from '../../actions';
import Pagination from '../Pagination/Pagination';
import { useSearchFilter } from '../../hooks/useSearchFilter';

const MyContracts = ({ currentUser, ...rest}) => {
  let pageSize = 4;
  const [activeChildId, setActiveChildId] = useState('');
  const [contractData, setData] = useState([]);
  const [allData, setAllData] = useState([]);
const [tnxId, setTnxId] = useState('tnx2022-327432665X');
const [pageIndex, setPageIndex] = useState(1);
const [searchKey, setSearchKey] = useState('')
const searchResult = useSearchFilter(allData, searchKey.trim());
  


    useEffect(() =>{
      const slicedList = searchKey && searchKey.length ? searchResult.slice(0,  pageSize) :searchResult.slice((pageIndex - 1) * pageSize, (pageIndex * pageSize))
      setData(slicedList)
    }, [searchResult])

useEffect(() => {
  getAllContracts()
  .then(res => {
    if(res && res.data && res.data?.data && res.data?.data.length){
      const contracts = res.data?.data;
      const myContrats = contracts.filter(e =>e.isActive && (currentUser._id === e.employerId ||  currentUser._id === e.employeeId))
      setData(myContrats);
      setAllData(myContrats);
      // let pendingAmount = 0;
      // myContrats.forEach(con =>{
      //   con.milestone.forEach((milestone) =>{
      //     if (milestone.status === 'In Progress') {
      //       pendingAmount+= milestone.milestoneAmount

      //     }
      //   })
      // })
      // setTotalPendingAmount(pendingAmount);
    }

  })
}, [currentUser._id])

const pageChangeHandler = (index) => {
  console.log(index)
  const slicedList = allData.slice((index - 1) * pageSize, (index * pageSize))
  setData(slicedList)
  setPageIndex(index)

}

  return (
    <div className="col-sm-12 search_job_list" style={{padding:0}}>
      <div className="inside_listing_tab">
        

        {/* <div className="tab-content">
          <div className="tab-pane active" id="list_tab1"> */}
            <div className="form-group search_field">
              <input
                type="search"
                placeholder="Search for Contracts"
                className="form-control"
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <button type="button" className="default_btn">
                <FaSearch />
              </button>
            </div>

            <ul>
              {contractData.map((val,i) => (
                <>
                  <li key={i + "mile"} style={{ position: "relative" , display: 'flex'}}>
                    <div className="audition_date">
                      <span>{moment(val.updatedAt).format("MMM")}</span>
                      <h5>{moment(val.updatedAt).format("D")}</h5>
                      <small>{moment(val.updatedAt).format("ddd")}</small>
                    </div>
                    <div className="breakdown_casting_info audition_info" style={{width:'100%'}}>
                      <div className="casting_list_head clearfix">
                        {/* <h3>{val.title}</h3> */}
                        <div className="row">
                          <div className="col-md-6">
                            <div>
                              <strong> Hiring Company : </strong>{" "}
                              {val.hiringTeam}
                            </div>
                            <div>
                              <strong>Job Category : </strong> {val.jobCategory}
                            </div>
                            {/* <div>
                              <strong> Pending Amount : </strong>{" "}
                              {totalPendingAmount}
                            </div> */}
                          </div>
                          <div className="col-md-5">
                            <div>
                              <strong>Payment Mode : </strong>
                              {val.paymentOption}
                            </div>
                            <div style={{ textTransform: "capitalize" }}>
                              <strong>Deposit Type : </strong>{" "}
                              {val.depositeType}
                            </div>

                           
                          </div>
                        
                        </div>
                        
                      </div>
                      <p className="brief_intro">{val.description}</p>
   
                    </div>
                
                    <button
                      className="milestone-toggler"
                      onClick={() => {
                        setActiveChildId(
                          val._id === activeChildId ? "" : val._id
                        );
                      }}>
                      {activeChildId === val._id ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </button>
                    {/*} */}
                  </li>
                  {activeChildId === val._id && val.depositeType ==='milestoneWise' && (
                    <li style={{ display: "block" }}>
                      {_.map(val.milestone, (mData, indx) => (
                        <>
                          <div
                            key={indx}
                            className="row"
                            style={{
                              width: "100%",
                              padding: "6px 0px",
                              borderBottom: "1px dotted ",
                            }}>
                            <div className="col-md-5">
                              <div>
                                <input
                                  style={{ marginTop: 10 }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Milestone Description"
                                  name="milestoneDescription"
                                  value={mData.milestoneDescription}
                                  disabled
                                />
                              </div>
                              <div>
                                <input
                                  style={{ marginTop: 10 }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Milestone Amount"
                                  name="milestoneAmount"
                                  value={mData.milestoneAmount}
                                  disabled
                                  
                                />
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div>
                                <input
                                  style={{ marginTop: 10 }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Due Date"
                                  name="dueDate"
                                  value={moment(mData.dueDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-2"
                              style={{ borderLeft: "2px solid #9f89a56b" }}>
                             
                               {mData.paymentTransactionId  && (
                                <div style={{ marginTop: 10 }}>
                                <DropdownButton id="dropdown-item-button" title="Invoice" onClick={()=>setTnxId(mData.paymentTransactionId)} style={{width:'100%'}}>
                                  <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <ArtistInvoiceContainer
                                    transactionId={tnxId}
                                  />
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <AggregatorInvoiceToMMContainer
                                    transactionId={tnxId}
                                  />
                                   </Dropdown.Item>
                                  <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <AggregatorInvoiceToArtistContainer
                                    transactionId={tnxId}
                                  />
                                  </Dropdown.Item>
                                </DropdownButton>
                                 
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </li>
                  )}
                  {activeChildId === val._id && val?.depositeType ==='whole' && (
                    <li style={{ display: "block" }}>
                      {_.map(val.wholePayment, (mData, indx) => (
                        <>
                          <div
                            key={indx}
                            className="row"
                            style={{
                              width: "100%",
                              padding: "6px 0px",
                              borderBottom: "1px dotted ",
                            }}>
                            <div className="col-md-5">
                              <div>
                                <input
                                  style={{ marginTop: 10 }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Payment Description"
                                  name="paymentDescription"
                                  value={mData.paymentDescription}
                                  disabled
                                />
                              </div>
                              <div>
                                <input
                                  style={{ marginTop: 10 }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Amount"
                                  name="Amount"
                                  value={val.maxFixedPay}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div>
                                <input
                                  style={{ marginTop: 10 }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Due Date"
                                  name="dueDate"
                                  value={moment(mData.dueDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-2"
                              style={{ borderLeft: "2px solid #9f89a56b" }}>
                             
                             
                              {mData.paymentTransactionId  && (
                                <div style={{ marginTop: 10 }}>
                                <DropdownButton id="dropdown-item-button" title="Invoice" onClick={()=>setTnxId(mData.paymentTransactionId)} style={{width:'100%'}}>
                                  <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <ArtistInvoiceContainer
                                    transactionId={tnxId}
                                  />
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <AggregatorInvoiceToMMContainer
                                    transactionId={tnxId}
                                  />
                                   </Dropdown.Item>
                                  <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                                  <AggregatorInvoiceToArtistContainer
                                    transactionId={tnxId}
                                  />
                                  </Dropdown.Item>
                                </DropdownButton>
                                 
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </li>
                  )}
                </>
              ))}
            </ul>
            {/* <div class="pagination_part clearfix text-right">
              <ul class="pagination">
                <li>
                  <Link to="#">
                    <FaAngleLeft /> Prev
                  </Link>
                </li>
             <li><Link to="#">1</Link></li>
         
                <li>
                  <Link to="#">
                    {" "}
                    Next <FaAngleRight />
                  </Link>
                </li>
              </ul>
            </div> */}
        <Pagination totalCount={allData.length} currentPage={searchKey && searchKey.length >0 ? 1 :pageIndex} onPageChange={(p)=>{pageChangeHandler(p)}} pageSize={pageSize}  key={'P'+Date.now.toString()}/>
      </div>
    </div>
  );
}

export default MyContracts;
