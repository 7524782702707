import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import converter from 'number-to-words';
const borderColor = '#555'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 11,
    },
    description: {
        width: '50%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
        height:24,
        fontStyle: 'bold',
    },
    total: {
        width: '10%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        flexDirection:'column',
        // paddingTop: 10,
        height: 24,
        fontWeight:300
    },
    totalLast: {
        width: '10%',
        textAlign: 'right',
        paddingRight: 8,
        flexDirection:'column',
        height: 24,
        fontWeight:300
    },
    sno: {
        width: '5%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        flexDirection:'column',
        paddingTop: 10,
        height: 24
    },
    totalInWord:{
        width: '51%',
        textAlign: 'left',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        flexDirection:'column',
        height: 24,
        fontWeight:300,
        paddingLeft:10
    }
  });


const InvoiceTableFooter = ({items,payer, payee}) => {
    const total = items.map(item => (item.netAmount * item.gstPercent/100)+ item.netAmount)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0);

    const totalNetAmount = items.map(item => (item.netAmount ))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0);

    const totalTaxPercentage = items.map(item => (item.gstPercent ))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0);

    const totalTax = items.map(item => payer?.state === payee?.state ? (item.netAmount * item.taxRate.igst) / 100 : (item.netAmount * (item.taxRate.cgst+item.taxRate.sgst)) / 100)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    return (
      <>
        <View style={styles.row}>
          <Text style={styles.sno}></Text>
          <Text style={{...styles.description,fontFamily: "Helvetica-Bold"}}>Total Amount</Text>
          <Text style={styles.total}></Text>
          <Text style={{...styles.total, width: "15%",fontFamily: "Helvetica-Bold"}}>
            {Number.parseFloat(totalNetAmount).toFixed(2)}
          </Text>
          {/* <Text style={{...styles.total,width: '15%'}}>
            {Number.parseFloat(totalTaxPercentage).toFixed(2)}
          </Text> */}
          {/* <Text style={{...styles.total, width: "14%",fontFamily: "Helvetica-Bold"}}>
            {Number.parseFloat(totalTax).toFixed(2)}
          </Text> */}
          {/* <Text style={styles.total}></Text> */}
          <Text style={{...styles.total, width: "20%",fontFamily: "Helvetica-Bold", borderRightWidth:0}}>
            {Number.parseFloat(total).toFixed(2)}
          </Text>
        </View>
        <View style={{ ...styles.row, borderBottomWidth: 0 }}>
          <Text
            style={{
              ...styles.description,
              width: "35%",fontStyle: 'bold',
              fontFamily: "Helvetica-Bold",
              borderBottomWidth: 0,
            }}>
            Amounts in words
          </Text>
          <Text style={{ ...styles.totalInWord, borderBottomWidth: 1 }}>{converter.toWords(total)}</Text>
          <Text
            style={{
              ...styles.totalLast,
              width: "14%",
              borderBottomWidth: 1,
            }}></Text>
        </View>
        <View style={{ display: "flex", justifyContent: "center", height: 50 }}>
          <View style={{...styles.row, borderBottomWidth:0}}>
            <Text style={{ width: "20%" }}></Text>
            <Text style={{ width: "80%" }}>
              Note : Whether the tax is payable on reverse charges :- No
            </Text>
          </View>
        </View>
      </>
    );
};
  
  export default InvoiceTableFooter