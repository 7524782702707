import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class CelebrityManagementCompany extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>Celebrity Management Company</h1>
                  <p align="justify"> BollywoodisGood coordinate and manage numerous of well-known Celebrities from the Film and Music Industry. Be it Performance at Events, Concerts, shows Launching and Brand Endorsements or celebrity appearance we manage it all for you.</p>
                
              <Link to="/ReachUs" className="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/celebrity-management-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>
          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/bollywood_stars.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Celebrity Management Services</h2>
          </div>
          <p align="justify">BollywoodisGood, as a <strong>celebrity management company in Mumbai</strong> aims to carve its place by managing and sourcing artists across the globe. Our primary concern is to offer renowned and remarkable services to the companies we are partner with. We outshine in offering entertainment solutions to numerous sort of events, shows and occasions. We also expand our expertise into several verticals like catalogue photo shoots, ad films and television, brand endorsements and tie-ups, ground activations and many more.</p>
          <br/>
          <p align="justify">We have grown quickly within a very short span of time and tries to give every association an unforgettable experience by enhancing it with an energy that’s true to its name. Our Artist Management Services offers proficiency based solution and Celebrity Coordination & Management Services. Our expert team of celebrity management deal with a Celebrity Promotional Events for a Specific Film or Store launch, Ribbon-Cutting Occasion, Product launch, Award Shows, Campaigning, Celebrity Parties or Weddings and Brand Endorsement where Celebrities becomes the Centre of Attraction for their simplicity and style.</p>
          </div>
          </div>
          </div>
          </section>
          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Artist Management Services</h2>
          </div>
          <p>We have the Capability of managing such a big event with professionalism from past so many years. We are very capable in organizing such challenging events in an efficient and well-organized manner. Following are the services where we can help you.</p>
          <br />
          <p>
          <ul>
          <li>Actor/Actress Co-ordination & Management</li>
          <li>Co-Star Co-ordination & Management</li>
          <li>Artist Management For any singing, dancing or another event.</li>
          <li>Host & Guest Services</li>
          <li>Top DJ’s</li>
          <li>Comedians</li>
          <li>Sport stars</li>
          <li>Rock bands</li>
          <li>TV Serial artists</li>
          <li>Emcees/Anchors</li>
          <li>Models</li>
          </ul>
          </p>
          </div>
          <div class="col-sm-5">
          <img src="images/our_role_img.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>
          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
          <h2>Best Celebrity Management Agency</h2>
          </div>

          <p align="justify">Not like most <strong>Celebrity Management Agencies</strong> presenting as artist management companies, we are a vivacious incorporated Artist Management company. Our vision is to be a best company, leaving ineradicable marks on public awareness. The idea of young professionals with extended association with the film industry and management services, the company provides Bollywood Celebrity Management Services. We have taken celebrity management by storm and have become well-known management company. We have huge experience on how to raise the glitz and glam amount of an event. Our end-to-end initiatives on celebrity management are known to be right up there in the exclusive list of quality Artist management company.
          <br/><br/>
          we consider the fact that relationship between our clients and our celebrities are mutual as they require to feed on each other’s success and image. Being a top celebrity management service provider, we make sure that celebrities suitably reflect a brand they promote or properly represent the event they are gracing. Our capability to mix out enormously successful personalised events, loyalty to service contracts, fondness for full discretion, security and privacy, deep study for detail, quality of talent and unified management make us the only choice for high-profile clients and celebrities. Careful planning and implementation, loads of rock-hard flexibility and endurance, industry experience, experienced staff make us one of the most effective company.
          </p>
          </div>
          </section>              

        <section id="last_info">
        <div class="container">
        <div class="row">
        <div class="col-xs-12">
        <blockquote>
        <p align="justify">So whenever a thought comes to contact for <strong>Celebrity management Services</strong>, always remember the name BollywoodisGood. You can contact us via call, e-mail, skype or even WhatsApp. We have opened every medium of communication, so that you don’t have to struggle in getting in touch with us. We have many satisfied clients and you can be the next one. So don’t waste your time on searching the best Celebrity Management company, you already know the name. So just gather all your queries and what all services you need and you are one call away from getting the best service in the market.
        <br/><br/>
        The professional team of BollywoodisGood has tremendous knowledge and you will be amazed to see the promptness and skills our staff have. When it comes to Celebrity Management, we are the only best option available in the market. With huge list of existing customers and prospective customers, we are undoubtedly leading the market. We make sure that you get the best services from us and that understanding of clients need makes us different from others. Just open your phone, dial our number and get it done. We are here to help you.</p>
        </blockquote>
        </div>
        </div>
        </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default CelebrityManagementCompany;
