import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { city, gender, Lang, profession } from "./registerConst";
import { FaUpload } from "react-icons/fa";
import Select from "react-select";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import AsyncSelect from "react-select/async";
import { loadOptionsResolveLanguage } from "../../Constants.js/languageConst";
import cityStateConst from "../../components/CreateUserProfile/cityStateConst";
import { countries } from '../../components/CreateUserProfile/cityStateConst';
import NoOptionsMessage from '../../commons/ReactSelectNoOptionsMessage';
import moment from 'moment'
import { config } from "../../actions/config";
import currency from "./currency";
import FileInput from "../FileCropper/FileInput";
import ImageCropper from "../FileCropper/FileCropper";

const StateOptions = _.map(cityStateConst, (val) => ({
  label: val.state,
  value: val.state,
}));

function PersonalInfo({
  handleChangeDropDown,
  handleChange,
  nextStep,
  errors,
  submitForm,
  defaultProps,
  fileSelectedHandler,
  ...props
}) {
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imageName, setImageName] = useState("");
const [bioLength, setBioLength] = useState(props?.bio || "")
  // Invoked when new image file is selected
  const onImageSelected = (selectedImg, name) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
    setImageName(name)
  };
  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
    
  };
  const onCropDone = (img) => {
    fileSelectedHandler({target:{name: imageName, files:[img]}})
    setCurrentPage("choose-img");
  }

  const { t } = useTranslation();

  const districtArray = _.find(cityStateConst, {
    state: props.state,
  }) ?.districts;

  const districtOptions = _.map(districtArray, (val) => ({
    label: val,
    value: val,
  }));
  const currencyOptions = _.map(currency, (val) => ({
    label: val.currency,
    value: val.currency,
  }));
  return (
    <>
      <div className="setup-content row" id="step-1">
        <div className="form-group col-md-12">
       
          <div className="avatar-wrapper">
            <img className="profile-pic" src={props.profilePic ?config.FILE_URL+props.profilePic : '../../images/camera_icon.jpg'} />
            <label for="profilePic">
                 {currentPage === "choose-img" ? (
            <div className="upload-button stroke_btn" style={{display:'flex', alignItems:'center'}}>
              <FaUpload /> 
        <FileInput setImage={setImage} onImageSelected={onImageSelected} name={'profilePic'}/>
        </div>
      ) : currentPage === "crop-img" ? (
        <ImageCropper
          image={image}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
          
        />
      ):''}
           
            </label>

            {/* <input id="profilePic"  name="profilePic" type="file" accept="image/*" onChange={(e)=>{fileSelectedHandler(e)}} /> */}

          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">{t("SignUpNameLabel")}</label>
          <input
            name="userName"
            maxLength={100}
            value={props.userName}
            type="text"
            className="form-control"
            placeholder="Enter full name"
            onChange={handleChange}
            required
          />
          <span style={{ color: "red" }}>{errors["userName"]}</span>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">{t("SignUpEmailLabel")}</label>
          <input
            maxLength={100}
            type="email"
            name="emailId"
            className="form-control"
            onChange={handleChange}
            placeholder="Enter email"
            value={props.emailId}
            required
          />
          <span style={{ color: "red" }}>{errors["email"]}</span>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">Gender</label>
          <Select
            options={gender}
            value={{ label: props.gender, value: props.gender }}
            onChange={(e) =>
              handleChangeDropDown({ name: "gender", value: e.label })
            }
          />
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">D.O.B</label>
          <input
            name="dateOfBirth"
            maxLength={100}
            value={props.dateOfBirth? moment(props.dateOfBirth).format('YYYY-MM-DD'):''}
            type="date"
            className={`form-control ${props.dateOfBirth ? "" : "date-time-picker"}`}
            placeholder="Enter date of birth"
            onChange={(e) => {
              if ((moment().format('YYYY-MM-DD')) < moment(e.target.value).format('YYYY-MM-DD')) {
                alert('can not select')
              }
              else {
                handleChange({target:{name:'dateOfBirth', value: new Date(e.target.value).toISOString()}})
              }


            }}
            required
          />
          <span style={{ color: "red" }}>{errors["dateOfBirth"]}</span>
        </div>

        <div className="form-group col-md-6">
          <label className="control-label">{t("SignUpNumberLabel")}</label>
          <input
            maxLength={10}
            name="phoneNumber"
            type="tel"
            value={props.phoneNumber || ""}
            className="form-control"
            onChange={(e) => {
              vlidateNumberField(e) && handleChange(e);
            }}
            placeholder="Enter number"
            required
          />
        </div>

        <div className="form-group col-md-6">
          <label className="control-label">Alternative Phone Number</label>
          <input
            maxLength={10}
            name="alternatePhoneNumber"
            type="tel"
            value={props.alternatePhoneNumber || ""}
            className="form-control"
            onChange={(e) => {
              vlidateNumberField(e) && handleChange(e);
            }}
            placeholder="Enter alternative number"
          />
        </div>

        <div className="form-group col-md-6">
          <label className="control-label">My Manager Name</label>
          <input
            name="managerName"
            type="text"
            value={props.managerName || ""}
            className="form-control"
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Enter manager name"
          />
        </div>

        <div className="form-group col-md-6">
          <label className="control-label">Manager Phone Number</label>
          <input
            maxLength={10}
            name="managerNumber"
            type="tel"
            value={props.managerNumber || ""}
            className="form-control"
            onChange={(e) => {
              vlidateNumberField(e) && handleChange(e);
            }}
            placeholder="Enter manager number"
          />
          <label className="check_bx inside_label">
            <input
              type="checkbox"
              onChange={() =>
                handleChange({
                  target: { value: !props.contactManager, name: "contactManager" },
                })
              }
              checked={props.contactManager}
              value={props.contactManager}
              name="contactManager"
            />
            <span className="checkmark" /> Contact my manager first
          </label>

        </div>
        <div className="form-group col-md-12">
          <label className="control-label">Address</label>
          <textarea
            placeholder="Enter Address"
            name="address"
            className="form-control"
            onChange={handleChange}
            value={props.address}
          />
        </div>

        <div className="form-group col-md-4">
          <label className="control-label">{t("*Country")}</label>

          <Select
            options={countries}
            isClearable
            value={{ label: props.country, value: props.country }}
            onChange={(e) =>
              handleChangeDropDown({ name: "country", value: e ?.value })
            }
          />

          <small>{props.countryError}</small>

        </div>


        <div className="form-group col-md-4">
          <label className="control-label">{t("*State")}</label>
          <Select
            isClearable
            options={StateOptions}
            value={{ label: props.state, value: props.state }}
            onChange={(e) =>
              handleChangeDropDown({ name: "state", value: e ?.label })
            }
          />
          {/* {!props.country &&
            <small>Country is required</small>
          } */}
        </div>
        <div className="form-group col-md-4">

          <label className="control-label">{t("*City")}</label>
          <Select
            isClearable
            options={districtOptions}
            value={{ label: props.city, value: props.city }}
            onChange={(e) =>
              handleChangeDropDown({ name: "city", value: e ?.label })
            }
          />
          {/* {!props.country &&
            <small>Country is required</small>
          } */}
          {/* <label className="check_bx inside_label">
            <input
              type="checkbox"
              name="field"
              onChange={() =>
                handleChange({
                  target: { value: !props.outSideIndia, name: "outSideIndia" },
                })
              }
              value={props.outSideIndia}
              name="outSideIndia"
            />
            <span className="checkmark" /> {t("SignUpOutsideCheckLabel")}
          </label> */}
        </div>
        {/* <div className="form-group col-md-4">
          <label className="control-label">Hourly Work Price in (INR)</label>
          <input
            maxLength={10}
            name="hourlyRate"
            type="text"
            value={props.hourlyRate || ""}
            className="form-control"
            onChange={(e) => vlidateNumberField(e) && handleChange(e)}
            placeholder="Enter price"
            required
          />
        </div> */}
        <div className="form-group col-md-4">
                  <label className="control-label">{t("*Currency")}</label>

                  <Select
                    options={currencyOptions}
                    isDisabled={true}
                    isClearable
                    value={{ label: props.currency, value: props.currency }}
                    onChange={(e) =>
                      handleChangeDropDown({ name: "currency", value: e ?.value })
                    }
                  />
                </div>
                <div className="form-group col-md-8">
                  <label className="control-label">Job category</label>

                  <Select
                    options={profession}
                    isClearable
                    value={{ label: props.profession, value: props.profession }}
                    onChange={(e) =>
                      handleChangeDropDown({ name: "profession", value: e?.value })
                    }
                  />
                </div>

       {/* <div className="col-sm-12 tax-details-container">
       <div className="form-group col-sm-4">
          <label className="control-label">
           PAN
          </label>
          <span className=" tooltp" >
            <span className="field-info">
            <img height={10} src={process.env.PUBLIC_URL+'icon/question-mark.svg'} alt="" />
            </span>
          <span class="tooltiptext">PAN No is required for generating Invoice.</span>
          </span>
          <input
            type="text"
            name="PAN"
            className="form-control"
            placeholder='Enter PAN No'
            value={props?.PAN}
            onChange={handleChange
            }
          />
          </div>
          <div className="form-group col-sm-4">
          <label className="control-label">
           Name on PAN
          </label>
          <span className=" tooltp" >
            <span className="field-info">
            <img height={10} src={process.env.PUBLIC_URL+'icon/question-mark.svg'} alt="" />
            </span>
          <span class="tooltiptext">Name is required for generating Invoice.</span>
          </span>
          <input
            type="text"
            name="nameOnPan"
            className="form-control"
            placeholder='Enter Name as per PAN'
            value={props?.nameOnPan}
            onChange={handleChange
            }
          />
        </div>
        <div className="form-group col-sm-4">
          <label className="control-label">
           GST No.
          </label>
          <span className=" tooltp" >
            <span className="field-info">
            <img height={10} src={process.env.PUBLIC_URL+'icon/question-mark.svg'} alt="" />
            </span>
          <span class="tooltiptext">GST No is required for generating Invoice.</span>
          </span>
          <input
            type="text"
            name="GST"
            className="form-control"
            placeholder='Enter Your GST No'
            value={props?.GST}
            onChange={handleChange
            }
          />
        </div>
       </div> */}
        <hr />
        <div className="form-group col-md-12">
          <p>
            <strong>Lanugages</strong>
          </p>
          <div className="row">
            <div className="col-md-4">
              <label>Speak</label>
              <AsyncSelect
                cacheOptions
                defaultOptions={[]}
                components={{ NoOptionsMessage }}
                loadOptions={loadOptionsResolveLanguage}
                {...defaultProps}
                isMulti
                value={_.map(props.languagesSpeak, (val) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(e) =>
                  handleChangeDropDown({
                    name: "languagesSpeak",
                    value: _.map(e, (val) => val.label),
                  })
                }
              />
            </div>
            <div className="col-md-4">
              <label>Read</label>
              <AsyncSelect
                cacheOptions
                defaultOptions={[]}
                components={{ NoOptionsMessage }}
                loadOptions={loadOptionsResolveLanguage}
                className="field_height"
                {...defaultProps}
                isMulti
                value={_.map(props.languagesRead, (val) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(e) =>
                  handleChangeDropDown({
                    name: "languagesRead",
                    value: _.map(e, (val) => val.label),
                  })
                }
              />
            </div>
            <div className="col-md-4">
              <label>Write</label>
              <AsyncSelect
                cacheOptions
                defaultOptions={[]}
                components={{ NoOptionsMessage }}
                loadOptions={loadOptionsResolveLanguage}
                {...defaultProps}
                isMulti
                value={_.map(props.languagesWrite, (val) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(e) =>
                  handleChangeDropDown({
                    name: "languagesWrite",
                    value: _.map(e, (val) => val.label),
                  })
                }
              />
            </div>

          </div>
        </div>
        <div className="form-group col-md-12">
          <label className="control-label">
           Bio
          </label>
          <textarea
            type="text"
            rows={5}
            name="bio"
            className="form-control"
            placeholder='Write your bio here in maximum 3000 charecters.'
            value={props?.bio}
            onChange={(e)=> {
              if(e.target?.value?.length <= 3000) {
                setBioLength(e.target?.value || "");
                handleChange(e);
              };
            }
            }
          />
          <div ><small>Charecter left: {3000 - bioLength.length}</small></div>
          </div>

        <div className="col-sm-12 text-right mb-4">


          <button
            type="submit"
            className="btn btn-primary nextBtn btn-lg blue_btn"
            style={{ background: "#1683f3", marginRight: "20px" }}
            onClick={submitForm}>

Save changes
          </button>

          <button
            className="btn btn-primary nextBtn btn-lg blue_btn"
            onClick={nextStep}
          >
            {t("ContinueBtn")}
          </button>{" "}
        </div>
      </div>
    </>
  );
}
export default PersonalInfo;
