import React from 'react';
import { Link } from 'react-router-dom';

const TermsPopup = ({handleChange}) => {
    return(
        <>   

      <div class="modal fade" id="terms" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Terms & Condition 344</h4>
        </div>
        <div class="modal-body">
            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>  
            <hr />
            <h6>Lorem ipsum dolor sit amet</h6>
            <p>Unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            </p>  
            <hr />
            <h6>Lorem ipsum dolor sit amet</h6>
            <p>Unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            </p> 
            <hr />
            <h6>Lorem ipsum dolor sit amet</h6>
            <ul>
             <li>Voluptatem accusantium doloremque laudantium</li>
            <li>Unde omnis iste natus error sit voluptatem accusantium doloremque </li>
            <li>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</li>
           </ul>
           <br />
           <p>Aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Sed ut perspiciatis unde omni.
            </p> 
          <div class="application_btn text-right">
            <hr />
        <button type="submit"  data-dismiss = 'modal' className="blue_btn" onClick = {() => handleChange({target: {name:'agreed' , value : true}})}>I Agree the Terms</button>
        </div>       
          </div>         
          
        </div>       
        </div>        
      </div>

</>
);
}

export default TermsPopup;