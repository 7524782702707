import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class SportsCelebrityManagementCompany extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>Modeling Agency</h1>
                  <p align="justify">The aim of becoming a model is quite fascinating and it needs lots of hard work to maintain that body and confidence. To make the journey of prospective models easy and convenient, we BollywoodisGood assist them. There is a requirement of models for various projects like advertisements, product shoots, print ads, fashion shows, Catalogues shoots, web series and events.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/modeling-agency.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/celebrity-modeling-services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Modeling Services</h2>
          </div>
          <p align="justify">There is a chance that they may get a chance in movies. Therefore, to make all the process easy we have built this portal. We are considered as one of the leading Modeling agency and to maintain this reputation, we make sure that every company or model that is being associated with us are fully satisfied. BollywoodisGood has worked with leading and reputed top advertising agency, top music companies, best production house, top TV channels, top choreographers, top designers and top actors & models.</p>
          <br/>
          <p align="justify">We feel proud that we got the opportunity to work with some great and popular top & high profile companies , directors , production houses , advertising agencies , celebrities , designers from all over the world. BollywoodisGood is a platform that has become a platform where the models get the work and the companies get the best and talented models. We have great portfolios of models and are talented, smart and highly professional. Our managers are well qualified and have huge experience in the same field. They know the models and projects and can very professionally associate both the parties.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Celebrity Modeling Company</h2>
          </div>
          <p align="justify">BollywoodisGood provides excellent opportunities for the people who are motivated and are looking for a challenging future in an entertainment industry in the field of Indian cinema, fashion, media & advertisement. The services we offer are portfolio management, model co-ordination, casting agency, fashion show organizer, event organizer, grooming classes and many more. We make sure that every model and projects that we manage becomes a success and for that we hire highly experienced managers and keep updating the company with latest trends in the entertainment industry.</p>
          <br />
          <p align="justify">There are plenty of modeling agencies but the risk of reputation and work also get attached with the agency, therefore choosing the right one is very important. Any company or models should go through the previous work, portfolio and feedbacks of the agency before getting associated with them. And BollywoodisGood has all these features and is very much reputed in the Industry.</p>
         
          </div>
          <div class="col-sm-5">
          <img src="images/modeling-company.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
        <h2>Best Modeling Agency</h2>
        </div>
          <p align="justify">We have worked with renowned companies and very popular models. They have even given extremely positive feedbacks which has increased our customer base and reputation in the market. It’s very motivating when your existing clients appreciate your work and through word of mouth spread the tremendous work you do. We as a modeling agency has gained that reputation in the market and always been appreciated for our work. You can easily get in touch with us to know more about the process and payment methods.</p>
        <br />
         <p align="justify">We are here to help you and make your journey towards the world of entertainment easy and successful. Our managers will assist and guide you regarding every details you need to associate with us.</p>
          </div>
          </section>         
        <Footer />
      </>
    );
  }
}

export default SportsCelebrityManagementCompany;
