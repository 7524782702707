import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaStar, FaRegEdit, FaSearch, FaTimes, FaAngleLeft, FaAngleRight, FaHeart, } from 'react-icons/fa';
import _ from 'lodash';
import moment from 'moment';
import Pagination from '../Pagination/Pagination';
import { FaFilm, FaMapMarkerAlt } from 'react-icons/fa';

const SearchAuditionList = ({ data, onChangeSearch, savedJobs, appliedJobs, setToggleFilter, onChangeFilter }) => {
  let pageSize = 10;
  const user = localStorage.getItem('user');
  const myId = user ? JSON.parse(user)?._id : '';
  const [auditioList, setauditioList] = useState([])
  const [pageIndex, setPageIndex] = useState(1);
  console.log(myId, 'hhhhh')

  useEffect(() => {
    if (data) {
      let d = data.slice(0, pageSize)
      setauditioList(d)
    }
  }, [data])
  const pageChangeHandler = (index) => {
    console.log(index)
    const slicedList = data.slice((index - 1) * pageSize, (index * pageSize))
    setauditioList(slicedList)
    setPageIndex(index)

  }
  return (
    <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 search_job_list" onClick={() => { setToggleFilter(true) }}>
      <div className="inside_listing_tab">
        <ul className="nav nav-tabs">
          <li className="active">
            <Link to="#list_tab1" data-toggle="tab">
              Search
            </Link>
          </li>
          <li>
            <Link to="#list_tab2" data-toggle="tab">
              Applied Audition<span>({appliedJobs.length})</span>{" "}
            </Link>
          </li>
        </ul>

        <div className="tab-content">
          <div className="tab-pane active" id="list_tab1">
            <div className='flex_bx'>
              <div className="form-group">
                <input
                  type="search"
                  placeholder="Search for audition"
                  className="form-control"
                  onChange={(e) => onChangeSearch(e.target.value)}
                />
              </div>
              <div className="filter_points">
                <div id="group-2" className="list-group collapse in">
                  <p className="list-group-item full_field"><input onChange={e => onChangeFilter({ type: 'location', value: e.target.value })} type="search" placeholder="Search client location" className="form-control" /> </p>
                </div>
              </div>
              <button type="button" className="blue_btn">
                <FaSearch /> Search
              </button>
            </div>
            <div className='row'>
              {_.map(auditioList, (val) => (
                <>
                  <div className='col-md-4 custom_space'>
                    <div className="outline_bx">
                      <div className="audition_date">
                        <img src="images/making_film.jpg" />
                      </div>
                      <div className="breakdown_casting_info audition_info">
                        <div className='audtion_content'>
                          <Link to={`/AuditionDetail/${val._id}`}>
                            <div className="casting_list_head clearfix">
                              <h3>{val.title}</h3>
                              <p className='audi_date'><strong> Audition : </strong> {moment(val.auditionStartDate).format("MMM")}
                                {val.auditionStartDate !== null && <>{moment(val.auditionStartDate).format(" D")}</>}
                                {val.auditionStartDate !== null && <>
                                  {moment(val.auditionStartDate).format(", ddd")}
                                </>}</p>
                              <p>
                                {/* <div>
                            <strong>Title : </strong> {val.title}
                          </div> */}
                                {/* {val.auditionType && <span><strong> Audition Type : </strong>
                            {val.auditionType}</span>} 
                          
                            {val.actorType &&<span>
                            {val.actorType?.join()}</span>}*/}

                                {val.auditionLocationCity && <span> <FaMapMarkerAlt />
                                  {val.auditionLocationCity}</span>}

                                {val.filmType && <span> <FaFilm />
                                  {val.filmType?.join(", ")}</span>}

                                {/* {val.minAge && <span><strong>Age : </strong>
                           {val.minAge} - {val.maxAge}</span>}
                          
                          {val.languagesSpeak && val.languagesSpeak.length ? <span><strong>Language Speak : </strong>
                          {val.languagesSpeak?.join(", ")}</span> : ''} */}
                              </p>
                            </div>
                          </Link>
                          <p className="brief_intro">{val.characterDescription}</p>
                          <small><strong>Post on : </strong>{" "}
                            {moment(val.createdAt).format("ll")}</small>
                        </div>
                        <hr />
                        <div className="searchjob_point searchjob_bx">
                          <div className="application_info">

                            {val.userId === myId && <Link style={{ marginRight: 10 }} to={"/chat?auditionId=" + val._id}>Chat</Link>} {" "}
                            <p>
                              Application: <strong>{val?.applications?.length || 0}</strong>
                            </p>
                            {
                              val?.appliedUsers && val?.appliedUsers.find(e => e === val.userId) ?
                                <span style={{ color: '#ff3838', fontSize: '15px', textTransform: 'uppercase', fontWeight: '600' }}>Applied</span>
                                :
                                val.userId !== myId ? (val.appliedUsers.includes(myId) ? <span> Applied</span> : <Link to={`/UserSideAudition/${val._id}`}> Apply</Link>) : ''}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div class="pagination_part clearfix text-right">
              <Pagination totalCount={data?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} />
            </div>
          </div>

          <div className="tab-pane" id="list_tab2">
            <div className="row">
              {_.map(appliedJobs, (val) => (
                <div className='col-md-4 custom_space'>
                  <div className="outline_bx">
                    <div className="audition_date">
                      <img src="images/making_film.jpg" />
                    </div>
                    <div className="breakdown_casting_info audition_info">
                      <div className='audtion_content'>
                        <Link to={`/AuditionDetail/${val._id}`}>
                          <div className="casting_list_head clearfix">
                            <h3>{val.title}</h3>
                            <p className='audi_date'><strong> Audition : </strong> {moment(val.auditionStartDate).format("MMM")}
                              {val.auditionStartDate !== null && <>{moment(val.auditionStartDate).format(" D")}</>}
                              {val.auditionStartDate !== null && <>
                                {moment(val.auditionStartDate).format(", ddd")}
                              </>}</p>
                            <p>
                              {/* <span>
                            <strong>Title : </strong> {val.title}
                          </span>
                          <span>
                            <strong> Gender : </strong> {val.actorType?.join()}
                          </span> */}
                              <span>
                                <FaMapMarkerAlt /> {val.auditionLocationCity}
                              </span>
                              <span>
                                <FaFilm />
                                {val.filmType?.join(", ")}
                              </span>
                              {/* <span>
                            <strong>Age : </strong> {val.minAge} - {val.maxAge}
                          </span>
                          <span>
                            <strong>Language Speak : </strong>
                            {val.languagesSpeak?.join(", ")}
                          </span> 
                          <span>
                            <strong>Post on : </strong>{" "}
                            {moment(val.createdAt).format("ll")}
                          </span>*/}
                            </p>
                          </div>
                        </Link>
                        <p className="brief_intro">
                          {val.characterDescription}
                        </p>
                        <small><strong>Post on : </strong>{" "}
                          {moment(val.createdAt).format("ll")}</small>
                      </div>
                      <hr />
                      <div className="searchjob_point searchjob_bx">
                        <div className="application_info">
                          <p>
                            Application: <strong>{val.applications.length}</strong>
                          </p>
                          {val.userId !== myId && <Link to={"/chat?auditionId=" + val._id + '&receiverId=' + val.userId}>Chat</Link>}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              ))}
            </div>
            <div class="pagination_part clearfix text-right">
              <br />
              <ul class="pagination">
                <li>
                  <Link to="#">
                    <FaAngleLeft /> Prev
                  </Link>
                </li>
                {/* <li><Link to="#">1</Link></li>
            <li><Link to="#">2</Link></li>
            <li><Link to="#">3</Link></li>
            <li><Link to="#">4</Link></li>
            <li><Link to="#">5</Link></li> */}
                <li>
                  <Link to="#">
                    {" "}
                    Next <FaAngleRight />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SearchAuditionList;
