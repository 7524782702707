import React from 'react';
import { Link } from 'react-router-dom';
import AllApplicationTab from './AllApplicationTab';
import ShortlistedTab from './ShortlistedTab';

const ReviewApplicationsTab = (props) => {
    return (
        <div className="tab-pane" id="tab_default_3">
            <div className="shadow_bg clearfix">
                <div className="inside_listing_tab">
                    <ul className="nav nav-tabs">
                        <li className="active"><Link to="#review_tab1" data-toggle="tab">All Applications  ({Number(props.jobPostDetail ?.appliedUsers ?.length) || 0})</Link></li>
                        <li><Link to="#review_tab2" data-toggle="tab" >Shortlisted ({Number(props.jobPostDetail ?.shortListedUsers ?.length) || 0})</Link></li>
                    </ul>

                    <div className="tab-content">
                        <AllApplicationTab {...props} />
                        <ShortlistedTab {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ReviewApplicationsTab;