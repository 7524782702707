import React from "react";
import { useTranslation } from "react-i18next";

function RegisterHeader(props) {
  //const classes = useStyles();
  const { t } = useTranslation();
  //const globalStyle = globalStyles();

  return (
    <div>
      <h4>{t("SignUpPageTitle")}</h4>
      <div className="stepwizard-row setup-panel clearfix">
        <div className="stepwizard-step ">
          <a
            type="button"
            className={props.step === 1 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(1)}
          >
            <span className="btn-circle">1</span>
            <p>Personal Info</p>
          </a>
        </div>
        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 2 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(2)}
          >
            <span className="btn-circle">2</span>
            <p>Experience</p>
          </a>
        </div>
        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 3 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(3)}
          >
            <span className="btn-circle">3</span>
            <p>Award</p>
          </a>
        </div>
        <div className="stepwizard-step">
          <a
            type="button"
            className={props.step === 4 ? "btn btn-primary" : "btn btn-default"}
            onClick={() => props.changeRegisterStep(4)}
          >
            <span className="btn-circle">4</span>
            <p>Additional Info</p>
          </a>
        </div>
      </div>
    </div>
  );
}
export default RegisterHeader;
