import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import TitleAndCategory from "./TitleAndCategory";
import DetailsAndDescription from "./DetailsAndDescription";
import Expertise from "./Expertise";
import JobLocation from "./JobLocation";
import Visibility from "./Visibility";
import Budget from "./Budget";

const PostJobForm = ({ step, ...props }) => {
  console.log(step, "STEP COUNT");
  return (
    <>
      <div className="col-md-3 col-sm-4 right_space sidebar_part">
        <ul className="nav nav-tabs" style={{background:"linear-gradient(143deg, #f35524 1%, #c82538 15%, #1c1c1c 95%)"}}>
          <li className={step === 1 ? 'active1' : ''} >
            <a href="javascript:void" onClick={() => props.nextStep(1)} style={{color: step === 1 ? 'black' : ''}}> Title and Category</a>
          </li>
          <li className={step === 2 ? 'active1' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(2)} style={{color: step === 2 ? 'black' : ''}}>Details and Description{" "}</a>
          </li>
          <li className={step === 3 ? 'active1' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(3)} style={{color: step === 3 ? 'black' : ''}}> Expertise{" "}</a>
          </li>
          <li className={step === 4 ? 'active1' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(4)} style={{color: step === 4 ? 'black' : ''}}>Job Location{" "}</a>{" "}
          </li>
          <li className={step === 5 ? 'active1' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(5)} style={{color: step === 5 ? 'black' : ''}}>Visibility{" "}</a>{" "}
          </li>
          <li className={step === 6 ? 'active1' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(6)} style={{color: step === 6 ? 'black' : ''}}>Budget{" "}</a>{" "}
          </li>
        </ul>
      </div>
      <div className="col-md-9 col-sm-8">
        <div className="tab-content shadow_bg">
          {
            step === 1 ? <TitleAndCategory {...props} /> :
              step === 2 ? <DetailsAndDescription {...props} /> :
                step === 3 ? <Expertise {...props} /> :
                  step === 4 ? <JobLocation {...props} /> :
                    step === 5 ? <Visibility {...props} /> :
                      <Budget {...props} />
          }
        </div>
      </div>
    </>
  );
};

export default PostJobForm;
