import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class DigitalInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>DIGITAL INFLUENCER AGENCY</h1>
                  <p align="justify">On social media Digital Influencers are people who have already built a fame and popularity for their expertise, charisma and knowledge on a specific topic. On social media the influencers are very active and they frequently post about their diverse collaborations. With the enhancing and growing importance and use of social media platforms like Instagram, Facebook and Snapchat, influencers are considered as the most efficient marketing tool to reach to customers.</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/digital-influencer-agency.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/digital-influencer-services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Why Influencer Marketing?</h2>
          </div>
          <p align="justify">Fundamentally, with digital influencer marketing, you will be using the audience that has been already created or build by someone else. The complete use of content marketing and social media marketing is to construct a huge and faithful audience to whom you can market your product and services. The issue with these strategies is that this can takes a lot of time, enormous cost, and yet doesn’t offer any guarantee. With digital influencer marketing, you can get the audience that has already been built by someone else, and you just need to pay the influencer to market your brand/business.</p>
          <br />
          <p align="justify">The message and the post they share with their audience will influence them fast and hence, the growth of your brand/business will increase with good pace. By utilizing digital influencer marketing you fundamentally leapfrog the primary gigantic step in marketing. But finding the correct influencer for your brand/business is a hard job but for this BollywoodisGood is here to help you as a most trusted influencer marketing company.</p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Digital Influencer Services</h2>
          </div>
         <p align="justify">The significance and popularity of social media cannot be ignored and especially the influencers who are considered as most effective marketing tool these days. Digital Influencer Marketing is no more a niche marketing strategy however a plan that is considered perfect for promoting product or services. To showcase their brands Businesses are swiftly learning the benefits of collaborating with social media influencers. As a best influencer marketing firm Bollywoodis Good will not only find the perfect influencer for your brand/business but also make sure that the influencer marketing campaign enhanced the growth and ROI of the company.</p>
          <br />           
          <p align="justify">We are considered as one of the leading influencer marketing agency with all the top talents that will build your brand and make popular your products and services. BollywoodisGood, being an Influencer Marketing Services Company, assists numerous brands in brand awareness process as well as lead generation through influencers who are available at best influencer marketing platform such as YouTubers, vloggers, bloggers and other social media influencers.</p>
         
       </div>
          <div class="col-sm-5">
          <img src="images/digital-influencer-company.webp"  loading="lazy" width="458" height="400" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">     
          <div class="col-sm-12">
          <div class="text-center inner_head">
          <h2>Best Digital Influencer Marketing Company</h2>
          </div>
          <p align="justify">With our expert team we generate and implement influencer marketing strategies to intensify your brand story and arrive at the preferred target audience. Get connected with us and build your brand and business in the market through influencer marketing that have a real impact on the audience which resonates with your fans and helps in sale and brand awareness.The best thing about influencers is that they are not only followed by local followers, but they are popular on a global level as well. Our enthralling team of professionals is like an expert who knows how to connect your brand with your potential customers. We are over the best influencer marketing platform over like – snapchat, instagram and facebook.</p>
           </div>
          </div>        
          </section> 

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h4>We go after a systematic process which consists of:</h4>
          </div>
          <br />
          <ul>
            <li> Setting the strategy on what is your brand/business all about and what you exactly want with how the influencer can help you with it.</li>
            <li>For the campaign recognize the most appropriate influencers</li>
            <li>Examine the brand fit, quality of content, followers numbers and numerous other aspects of shortlisted influencers</li>
            <li>Get in touch with the influencers and negotiate terms</li>
            <li> And the final one is to increase your ROI and take your brand at the top level.</li>
          </ul>
          <br />
         <p align="justify">In today’s era it has become mandatory to have a social media account of your brand/business and go for digital influencer marketing. But doing it on your own is not an easy process, so you will be needed a reputed and leading digital influencer agency like BollywoodisGood. We not only help you in searching the right influencer but also help you in making the campaign as well as will be with you throughout the process.</p>
        </div>
          <div class="col-sm-5">
          <img src="images/digital-marketing-with-influencers.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section> 

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h4>BollywoodisGood offers benefits regarding digital influencer marketing</h4>
          </div>
        <ul>
          <li>You get experts and professional team for your brand promotion</li>
          <li>The ideal influencer is searched by the team</li>
          <li> The process of endorsement and result is taken care by BollywoodisGood</li>
          <li>Identification of target audience and working as per the strategy is done by the team</li>
          <li>Complete support of experts during the campaign of influencer marketing</li>
          </ul>
        <br />
          <div class="inner_head">
          <h4>There are various category of digital influencer marketing like</h4>
          </div>
        <ul>
          <li>Automobile</li>
          <li>Beauty/Make-up</li>
          <li>E-Commerce</li>
          <li>Fashion</li>
          </ul>       </div>
          <div class="col-sm-5">
          <img src="images/social-media-influencer-marketing-solutions.webp"  loading="lazy" width="458" height="400" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

           
        
           
        <Footer />
      </>
    );
  }
}

export default DigitalInfluencer;
