import React, { Component } from 'react';
import SearchAuditionPage from "../../pages/SearchAudition/SearchAuditionPage";
import { getAuditionList, getJobListUserSide, saveJobs, searchAuditions, getAppliedobs, getCurrentUser, getAppliedAuditions, getHiredContracts, loginUser, updateMilestone, generateInvoice, getGstDetails, getCommissionRate, updateMilestoneCompleted } from '../../actions/index';
import _ from 'lodash';
import swal from 'sweetalert';
import HiredContractsPage from '../../pages/HiredContracts/HiredContractsPage';
import moment from 'moment';
let timer = "";

class HiredContractContainer extends Component {
 allData=[];
    state = {
        filters: [{ type: 'status', value: 'All' }],
        search: '',
        
    };
    updateMilestoneValues = (e,index) => {
        const {data} = _.cloneDeep(this.state);
        console.log(data, e, "data");
        data[index][e.key][e.index][e.name] = e.value;
        this.setState({ data:data });
    }
    onChangeSearch = search => {
     
    }

    componentDidMount() {
        // this.getAuditionList("");
         this.getCurrentUser();
         getGstDetails().then(res =>{
            this.setState({getGstDetails:res.data.data})
          }).catch(e=>{});
      
          getCommissionRate().then(res =>{
            this.setState({commissionRate: res.data &&  res.data.data && res.data.data.length && res.data.data[0] ? res.data.data[0]:{}})
          }).catch(e=>{})
    };

    getContracts = (userId,pageIndex = 1) => {
        getHiredContracts({userId, pageIndex})
            .then(res => {
                let complete = (res.data?.data[0]?.search || []).filter(f => !!(f.milestone.find( m=> m.status === 'Completed')) )
                this.setState({ data: _.filter(res.data?.data[0].search), completedList: complete })
                this.allData = res.data?.data[0]?.search;
            })
    }


    onChangeFilter = e => {
        console.log(e)
        let { filters, search } = this.state;
        const alreadyPresent = _.find(filters, { ...e });
        if (_.isEmpty(alreadyPresent)) {
            this.setState({ filters: _.uniqBy([e, ...filters], 'type') }, () => {});
        }
        else {
            const newFilters =  _.filter(filters, val => val.type != e.type)
            this.setState({ filters: newFilters}, () => {});
        }
        if (e.type === 'fromDate' || e.type === 'toDate' ) {
            const filteredData = e.type === 'fromDate' ? this.allData.filter(f => moment(f.updatedAt).isSameOrAfter(moment(e.value),'date')) : this.allData.filter(f =>moment(f.updatedAt).isSameOrBefore(moment(e.value),'date') )
            this.setState({data: filteredData})
        }else{

            if (e.value !== "All") {
                const filteredData = this.allData.filter(f => !!(f.milestone.find( m=> m.status === e.value)) )
            this.setState({data: filteredData})
            }else{
                this.setState({data: this.allData})
        }
        }
        
    }


    getCurrentUser = () => {
        getCurrentUser((user) => {
            this.setState({ currentUser: user, appliedJobs: user.appliedJobs, savedJobs: user.savedJobs });
            this.getContracts(user._id)
        })
    }
    getInvoice = (transactionId) =>{

    }
    updateStatus = (milestoneId, index, isStatusCompleted) =>{
        if(isStatusCompleted){
            updateMilestoneCompleted({id:milestoneId,data:this.state.data[index]}).then(res =>{
                this.getContracts(this.state.currentUser._id)
            }).catch(e =>{
                this.getContracts(this.state.currentUser._id)
            })
        }else{
        updateMilestone({id:milestoneId,data:this.state.data[index]}).then(res =>{
            this.getContracts(this.state.currentUser._id)
        }).catch(e =>{
            this.getContracts(this.state.currentUser._id)
        })
    }
        // const body={
        //     amount: paymentRes.amount,
        //     contractId: paymentRes.contractId,
        //     payee: paymentRes.payee,
        //     payer: paymentRes.payer,
        //     transactionId: paymentRes.transactionId
        // }
        // generateInvoice(body).then(e =>{}).catch(e =>{})
        // generateInvoice(body).then(e =>{}).catch(e =>{}) // for Aggregator
        // generateInvoice(body).then(e =>{}).catch(e =>{})
    }
    render() {
        // console.log(this.state, "dkejdedei");
        return <HiredContractsPage
            {...this.state}
            {...this.props}
            updateMilestoneValues={this.updateMilestoneValues}
            onChangeFilter={this.onChangeFilter}
            onChangeSearch={this.onChangeSearch}
            saveJobs={this.saveJobs}
            getInvoice={this.getInvoice}
            updateStatus = {this.updateStatus}
            getContracts={this.getContracts}
            allData={this.allData}
        />
    }
}
export default HiredContractContainer;
