const SET_PROJECT_NAME = "SET_PROJECT_NAME";
const SET_ANALYSIS_DATA = "SET_ANALYSIS_DATA";
const FETCH_ANALYSIS_DATA_ON_FAILURE = "FETCH_ANALYSIS_DATA_ON_FAILURE";
const SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS";
const FETCH_PROJECT_DETAILS_ON_FAILURE = "FETCH_PROJECT_DETAILS_ON_FAILURE";
const SET_VESSEL_LIST = "SET_VESSEL_LIST";
const FETCH_VESSEL_LIST_ON_FAILURE = "FETCH_VESSEL_LIST_ON_FAILURE";
const FETCH_CAPTURE_CONDITIONS_OF_AREA_ON_FAILURE = "FETCH_CAPTURE_CONDITIONS_OF_AREA_ON_FAILURE";
const FETCH_MAX_FRAME_NO_ON_FAILURE = "FETCH_MAX_FRAME_NO_ON_FAILURE";
const COMMONDATA = "COMMONDATA";
const GETCOMMENTLISTDATA = 'GETCOMMENTLISTDATA';
const COMMENTLIST_ADD_COMMENT = 'COMMENTLIST_ADD_COMMENT';
const USER_SIGNUP = 'USER_SIGNUP';
export default {
    USER_SIGNUP,
};
