import React, { Component } from 'react';
import { getInvoiceData } from '../../actions';
import ArtistInvoicePage from '../../pages/ArtistInvoice/ArtistInvoicePage';
import invoiceDataModal from '../../core/services/invoiceData';
class ArtistInvoiceContainer extends Component {
    state={invoiceData:null}
componentDidMount (){
    getInvoiceData(this.props.transactionId).then( res =>{
        if (res && res.data && res.data.data ) {
            console.log(res.data)
            this.setState({invoiceData :res.data.data.ArtistInvoice})
        }
//           setInterval(() => {
//     invoiceData.invoiceNo = 'svdfsdgfssd fdsus'
//   }, 1000);
    })

}
// componentDidUpdate(){
//     getInvoiceData(this.props.transactionId).then( res =>{
//         if (res && res.data ) {
//             console.log(res.data)
//             this.setState({invoiceData :res.data.data})
//         }
//     })

// }
    render() {
        
        return (
            <div>
               {this.state.invoiceData &&  <ArtistInvoicePage  invoice={this.state.invoiceData} transactionId={this.props?.transactionId || 'tnx2022-327432665'}/>}
            </div>
        );
    }
}

export default ArtistInvoiceContainer;
