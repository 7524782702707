import React, { Component } from 'react';
import FindTalentPage from "../../pages/FindTalent/FindTalentPage";
import { getCurrentUser, getTalentUserSide } from '../../actions/index';
import _ from 'lodash';
let timer = "";

class FindTalentPageContainer extends Component {

    state = {
        savedJobs: [],
        appliedJobs: [],
        filters: [{ type: 'visibility', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }],
        search: '',
        pageIndex:1,
        loading: false
    };

    resetAllFilters = () => {
        this.setState({ filters: [{ type: 'visibility', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }], search: '' }, () => this.getTalent(""));

    }

    onChangeSearch = search => {
        this.setState({ search });
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.getTalent(search);
        }, 300);
    }


    componentDidMount() { this.getTalent(""); this.getCurrentUser() };

    getTalent = (search) => {
        this.setState({loading: true})
        getTalentUserSide({ filters: JSON.stringify(_.filter(this.state.filters, val => val.value != 'All')), search: search,page: this.state.pageIndex })
            .then(res => {
                this.setState({ data: (res.data ?.data[0] ?.search || []), totalCount: (res.data ?.data[0] ?.totalCount[0].total || 0)});
        this.setState({loading: false})
            
            }).catch((e)=>{
        this.setState({loading: false})
        this.setState({ data: [], totalCount: 0});
            })
    }


    onChangeFilter = (e,multiselect) => {
        console.log('e    ' , e)
        console.log('multiselect    ' , multiselect)
        var statename;   if(e.type == "state") { statename  = e.value}  else { statename = this.state.stateval }
        console.log(this.props)
        let { filters, search } = this.state;
        
        const alreadyPresent = multiselect != 'multiselect'?_.find(filters, { ...e }) : null;

        // const alreadyPresent2 = filters.find((val)=>  val.type == e.type )

        // if(multiselect == 'multiselect' && alreadyPresent2){
        //     let response =  alreadyPresent2.value.find((data)=>  data == e.value )
        //     if(response){
        //         e.value = alreadyPresent2.value.filter((data)=> data != response )
        //     }else{
        //         e.value = [...alreadyPresent2?.value,...e.value]
        //     }
        // }

        const pureFilters =  _.uniqBy([e, ...filters], 'type').filter(e => e.value)
        
        if (_.isEmpty(alreadyPresent)) {
            this.setState({ filters: pureFilters , stateval:statename }, () => this.getTalent(search));
        }
        else {
            this.setState({ filters: _.filter(filters, val => val.type != e.type) }, () => this.getTalent(search));
        }
    }





    // saveJobs = id => {
    //     saveJobs(id)
    //         .then(res => {
    //             localStorage.setItem('user', JSON.stringify(res.data.data))
    //             this.getTalent(this.state.search);
    //             this.getCurrentUser();
    //             swal(`Done`, { buttons: false, timer: 1700, icon: 'success' });
    //         });
    // }

    getCurrentUser = () => {
        // getSavedJobs()
        //  .then(res => this.setState({savedJobs : res.data.data}));
        getCurrentUser((user) => {
            this.setState({ currentUser: user, appliedJobs: user.appliedJobs, savedJobs: user.savedJobs });
        })
    }

    // getAppliedJobs = () => {
    //     getAppliedobs()
    //         .then(res => {
    //             this.setState({ appliedJobs: res.data.data });
    //             //this.getAppliedJobs();
    //         });
    // };
    resetAllFilters = () => {
        this.setState({ filters: [{ type: 'visibility', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }], search: '' }, () => this.getTalent(''));
        this.setState({resetFilters: true}, ()=>{
           
                this.setState({resetFilters: false})
            
        })

    }
    pageChangeHandler =(index)=>{
        this.setState({pageIndex: index}, ()=>{this.getTalent()})
    }
    defaultProps = {
        placeholder: "Search Languages"
    }

    render() {
        console.log(this.state, "dkejdedei");
        return <FindTalentPage
            {...this.state}
            {...this.props}
            defaultProps={this.defaultProps}
            getSavedJobs={this.getSavedJobs}
            getAppliedJobs={this.getAppliedJobs}
            onChangeFilter={this.onChangeFilter}
            onChangeSearch={this.onChangeSearch}
            resetAllFilters={this.resetAllFilters}
            saveJobs={this.saveJobs}
            resetFilters={this.state.resetFilters}
            pageChangeHandler={this.pageChangeHandler}
        />
    }
}
export default FindTalentPageContainer;
