import React, { Component } from "react";
import SubmitApplicationPage from "../../components/SubmitApplication/Index";
import { getJobPostDetail, submitApplication, uploadFiles } from '../../actions/index';
import _ from 'lodash';
import swal from 'sweetalert';
import PopUp from "../ApplyAudition/Popup";

class SubmitApplicationContainer extends Component {

  state = {
    jobPostDetail: {},
    showPopup: false, // State to control the Popup visibility
  };

  componentDidMount() {
    if (this.props.match?.params?.id) {
      getJobPostDetail(this.props.match.params.id)
        .then(res => this.setState({ jobPostDetail: res.data?.data }));
    }
  }

  handleChangeValues = e => {
    if (e.index || e.index === 0) {
      const answers = _.cloneDeep(this.state.answers) || [];
      answers[e.index] = e.value;
      this.setState({ answers });
    }
    else {
      this.setState({ [e.name]: e.value })
    }
  };


  // handleSubmit = () => {
  //   submitApplication(this.state)
  //     .then(res => this.props.history.push('/SearchJob'));
  // }

  handleSubmit = (e, published) => {
    console.log(e, "sdwwd");
    e.preventDefault();

    submitApplication({ ...this.state, published })
      .then((res) => {
        this.props.history.push('/SearchJob'); // Navigate on success
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          const errorMessage = error.response.data.Error;
          if (errorMessage) {
            swal({
              title: "Access Exclusive Features!",
              // icon: "error",

              content: {
                element: "div",
                attributes: {
                  innerHTML: `
                   
                  <p style="text-align: center;font-size:18px">Subscribe now to unlock premium opportunities.
Or refer a friend and earn credits to access BiG for free!</p>`,
                  // Center the text❌😟⚠️🚫 ${errorMessage} <div style="font-size: 40px; margin-bottom: 25px;">❌</div>
                  // Subscribe now to unlock premium opportunities.Or refer a friend and earn credits to access BiG for free!
                },
              },
              buttons: {
                aaaa: {
                  text: "Refer a Friend",
                  value: "referal",
                  visible: true,
                  className: "blue_btn",
                },
                bbbb: {
                  text: "Subscribe Now",
                  value: "renew",
                  visible: true,
                  className: "blue_btn",
                },
              },
            }).then((value) => {
              if (value === "referal") {
                window.location.href = "#/Referal";
              }
              if (value === "renew") {
                // Show the Popup component instead of navigating
                this.setState({ showPopup: true });
              }
            });
            setTimeout(() => {
              const modal = document.querySelector(".swal-modal");
              const closeButton = document.createElement("button");
              closeButton.innerHTML = "&times;";
              closeButton.style.position = "absolute";
              closeButton.style.top = "5px";
              closeButton.style.right = "5px";
              closeButton.style.background = "transparent";
              closeButton.style.border = "none";
              // closeButton.style.borderRadius = "50%";
              closeButton.style.fontSize = "30px";
              closeButton.style.cursor = "pointer";
              modal.appendChild(closeButton);
              closeButton.addEventListener("click", () => swal.close());
            }, 0);
          }
        }
      });
  };


  // handleSubmit = (e, published) => {
  //   console.log(e, "sdwwd");
  //   e.preventDefault();

  //   submitApplication({ ...this.state, published })
  //     .then((res) => {
  //       this.props.history.push('/SearchJob'); 
  //       swal(`Job Applied Successfully`, { buttons: false, timer: 2000, icon: 'success' });
  //     })
  //     .catch((error) => {
  //       if (error && error.response && error.response.data) {
  //         const errorMessage = error.response.data.Error;
  //         if (errorMessage) {
  //           Swal.fire({
  //             title: "Subscription Error",
  //             text: errorMessage,

  //             showCloseButton: true, 
  //             buttonsStyling: false,
  //             customClass: {
  //               closeButton: 'custom-close-button', 
  //             },
  //             showCancelButton: true,
  //             showDenyButton: true,
  //             cancelButtonText: "Refer a Friend",
  //             denyButtonText: "Get Subscription",
  //             reverseButtons: true,
  //           }).then((result) => {
  //             if (result.isDenied) {

  //               this.setState({ showPopup: true });
  //             }
  //           });
  //         }
  //       }
  //     });
  // };

  closePopup = () => {
    this.setState({ showPopup: false }); // Close the Popup
  };

  fileSelectedHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const configs = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const targetName = e.target.name;
    try {
      const res = await uploadFiles(formData, configs)
      if (res && res.data && res.data.data) {
        this.setState({ [targetName]: res.data.data.filename });
      }
    } catch (ex) {
      console.log('upload error', ex);
    }
  }

  render() {
    console.log(this.state, "kedjeijde");
    return (
      <><SubmitApplicationPage
        {...this.state}
        handleChangeValues={this.handleChangeValues}
        handleSubmit={this.handleSubmit}
        fileSelectedHandler={this.fileSelectedHandler}
      />
        {/* Conditionally render Popup */}
        {this.state.showPopup && <PopUp
          closePopup={this.closePopup} />}
      </>
    )
  }
}

export default SubmitApplicationContainer;
