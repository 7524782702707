import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import ApplicationSetting from "./ApplicationSetting";
import ApplicationForm from "./ApplicationForm";
import ApplicationTerms from "./ApplicationTerms";

const SubmitApplicationPage = ({ handleSubmit, prevStep, ...props }) => (
  <>
    <div className="top_slide inner_header">

      <Header {...props} />
      <SubHeader />
    </div>
    <section id="postjob_detail" className="submit_application default_bg">
      <img src="images/make_film_bg.png" alt="img" className="bg_img" />
      <div className="container">
        <h3 className="main_head">Submit Application</h3>
        {/* <div className="postjob_tab">
          <ul className="nav nav-tabs">
            <li className="active"><Link to="#tab_default_1" data-toggle="tab">Application Setting</Link></li>
            <li><Link to="#tab_default_3" data-toggle="tab">Introduction</Link></li>
         <li><Link to="#tab_default_4" data-toggle="tab">Budget</Link></li> 
          </ul>
        </div> 
        <div className="tab-content">
           <div className="tab-pane active" id="tab_default_1">
            <ApplicationSetting {...props} />
            <div className="application_btn info_bx">
              <button type="button" className="stroke_btn">Cancel</button>
              <Link to="#tab_default_2" data-toggle="tab" className="blue_btn pull-right">Continue</Link>
            </div>
          </div>
          <div className="tab-pane" id="tab_default_3">
            <ApplicationForm {...props} />
            <div className="application_btn info_bx">
              <button type="button" className="stroke_btn">Back</button>
              <button type="submit" className="blue_btn pull-right" onClick={handleSubmit}>Submit Application</button>
            </div>
          </div>
          <div className="tab-pane" id="tab_default_4">
            <ApplicationTerms {...props} />
            <div className="application_btn info_bx">
              <button type="button" className="stroke_btn">Back</button>
              <button type="submit" className="blue_btn pull-right" onClick={handleSubmit}>Submit Application</button>
            </div>
          </div>
        </div> */}

        <ApplicationForm {...props} />
        <div className="application_btn info_bx">
          <button className="blue_btn" onClick={() => window.history.back()}>Back</button>
          <button type="submit" className="blue_btn pull-right" onClick={handleSubmit}>Submit Application</button>
        </div>
      </div>
    </section>
    <Footer />
  </>
);


export default SubmitApplicationPage;
