// src/BubbleContainer.js
import React from 'react';


const BubbleContainer = () => {
  const numberOfBubbles = 100; // Change this to the desired number of bubbles
  const bubbles = Array.from({ length: numberOfBubbles });

  return (
    <div className="bottom-particles">
      {bubbles.map((_, index) => (
        <div key={index} className="bubble"></div>
      ))}
    </div>
  );
};

export default BubbleContainer;