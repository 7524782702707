import React, { Component } from 'react';
import SearchAuditionPage from "../../pages/SearchAudition/SearchAuditionPage";
import { getAuditionList, getJobListUserSide, saveJobs, searchAuditions, getAppliedobs, getCurrentUser, getAppliedAuditions, getUsersRole } from '../../actions/index';
import _ from 'lodash';
import swal from 'sweetalert';
let timer = "";

class SearchAuditionContainer extends Component {

    state = {
        savedJobs: [],
        appliedJobs: [],
        filters: [{ type: 'professionType', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }],
        search: '',
        token: localStorage.getItem('accessToken')
    };

    onChangeSearch = search => {
        this.setState({ search });
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.getAuditionList(search);
        }, 300);
    }


    // componentDidMount() {
    //     this.getJobList(""); this.getCurrentUser()
    // };

    // getJobList = search => {
    //     searchAuditions({ filters: JSON.stringify(_.filter(this.state.filters, val => val.value != 'All')), search: search })
    //         .then(res => {
    //             this.setState({ data: res.data ?.data[0] ?.search });
    //         })
    // }

    componentDidMount() {
        this.getAuditionList("");
        if (this.state.token) {
            this.getAppliedJobs();
            this.getCurrentUser()
        }
        const userStr = localStorage.getItem("user");
        const user = userStr ? JSON.parse(userStr):null
        this.setState({ loginUser: user },()=>{
          getUsersRole(this.state.loginUser?._id).then(res =>{
            this.setState({ usersRoleDetails: res.data.data })
          })
        });
    };

    getAuditionList = search => {
        const searchParam = this.props.location.search;
        const ft = new URLSearchParams(searchParam).get('filmType')
       const loc = new URLSearchParams(searchParam).get('location');
        const additionalFilters = [{type:'filmType', value: ft ? ft: 'All'}, {type:'auditionLocationCity', value: loc ? loc: 'All'}]
        getAuditionList({ filters: JSON.stringify(_.filter([...this.state.filters, ...additionalFilters], val => val.value != 'All')), search: search })
            .then(res => {
                this.setState({ data: res.data ?.data[0] ?.search.filter(dd => loc ? dd.auditionLocationCity === loc : true ) },()=>{
                    let user = localStorage.getItem('user');
                    let uId = user ? JSON.parse(user)._id:'';
                    this.state.data.filter(e=>e.appliedUsers?.includes(uId))
                });

            })
    }


    onChangeFilter = e => {
        let { filters, search } = this.state;
        const alreadyPresent = _.find(filters, { ...e });
        if (_.isEmpty(alreadyPresent)) {
            this.setState({ filters: _.uniqBy([e, ...filters], 'type') }, () => this.getAuditionList(search));
        }
        else {
            const newFilters =  _.filter(filters, val => val.type != e.type)
            this.setState({ filters: newFilters}, () => this.getAuditionList(search));
        }
    }

    saveJobs = id => {
        saveJobs(id)
            .then(res => {
                localStorage.setItem('user', JSON.stringify(res.data.data))
                this.getAuditionList(this.state.search);
                this.getCurrentUser();
                swal(`Done`, { buttons: false, timer: 1700, icon: 'success' });
            });
    }

    getCurrentUser = () => {
        getCurrentUser((user) => {
            this.setState({ currentUser: user, savedJobs: user.savedJobs });
        })
    }

    getAppliedJobs = () => {
        getAppliedAuditions()
            .then(res => {
                this.setState({ appliedJobs: res.data.data });
                //this.getAppliedJobs();
            });
    }

    render() {
        console.log(this.state, "dkejdedei");
        return <SearchAuditionPage
            {...this.state}
            {...this.props}
            getSavedJobs={this.getSavedJobs}
            getAppliedJobs={this.getAppliedJobs}
            onChangeFilter={this.onChangeFilter}
            onChangeSearch={this.onChangeSearch}
            saveJobs={this.saveJobs}
        />
    }
}
export default SearchAuditionContainer;
