import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class HomeApplianceInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>HOME APPLIANCES INFLUENCER MARKETING</h1>
                  <p align="justify">Consumer electronics are developing every day and a new style and newly featured consumer electronics like TV, appliances, electronics, etc. come in the market. It’s very hard to keep your product upgraded all the time and give the new and fresh one to the customers. Hence, it’s important to do the ideal marketing to grab the attention of the customers and make your product exclusive.</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/home-appliances-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/home-products-brand-promotion.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Product Branding</h2>
          </div>
          <p align="justify">With the increasing trust and popularity and users on the social media platform, going for influencer marketing is one of the great options. People spend plenty of time browsing the influencers on social media and somehow they trust them. Hence, finding the correct influencer for your brand/business is very vital. BollywoodisGood as the most trusted influencer marketing agency will help you in the process of increasing brand awareness and ROI. </p>
          <br />
          <p align="justify">We at BollywoodisGood ensure that the brand/business gets the best services and effective results from our influencer marketing campaign. We not only find the correct influencer for your brand/business but also coordinate, collaborate, and keep the track with the influencer. All this is done after conducting deep research regarding the aim of the brand/business, target audience and what are you offering to the customers through your brand/business. The market of consumer electronics is very vast and maintaining a place is hard but with the experienced team of BollywoodisGood, this is very easy. We make sure that you get the report of progress and will be able to know what is being done by the influencer as well as what results from our influencer campaign has given.</p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="last_info">
            <div class="container">
            <div class="row">
            <div class="col-xs-12">
            <blockquote>
            <p align="justify">The influencers already have engagement and followers who trust them, hence it becomes easy to convince the audience and build brand awareness through influencer marketing and it gives you the instant result. BollywoodisGood will do everything to grow your brand/business and to increase your return on investment.</p>
            </blockquote>
            </div>
            </div>
            </div>
            </section>
           
        <Footer />
      </>
    );
  }
}

export default HomeApplianceInfluencer;
