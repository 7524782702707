import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { payout, supplementalPayTypes, workDuration } from "../../components/CreateUserProfile/registerConst";

export const Budget = ({
    handleChangeDropDown,
    PostJobSubmit,
    handleChangeSingle,
    prevStep,
    nextStep,
    ...props
}) => {

    return (
        <>
            <div >
                <div className="tab_head">
                    <h4>Budget</h4>
                    <hr />
                </div>
                <div className="row">
                    <div className="form-group col-md-6 col-sm-12">
                        <label> How you want to pay </label>
                        <Select
                            options={payout}
                            //isMulti
                            value={{
                                label: props?.payType,
                                value: props?.payType,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "payType",
                                    value: e.label,
                                })
                            }
                        />
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                        <label>Payment Range</label>
                        <div className="row">
                            <div className="col-xs-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min"
                                    name="minAmount"
                                    value={props.minAmount || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                            </div>
                            <div className="col-xs-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max"
                                    name="maxAmount"
                                    value={props.maxAmount || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />

                            </div>
                            {props.maxAmount < props.minAmount && <p style={{ color: 'red', fontSize: '12px', marginLeft: "100px" }}>MaxAmount is not less than MinAmount</p>}

                        </div>
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                        <label> Currency </label>
                        <Select
                            options={[{ label: "INR", value: "INR" }, { label: "USD", value: "USD" }]}
                            //isMulti
                            value={{
                                label: props?.currencyType,
                                value: props?.currencyType,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "currencyType",
                                    value: e.label,
                                })
                            }
                        />
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                        <label> Work Type </label>
                        <Select
                            options={workDuration}
                            //isMulti
                            value={{
                                label: props?.workDurationType,
                                value: props?.workDurationType,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "workDurationType",
                                    value: e.label,
                                })
                            }
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 form-group">
                        <label>Work Duration</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter duration"
                            name="workDuration"
                            value={props.workDuration || ""}
                            onChange={(e) => {
                                vlidateNumberField(e) && handleChangeSingle(e);
                            }}
                        />
                    </div>
                    <div className="form-group col-md-6 col-sm-12">
                        <label className="control-label">Supplemental pay types</label>
                        <Select
                            options={supplementalPayTypes}
                            value={{
                                value: props.supplementalPayTypes,
                                label: props.supplementalPayTypes,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "supplementalPayTypes",
                                    value: e.label,
                                })
                            }
                        />
                    </div>

                    <div className="col-sm-12">

                        <hr />
                        <br />
                        <button
                            type="submit"
                            className="blue_btn pull-right"
                            onClick={(e) => PostJobSubmit(e, true)}
                        >
                            {" "}
                            Post a Job{" "}
                        </button>
                        <Link to="/" className="blue_btn">
                            Cancel
                        </Link>
                        &nbsp; &nbsp;
                        <button
                            type="submit"
                            className="blue_btn"
                            onClick={(e) => PostJobSubmit(e, false)}
                        >
                            {" "}
                            Save{" "}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Budget;