import React from 'react';
import {  PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import {AggregatorArtistInvoice} from '../../core/services/invoiceData';
import Invoice from '../../components/AggregatorInvoiceToArtist/Invoice';
import { FaFileInvoiceDollar } from 'react-icons/fa';



const AggregatorInvoiceToArtistPage = ({invoice}) => {
  console.log(invoice)
  const invoiceData = invoice
console.log('first',invoiceData)
  return(
  <div className=''>
  {/* <PDFViewer showToolbar={false} width="800" height="1110" className="app" >
                <Invoice invoice={invoiceData}/>
            </PDFViewer> */}
            <PDFDownloadLink document={ <Invoice invoice={invoiceData}/>} fileName="Service Invoice for Talent.pdf">
      {({ blob, url, loading, error }) =>
        loading ?  <button style={{width:'100%', padding:'6px'}}>Loading...</button> : <button style={{width:'100%', padding:'6px'}}><FaFileInvoiceDollar/> Service Invoice for Talent</button>
      }
    </PDFDownloadLink>
  </div>
)};
export default AggregatorInvoiceToArtistPage;
