import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import { config } from "../../actions/config";

const AnyReactComponent = ({ text, lat, lng }) => <div>
    <div>
        <img onClick={()=>window.open(`http://maps.google.com/maps?z=15&t=m&q=loc:${lat}+${lng}`)} style={{borderRadius:'50%', height:30,width:30}} src={process.env.PUBLIC_URL+'icon/map-pin.png'} alt=''/>
    </div>
    </div>;

export const Gmap = ({location})=> {
  const defaultProps = {
    center: {
      lat: 19.0759837,
      lng: 72.8776559
    }
  };

  const [center, setCenter] = useState(defaultProps.center);
  const [zoomLavel, setZoom] = useState(10);
useEffect(() => {
    if (location && location.lat && location.lng) {
        setCenter(location)
        setZoom(15)
    }
    console.log(center)
}, [location.lat, location.lng]);
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '250px', width: '100%' }}>
      <GoogleMapReact
        defaultCenter={defaultProps.center}
        center={center}
        zoom={zoomLavel}
        bootstrapURLKeys= {{key: config.GOOGLE_MAPS_API_KEY,libraries:['places']}}
      >
      {location?.lat && <AnyReactComponent
          lat={center.lat}
          lng={center.lng}
          
        />}
      </GoogleMapReact>
    </div>
  );
}