import React, { Component } from 'react';
import AuditionApplicationViewPage from "../../pages/AuditionApplicationView/AuditionApplicationViewPage"
import { getUserApplication } from '../../actions/index';

class AuditionApplicationViewContainer extends Component {

    state = { jobApplication: {} };

    componentDidMount() {
        if (this.props.match ?.params ?.id) {
            getUserApplication(this.props.match ?.params ?.id)
                .then(res => this.setState({ jobApplication: res.data?.data   }));
        }
    }

    render() {
        console.log(this.state, "vishnu pathak");
        return <AuditionApplicationViewPage {...this.state} {...this.props} />
    }

}

export default AuditionApplicationViewContainer;