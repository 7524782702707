import React,{useEffect,useRef, useState} from "react";
import Select from "react-select";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { workExp, workExpMax, payout, plateformOfRelease } from "../CreateUserProfile/registerConst";
import { config } from "../../actions/config";
import { FacebookShareButton , TwitterIcon, TwitterShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TelegramShareButton, TelegramIcon,WhatsappShareButton, WhatsappIcon} from "react-share";
import * as htmlToImage from 'html-to-image';
import moment from "moment";
import SharingImg from '../../images/audition.png'
import Autocomplete from "react-google-autocomplete";
import { uploadFiles } from "../../actions";
import './index.css';

const OtherRequirements = ({ handleChangeDropDown, handleChangeSingle, prevStep, nextStep, PostAuditionSubmit, ...props }) => {
    const [location, setLocation] = useState({lat: +props?.shootLocationLatLng?.lat || '',lng: +props?.shootLocationLatLng?.lng || ''});
    const domEl = useRef(null);
 const [socialShareImgURL, setSocialShareImgURL] = useState('')

    const downloadImage = async () => {
      const dataUrl = await htmlToImage.toPng(domEl.current);
    //  return dataUrl;
     const blob = await fetch(dataUrl).then((res) => res.blob());
     const formData = new FormData();
     formData.append('file', blob )
     const configs = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      try {
        const res = await uploadFiles(formData,configs)
        if (res && res.data && res.data.data) {
            setSocialShareImgURL(res.data.data.filename);
        }
      } catch (ex) {
        console.log('upload error', ex);
      }
    }


    useEffect(()=>{
        if (props?.allowSocialSharing) {
            downloadImage().then(()=>{
                // setSocialShareImgURL(dataUrl)
            })
        }
    },[props?.allowSocialSharing])

    const SocialSharingImage = () =>{

        return(
            <div rid="domEl" ref={domEl} style={{backgroundColor:'rebeccapurple',borderRadius:10, height:450, width:400}}>
                <div className="col-md-12" style={{paddingTop: 35}}>
                    <div className="col-md-12 " style={{fontSize:30, color:'#FFF', fontWeight:600, textAlign:'center', paddingBottom:24}}>Audition Call</div>
                    <div style={{fontSize:14, color:'#FFF', fontWeight:400, textAlign:'center',paddingTop :60,}}>
                    <div className="col-md-12" style={{borderTop:'1px solid #767373'}}></div>
                    <div className="col-md-6">
                        <div className="col-md-12" style={{paddingTop :15, fontSize:16,fontWeight:500}}>Role </div>
                        <div className="col-md-12 chips">{props?.roleType}/{props?.actorType}</div>
                    </div>
                    <div className="col-md-6">
                    <div className="col-md-12"  style={{paddingTop :15, fontSize:16,fontWeight:500}}>Age</div>
                    <div className="col-md-12 chips" >{props?.minAge} - {props.maxAge} Years</div>
                    {/* <div className="col-md-12" style={{borderTop:'1px solid #767373'}}></div> */}
                    </div>
                  
                    <div className="col-md-6">
                    <div className="col-md-12" style={{paddingTop :15, fontSize:16,fontWeight:500}}>Start Date</div>
                    <div className="col-md-12 chips">{ moment(props.auditionStartDate).format("DD MMM YYYY")}  </div>
                    </div>
                    <div className="col-md-6">
                    <div className="col-md-12" style={{paddingTop :15, fontSize:16,fontWeight:500}}>End Date</div>
                    <div className="col-md-12 chips">{ moment(props.auditionEndDate).format("DD MMM YYYY")}  </div>
                    </div>
                    <div className="col-md-6">
                    <div className="col-md-12"  style={{paddingTop :15, fontSize:16,fontWeight:500}}>Timing</div>
                    <div className="col-md-12 chips"> {props?.auditionStartTime} - {props?.auditionEndTime}</div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-md-12"style={{paddingTop :15, fontSize:16,fontWeight:500}}>Audition Type</div>
                        <div className="col-md-12 chips"> {props?.auditionType}</div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-md-12"style={{paddingTop :15, fontSize:16,fontWeight:500}}>Location</div>
                        <div className="col-md-12 chips"> {props?.auditionLocationCity}</div>
                    </div>
                    <div className="col-md-6">
                    <div className="col-md-12"  style={{paddingTop :15, fontSize:16,fontWeight:500}}>Ethnicity</div>
                    <div className="col-md-12 chips" > {props?.ethnicity || " Yet to Submit"}</div>
                    </div>

                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div >
            
                <div className="tab_head">
                    <h4>Role Brief</h4>
                    <hr />
                </div>
                <div className="row">
                <div className="form-group col-md-12">
                                <label className="control-label">Character Name</label>
                                <input
                                    type="text"
                                    name="characterName"
                                    className="form-control"
                                    placeholder="Enter character name"
                                    value={props.characterName}
                                    onChange={handleChangeSingle}
                                />
                    </div>
                   
                <div className="form-group col-sm-9">
                        <label>Character Brief / Sketch </label>
                        <textarea
                            className="form-control"
                            placeholder="Enter here"
                            value={props.characterDescription}
                            name="characterDescription"
                            onChange={handleChangeSingle}
                        />
                        <small>Minimum 100 Character</small>
                    </div>
                    <div className="form-group  col-sm-3 manage_space" style={{paddingLeft:0}}>  
                    <p>
                    <strong>Attachment</strong>
                    </p> 
                        <div className="position-relative">
                            <input style={{position:'absolute',top:'50%',transform:'translate(0px, -10px)',opacity:0,height :30,width:140}}
                                type="file"
                                name="characterBriefAttachment"
                                id="characterBriefAttachment"
                                onChange={(e) => { props.fileSelectedHandler(e) }}
                            />
                            <label style={{fontSize:12, padding:7}} htmlFor="characterBriefAttachment" className="blue_btn "> Attach Character Brief  </label>
                        <span>
                        {props.characterBriefAttachment &&  <>
                            <a href={config.FILE_URL+ props.characterBriefAttachment} target="_blank">
                            <img src={`${process.env.PUBLIC_URL}images/Downloads-icon.png`} style={{height:32,borderRadius:10,marginLeft:10}}/><br/>
                            </a>
                        </> }
                        </span>
                        </div>
                       
                    </div>
                    {/* <div className="form-group col-md-8">
                                <label className="control-label">Synopsis</label>
                                <input
                                    type="email"
                                    name="synopsis"
                                    className="form-control"
                                    placeholder="Enter Synopsis"
                                    value={props.synopsis}
                                    onChange={handleChangeSingle}
                                />
                    </div> */}
                    <div className="form-group  col-sm-6 col-xs-6"> 
                    <p>
                    <strong>Synopsis</strong>
                    </p>               
                        <div className="position-relative">
                            <input style={{position:'absolute',top:'50%',transform:'translate(0px, -10px)',opacity:0,height :30,width:140}}
                                type="file"
                                name="synopsisAttachment"
                                id="synopsisAttachment"
                                onChange={(e) => { props.fileSelectedHandler(e) }}
                            />
                            <label  htmlFor="synopsisAttachment" className="blue_btn "> Attach Synopsis  </label>
                        <span>
                        {props.synopsisAttachment &&  <>
                            <a href={config.FILE_URL+ props.synopsisAttachment} target="_blank">
                            <img src={`${process.env.PUBLIC_URL}images/Downloads-icon.png`} style={{height:32,borderRadius:10,marginLeft:10}}/><br/>
                            </a>
                        </> }
                        </span>
                        </div>
                       
                    </div>



                    {/* <div className="form-group col-sm-4">
                        <label>Minimum Experience<small>(in years)</small></label>
                        <Select
                            options={workExp}
                            value={{
                                value: props.minExperience,
                                label: props.minExperience,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "minExperience",
                                    value: e.label,
                                })
                            }
                        />
                    </div> */}
                    {/* <div className="form-group col-sm-4">
                        <label>Maximum Experience<small>(in years)</small></label>
                        <Select
                            options={workExpMax}
                            value={{
                                value: props.maxExperience,
                                label: props.maxExperience,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "maxExperience",
                                    value: e.label,
                                })
                            }
                        />
                    </div> */}
                    {/* <div className="col-sm-12 form-group radio_btn inline_feild">
                        <p>
                            {" "}
                            <strong>Actor Introduction Video Needed</strong>
                        </p>
                        <label className="check_bx">
                            <input
                                type="radio"
                                value="introVideoTrue"
                                name="checkField"
                                onClick={() =>
                                    handleChangeSingle({
                                        target: {
                                            value: true,
                                            name: "introductionVideoNeeded",
                                        },
                                    })
                                }
                            />
                            Yes
                    <span className="checkmark"></span>
                        </label>
                        <label className="check_bx">
                            <input
                                type="radio"
                                value="introVideoFalse"
                                name="checkField"
                                onClick={() =>
                                    handleChangeSingle({
                                        target: {
                                            value: false,
                                            name: "introductionVideoNeeded",
                                        },
                                    })
                                }
                            />
                            No
                    <span className="checkmark"></span>
                        </label>
                    </div>

                    {
                        props.introductionVideoNeeded &&
                        <>
                            <div className="form-group col-md-12">
                                <label className="control-label">Video Link</label>
                                <input
                                    type="text"
                                    name="introductionVideoLink"
                                    className="form-control"
                                    placeholder="Enter Url"
                                    value={props.introductionVideoLink}
                                    onChange={handleChangeSingle}
                                />
                            </div>

                            <>
                                <div className="form-group col-md-6">
                                    <label className="control-label">Email Id</label>
                                    <input
                                        type="email"
                                        name="introVideoEmail"
                                        className="form-control"
                                        placeholder="Enter email"
                                        value={props.introVideoEmail}
                                        onChange={handleChangeSingle}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="control-label">Whatsapp</label>
                                    <input
                                        type="text"
                                        name="introVideoWhatsappNumber"
                                        className="form-control"
                                        placeholder="Enter whatsapp"
                                        value={props.introVideoWhatsappNumber || ""}
                                        onChange={(e) => {
                                            vlidateNumberField(e) && handleChangeSingle(e);
                                        }}
                                    />
                                </div>
                            </>
                        </>
                    } */}


                    <div className="form-group  col-sm-6 col-xs-6">
                        <p>
                            <strong>Script (if any)</strong>
                        </p>
                                              
                        <div className="position-relative">
                            <input style={{position:'absolute',top:'50%',transform:'translate(0px, -10px)',opacity:0,height :30,width:140}}
                                type="file"
                                name="script"
                                id="script"
                                onChange={(e) => { props.fileSelectedHandler(e) }}
                            />
                            <label htmlFor="script" className="blue_btn "> Attach Script  </label>
                        <span>
                        {props.script &&  <>
                            <a href={config.FILE_URL+ props.script} target="_blank">
                            <img src={`${process.env.PUBLIC_URL}images/Downloads-icon.png`} style={{height:32,borderRadius:10,marginLeft:10}}/><br/>
                            </a>
                        </> }
                        </span>
                        </div>
                       
                    </div>

                    {
                        props.videoForScript &&
                        <>
                            <div className="form-group col-md-12">
                                <label className="control-label">Video Link</label>
                                <input
                                    type="text"
                                    name="videoLinkForScript"
                                    className="form-control"
                                    placeholder="Enter Url"
                                    value={props.videoLinkForScript}
                                    onChange={handleChangeSingle}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label">Email Id</label>
                                <input
                                    type="email"
                                    name="scriptVideoEmail"
                                    className="form-control"
                                    placeholder="Enter email"
                                    value={props.scriptVideoEmail}
                                    onChange={handleChangeSingle}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label className="control-label">Whatsapp</label>
                                <input
                                    type="text"
                                    name="scriptVideoWhatsappNumber"
                                    className="form-control"
                                    placeholder="Enter whatsapp"
                                    value={props.scriptVideoWhatsappNumber || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                            </div>
                        </>
                    }

                    <div className="form-group col-md-6">
                                <label className="control-label">Total Shoot Days Required</label>
                                <input
                                    type="number"
                                    name="totalShootDays"
                                    className="form-control"
                                    placeholder="Enter Total Shoot Days"
                                    value={props.totalShootDays}
                                    onChange={handleChangeSingle}
                                />
                    </div>
                    <div className="form-group col-md-6">
                                <label className="control-label">Timelines</label>
                                <input
                                    type="text"
                                    name="timelines"
                                    className="form-control"
                                    placeholder="Enter Timelines"
                                    value={props.timelines}
                                    onChange={handleChangeSingle}
                                />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Shoot Locations</label>
                        {/* <input
                            type="text"
                            name="shootLocations"
                            className="form-control"
                            placeholder="Mumbai, Jaipur, Newyork, Washington...etc"
                            value={props.shootLocations}
                            onChange={handleChangeSingle}
                        /> */}
                        <Autocomplete
                    apiKey={config.GOOGLE_MAPS_API_KEY}
                    className="form-control"
                    placeholder= {props?.shootLocations }
                    onPlaceSelected={(place) => {
                      let address = '';
                      place.address_components.forEach((e,i) => {
                        address += e.long_name ;
                        if (i< place.address_components.length-1) {
                          address += ', '
                        }
                      });
                      setLocation(
                        {
                          lat:place.geometry.location.lat(),
                          lng:place.geometry.location.lng()
                        })
                        handleChangeSingle({target:{name:'shootLocations',value:address}})
                        handleChangeSingle({
                          target:{name:'shootLocationLatLng',
                          value:{
                            lat:place.geometry.location.lat(),
                            lng:place.geometry.location.lng(),
                          }
                        }
                        })
                      }}
                  />
                  {location?.lat && <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div>lat: {location.lat}</div>
                    <div>lng: {location.lng}</div>
                  </div>}
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Producer</label>
                        <input
                            type="text"
                            name="producer"
                            className="form-control"
                            placeholder="Producer"
                            value={props.producer}
                            onChange={handleChangeSingle}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Director Details</label>
                        <input
                            type="text"
                            name="directorDetails"
                            className="form-control"
                            placeholder="Director Details"
                            value={props.directorDetails}
                            onChange={handleChangeSingle}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Co-Stars</label>
                        <input
                            type="text"
                            name="coStars"
                            className="form-control"
                            placeholder="Co-Stars"
                            value={props.coStars}
                            onChange={handleChangeSingle}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="control-label">Platform of Release</label>
                        <Select
                            options={plateformOfRelease}
                            //isMulti
                            value={{
                                label: props?.plateformOfRelease,
                                value: props?.plateformOfRelease,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "plateformOfRelease",
                                    value: e.label,
                                })
                            }
                        />
                    </div>
                    {props.plateformOfRelease === 'OTT'? <div className="form-group col-md-6">
                        <label className="control-label">Name of OTT</label>
                        <input
                            type="text"
                            name="ottName"
                            className="form-control"
                            placeholder="Netflix, Hotstar, Amazon Prime, Zee5..."
                            value={props.ottName}
                            onChange={handleChangeSingle}
                        />
                    </div>: null}
                    {/* <div className="col-sm-12 form-group radio_btn inline_feild">
                        <p>
                            {" "}
                            <strong>Actor Script Video Needed</strong>
                        </p>
                        <label className="check_bx">
                            <input
                                type="radio"
                                value="checkbx"
                                name="checkField2"
                                onClick={() =>
                                    handleChangeSingle({
                                        target: { value: true, name: "videoForScript" },
                                    })
                                }
                            />
                            Yes
                    <span className="checkmark"></span>
                        </label>
                        <label className="check_bx">
                            <input
                                type="radio"
                                value="checkbx"
                                name="checkField2"
                                onClick={() =>
                                    handleChangeSingle({
                                        target: { value: false, name: "videoForScript" },
                                    })
                                }
                            />
                            No
                    <span className="checkmark"></span>
                        </label>
                    </div> */}

                   



                    {/* <div className="form-group col-sm-12">
                        <label>Project Budget </label>
                        <div className="row">
                            {!props.discloseLaterBudget &&
                                <>
                                    <div className="col-md-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Max in rupee"
                                            name="maxProjectBudget"
                                            value={props.maxProjectBudget || ""}
                                            onChange={(e) => {
                                                vlidateNumberField(e) && handleChangeSingle(e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Min in rupee"
                                            name="minProjectBudget"
                                            value={props.minProjectBudget || ""}
                                            onChange={(e) => {
                                                vlidateNumberField(e) && handleChangeSingle(e);
                                            }}
                                        />
                                    </div>
                                </>
                            }
                            <div className="col-md-4">
                                <label className="check_bx">
                                    <input
                                        type="checkbox"
                                        value="checkbx"
                                        name="checkField"
                                        onClick={() =>
                                            handleChangeSingle({
                                                target: { value: !props.discloseLaterBudget, name: "discloseLaterBudget" },
                                            })
                                        }
                                    />
                                    Disclose Latter
                        <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="form-group col-md-6">
                        <label> How you want to pay </label>
                        <Select
                            options={payout}
                            //isMulti
                            value={{
                                label: props ?.payType,
                                value: props ?.payType,
                            }}
                            onChange={(e) =>
                                handleChangeDropDown({
                                    name: "payType",
                                    value: e.label,
                                })
                            }
                        />
                    </div> */}

                    {/* <div className="form-group col-sm-6">
                        <label>Set your range in rupee</label>
                        <div className="row">
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min"
                                    name="minAmount"
                                    value={props.minAmount || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max"
                                    name="maxAmount"
                                    value={props.maxAmount || ""}
                                    onChange={(e) => {
                                        vlidateNumberField(e) && handleChangeSingle(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className="col-sm-12 form-group radio_btn inline_feild">
                        <p>
                            {" "}
                            <strong>Allow Social Media Sharing</strong>
                        </p>
                        <label className="check_bx">
                            <input
                                type="radio"
                                name="allowSocialSharing"
                                checked={props?.allowSocialSharing}
                                onClick={()=>
                                    handleChangeSingle({target:{checked: true, name: 'allowSocialSharing',type: 'radio'}})
                                }
                            />
                            Yes
                    <span className="checkmark"></span>
                        </label>
                        <label className="check_bx">
                            <input
                                type="radio"
                                name="allowSocialSharing"
                               checked={!props?.allowSocialSharing}
                               onClick={()=>
                                handleChangeSingle({target:{checked: false, name: 'allowSocialSharing',type: 'radio'}})
                            }
                            />
                            No
                    <span className="checkmark"></span>
                        </label>
                    </div>
                    {props?.allowSocialSharing && <div className="col-sm-12 form-group radio_btn inline_feild">
                     <div style={{height: 0, overflow:'hidden'}}>
                     <SocialSharingImage />
                     </div>
                    <FacebookShareButton
                                url={config.FILE_URL+socialShareImgURL}
                                quote={"JSON.stringify(props)"}
                                hashtag={"#BIG#Audition"}
                                description={"JSON.stringify(props)"}
                                // children={"defee feuef feu"}
                            >
                                <FacebookIcon size={32} round={true} />
                    </FacebookShareButton >
                    <TwitterShareButton
                                url={"http://65.2.49.57:5000/uploads/BIG_file-1664424846869.png"}
                                quote={"JSON.stringify(props)"}
                                hashtag={"#BIG#Audition"}
                                description={"JSON.stringify(props)"}
                                // children={"defee feuef feu"}
                            >
                                <TwitterIcon size={32} round={true} />
                                </TwitterShareButton >
                                <LinkedinShareButton
                                url={"http://65.2.49.57:5000/uploads/BIG_file-1664424846869.png"}
                                quote={"JSON.stringify(props)"}
                                hashtag={"#BIG#Audition"}
                                description={"JSON.stringify(props)"}
                                // children={"defee feuef feu"}
                            >
                                <LinkedinIcon size={32} round={true} />
                                </LinkedinShareButton >
                                <TelegramShareButton
                                url={"http://65.2.49.57:5000/uploads/BIG_file-1664424846869.png"}
                                quote={"JSON.stringify(props)"}
                                hashtag={"#BIG#Audition"}
                                description={"JSON.stringify(props)"}
                                // children={"defee feuef feu"}
                            >
                                <TelegramIcon size={32} round={true} />
                                </TelegramShareButton >
                                <WhatsappShareButton
                                title={"BIG Audition "}
                                url={"http://65.2.49.57:5000/uploads/BIG_file-1664424846869.png"}
                                quote={"JSON.stringify(props)"}
                                hashtag={"#BIG#Audition"}
                                description={"JSON.stringify(props)"}
                                // children={"defee feuef feu"}
                            >
                                <WhatsappIcon size={32} round={true} />
                                </WhatsappShareButton >
                    </div>}
                    <div>
                        
                    </div>
                    <div className="col-sm-12">
                        <hr />
                        <button className="blue_btn" onClick={prevStep}>Back</button>
                        {/* <button className="blue_btn pull-right" onClick={() => nextStep()} >Next</button> */}
                        <button className="blue_btn pull-right" onClick={(e) => PostAuditionSubmit(e, true)}>Submit</button>
                        <button className="blue_btn pull-right" style={{ marginRight: "10px" }} onClick={(e) => PostAuditionSubmit(e, false)}>Save as Draft</button>

                    </div>

                </div>
            </div>

        </>
    )
}

export default OtherRequirements;