import React, { useState } from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import { FaCreditCard } from "react-icons/fa";
import swal from 'sweetalert';
import { addUserFundsUsingUpi } from "../../actions";

const UserPayment = ({onPayment }) => {

  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [disableButton, setDisableButton] = useState(false);
  const [txId, setTxId] = useState('');
  const [upiInfo, setUpiInfo] = useState()

  const onPaymentDone = () => {
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;
  
    const data = {
      orderCreationId: 'order_' + Date.now(),
      userId: user._id,
      amount: amount,
      transactionId: txId,
      emailId: user.emailId,
      userName: user.userName,
    };
  
    addUserFundsUsingUpi(data)
      .then((res) => {
        onPayment();
        handleClose();
        
      })
      .catch((e) => {
        handleClose();
        swal({
          title: "Funds Added!",
          icon: "success",

          content: {
            element: "div",
            attributes: {
              innerHTML: `
               
              <p style="text-align: center;font-size:18px">₹ ${amount || 0} will get added to your BiG Pocket. Start exploring premium opportunities now!</p>`,
              // Center the text❌😟⚠️🚫 ${errorMessage} <div style="font-size: 40px; margin-bottom: 25px;">❌</div>
              // Subscribe now to unlock premium opportunities.Or refer a friend and earn credits to access BiG for free!
            },
          },
          buttons: {
            aaaa: {
              text: "View Opportunities",
              value: "referal",
              visible: true,
              className: "blue_btn",
            },
          },
        }).then((value) => {
          if (value === "referal") {
            window.location.href = "#/home";
          }
        });
        setTimeout(() => {
          const modal = document.querySelector(".swal-modal");
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.style.position = "absolute";
          closeButton.style.top = "5px";
          closeButton.style.right = "5px";
          closeButton.style.background = "transparent";
          closeButton.style.border = "none";
          // closeButton.style.borderRadius = "50%";
          closeButton.style.fontSize = "30px";
          closeButton.style.cursor = "pointer";
          modal.appendChild(closeButton);
          closeButton.addEventListener("click", () => swal.close());
        }, 0);
        console.error("Payment error:", e);
      });
  };
  

  return (
    <>
    <div className="top_slide inner_header">
      <Header />
      <SubHeader />
      
        <section id="user_payment" className="mid_info inner_banner">
          <div className="container side_space">         
            <div className="row">   
            <div className="col-md-12 inner_banner_text text-center">
            <h2>Make the payment with Ease!</h2>
          </div>           
            </div>            
          </div>
      </section>
      </div>
      <div id="payment_mode" className="default_bg">
        <div className="container">
          <div className="row">           
            <div className="col-md-12">
              <div className="payment_area">
            <ul class="nav nav-tabs" role="tablist" id="myTab">
  <li class="active"><a href="#cardpayment" role="tab" data-toggle="tab"><FaCreditCard /> Card Payment</a></li>
  <li><a href="#upipay" role="tab" data-toggle="tab"><FaCreditCard /> UPI Payment</a></li>
  {/* <li><a href="#currentIssue" role="tab" data-toggle="tab"><FaCreditCard /> Bank Payment</a></li> */}
</ul>
<div class="tab-content">
  <div class="tab-pane active" id="cardpayment">
              <div className="form_outer">
              <h6>Bank Account</h6>
              <p>Add your card details to add the payment</p>
              <hr/>
              <div className="row">
              <div className="col-md-12 form-group">                  
                  <input type="radio" value="1" name="visa" id="visa" />
                  <label for="visa"><img src="images/visa.png" alt="" /></label>
                  
                  <input type="radio" value="1" name="master" id="master" />
                  <label for="master"><img src="images/master.png" alt="" /></label>
                </div> 

                <div className="col-md-12 form-group">
                  <label>Cardholder name</label>
                  <input type="text" className="form-control" />
                </div> 
                <div className="col-md-12 form-group">
                  <label>Card number</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="col-md-8 form-group">
                  <label>Expiraton</label>
                  <div className="row">
                    <div className="col-md-6">
                    <input type="text" className="form-control" placeholder="MM" />
                    </div>
                    <div className="col-md-6">
                    <input type="text" className="form-control" placeholder="YY" />
                    </div>
                  </div>
                 
                </div>
                <div className="col-md-4 form-group">
                  <label>CVV</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="col-md-12 form-group">
                  <label>Amount</label>
                  <input type="text" className="form-control"/>
                </div>
                <div className="col-md-12 form-group">
                  <button type="submit" className="blue_btn">Add Funds</button>
                </div>
                </div>
              </div>
              </div>

              <div class="tab-pane" id="upipay">
              <div className="form_outer">
              <div className="row">
                <div className="col-md-12 payment_qr">
                <div className="qr_inside">
                  <h6>Kalibroida Tech..</h6>
                  <p>Scan thw QR with any Bharat QR/UPI enable app</p>
                  </div>
            <img src="images/payment_qr.jpg" />           
                </div> 
             
                <div className="col-md-12 form-group">  
                <hr/>
                <p><strong>Or use UPI ID to add the fund and submit transaction id</strong></p>  
                <br />        
                  <input type="radio" value="1" name="visa" id="visa" />
                  <label for="visa"><img src="images/phonepay.png" alt="" /></label>                  
                  <input type="radio" value="1" name="master" id="master" />
                  <label for="master"><img src="images/upi.png" alt="" /></label>
                  <input type="radio" value="1" name="master" id="master" />
                  <label for="master"><img src="images/paytm.png" alt="" /></label>
                  <input type="radio" value="1" name="master" id="master" />
                  <label for="master"><img src="images/gpay.png" alt="" /></label>
                </div> 
                <div className="col-md-12 form-group">
                  <label>UPI id</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="col-md-12 form-group">
                  <label>Transation id</label>
                  <input type="text" className="form-control" value={txId} onChange={(e)=>setTxId(e.target.value)}/>
                </div>
                <div className="col-md-12 form-group">
                  <label>Amount</label>
                  <input type="text" className="form-control" value={amount} onChange={(e)=>setAmount(e.target.value)} />
                </div>
                <div className="col-md-12 form-group">
                  <button type="submit" className="blue_btn">Add Funds</button>
                </div>
                </div>
             
         
            </div>
            </div>
              </div>
            
            </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </>
  );
};

export default UserPayment;
