import React from 'react';
import moment from 'moment';
import { FaMoneyBill, FaCalendarCheck, FaSuitcase } from 'react-icons/fa';
import _ from 'lodash';
import { config } from '../../actions/config';
import { Gmap } from '../Gmap';

const AuditionDetailData = ({ AuditionDetail }) => (
  <div className="col-md-9 col-sm-8">
    <div className='text-left'>
    <h5>
      <strong>{AuditionDetail.title}</strong>
    </h5>
    <small>
        {" "}
        <strong>Posted on:</strong>{" "}
        {moment(AuditionDetail?.createdAt).format("ll")}
      </small>

    {AuditionDetail.banner && (
      <div className="job_cat">
        <br />
        <img
          src={config.FILE_URL + AuditionDetail.banner}
          style={{ height: 200, borderRadius: 10 }}
        />
      </div>
    )}
  
    </div>
    { AuditionDetail?.createdAt? <hr />:null}
    <div className="job_list mid_job_info clearfix">
      <ul>
        <li>
          <div className="side_icon text-center">
            <FaMoneyBill />
          </div>
          <div className="job_info">
            <span>
             {AuditionDetail?.minProjectBudget ? <strong>
                {AuditionDetail?.minProjectBudget} -{" "}
                {AuditionDetail?.maxProjectBudget}
              </strong> : <strong> Yet to Submit</strong>}
            </span>
            <small>Payout Type</small>
          </div>
        </li>
        <li>
          <div className="side_icon text-center">
            <FaSuitcase />
          </div>
          <div className="job_info">
            <span>
             {AuditionDetail?.minExperience ? <strong>
                {" "}
                {AuditionDetail?.minExperience} year -{" "}
                {AuditionDetail?.maxExperience} year
              </strong> : <strong> Yet to Submit</strong>}
            </span>
            <small>Experience</small>
          </div>
        </li>
        <li>
          <div className="side_icon text-center">
            <FaCalendarCheck />
          </div>
          <div className="job_info">
            <span>
             {AuditionDetail?.auditionStartDate ? <strong>
                {moment(AuditionDetail?.auditionStartDate).format("ll")} -{" "}
                {moment(AuditionDetail?.auditionEndDate).format("ll")}
              </strong>: <strong> Yet to Submit</strong>}
            </span>
            <small>Audition Date</small>
          </div>
        </li>
      </ul>
    </div>
    
    <div className="job_cat">
      <h6>{AuditionDetail?.category}</h6>
      
    </div>
    {AuditionDetail.banner || AuditionDetail?.category? <hr />:null}
    <div className="job_list job_des_info">
      <ul>
        <li className='card m-1'>
          <div><strong>Type </strong></div> 
          <div>{AuditionDetail?.auditionType || ' Yet to Submit'}</div>
        </li>
        <li className='card m-1'> 
        <div>
          <strong>Location</strong>
        </div>
        <div>
          {AuditionDetail.auditionLocationCity || ' Yet to Submit'}
        </div>
        </li>
        <li className='card m-1'>
          <div><strong>Actor</strong></div>
          <div>{AuditionDetail.actorType?.join() || ' Yet to Submit'}</div>
        </li>
        <li className='card m-1'>
          <div><strong>Film Industry</strong></div> 
          <div>{AuditionDetail.filmIndustry?.join() || ' Yet to Submit'}</div>
        </li>
        <li className='card m-1'>
          <div><strong>Age</strong></div>
          <div>{ AuditionDetail.minAge ? AuditionDetail.minAge + " - " + AuditionDetail.maxAge: ' Yet to Submit' }</div>
        </li>
      </ul>
    </div>
    <p>
      <strong>Role Type:</strong>
      {AuditionDetail.roleType && AuditionDetail.roleType.length ? AuditionDetail.roleType?.join(): ' Yet to Submit'}
    </p>
    <p>{AuditionDetail?.characterDescription || ' Yet to Submit'}</p>
    <br />
    <p>
      <strong>Audition Time:</strong> {AuditionDetail?.auditionStartTime ? AuditionDetail?.auditionStartTime +" - " +AuditionDetail?.auditionEndTime :' Yet to Submit'}
    </p>
    
    <hr />
   
    <div class="row">
      <div className="job_list job_list_info col-md-6 col-sm-12 col-xs-6 full">
        <h6>Physical Traits</h6>
        <ul>
          <li>
            <span>Height:</span>
            {AuditionDetail?.minHeightFeet ? `${AuditionDetail?.minHeightFeet} ft ${AuditionDetail?.minHeightInch}${" "}
            in to ${AuditionDetail?.maxHeightFeet} ft${" "}
            ${AuditionDetail?.maxHeightInch} in`: ' Yet to Submit'}
          </li>
          <li>
            <span>Weight:</span>
            {AuditionDetail?.minWeight ?AuditionDetail?.minWeight +" kg to " +AuditionDetail?.maxWeight+" kg " : " Yet to Submit"}
          </li>
          <li>
            <span>Physique:</span> {AuditionDetail?.physique || " Yet to Submit"}
          </li>
          <li>
            <span>Unique Traits:</span> {AuditionDetail?.uniqueTraits || " Yet to Submit"}
          </li>
          <li>
            <span>Must Have:</span> {AuditionDetail?.mustHave || " Yet to Submit"}
          </li>
          <li>
            <span>Mandatory Conditions:</span>{" "}
            {AuditionDetail?.mandatoryConditions || " Yet to Submit"}
          </li>
          <li>
            <span>Appearance or D/C:</span>
            <small>{AuditionDetail?.appearance || " Yet to Submit"}</small>
          </li>
        </ul>
      </div>
      <div className="job_list job_list_info col-md-6 col-sm-12 col-xs-6 full">
        <h6>Other Information</h6>
        <ul>
          <li>
            <span>Shoot Dates:</span> {AuditionDetail?.shootStartDate ?`
            ${AuditionDetail?.shootStartDate ? "to" : ""}${" "}
            ${AuditionDetail?.shootEndDate}`: " Yet to Submit"}
          </li>
          <li>
            <span>Specific Role Criteria:</span>{" "}
            {AuditionDetail?.specificRoleCriteria || " Yet to Submit"}
          </li>
          {/* <li>
            <span>Competitor Brands (if any):</span> {AuditionDetail ?.competitorBrands}
          </li> */}
          <li>
            <span>Shoot Locations:</span> {AuditionDetail?.shootLocations || " Yet to Submit"}
          </li>
          <li>
            <span>Passport:</span>
            {AuditionDetail?.passportRequired ? "Required" : " Not required"}
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <div className="job_list">
      <h6>Organiser Details</h6>
      <ul>
        <li>
          <strong>Name:</strong> {AuditionDetail?.organiserName || " Yet to Submit"}
        </li>
        <li>
          <strong>Disignation:</strong> {AuditionDetail?.designation || " Yet to Submit"}
        </li>
        <li>
          <strong>Agency Name:</strong> {AuditionDetail?.companyName || " Yet to Submit"}
        </li>
        <li>
          <strong>Production House:</strong> {AuditionDetail?.productionHouse || " Yet to Submit"}
        </li>
        <li>
          <strong>Email Id:</strong> {AuditionDetail?.emailId || " Yet to Submit"}
        </li>
        <li>
          <strong>Contact Number:</strong>
          {AuditionDetail?.contactNumber || " Yet to Submit"}
        </li>
        <li>
          <strong>WhatsApp:</strong>
          {AuditionDetail?.whatsappNumber || " Yet to Submit"}
        </li>
      </ul>
    </div>
    <hr />
    <div class="row">
      <div className="job_list col-md-6 col-sm-12 col-xs-6 full">
        <h6>Other Details</h6>
        <ul>
          {/* <li><strong>Actor Introduction Video Needed:</strong> {AuditionDetail ?.introductionVideoNeeded ? 'YES' : 'NO'}</li> */}
          <li>
            <strong>Preferred Language:</strong>{" "}
            {AuditionDetail?.languagesRead && AuditionDetail?.languagesRead.length ? AuditionDetail?.languagesRead?.join(): " Yet to Submit"}
          </li>
          {/* <li>
            <strong>Budget:</strong> {AuditionDetail?.maxProjectBudget}
          </li> */}
          <li>
            <strong>Allow Social Media Sharing:</strong>{" "}
            {AuditionDetail?.allowSocialSharing ? "YES" : "NO"}
          </li>
        </ul>
      </div>
      <div className="job_list col-md-6 col-sm-12 col-xs-6 full">
        <h6>Audition Status</h6>
        <ul>
          <li>
            <strong>Applied Users:</strong>
            {Number(AuditionDetail?.appliedUsers?.length || 0)}
          </li>
          <li>
            <strong>Invite Sent:</strong> {Number(AuditionDetail?.invites?.length || 0)}
          </li>
        </ul>
      </div>     
    </div>
    <div className="row">
      <div className='col-sm-6 map_info'>
    <p style={{minHeight:'50px'}}>
      <strong>Audition Address:</strong> {AuditionDetail?.auditionAddress || " Yet to Submit"}
    </p>
        <Gmap
          location={{
            lat: +AuditionDetail?.auditionLatLng?.lat,
            lng: +AuditionDetail?.auditionLatLng?.lng,
          }}
        />
      </div>
      <div className='col-sm-6 map_info'>
      <p style={{minHeight:'50px'}}>
      <strong>Shoot Location:</strong> {AuditionDetail?.shootLocations}
    </p>
        <Gmap
          location={{
            lat: +AuditionDetail?.shootLocationLatLng?.lat,
            lng: +AuditionDetail?.shootLocationLatLng?.lng,
          }}
        />
      </div>
    </div>
  </div>
);

export default AuditionDetailData;