import React, { Component } from "react";
import NewSignupPage from "../../pages/NewSignup/NewSignupPage";
import swal from 'sweetalert';
import { userSignUp, userSignUpOtp, verifyEmailUniqueness,verifyPhoneNumberUniqueness } from '../../actions/index';
import _ from 'lodash';
let timer = '';
class NewSignupContainer extends Component {

  state = { emailId: "", password: "", phoneNumber: '', userName: "", userType: 'Independent', errors: {}, otpScreen: false, otp: '', emailExist: false, phoneNumberExist:false };


  handleChangeSingle = e => {
    this.setState({ [e.target.name]: e.target.value })

    if (e.target.name === 'emailId') {  // Debouncing method//
      clearTimeout(timer);
      timer = setTimeout((e) => {
        verifyEmailUniqueness(this.state.emailId)
          .then(res => {
            this.setState({ emailExist: res.data.data })
          })
      }, 200)
    }
    if (e.target.name === 'phoneNumber') {  // Debouncing method//
      clearTimeout(timer);
      timer = setTimeout((e) => {
        verifyPhoneNumberUniqueness(this.state.phoneNumber)
          .then(res => {
            this.setState({ phoneNumberExist: res.data.data })
          })
      }, 200)
    }
  };

  submitForm = e => {
    e.preventDefault();
    const { password, confirmPassword, otp, emailExist, phoneNumberExist } = this.state;
    if ((password === confirmPassword && !emailExist && !phoneNumberExist)) {
      if (otp) {
        userSignUp(_.pickBy(_.omit(this.state, ['errors', 'otpScreen']), _.identity))
          .then(res => {
            swal('Done', { buttons: false, timer: 2000, icon: 'success' });
            this.props.history.push('/')
          })
          .catch(e => {
            if (e ?.response ?.data ?.Error ?.errors) {
              let errors = {}
              _.map(e.response.data.Error.errors, (val, key) => {
                errors[key] = val.message;
              })
              this.setState({ errors });

            }
            else {
              swal(e.response.data.Error, { buttons: false, timer: 2000, icon: 'error' });
            }
          })
      }
      else {
        userSignUpOtp(_.pickBy(_.omit(this.state, ['errors', 'otpScreen']), _.identity))
          .then(res => {
            this.setState({ otpScreen: true });
          })
      }
    }
    else {
      swal("Password and confirm password does not match", { buttons: false, timer: 2000, icon: 'error' });
    }

  };


  render() {
    return <NewSignupPage {...this.state} handleOnChange={this.handleChangeSingle} submitForm={this.submitForm} />
  }
}


export default NewSignupContainer;