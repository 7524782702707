import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { FaFilm, FaMapMarkerAlt, FaRegEdit, FaSearch, FaTimes } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight, } from 'react-icons/fa';
import _, { isArray } from 'lodash';
import moment from 'moment';
import Select from "react-select";
import Pagination from "../Pagination/Pagination";

const sortOptions = [
  {
    label: 'Latest',
    value: 'updatedAt',
    order: -1
  },
  {
    label: 'Title',
    value: 'title',
    order: 1
  },
  {
    label: 'Relavence',
    value: 'category',
    order: 1
  },
]
const AllPost = ({ onChangeSearch, removePost, publishPost, data, onChangeSort, setToggleFilter, user, ...props }) => {
  let pageSize = 5;
  const [newList, setNewList] = useState([])
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    if (data) {
      let d = data.slice(0, pageSize)
      setNewList(d)
    }
  }, [data])
  const pageChangeHandler = (index) => {
    console.log(index)
    const slicedList = data.slice((index - 1) * pageSize, (index * pageSize))
    setNewList(slicedList)
    setPageIndex(index)

  }
  return (
    <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 search_job_list" onClick={() => { setToggleFilter(true) }}>

      <div className='flex_bx'>
        <div className="form-group">
          <input
            type="search"
            placeholder="Search for audition"
            className="form-control"
            onChange={(e) => onChangeSearch(e.target.value)}
          />
        </div>
        <div className="form-group col-sm-3 col-xs-4 full">
          <Select
            placeholder="Sort By"
            options={sortOptions}
            onChange={(e) => onChangeSort(e)}
          />
        </div>
        <button type="button" className="blue_btn">
          <FaSearch /> Search
        </button>
      </div>



      <div className="row">
        <>
          {_.map(newList, (val) => (
            <>
              <div className='col-md-4 custom_space'>
                <div className="joboutline_bx">
                  <div className="breakdown_casting_info audition_info">
                    <div className='audtion_content'>
                      <div className="casting_list_head clearfix">
                        <h3>{val.title}</h3>

                        {/* {val.jobTitle && <span>
                        <strong>Job Title :</strong>{" "}
                        {val.jobTitle}
                      </span>} */}

                        {/* {val.filmType && <span>
                        <strong>Production Type :</strong>{" "}
                        {val.filmType?.join(", ")}
                      </span>}
                      {val.talentType && val.talentType.filter(e => e.length).length > 0 ? <span>
                        <strong> Character type : </strong>{" "}
                        {val.talentType?.join(", ")}{" "}
                      </span> : ''}
                      {val.workStyle && <span>
                        <strong>Work Style :</strong>{" "}
                        {val.workStyle}
                      </span>} */}

                        <p className='title-ellipsis'>
                          <strong>Production House : </strong>
                          {val.productionHouse}
                        </p>
                        {/* {val.benefits && val.benefits.length ? <span>
                        <strong>benefits :</strong>{" "}
                        {isArray(val.benefits) ? val.benefits?.join(", ") : val.benefits}
                      </span> : ''} */}
                        <span>
                          <FaMapMarkerAlt /> {val.currentCity}
                        </span>
                        <span className="film-type-ellipsis">
                          <FaFilm /> {val.filmType}
                        </span>
                        {/* {val.minAge && <span>
                        <strong>Age: </strong> {val.minAge} - {val.maxAge}
                      </span>} */}

                      </div>
                      <p className="brief_intro">{val.jobDescription}</p>
                      <span>
                        <strong>Posted on : </strong>{" "}
                        {moment(val.createdAt).format("YYYY-MM-DD")}
                      </span>
                    </div>
                    {/* {val.description && <span className="discrption">
                      <strong>Charecter Breif / Sketch : </strong>
                      {val.description}
                    </span>} */}

                    {/* {val.languagesSpeak && val.languagesSpeak.length ? <span>
                      <strong>Language Speak : </strong>
                      {val.languagesSpeak?.join(", ")}
                    </span> : ''} */}
                    {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}

                    {/* <div className="row">
                      <div className="col-md-6">aaa</div>
                      <div className="col-md-6">bbb</div>

                    </div> */}

                    <div className="postjop_casting_btn">
                      <Link
                        to={{
                          pathname: "/PostJob",
                          state: { id: val._id, new: true },
                        }}
                        >
                        Reuse Post
                      </Link>

                      {user && user._id === val.userId ? <>
                        <Link
                          to={{ pathname: "/PostJob", state: { id: val._id } }}>
                          {" "}
                          <FaRegEdit /> Edit Post
                        </Link>
                        <Link
                          to="/JobList"
                          onClick={() => removePost(val._id)}>
                          {" "}
                          <FaTimes />  Delete Post
                        </Link>
                      </> : null}
                    </div>

                    <div >
                      <div >
                        
                        {/* {!val.published && (
                     <Link onClick={() => val._id} className="stroke_btn">
                       publish Post
                     </Link>
                   )} */}
                   
                   <div className="searchjob_point searchjob_bx">
                   <div className="application_info">
                   
                            <p>
                                <strong>{val.appliedUsers?.length}</strong>{" "}
                                Applications
                                </p>
                                {/* <strong>0</strong> Messaged */}
                              
                                {/* <strong>{val.hiredUsers?.length}</strong> Hired */}
                                <Link to={`/PostJobDetail/${val._id}`}>More Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </>
        <div class="pagination_part clearfix text-right">
          <Pagination totalCount={data?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} />
        </div>
      </div>
    </div>
  );
};

export default AllPost;