import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class SmallThumbnail extends Component {     
    state={
        item: [
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
            {url:'images/movie_img3.jpg', date:'3rd, Dec, 2017', title:'Lorem ipsum dolor', diector:'Director Taika Waititi'},
        ]        
}

render(){
    const {item} = this.state;
    return(
        <div >  
             {item.map(item => 
             <div className="col-md-3 col-xs-6 full">
                 <ul>
               <li>
               <div className="trendy_txt"> 
               <img src={item.url} alt="img" />
                 <div className="trend_bx">
                   <small><em>{item.date}</em></small>
                   <a href="#"><h6>{item.title}</h6></a>
                   <small>{item.diector}</small>
                 </div>
               </div>
             </li>
             </ul>
             </div>
              )              
             }        
        </div>
           
    )
}

}

export default SmallThumbnail

          

