import React, { Component } from 'react';
import AuditionDetailPage from '../../pages/AuditionDetail/AuditionDetailPage';
import { getAuditionDetails, sendInvitationAudition, saveUserAudition, shortlistUserAudition, getCurrentUser, searchUser, deleteAudition, getUsersRole } from '../../actions/index';
import _ from 'lodash';
import swal from 'sweetalert';
let timer = "";
class AuditionDetailPageContainer extends Component {

    state = { AuditionDetail: {}, search: "", searchedData: [], currentUser: {} };

    componentDidMount() {
        if (this.props.match ?.params ?.id) {
            this.getAudition();
            this.setCurrentUser(JSON.parse(localStorage.getItem('user')));
        }
        const userStr = localStorage.getItem("user");
        const user = userStr ? JSON.parse(userStr):null
        this.setState({ loginUser: user },()=>{
          getUsersRole(this.state.loginUser?._id).then(res =>{
            this.setState({ usersRoleDetails: res.data.data })
          })
        });
    }

    setCurrentUser = currentUser => this.setState({ currentUser });

    getAudition = () => {
        getAuditionDetails(this.props.match.params.id)
            .then(res => this.setState({ AuditionDetail: res.data ?.data }, () => this.onUserSearch("")));
    }
    onUserSearch = search => {
        this.setState({ search });
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.getSearchedUser();
        }, 300);
    }

    sendInvitaion = userId => {
        sendInvitationAudition({ AuditionId: this.props.match.params.id, userId })
            .then(res => {
                swal('Invitation sent', { buttons: false, timer: 2000, icon: 'success' })
                this.getAudition()
            })
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }

    saveUser = userId => {
        saveUserAudition({ AuditionId: this.props.match.params.id, userId })
            .then(res => {
                swal('User Saved', { buttons: false, timer: 2000, icon: 'success' })
                this.getAudition()
            })
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }

    shortlistUser = userId => {
        shortlistUserAudition({ AuditionId: this.props.match.params.id, userId })
            .then(res => {
                swal('User shortlisted', { buttons: false, timer: 2000, icon: 'success' })
                this.getAudition()
            })
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }


    getSearchedUser = () => {
        searchUser(this.state.search)
            .then(res => this.setState({ searchedData: [...res.data ?.data[0] ?.name , ...res.data ?.data[0] ?.name] }))
    }

    removeAudition = () => {
        deleteAudition(this.state.AuditionDetail._id)
            .then(res => this.props.history.push('/SearchAudition'))
            .catch(err => swal('Something went wrong', { buttons: false, timer: 2000, icon: 'error' }));
    }

    render() {
        console.log(this.state, "ddjoeuded8e");
        return <AuditionDetailPage
            {...this.state}
            removeAudition={this.removeAudition}
            onUserSearch={this.onUserSearch}
            sendInvitaion={this.sendInvitaion}
            saveUser={this.saveUser}
            shortlistUser={this.shortlistUser}
        />
    }
}

export default AuditionDetailPageContainer