import React from 'react';
import { FaRupeeSign,FaUserPlus, FaTimes, FaRegEnvelope, FaMapMarkerAlt, FaEye, FaStar, FaPlus, } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const MakeFilmPopup = ({ onAddNewUser,resetSearch,searchUser,...props}) => {
  console.log(props , "dwijdowhu");
    return(
        <>
    <div className="modal fade" id="view_more_list" role="dialog">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          <h4 className="modal-title">Cast List</h4>
        </div>
        <div className="modal-body">
        <div className="table-responsive">
            <table className="table people_table">
                                    <tbody>                                        
                                        
                                    {
                                _.map(_.groupBy(props.filmData.categoryWiseUsers, 'category'), (val, key) => (
                                    <>
                                        <tr className="table_label">
                                            <td colSpan="5">{key}</td>
                                            <td colSpan="2" align="right"><Link to="" data-toggle="modal" data-target="#view_more_list"><small>View More</small></Link></td>
                                        </tr>
                                        {
                                            _.map(val, val1 => {
                                                const user = _.find(props.filmData.team , {_id: val1.userId })
                                                return (
                                                    <tr>
                                                        <td className="user_cat_info">
                                                            <img src="images/akshay.jpg" alt="img" />
                                                            <Link to="people_detail.php"><h5>{user.userName}</h5></Link>
                                                            <small>4.5 rating</small>
                                                        </td>
                                                        <td><p><small><FaMapMarkerAlt /> {user.city}</small></p></td>
                                                        <td><p><small>{user.phoneNumber ? String(user.phoneNumber || '')?.slice(0, 4) + "*******" : ""}</small></p></td>
                                                        <td><p><small><FaRupeeSign /> 00000.00</small></p></td>
                                                        {
                                                            val1.invited ? <span>Invited</span> : <td onClick = {() => props.onsendSingleInvitation(val1.userId)} ><FaRegEnvelope /> Invite</td>
                                                        }
                                                        
                                                        <td ><Link to={`UserProfile/${user._id}`} className="invite_icon" ><FaEye /> Contact</Link></td>
                                                        <td onClick = {() => props.onAddRemoveUser(user._id)}><FaTimes /></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>
                                ))
                            }

                                    </tbody>  
                                </table>                                
                               
                                </div> 
                                    </div>                
        </div>       
        </div>        
      </div>

      <div class="modal fade" id="invite_people" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Send Invitation</h4>
        </div>
        <div class="modal-body text-center">
          <div className="icon_bx"><FaUserPlus /></div>
            <p>Need 15 connects to invite. </p>  
            <div class="application_btn">
        <button type="submit" className="blue_btn">Submit</button>
            <button type="button" className="stroke_btn" data-dismiss="modal">Cancel</button>
        </div>       
          </div>         
          
        </div>       
        </div>        
      </div>

      {/* job category popup */}
      <div className="modal fade" id="job_cat" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" onClick = {()=> resetSearch()}>&times;</button>
          <h4 className="modal-title">{props.category}</h4>
        </div>
        <div className="modal-body text-center">
        <div className="input_field">
                    <input type="search" placeholder="Search" className="form-control" value = {props.searchText} onChange = {(e) => searchUser(e.target.value)}/>
                  </div>
                 <ul>
                   
                {
                  _.map(props.users , val => (
                    <>
                     <li><Link to="#">
                      <div className="cat_info">
                        <div className="small_profile">
                          <img src="images/akshay.jpg" alt="img" />
                        </div>
                        <div className="list_info">
                          <h6>{val.userName}</h6>
                          <div className="rating">
                            <div className="star">
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="unfill" />
                              <FaStar className="unfill" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="add_icon selected_icon" onClick = {() => onAddNewUser(val._id)}><FaPlus /></div>
                    </Link>
                    </li>
                    </>
                  ))
                }
                  </ul>
          </div>         
          
        </div>       
        </div>        
      </div>


</>
);
}

export default MakeFilmPopup;