import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaHeart, FaAngleRight, FaStar, FaAngleLeft, FaBookmark, FaMapMarkerAlt, FaFilm, } from 'react-icons/fa';
import _, { isArray } from 'lodash';
import moment from 'moment';
import Pagination from '../Pagination/Pagination';

const SearchJobList = ({ data, onChangeSearch, saveJobs, onChangeFilter, unSaveJobs, getSavedJobs, getAppluedJobs, savedJobs, appliedJobs, setToggleFilter }) => {
  let pageSize = 10;
  const [newList, setNewList] = useState([])
  const [pageIndex, setPageIndex] = useState(1);

  const user = localStorage.getItem('user');
  const myId = user ? JSON.parse(user)?._id : '';

  useEffect(() => {
    if (data) {
      let d = data.slice(0, pageSize)
      setNewList(d)
    }
  }, [data])
  const pageChangeHandler = (index) => {
    console.log(index)
    const slicedList = data.slice((index - 1) * pageSize, (index * pageSize))
    setNewList(slicedList)
    setPageIndex(index)

  }
  return (
    <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 search_job_list" onClick={() => { setToggleFilter(true) }}>
      <div className="inside_listing_tab">
        <ul className="nav nav-tabs">
          <li className="active"><Link to="#list_tab1" data-toggle="tab">Search</Link></li>
          <li><Link to="#list_tab2" data-toggle="tab"  >Saved Jobs ({savedJobs.length})</Link></li>
          <li><Link to="#list_tab3" data-toggle="tab" >Applied Jobs<span>({appliedJobs.length})</span> </Link></li>
        </ul>

        <div className="tab-content">
          <div className="tab-pane active" id="list_tab1">
            <div className='flex_bx'>
              <div className="form-group">
                <input type="search" placeholder="Search for Job" className="form-control" onChange={e => onChangeSearch(e.target.value)} />
              </div>
              <div className="filter_points">
                <div id="group-2" className="list-group collapse in">
                  <p className="list-group-item full_field"><input onChange={e => onChangeFilter({ type: 'location', value: e.target.value })} type="search" placeholder="Search client location" className="form-control" /> </p>
                </div>
              </div>
              <button type="button" className="blue_btn">
                <FaSearch /> Search
              </button>

            </div>

            <div className="row">
              {
                _.map(_.orderBy(newList, 'createdAt', 'desc'), val => (
                  <>
                    <div className='col-md-4 custom_space'>
                      <div className="joboutline_bx">



                        {/* <div className="searchjob_head clearfix"> */}
                        <div className="breakdown_casting_info audition_info">
                          <div className='audtion_content'>
                            <Link to={`/UserSideJob/${val._id}`}>
                              <div className="casting_list_head clearfix ">
                                <div className="side_action_btn pull-right">
                                  {!savedJobs.map(e => e._id).includes(val._id) ? <div className="icon" onClick={() => saveJobs(val._id)}><FaBookmark /></div> :
                                    <div className="icon saved_job" onClick={() => unSaveJobs(val._id)}><FaBookmark /></div>}
                                </div>
                                <h3 className="title-ellipsis">{val.title}</h3>


                                {/* <div className="disc_brief"> */}
                                {/* {val.jobTitle && <span>
                              <strong>Job Title :</strong>{" "}
                              {val.jobTitle}
                            </span>} */}
                                {/* {val.filmType && val.filmType.filter(e => e.length).length > 0 ? <span>
                              <strong>Production Type :</strong>{" "}
                              {val.filmType?.join(", ")}
                            </span> : ''}
                            {val.talentType && val.talentType.filter(e => e.length).length > 0 ? <span>
                              <strong> Character type : </strong>{" "}
                              {val.talentType?.join(", ")}{" "}
                            </span> : ''}
                            {val.workStyle && <span>
                              <strong>Work Style :</strong>{" "}
                              {val.workStyle}
                            </span>} */}
                                <p className='title-ellipsis'>
                                  <strong>Production House : </strong>
                                  {val.productionHouse}
                                </p>
                                {/* {val.benefits && val.benefits.length ? <span>
                              <strong>benefits :</strong>{" "}
                              {isArray(val.benefits) ? val.benefits?.join(", ") : val.benefits}
                            </span> : ''} */}
                                {/* {val.currentCity && <span><FaMapMarkerAlt />
                              <strong> </strong> {val.currentCity}
                            </span>} */}
                                {/* {val.currentCity && <span> <FaMapMarkerAlt />
                              {val.currentCity}</span>} */}
                                <span>
                                  <FaMapMarkerAlt /> {val.currentCity}
                                </span>
                                <span className="film-type-ellipsis">
                                  <FaFilm /> {val.filmType}
                                </span>
                                {/* {val.minAge && <span>
                              <strong>Age: </strong> {val.minAge} - {val.maxAge}
                            </span>} */}
                              </div>
                            </Link>
                            <p className="brief_intro">{val.jobDescription}</p>
                            <small><strong>Posted on : </strong>{" "}
                              {moment(val.createdAt).format("YYYY-MM-DD")}</small>

                          </div>
                          {/* {val.description && <span className="discrption">
                            <strong>Charecter Breif / Sketch : </strong>
                            {val.description}
                          </span>}
                          {val.jobDescription && <span className="discrption">
                            <strong>Job Description : </strong>
                            {val.jobDescription}
                          </span>}{" "}
                          {val.languagesSpeak && val.languagesSpeak.length ? <span>
                            <strong>Language Speak : </strong>
                            {val.languagesSpeak?.join(", ")}
                          </span> : ''} */}
                          {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}



                          {/* <div className="search_btm_info">
                          <p><strong>Payment unverified</strong></p>
                          <div className="rating">
                            <div className="star">
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="unfill" />
                              <FaStar className="unfill" />
                            </div>
                          </div>
                          <p>Spent <strong>Rs 100k+</strong></p>
                        </div> */}
                          {/* <Link to={`/UserSideJob/${val._id}`}>More Details</Link>
                          <div className="application_info">
                            <p>Application: <strong>{val.applications?.length}</strong></p>

                            {
                              val?.appliedUsers && val?.appliedUsers.find(e => e === val.userId) ?
                                <span>Applied</span>
                                :
                                val.userId !== myId ? (val.appliedUsers.includes(myId) ? <span> Applied</span> : <Link to={`/UserSideJob/${val._id}`}>Apply</Link>) : ''}
                          </div> */}
                          <hr />
                          <div className="searchjob_point searchjob_bx">
                            <div className="application_info">

                              {/* {val.userId === myId && <Link style={{ marginRight: 10 }} to={"/chat?auditionId=" + val._id}>Chat</Link>} {" "} */}
                              <p>
                                Application: <strong>{val?.applications?.length || 0}</strong>
                              </p>
                              {
                                val?.appliedUsers && val?.appliedUsers.find(e => e === val.userId) ?
                                  <span style={{ color: '#4bcc69', fontSize: '15px', fontWeight: '500' }}>Applied</span>
                                  :
                                  val.userId !== myId ? (val.appliedUsers.includes(myId) ? <span style={{ color: '#4bcc69', fontSize: '15px', fontWeight: '500' }}> Applied</span> : <Link to={`/UserSideJob/${val._id}`}> Apply</Link>) : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }


              <div className="pagination_part clearfix text-right">
                <br />
                <Pagination totalCount={data?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} />

              </div>
            </div >
          </div>

          <div className="tab-pane" id="list_tab2">
            <div className="row">

              {
                _.map(savedJobs, val => (
                  <>
                    <div className='col-md-4 custom_space'>
                      <div className="joboutline_bx">

                        <div className="breakdown_casting_info audition_info">
                          <div className='audtion_content'>
                            <Link to={`/UserSideJob/${val._id}`}>
                              <div className="casting_list_head clearfix">
                                <div className="side_action_btn pull-right">
                                  <div className="icon saved_job" onClick={() => unSaveJobs(val._id)}><FaBookmark /></div>
                                </div>
                                <h3 className='title-ellipsis'>{val.title}</h3>
                                {/* <div className="disc_brief"> */}
                                {/* {val.jobTitle && <span>
                              <strong>Job Title :</strong>{" "}
                              {val.jobTitle}
                            </span>} */}
                                {/* {val.filmType && val.filmType.filter(e => e.length).length > 0 ? <span>
                              <strong>Production Type :</strong>{" "}
                              {val.filmType?.join(", ")}
                            </span> : ''}
                            {val.talentType && val.talentType.filter(e => e.length).length > 0 ? <span>
                              <strong> Character type : </strong>{" "}
                              {val.talentType?.join(", ")}{" "}
                            </span> : ''}
                            {val.workStyle && <span>
                              <strong>Work Style :</strong>{" "}
                              {val.workStyle}
                            </span>} */}
                                <p className='title-ellipsis'>
                                  <strong>Production House : </strong>
                                  {val.productionHouse}
                                </p>
                                {/* {val.benefits && val.benefits.length ? <span>
                              <strong>benefits :</strong>{" "}
                              {isArray(val.benefits) ? val.benefits?.join(", ") : val.benefits}
                            </span> : ''} */}
                                {/* {val.currentCity && <span><FaMapMarkerAlt />
                              <strong> </strong> {val.currentCity}
                            </span>} */}
                                {/* {val.currentCity && <span> <FaMapMarkerAlt />
                              {val.currentCity}</span>} */}
                                <span>
                                  <FaMapMarkerAlt /> {val.currentCity}
                                </span>
                                <span className="film-type-ellipsis">
                                   <FaFilm />{val.filmType}</span>
                                {/* {val.minAge && <span>
                              <strong>Age: </strong> {val.minAge} - {val.maxAge}
                            </span>} */}
                              </div>
                            </Link>
                            <p className="brief_intro">{val.jobDescription}</p>
                            <span>
                              <strong>Posted on : </strong>{" "}
                              {moment(val.createdAt).format("YYYY-MM-DD")}
                            </span>
                          </div>
                          {/* {val.description && <span className="discrption">
                              <strong>Charecter Breif / Sketch : </strong>
                              {val.description}
                            </span>}
                            {val.jobDescription && <span className="discrption">
                              <strong>Job Description : </strong>
                              {val.jobDescription}
                            </span>}{" "}
                            {val.languagesSpeak && val.languagesSpeak.length ? <span>
                              <strong>Language Speak : </strong>
                              {val.languagesSpeak?.join(", ")}
                            </span> : ''} */}
                          {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}


                          {/* <p className="job_des">{val.description}</p>
                          <div className="skills">
                            <span> {val.genre}</span>
                          </div> */}

                          <hr />
                          <div className="searchjob_point searchjob_bx">
                            <div className="application_info">
                              {/* <p><strong>Payment unverified</strong></p>
                          <div className="rating">
                            <div className="star">
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="unfill" />
                              <FaStar className="unfill" />
                            </div>
                          </div>
                          <p>Spent <strong>Rs 100k+</strong></p> */}

                              <div className="application_info">
                                <p>Application: <strong>{val.applications?.length}</strong></p>
                              </div>
                              <Link to={`/UserSideJob/${val._id}`}>More Details</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }


              <div className="pagination_part clearfix text-right">
                <br />
                {/* <ul className="pagination">
                  <li><Link to="#"><FaAngleLeft /> Prev</Link></li>
                  <li><Link to="#">1</Link></li>
            <li><Link to="#">2</Link></li>
            <li><Link to="#">3</Link></li>
            <li><Link to="#">4</Link></li>
            <li><Link to="#">5</Link></li>
                  <li><Link to="#"> Next <FaAngleRight /></Link></li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className="tab-pane" id="list_tab3">
            <div className="row">

              {
                _.map(appliedJobs, val => (
                  <>
                    <div className='col-md-4 custom_space'>
                      <div className="joboutline_bx">

                        <div className="breakdown_casting_info audition_info">
                          <div className='audtion_content'>
                            <Link to={`/UserSideJob/${val._id}`}>
                              <div className="casting_list_head clearfix">
                                <h3 className='title-ellipsis'>{val.title}</h3>

                                {/* {val.jobTitle && <span>
                                <strong>Job Title :</strong>{" "}
                                {val.jobTitle}
                              </span>}
                              {val.filmType && val.filmType.filter(e => e.length).length > 0 ? <span>
                                <strong>Production Type :</strong>{" "}
                                {val.filmType?.join(", ")}
                              </span> : ''}
                              {val.talentType && val.talentType.filter(e => e.length).length > 0 ? <span>
                                <strong> Character type : </strong>{" "}
                                {val.talentType?.join(", ")}{" "}
                              </span> : ''}
                              {val.workStyle && <span>
                                <strong>Work Style :</strong>{" "}
                                {val.workStyle}
                              </span>} */}
                                <p className='title-ellipsis'>
                                  <strong>Production House : </strong>{" "}
                                  {val.productionHouse}
                                </p>
                                {/* {val.benefits && val.benefits.length ? <span>
                                <strong>benefits :</strong>{" "}
                                {isArray(val.benefits) ? val.benefits?.join(", ") : val.benefits}
                              </span> : ''} */}
                                <span>
                                  <FaMapMarkerAlt /> {val.currentCity}
                                </span>
                                <span className="film-type-ellipsis"> <FaFilm />
                                  {val.filmType}</span>
                                {/* {val.minAge && <span>
                                <strong>Age: </strong> {val.minAge} - {val.maxAge}
                              </span>} */}
                              </div>
                            </Link>
                            <p className="brief_intro">{val.jobDescription}</p>
                            <span>
                              <strong>Posted on : </strong>{" "}
                              {moment(val.createdAt).format("YYYY-MM-DD")}
                            </span>

                            {/* {val.description && <span className="discrption">
                              <strong>Charecter Breif / Sketch : </strong>
                              {val.description}
                            </span>}
                            {val.jobDescription && <span className="discrption">
                              <strong>Job Description : </strong>
                              {val.jobDescription}
                            </span>}{" "}
                            {val.languagesSpeak && val.languagesSpeak.length ? <span>
                              <strong>Language Speak : </strong>
                              {val.languagesSpeak?.join(", ")}
                            </span> : ''} */}
                            {/* <p><strong>{val.filmType}</strong> | <strong>{val.genre}</strong> | <span><strong>Hourly: {val.hourlyRate}</strong></span> | <span><strong>{val.experienceLevel}</strong></span> | <span>{moment(val.createdAt).format('LL') }</span> |  <span><strong>Mumbai</strong></span></p> */}


                            {/* <p className="job_des">{val.description}</p>
                          <div className="skills">
                            <span> {val.genre}</span>
                          </div> */}
                          </div>

                          <hr />
                          <div className="searchjob_point searchjob_bx">
                            <div className="application_info">
                              {/* <p><strong>Payment unverified</strong></p>
                          <div className="rating">
                            <div className="star">
                              <FaStar />
                              <FaStar />
                              <FaStar />
                              <FaStar className="unfill" />
                              <FaStar className="unfill" />
                            </div>
                          </div>
                          <p>Spent <strong>Rs 100k+</strong></p> */}
                              {/* <Link to={`/UserSideJob/${val._id}`}>More Details</Link> */}


                              <div className="application_info">
                                <p>Application: <strong>{val.applications?.length}</strong></p>
                              </div>
                              <Link to={`/UserApplication/${val._id}`}>More Details</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }


              <div className="pagination_part clearfix text-right">
                <br />
                {/* <Pagination totalCount={data?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} /> */}
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}
export default SearchJobList;
