import React from 'react';
import {  PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import Invoice from '../../components/ArtistInvoice/Invoice';
import { FaFileInvoiceDollar } from 'react-icons/fa';

import bgImage from '../../images/logo_1.png'

const ArtistInvoicePage = ({invoice,transactionId}) => {
  const invoiceData = invoice
  return(
  <div>
  <div className='' >
  {/* <PDFViewer showToolbar={false} width="1000" height="600" className="app" >
                <Invoice invoice={invoiceData}/>
            </PDFViewer> */}
            <PDFDownloadLink  document={ <Invoice invoice={invoiceData}/>} fileName={"Talent to Agency Invoice.pdf"}>
      {({ blob, url, loading, error }) =>
        loading ? <button style={{width:'100%', padding:'6px'}}>Loading...</button> : <button style={{width:'100%', padding:'6px'}}><FaFileInvoiceDollar/> Talent to Agency Invoice</button>
      }
    </PDFDownloadLink>
  </div>
  </div>
)};
export default ArtistInvoicePage;
