import React from "react";
import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";

function PrivacyPolicy(props) {
    return (
      <div>
        <Header />   
        <section id="popular_tag" className="mid_info dark_clr">
            <div className="container side_space privacy-p">         
                <h3 className="text-center">Privacy Policy for Bollywood is Good (BiG)</h3><br />
        <p>At Bollywood is Good (BiG), we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our platform and services. By accessing or using BiG, you agree to the terms of this Privacy Policy.</p>
             <br />
             <h6>Information Collection:</h6>
             <ul>
                <li>
                    <p>We collect personal information, such as your name, contact details, and professional background, when you create an account or submit information through our platform.</p>
                </li>
                <li><p>We may also collect non-personal information, such as usage data and cookies, to improve our services and enhance your experience.</p></li>
             </ul>
             <br />
             <h6>Use of Information:</h6>
             <ul>
                <li><p>We use the information you provide to connect you with relevant job opportunities, audition calls, and industry-related updates.</p></li>
             <li><p>Your personal information may be shared with verified industry professionals and potential collaborators to facilitate connections and collaborations</p></li>
             </ul><br />
             <h6>Data Security:</h6>
             <ul>
                <li><p>
                We have implemented industry-standard security measures to protect your personal information from unauthorised access, alteration, or disclosure.
                </p></li>
                <li><p>However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security of your data.</p></li>
             </ul>
             <br />
             <h6>Third-Party Services:</h6>
             <ul>
                <li><p>BiG may include links to third-party websites or services. Please note that we do not have control over their privacy practices and are not responsible for their content or actions.</p></li>
                <li><p>We recommend reviewing the privacy policies of any third-party websites or services before providing them with your personal information</p></li>
             </ul>
             <br />
             <h6>Data Retention:</h6>
             <ul>
                <li><p>We retain your personal information as long as necessary to provide our services and fulfil the purposes outlined in this Privacy Policy.</p></li>
             <li><p>You may request the deletion of your account and personal information at any time, subject to applicable laws and regulations.</p></li>
             </ul>
             <br />
             <h6>Updates to Privacy Policy:</h6>
             <ul>
                <li><p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on our platform, and it is your responsibility to review the policy periodically.</p></li>
             </ul>
             <p>If you have any questions or concerns regarding our Privacy Policy, please contact us at info@bollywoodisgood.com</p>
             
             <br />
             <p>Last updated: 17th June 2023</p>
             <br />
             <p>By using BiG, you acknowledge and consent to the practices described in this Privacy Policy.</p>
              </div>
          </section>             
        <Footer />
      </div>
    );
  }
  export default PrivacyPolicy;