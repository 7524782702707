import React, { Component } from 'react';
import UserProfilePage from "../../pages/UserProfile/UserProfilePage";
import { getHiringAccessById, getUserInformation, getUsersRole, inviteForJob, loginUser, requestHiringAccess, undoJobinvite, updateUserReview } from '../../actions/index';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import PopUp from '../ApplyAudition/Popup';

class UserProfileContainer extends Component {
  state = { user: {}, step: 1, activeTab: 1, eligibleForHiring:false,
  showPopup: false, // State to control the Popup visibility
};

  componentDidMount() {
    const searchParam = this.props.location.search;
    const tab = new URLSearchParams(searchParam).get('tab');
    if(tab){
      this.setState({activeTab: +tab})
    }
    if (this.props.match?.params?.id) {
      getUserInformation(this.props.match?.params?.id).then((res) =>
        this.setState({ user: res.data.data })
      );
     
    }
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr):null
    this.setState({ loginUser: user },()=>{
      getUsersRole(this.state.loginUser?._id).then(res =>{
        this.setState({ usersRoleDetails: res.data.data })
      })
      getHiringAccessById({userId:this.state.loginUser?._id}).then((ress)=>{
        if(ress.data && ress.data.data && ress.data.data.status ==='Approved'){
          this.setState({eligibleForHiring: true})
        }
      })
    });
  }
  handleChangeSingle = (e) =>{
    console.log(e)
    if (e.target.name === 'user') {
        
    }
    this.setState({ user:{...this.state.user,[e.target.name]: e.target.value} });
}
onHireClick = () => {
  const { user } = this.state;
  const subscriptionPlan = JSON.parse(localStorage.getItem("subscriptionPlan"));
  console.log(subscriptionPlan.maxHireTalent,"aaa")
  if (subscriptionPlan.maxHireTalent == 0 ) {
    swal({
      title: "Access Exclusive Features!",
              // icon: "error",

              content: {
                element: "div",
                attributes: {
                  innerHTML: `
                   
                  <p style="text-align: center;font-size:18px">Subscribe now to unlock premium opportunities.
                   Or refer a friend and earn credits to access BiG for free!</p>`,
                  // Center the text❌😟⚠️🚫 ${errorMessage} <div style="font-size: 40px; margin-bottom: 25px;">❌</div>
                  // Subscribe now to unlock premium opportunities.Or refer a friend and earn credits to access BiG for free!
                },
              },
      buttons: {
        aaaa: {
          text: "Refer a Friend",
          value: "referal",
          visible: true,
          className: "blue_btn",
        },
        bbbb: {
          text: "Subscribe Now",
          value: "renew",
          visible: true,
          className: "blue_btn",
        },
      },
    }).then((value) => {
      if (value === "referal") {
        window.location.href = "#/Referal";
      }
      if (value === "renew") {
        // Show the Popup component instead of navigating
        this.setState({ showPopup: true });
      }
    });
    setTimeout(() => {
      const modal = document.querySelector(".swal-modal");
      const closeButton = document.createElement("button");
      closeButton.innerHTML = "&times;";
      closeButton.style.position = "absolute";
      closeButton.style.top = "5px";
      closeButton.style.right = "5px";
      closeButton.style.background = "transparent";
      closeButton.style.border = "none";
      // closeButton.style.borderRadius = "50%";
      closeButton.style.fontSize = "30px";
      closeButton.style.cursor = "pointer";
      modal.appendChild(closeButton);
      closeButton.addEventListener("click", () => swal.close());
    }, 0);
  } else{
    this.props.history.push(`/HireTalent/${user._id}`);
  }
};

closePopup = () => {
  this.setState({ showPopup: false }); // Close the Popup
};

 handleSubmitReview =(e) => {
    e.preventDefault()
      updateUserReview(_.pickBy(_.omit(this.state.user, ['errors', 'agreed']), _.identity))
        .then(res =>  {
          if(res.status && res.data.data){
            if (this.props.match?.params?.id) {
              getUserInformation(this.props.match?.params?.id).then((res) =>
                this.setState({ user: res.data.data })
              );
            }
            toast('Thank you for your valuable review', { isLoading: true, timer: 2000, icon: 'success' })
          }else{
            toast('Something went wrong.', { buttons: false, timer: 2000, icon: 'error' });
          }
          })
    }
  inviteForJobHandler =(e)=>{
    e.preventDefault()
    inviteForJob(_.pickBy(_.omit(this.state.user, ['errors', 'agreed']), _.identity),this.state.loginUser?._id)
        .then(res =>  {
          if(res.status && res.data.data){

            if (this.props.match?.params?.id) {
              getUserInformation(this.props.match?.params?.id).then((res) =>
                this.setState({ user: res.data.data })
              );
            }
          }
          })
  }
  undoInviteHandler =(e)=>{
    e.preventDefault()
    undoJobinvite(_.pickBy(_.omit(this.state.user, ['errors', 'agreed']), _.identity),this.state.loginUser?._id)
        .then(res =>  {
          if(res.status && res.data.data){

            if (this.props.match?.params?.id) {
              getUserInformation(this.props.match?.params?.id).then((res) =>
                this.setState({ user: res.data.data })
              );
            }
          }
          })
  }
  requestForHiringAccess=()=>{
    const {emailId, userName, _id} = this.state.loginUser
    const payload ={
      id: _id, emailId, userName
    }
    requestHiringAccess(payload).then((res) =>{
    if(res.data && res.data.message === 'success'){
      toast('Thank You, Admin Will Contact You Shortly.', { isLoading: true, timer: 2000, icon: 'success' })
    }else{
      toast('Something went wrong.', { isLoading: true, timer: 2000, icon: 'error' })
    }
  }
  ).catch((e)=>{
    toast('Something went wrong.', { isLoading: true, timer: 2000, icon: 'error' })
  });
  }

  render() {
    return (
      <>
      <ToastContainer />
      <UserProfilePage
        {...this.state}
        nextStep={this.nextStep}
        prevStep={this.prevStep}
        handleSubmitReview={this.handleSubmitReview}
        onHireClick={this.onHireClick}
        handleChangeSingle={this.handleChangeSingle}
        inviteForJob={this.inviteForJobHandler}
        requestHiringAccess={this.requestForHiringAccess}
        undoInviteHandler={this.undoInviteHandler}
      />
      {/* Conditionally render Popup */}
      {this.state.showPopup && <PopUp 
        closePopup={this.closePopup}/>}
      </>
    );
  }
}
export default UserProfileContainer;
