import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaSearch,
  FaSuitcase,
  FaUsers,
  FaLocationArrow,
  FaFilm,
} from 'react-icons/fa';

const TopCards = ({ ...rest }) => {
  const activeTab = rest.activeTab;
  const token = localStorage.getItem("accessToken");


  return (
    <>


      <section id="top_cards">
        <div className="container side_space">
          <div className="row">
            {/* <div class="col-md-12 head_part">
              <h2>Discover various BIG Services</h2>
            </div> */}
            <div className="col-sm-12 cards_info">
              <div className="item wow fadeInDown">
                <div className="gallery_info">
                  <div className="movie_img">
                    <img src="images/auditions.jpg" alt="img" />
                    <h5>Auditions</h5>
                  </div>
                  <div className="overlay">
                    <Link to="/SearchAudition" className="stroke_btn">Audition near me</Link>

                    <Link to={token ? "/CreateAudition" : '/login'} className="stroke_btn">Post Audition</Link>
                  </div>
                </div>
              </div>
              <div className="item wow fadeInDown">
                <div className="gallery_info">
                  <div className="movie_img">
                    <img src="images/profile_card.jpg" alt="img" />
                    <h5>{token ? "Update Profile" : "Create Profile"}</h5>
                  </div>
                  <div className="overlay">
                    {/* <Link className="stroke_btn" to="/CreateUserProfile">Edit Profile</Link> */}
                    {token ?
                      <Link to="/CreateUserProfile" className="stroke_btn">Update Profile</Link> :
                      <Link to='/NewSignup' className="stroke_btn">Create Profile</Link>
                    }</div>
                </div>
              </div>



              <div className="item wow fadeInDown">
                <div className="gallery_info">
                  <div className="movie_img">
                    <img src="images/find_talent.jpg" alt="img" />
                    <h5>Hire Talent</h5>
                  </div>
                  <div className="overlay">
                    <Link to="/FindTalent" className="stroke_btn">Hire Talent</Link>
                    <Link to="/hiredContracts" className="stroke_btn">Hired List</Link>
                  </div>
                </div>
              </div>


              <div className="item wow fadeInDown">
                <div className="gallery_info">
                  <div className="movie_img">
                    <img src="images/job_card.jpg" alt="img" />
                    <h5>Jobs</h5>
                  </div>
                  <div className="overlay">
                    <Link to="/SearchJob" className="stroke_btn">Search Job</Link>
                    <Link to={token ? "/PostJob" : '/login'} className="stroke_btn">Post Job</Link>
                  </div>
                </div>
              </div>
              <div className="item wow fadeInDown">
                <div className="gallery_info">
                  <div className="movie_img">
                    <img src="images/digital_activation.jpg" alt="img" />
                    <h5>Digital Activations</h5>
                  </div>
                  <div className="overlay">
                    <Link to="/InfliuencersForm" className="stroke_btn">Influencers</Link>
                    <Link to="/BrandIntegrations" className="stroke_btn">Brand Integrations</Link>
                  </div>
                </div>
              </div>
              <div className="item wow fadeInDown">
                <div className="gallery_info">
                  <div className="movie_img">
                    <img src="images/finance.jpg" alt="img" />
                    <h5>Finance </h5>
                  </div>
                  <div className="overlay">
                    <Link to="/wallet" className="stroke_btn">Invoices </Link>
                    {token ? <Link to="/userProfile/${JSON.parse(localStorage.getItem('user'))._id}" className="stroke_btn">My Contracts </Link>
                      : <Link to="/login" className="stroke_btn">My Contracts </Link>}

                  </div>
                </div>
              </div>
              
              {/* <div className="item">
                <div className="gallery_info">
                  <div className="movie_img">
                    <img src="images/making_film.jpg" alt="img" />
                    <h5>Celebrity Services</h5>
                  </div>
                  <div className="overlay">
                    <Link to="/CelebrityService" className="stroke_btn">Celebrity Services</Link>                    
                    <Link to="/FilmProduction" className="stroke_btn">Film Production</Link>
                    <Link to="/Marketing" className="stroke_btn">Marketing</Link>
                    <Link to="/InfluencerServices" className="stroke_btn">Influencer Services</Link>
                   <p><small>For Director Only*</small></p> 
                  </div>
                </div>
              </div> */}



            </div>
          </div>
        </div>
      </section>





    </>
  )
}

export default TopCards;