import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import InfliuencerPersonalInfo from "./InfliuencerPersonalInfo";
import InfliuencerSocialMedia from "./InfliuencerSocialMedia";
import InfliuencerBrandCollab from "./InfliuencerBrandCollab";

const InfliuencersFormTabs = ({ step, ...props }) => {
  console.log(step, "STEP COUNT");
  return (
    <>
      <div className="col-md-3 col-sm-4 right_space sidebar_part"> 
        <ul className="nav nav-tabs">
          <li className={step === 1 ? 'active' : ''} >
            <a href="javascript:void" onClick={() => props.nextStep(1)} className="active"> Personal Information</a>
          </li>
          <li className={step === 2 ? 'active' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(2)}>Social Media Insights{" "}</a>
          </li>
          <li className={step === 3 ? 'active' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(3)}> Brand Collabration{" "}</a>
          </li>         
        </ul>
      </div>
      <div className="col-md-9 col-sm-8">
        <div className="tab-content shadow_bg">
          {
            step === 1 ? <InfliuencerPersonalInfo {...props} /> :
              step === 2 ? <InfliuencerSocialMedia {...props} /> :
                <InfliuencerBrandCollab {...props} /> 
          }
        </div>
      </div>
    </>
  );
};

export default InfliuencersFormTabs;
