import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaAngleLeft, FaAngleRight, FaAngleDown, FaAngleUp, FaFileInvoice, FaRegCreditCard, } from 'react-icons/fa';
import _ from 'lodash';
import moment from 'moment';
// import RazorpayButton from '../Razorpay/Razorpay';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import ArtistInvoiceContainer from '../../Container/ArtistInvoice/ArtistInvoiceContainer';
import AggregatorInvoiceToMMContainer from '../../Container/AggregatorInvoiceToMM/AggregatorInvoiceToMMContainer';
import AggregatorInvoiceToArtistContainer from '../../Container/AggregatorInvoiceToArtist/AggregatorInvoiceToArtistContainer';
import { AmountBreackdown } from '../HireTalent/AmountBreakdown';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import Pagination from '../Pagination/Pagination';

const HiredContractsList = ({ data,onChangeFilter, onChangeSearch, updateStatus, updateMilestoneValues, getContracts, currentUser, setToggleFilter, completedList, ...rest }) => {
  let pageSize = 10;
  const [activeChildId, setActiveChildId] = useState('');
  const [activeChildIdComp, setActiveChildIdComp] = useState('');
  const [tnxId, setTnxId] = useState('tnx2022-327432665X');
  const [pageIndex, setPageIndex] = useState(1);
  const [searchKey, setSearchKey] = useState('')
  const [Listdata, setListdata] = useState([])
  const searchResult = useSearchFilter(data || [], searchKey.trim());

  useEffect(() => {
    // if (searchResult && searchResult.length) {
    const slicedList = searchKey && searchKey.length ? searchResult.slice(0, pageSize) : searchResult.slice((pageIndex - 1) * pageSize, (pageIndex * pageSize))
    setListdata(slicedList)
    // }
  }, [searchResult])

  useEffect(() => {
    if (data) {
      const slicedList = data.slice(0, pageSize)
      setListdata(slicedList)
    }
  }, [data])

  const pageChangeHandler = (index) => {
    console.log(index)
    const slicedList = data.slice((index - 1) * pageSize, (index * pageSize))
    setListdata(slicedList)
    setPageIndex(index)

  }

  return (
    <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 search_job_list" onClick={() => { setToggleFilter(true) }}>
      <div className="inside_listing_tab">
        {/* {localStorage.getItem('user') &&  <Link style={{position:'absolute', right: 20, top:10}} className='blue_btn' to="/wallet"> My Wallet</Link>} */}
        <ul className="nav nav-tabs">
          <li className="active">
            <Link to="#list_tab1" data-toggle="tab">
              Search
            </Link>
          </li>
          <li>
            <Link to="#list_tab2" data-toggle="tab">
              Completed Projects<span>({completedList?.length || 0})</span>{" "}
            </Link>
          </li>

        </ul>

        <div className="tab-content">
          <div className="tab-pane active" id="list_tab1">
          <div className='flex_bx'>
              <div className="form-group">
                <input
                  type="search"
                  placeholder="Search By Name, Job Category or Payment mode"
                  className="form-control"
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
              <div className="filter_points">
                <div id="group-2" className="list-group collapse in">
                  <p className="list-group-item full_field"><input onChange={e => onChangeFilter({ type: 'location', value: e.target.value })} type="search" placeholder="Search client location" className="form-control" /> </p>
                </div>
              </div>
              <button type="button" className="blue_btn">
                <FaSearch /> Search
              </button>
            </div>

            <div className='row'>
              {_.map(Listdata, (val, i) => (
                <>
                <div className='col-md-4 custom_space'>
                    <div className="outline_bx">
                  <li key={i + "mile"} style={{ position: "relative" }}>
                    <div className="audition_date">
                      <span>{moment(val.updatedAt).format("MMM")}</span>
                      <h5>{moment(val.updatedAt).format("D")}</h5>
                      <small>{moment(val.updatedAt).format("ddd")}</small>
                    </div>
                    <div className="breakdown_casting_info audition_info">
                      <div className="casting_list_head clearfix">
                        {/* <h3>{val.title}</h3> */}
                        <div className="row">
                          <div className="col-md-6">
                            <div>
                              <strong> Hiring Company : </strong>{" "}
                              {val.hiringTeam}
                            </div>
                            <div>
                              <strong>Job Category : </strong> {val.jobCategory}
                            </div>
                            {/* {val?.depositeType === 'whole' && <div style={{ marginTop: 10, width:120 }}>
                          <ArtistInvoiceContainer transactionId={"tnx2022-327432665"} />
                          </div>} */}
                          </div>
                          <div className="col-md-5">
                            <div>
                              <strong>Payment Mode : </strong>
                              {val.paymentOption}
                            </div>
                            <div style={{ textTransform: "capitalize" }}>
                              <strong>Deposit Type : </strong>{" "}
                              {val.depositeType}
                            </div>


                          </div>

                        </div>
                        {/* <p>
                          <span>
                            <strong> Hiring Company : </strong> {val.hiringTeam}
                          </span>
                          <span>
                            <strong>Job Category : </strong> {val.jobCategory}
                          </span>
                          <span>
                            <strong>Payment Mode : </strong>
                            {val.paymentOption}
                          </span>
                          <span style={{textTransform:'capitalize'}}>
                            <strong>Deposite Type : </strong> {val.depositeType}
                          </span>
                        </p> */}
                      </div>
                      <p className="brief_intro">{val.description}</p>
                      {/* <div className="searchjob_point searchjob_bx">
                        <div className="application_info">
                          <p>
                            Application: <strong>{val.applications.length}</strong>
                          </p>
                          <Link to={`/UserSideAudition/${val._id}`}>Apply</Link>
                        </div>
                      </div> */}
                    </div>

                    {/* {val.depositeType ==='milestoneWise' && */}
                    <button
                      className="milestone-toggler"
                      onClick={() => {
                        setActiveChildId(
                          val._id === activeChildId ? "" : val._id
                        );
                      }}>
                      {activeChildId === val._id ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </button>
                    {/*} */}
                  </li>
                  </div>
                  </div>

                  {activeChildId === val._id && val.depositeType === 'milestoneWise' && (
                    <li style={{ display: "block" }}>
                      {_.map(val.milestone, (mData, indx) => (
                        <>
                          <div
                            key={indx}
                            className="row"
                            style={{
                              padding: "12px 0px"
                            }}>
                            <div className="col-md-9 flex_area">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Milestone Description"
                                  name="milestoneDescription"
                                  value={mData.milestoneDescription}
                                  disabled
                                />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Milestone Amount"
                                  name="milestoneAmount"
                                  value={mData.milestoneAmount}
                                  disabled={mData.status !== "In Progress" || mData.paymentTransactionId}
                                  onChange={(e) =>
                                    updateMilestoneValues(
                                      {
                                        name: "milestoneAmount",
                                        value: e.target.value,
                                        key: "milestone",
                                        index: indx,
                                      },
                                      i
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Due Date"
                                  name="dueDate"
                                  value={moment(mData.dueDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-3 flex_area side_btns">
                              <div>
                                <UpdateStatusModal
                                  id={val._id}
                                  milestoneData={mData}
                                  updateStatus={updateStatus}
                                  index={indx}
                                  updateMilestoneValues={updateMilestoneValues}
                                  parentIndex={i}
                                />
                              </div>
                              {!mData.paymentTransactionId && <div>
                                <AmountBreackdown {...rest} disablePaymentOption={!!mData.paymentTransactionId} firstMilestoneAmount={mData.milestoneAmount} jobCategory={val.jobCategory} depositeType={'milestoneWise'} paymentDescription={mData.milestoneDescription || ''} contractId={val._id}
                                  onPaymentDone={(res) => {
                                    // getContracts(currentUser._id);
                                    updateMilestoneValues(
                                      {
                                        name: "paymentTransactionId",
                                        value: res.transactionId,
                                        key: "milestone",
                                        index: indx,
                                      },
                                      i
                                    );
                                    setTimeout(() => {
                                      updateStatus(val._id, i);
                                    }, 500);
                                  }}
                                  user={currentUser} />
                              </div>}
                              {mData.paymentTransactionId && (
                                <div>
                                  <DropdownButton id="dropdown-item-button" title="Invoice" onClick={() => setTnxId(mData.paymentTransactionId)} style={{ width: '100%' }}>
                                    <Dropdown.Item as="button" style={{ background: 'none', border: 'none' }}>
                                      <ArtistInvoiceContainer
                                        transactionId={tnxId}
                                      />
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" style={{ background: 'none', border: 'none' }}>
                                      <AggregatorInvoiceToMMContainer
                                        transactionId={tnxId}
                                      />
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" style={{ background: 'none', border: 'none' }}>
                                      <AggregatorInvoiceToArtistContainer
                                        transactionId={tnxId}
                                      />
                                    </Dropdown.Item>
                                  </DropdownButton>

                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </li>
                  )}
                  {activeChildId === val._id && val?.depositeType === 'whole' && (
                    <li style={{ display: "block" }}>
                      {_.map(val.wholePayment, (mData, indx) => (
                        <>
                          <div
                            key={indx}
                            className="row"
                            style={{
                              width: "100%",
                              padding: "12px 0px"
                            }}>
                            <div className="col-md-9 flex_area">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Payment Description"
                                  name="paymentDescription"
                                  value={mData.paymentDescription}
                                  disabled
                                />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Amount"
                                  name="Amount"
                                  value={val.maxFixedPay}
                                  disabled
                                />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Due Date"
                                  name="dueDate"
                                  value={moment(mData.dueDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-3 flex_area side_btns">
                              <div>
                                <UpdateStatusModal
                                  id={val._id}
                                  milestoneData={mData}
                                  updateStatus={updateStatus}
                                  index={indx}
                                  updateMilestoneValues={(e) => updateMilestoneValues({
                                    name: "status",
                                    value: e.value,
                                    key: "wholePayment",
                                    index: indx,
                                  }, i)}
                                  parentIndex={i}
                                />
                              </div>

                              {mData.paymentTransactionId && (
                                <div>
                                  <DropdownButton id="dropdown-item-button" title="Invoice" onClick={() => setTnxId(mData.paymentTransactionId)} style={{ width: '100%' }}>
                                    <Dropdown.Item as="button" style={{ background: 'none', border: 'none' }}>
                                      <ArtistInvoiceContainer
                                        transactionId={tnxId}
                                      />
                                    </Dropdown.Item>
                                    {currentUser?._id === mData.payer && <Dropdown.Item as="button" style={{ background: 'none', border: 'none' }}>
                                      <AggregatorInvoiceToMMContainer
                                        transactionId={tnxId}
                                      />
                                    </Dropdown.Item>}
                                    {currentUser?._id === mData.payee && <Dropdown.Item as="button" style={{ background: 'none', border: 'none' }}>
                                      <AggregatorInvoiceToArtistContainer
                                        transactionId={tnxId}
                                      />
                                    </Dropdown.Item>}
                                  </DropdownButton>

                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </li>
                  )}
                </>
              ))}
            </div>
            <Pagination totalCount={data?.length || 0} currentPage={searchKey && searchKey.length > 0 ? 1 : pageIndex} onPageChange={(p) => { pageChangeHandler(p) }} pageSize={pageSize} key={'P1' + Date.now.toString()} />
          </div>

          <div className="tab-pane" id="list_tab2">
            <ul>
              {_.map(completedList, (val, i) => (
                <>
                  <li key={i + "mile"} style={{ position: "relative" }}>
                    <div className="audition_date">
                      <span>{moment(val.updatedAt).format("MMM")}</span>
                      <h5>{moment(val.updatedAt).format("D")}</h5>
                      <small>{moment(val.updatedAt).format("ddd")}</small>
                    </div>
                    <div className="breakdown_casting_info audition_info">
                      <div className="casting_list_head clearfix">
                        {/* <h3>{val.title}</h3> */}
                        <div className="row">
                          <div className="col-md-6">
                            <div>
                              <strong> Hiring Company : </strong>{" "}
                              {val.hiringTeam}
                            </div>
                            <div>
                              <strong>Job Category : </strong> {val.jobCategory}
                            </div>
                            {/* {val?.depositeType === 'whole' && <div style={{ marginTop: 10, width:120 }}>
                      <ArtistInvoiceContainer transactionId={"tnx2022-327432665"} />
                      </div>} */}
                          </div>
                          <div className="col-md-5">
                            <div>
                              <strong>Payment Mode : </strong>
                              {val.paymentOption}
                            </div>
                            <div style={{ textTransform: "capitalize" }}>
                              <strong>Deposit Type : </strong>{" "}
                              {val.depositeType}
                            </div>


                          </div>

                        </div>

                      </div>
                      <p className="brief_intro">{val.description}</p>

                    </div>

                    {/* {val.depositeType ==='milestoneWise' && */}
                    <button
                      className="milestone-toggler"
                      onClick={() => {
                        setActiveChildIdComp(
                          val._id === activeChildIdComp ? "" : val._id
                        );
                      }}>
                      {activeChildIdComp === val._id ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </button>
                    {/*} */}
                  </li>
                  {activeChildIdComp === val._id && val.depositeType === 'milestoneWise' && (
                    <li style={{ display: "block" }}>
                      {_.map((val.milestone).filter(ms => ms.status === 'Completed'), (mData, indx) => (
                        <>
                          <div
                            key={indx}
                            className="row"
                            style={{
                              padding: "12px 0px"
                            }}>
                            <div className="col-md-9 flex_area">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Milestone Description"
                                  name="milestoneDescription"
                                  value={mData.milestoneDescription}
                                  disabled
                                />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Milestone Amount"
                                  name="milestoneAmount"
                                  value={mData.milestoneAmount}
                                  disabled={mData.status !== "In Progress" || mData.paymentTransactionId}
                                  onChange={(e) =>
                                    updateMilestoneValues(
                                      {
                                        name: "milestoneAmount",
                                        value: e.target.value,
                                        key: "milestone",
                                        index: indx,
                                      },
                                      i
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Due Date"
                                  name="dueDate"
                                  value={moment(mData.dueDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                  disabled
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-3 flex_area side_btns">
                              <div>
                                <UpdateStatusModal
                                  id={val._id}
                                  milestoneData={mData}
                                  updateStatus={updateStatus}
                                  index={indx}
                                  updateMilestoneValues={updateMilestoneValues}
                                  parentIndex={i}
                                />
                              </div>
                              {!mData.paymentTransactionId && <div>

                              </div>}
                              {/* {mData.paymentTransactionId  && (
                            <div>
                            <DropdownButton id="dropdown-item-button" title="Invoice" onClick={()=>setTnxId(mData.paymentTransactionId)} style={{width:'100%'}}>
                              <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                              <ArtistInvoiceContainer
                                transactionId={tnxId}
                              />
                              </Dropdown.Item>
                              <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                              <AggregatorInvoiceToMMContainer
                                transactionId={tnxId}
                              />
                               </Dropdown.Item>
                              <Dropdown.Item as="button" style={{background:'none',border:'none'}}>
                              <AggregatorInvoiceToArtistContainer
                                transactionId={tnxId}
                              />
                              </Dropdown.Item>
                            </DropdownButton>
                             
                            </div>
                          )} */}
                            </div>
                          </div>
                        </>
                      ))}
                    </li>
                  )}
                </>
              ))}
            </ul>
            <div className="pagination_part clearfix text-right">
              <br />
              {/* <ul className="pagination">
                <li>
                  <Link to="#">
                    <FaAngleLeft /> Prev
                  </Link>
                </li>
                <li><Link to="#">1</Link></li>
            <li><Link to="#">2</Link></li>
            <li><Link to="#">3</Link></li>
            <li><Link to="#">4</Link></li>
            <li><Link to="#">5</Link></li>
                <li>
                  <Link to="#">
                    {" "}
                    Next <FaAngleRight />
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const UpdateStatusModal = ({ id, milestoneData, updateStatus, index, updateMilestoneValues, parentIndex }) => {
  const [show, setShow] = useState(false);
  const [isCompletedSelected, setIsCompletedSelected] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button
        onClick={handleShow}
        disabled={milestoneData.status === "Completed"}
        className={`milestone-status ${milestoneData.status === "Started"
            ? "red"
            : milestoneData.status === "In Progress"
              ? "yellow"
              : "green"
          }`}>
        {milestoneData.status || "Started"}{" "}
      </button>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="skills_part">
            <label>
              <strong>Message</strong>{" "}
            </label>
            <p>Update milestone progress status. </p>
            <br />
            <FormControl fullWidth variant={"outlined"} size="medium">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={milestoneData.status}
                label="Status"
                onChange={(e) => {
                  if (e.target.value === "Completed") {
                    setIsCompletedSelected(true);
                  }
                  updateMilestoneValues(
                    {
                      name: "status",
                      value: e.target.value,
                      key: "milestone",
                      index: index,
                    },
                    parentIndex
                  );

                }}>
                <MenuItem value={"Started"}>Started</MenuItem>
                <MenuItem value={"In Progress"}>In Progress</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="blue_btn"
            data-dismiss="modal"
            onClick={() => {
              // if(isCompletedSelected){

              // }else{
              updateStatus(id, parentIndex, isCompletedSelected);
              // }
              handleClose();
            }}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
      {/* <div className="modal fade" id="review" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
            <h4 className="modal-title">Update Status</h4>
          </div>
          <div className="modal-body terms_bx">
            <div className="skills_part">
              <label>
                <strong>Message</strong>{" "}
              </label>
              <p>Update milestone progress status. </p>
              <br />
              <FormControl fullWidth variant={'outlined'} size='medium'>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={milestoneData.status}
                  label="Status"
                  onChange={(e) =>
                    updateMilestoneValues({
                      name: "status",
                      value: e.target.value,
                      key: "milestone",
                      index: index,
                    },parentIndex)
                  }>
                  <MenuItem value={'Started'}>Started</MenuItem>
                  <MenuItem value={'In Progress'}>In Progress</MenuItem>
                  <MenuItem value={'Completed'}>Completed</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="modal-footer application_btn">
            <button
              type="submit"
              className="blue_btn"
              data-dismiss="modal"
              onClick={()=>updateStatus(id,parentIndex)}>
              Update
            </button>
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
};

export default HiredContractsList;
