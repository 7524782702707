import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class SportsFitnessInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>SPORTS INFLUENCER MARKETING</h1>
                  <p align="justify">The market for fitness, sports, bags and luggage products are very vast and it keeps on getting upgraded on a continuous basis. Hence capturing the market and making your place is very hard but with BollywoodisGood influencer marketing you can easily spread the brand awareness and gain the customers. A survey has proven that for sports products, fitness gadgets, and travel bags the influencer marketing is very effective.
CONTACT US</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/fitness-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/sports-influencer-marketing.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Fitness Influencer Marketing</h2>
          </div>
          <p align="justify">As with the increasing social media presence in the life of the people and liking the work of influencers plus following what they do and what they buy has made it easy for the brands/business who are dealing in this range of products to promote their business. Searching the right influencer is the most difficult task because you don’t know which one will be beneficial for your brand/business; hence BollywoodisGood can help you with that.</p>
          <br />
          <p align="justify"> As the leading <strong>influencer marketing agency</strong> the team ensures that every brand/business that is linked with us become a big brand and for this our team conduct a deep research about the aim of the business, what sort of audience are you targeting, what products or services are you offering and then create a successful influencer marketing campaign. Every product of this range needs to be displayed properly and what is the purpose of the product requires to be showcased by the influencer. Therefore, finding the influencer, coordinating with the influencer and collaboration, every step will be covered by the BollywoodisGood team. </p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote>
          <p align="justify">Our experienced team of experts will help you and ensure that the results are positive. You will be getting a report that will display how successful was the campaign and what positive response your brand and business has received from the influencer. This marketing tool is said to be the most effectual one and gives instant results, hence it will increase your ROI and sales. 

          </p>
          </blockquote>
          </div>
          </div>
          </div>
        </section>

           
        
           
        <Footer />
      </>
    );
  }
}

export default SportsFitnessInfluencer;
