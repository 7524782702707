import _ from 'lodash';
export const language = [
    "English",
    "Afrikaans (Namibia)",
    "Afrikaans (South Africa)",
    "Afrikaans",
    "Akan (Ghana)",
    "Akan",
    "Albanian (Albania)",
    "Albanian",
    "Amharic (Ethiopia)",
    "Amharic",
    "Arabic (Algeria)",
    "Arabic (Bahrain)",
    "Arabic (Egypt)",
    "Arabic (Iraq)",
    "Arabic (Jordan)",
    "Arabic (Kuwait)",
    "Arabic (Lebanon)",
    "Arabic (Libya)",
    "Arabic (Morocco)",
    "Arabic (Oman)",
    "Arabic (Qatar)",
    "Arabic (Saudi Arabia)",
    "Arabic (Sudan)",
    "Arabic (Syria)",
    "Arabic (Tunisia)",
    "Arabic (United Arab Emirates)",
    "Arabic (Yemen)",
    "Arabic",
    "Armenian (Armenia)",
    "Armenian",
    "Assamese (India)",
    "Assamese",
    "Asu (Tanzania)",
    "Asu",
    "Azerbaijani (Cyrillic)",
    "Azerbaijani (Cyrillic, Azerbaijan)",
    "Azerbaijani (Latin)",
    "Azerbaijani (Latin, Azerbaijan)",
    "Azerbaijani",
    "Bambara (Mali)",
    "Bambara",
    "Basque (Spain)",
    "Basque",
    "Belarusian (Belarus)",
    "Belarusian",
    "Bemba (Zambia)",
    "Bemba",
    "Bena (Tanzania)",
    "Bena",
    "Bengali (Bangladesh)",
    "Bengali (India)",
    "Bengali",
    "Bosnian (Bosnia and Herzegovina)",
    "Bosnian",
    "Bulgarian (Bulgaria)",
    "Bulgarian",
    "Burmese (Myanmar [Burma])",
    "Burmese",
    "Cantonese (Traditional, Hong Kong SAR China)",
    "Catalan (Spain)",
    "Catalan",
    "Central Morocco Tamazight (Latin)",
    "Central Morocco Tamazight (Latin, Morocco)",
    "Central Morocco Tamazight",
    "Cherokee (United States)",
    "Cherokee",
    "Chiga (Uganda)",
    "Chiga",
    "Chinese (Simplified Han)",
    "Chinese (Simplified Han, China)",
    "Chinese (Simplified Han, Hong Kong SAR China)",
    "Chinese (Simplified Han, Macau SAR China)",
    "Chinese (Simplified Han, Singapore)",
    "Chinese (Traditional Han)",
    "Chinese (Traditional Han, Hong Kong SAR China)",
    "Chinese (Traditional Han, Macau SAR China)",
    "Chinese (Traditional Han, Taiwan)",
    "Chinese",
    "Cornish (United Kingdom)",
    "Cornish",
    "Croatian (Croatia)",
    "Croatian",
    "Czech (Czech Republic)",
    "Czech",
    "Danish (Denmark)",
    "Danish",
    "Dutch (Belgium)",
    "Dutch (Netherlands)",
    "Dutch",
    "Embu (Kenya)",
    "Embu",
    "English (American Samoa)",
    "English (Australia)",
    "English (Belgium)",
    "English (Belize)",
    "English (Botswana)",
    "English (Canada)",
    "English (Guam)",
    "English (Hong Kong SAR China)",
    "English (India)",
    "English (Ireland)",
    "English (Israel)",
    "English (Jamaica)",
    "English (Malta)",
    "English (Marshall Islands)",
    "English (Mauritius)",
    "English (Namibia)",
    "English (New Zealand)",
    "English (Northern Mariana Islands)",
    "English (Pakistan)",
    "English (Philippines)",
    "English (Singapore)",
    "English (South Africa)",
    "English (Trinidad and Tobago)",
    "English (U.S. Minor Outlying Islands)",
    "English (U.S. Virgin Islands)",
    "English (United Kingdom)",
    "English (United States)",
    "English (Zimbabwe)",
    "Esperanto",
    "Estonian (Estonia)",
    "Estonian",
    "Ewe (Ghana)",
    "Ewe (Togo)",
    "Ewe",
    "Faroese (Faroe Islands)",
    "Faroese",
    "Filipino (Philippines)",
    "Filipino",
    "Finnish (Finland)",
    "Finnish",
    "French (Belgium)",
    "French (Benin)",
    "French (Burkina Faso)",
    "French (Burundi)",
    "French (Cameroon)",
    "French (Canada)",
    "French (Central African Republic)",
    "French (Chad)",
    "French (Comoros)",
    "French (Congo - Brazzaville)",
    "French (Congo - Kinshasa)",
    "French (Côte d’Ivoire)",
    "French (Djibouti)",
    "French (Equatorial Guinea)",
    "French (France)",
    "French (Gabon)",
    "French (Guadeloupe)",
    "French (Guinea)",
    "French (Luxembourg)",
    "French (Madagascar)",
    "French (Mali)",
    "French (Martinique)",
    "French (Monaco)",
    "French (Niger)",
    "French (Rwanda)",
    "French (Réunion)",
    "French (Saint Barthélemy)",
    "French (Saint Martin)",
    "French (Senegal)",
    "French (Switzerland)",
    "French (Togo)",
    "French",
    "Fulah (Senegal)",
    "Fulah",
    "Galician (Spain)",
    "Galician",
    "Ganda (Uganda)",
    "Ganda",
    "Georgian (Georgia)",
    "Georgian",
    "German (Austria)",
    "German (Belgium)",
    "German (Germany)",
    "German (Liechtenstein)",
    "German (Luxembourg)",
    "German (Switzerland)",
    "German",
    "Greek (Cyprus)",
    "Greek (Greece)",
    "Greek",
    "Gujarati (India)",
    "Gujarati",
    "Gusii (Kenya)",
    "Gusii",
    "Hausa (Latin)",
    "Hausa (Latin, Ghana)",
    "Hausa (Latin, Niger)",
    "Hausa (Latin, Nigeria)",
    "Hausa",
    "Hawaiian (United States)",
    "Hawaiian",
    "Hebrew (Israel)",
    "Hebrew",
    "Hindi (India)",
    "Hindi",
    "Hungarian (Hungary)",
    "Hungarian",
    "Icelandic (Iceland)",
    "Icelandic",
    "Igbo (Nigeria)",
    "Igbo",
    "Indonesian (Indonesia)",
    "Indonesian",
    "Irish (Ireland)",
    "Irish",
    "Italian (Italy)",
    "Italian (Switzerland)",
    "Italian",
    "Japanese (Japan)",
    "Japanese",
    "Kabuverdianu (Cape Verde)",
    "Kabuverdianu",
    "Kabyle (Algeria)",
    "Kabyle",
    "Kalaallisut (Greenland)",
    "Kalaallisut",
    "Kalenjin (Kenya)",
    "Kalenjin",
    "Kamba (Kenya)",
    "Kamba",
    "Kannada (India)",
    "Kannada",
    "Kazakh (Cyrillic)",
    "Kazakh (Cyrillic, Kazakhstan)",
    "Kazakh",
    "Khmer (Cambodia)",
    "Khmer",
    "Kikuyu (Kenya)",
    "Kikuyu",
    "Kinyarwanda (Rwanda)",
    "Kinyarwanda",
    "Konkani (India)",
    "Konkani",
    "Korean (South Korea)",
    "Korean",
    "Koyra Chiini (Mali)",
    "Koyra Chiini",
    "Koyraboro Senni (Mali)",
    "Koyraboro Senni",
    "Langi (Tanzania)",
    "Langi",
    "Latvian (Latvia)",
    "Latvian",
    "Lithuanian (Lithuania)",
    "Lithuanian",
    "Luo (Kenya)",
    "Luo",
    "Luyia (Kenya)",
    "Luyia",
    "Macedonian (Macedonia)",
    "Macedonian",
    "Machame (Tanzania)",
    "Machame",
    "Makonde (Tanzania)",
    "Makonde",
    "Malagasy (Madagascar)",
    "Malagasy",
    "Malay (Brunei)",
    "Malay (Malaysia)",
    "Malay",
    "Malayalam (India)",
    "Malayalam",
    "Maltese (Malta)",
    "Maltese",
    "Manx (United Kingdom)",
    "Manx",
    "Marathi (India)",
    "Marathi",
    "Masai (Kenya)",
    "Masai (Tanzania)",
    "Masai",
    "Meru (Kenya)",
    "Meru",
    "Morisyen (Mauritius)",
    "Morisyen",
    "Nama (Namibia)",
    "Nama",
    "Nepali (India)",
    "Nepali (Nepal)",
    "Nepali",
    "North Ndebele (Zimbabwe)",
    "North Ndebele",
    "Norwegian Bokmål (Norway)",
    "Norwegian Bokmål",
    "Norwegian Nynorsk (Norway)",
    "Norwegian Nynorsk",
    "Nyankole (Uganda)",
    "Nyankole",
    "Oriya (India)",
    "Oriya",
    "Oromo (Ethiopia)",
    "Oromo (Kenya)",
    "Oromo",
    "Pashto (Afghanistan)",
    "Pashto",
    "Persian (Afghanistan)",
    "Persian (Iran)",
    "Persian",
    "Polish (Poland)",
    "Polish",
    "Portuguese (Brazil)",
    "Portuguese (Guinea-Bissau)",
    "Portuguese (Mozambique)",
    "Portuguese (Portugal)",
    "Portuguese",
    "Punjabi (Arabic)",
    "Punjabi (Arabic, Pakistan)",
    "Punjabi (Gurmukhi)",
    "Punjabi (Gurmukhi, India)",
    "Punjabi",
    "Romanian (Moldova)",
    "Romanian (Romania)",
    "Romanian",
    "Romansh (Switzerland)",
    "Romansh",
    "Rombo (Tanzania)",
    "Rombo",
    "Russian (Moldova)",
    "Russian (Russia)",
    "Russian (Ukraine)",
    "Russian",
    "Rwa (Tanzania)",
    "Rwa",
    "Samburu (Kenya)",
    "Samburu",
    "Sango (Central African Republic)",
    "Sango",
    "Sena (Mozambique)",
    "Sena",
    "Serbian (Cyrillic)",
    "Serbian (Cyrillic, Bosnia and Herzegovina)",
    "Serbian (Cyrillic, Montenegro)",
    "Serbian (Cyrillic, Serbia)",
    "Serbian (Latin)",
    "Serbian (Latin, Bosnia and Herzegovina)",
    "Serbian (Latin, Montenegro)",
    "Serbian (Latin, Serbia)",
    "Serbian",
    "Shona (Zimbabwe)",
    "Shona",
    "Sichuan Yi (China)",
    "Sichuan Yi",
    "Sinhala (Sri Lanka)",
    "Sinhala",
    "Slovak (Slovakia)",
    "Slovak",
    "Slovenian (Slovenia)",
    "Slovenian",
    "Soga (Uganda)",
    "Soga",
    "Somali (Djibouti)",
    "Somali (Ethiopia)",
    "Somali (Kenya)",
    "Somali (Somalia)",
    "Somali",
    "Spanish (Argentina)",
    "Spanish (Bolivia)",
    "Spanish (Chile)",
    "Spanish (Colombia)",
    "Spanish (Costa Rica)",
    "Spanish (Dominican Republic)",
    "Spanish (Ecuador)",
    "Spanish (El Salvador)",
    "Spanish (Equatorial Guinea)",
    "Spanish (Guatemala)",
    "Spanish (Honduras)",
    "Spanish (Latin America)",
    "Spanish (Mexico)",
    "Spanish (Nicaragua)",
    "Spanish (Panama)",
    "Spanish (Paraguay)",
    "Spanish (Peru)",
    "Spanish (Puerto Rico)",
    "Spanish (Spain)",
    "Spanish (United States)",
    "Spanish (Uruguay)",
    "Spanish (Venezuela)",
    "Spanish",
    "Swahili (Kenya)",
    "Swahili (Tanzania)",
    "Swahili",
    "Swedish (Finland)",
    "Swedish (Sweden)",
    "Swedish",
    "Swiss German (Switzerland)",
    "Swiss German",
    "Tachelhit (Latin)",
    "Tachelhit (Latin, Morocco)",
    "Tachelhit (Tifinagh)",
    "Tachelhit (Tifinagh, Morocco)",
    "Tachelhit",
    "Taita (Kenya)",
    "Taita",
    "Tamil (India)",
    "Tamil (Sri Lanka)",
    "Tamil",
    "Telugu (India)",
    "Telugu",
    "Teso (Kenya)",
    "Teso (Uganda)",
    "Teso",
    "Thai (Thailand)",
    "Thai",
    "Tibetan (China)",
    "Tibetan (India)",
    "Tibetan",
    "Tigrinya (Eritrea)",
    "Tigrinya (Ethiopia)",
    "Tigrinya",
    "Tonga (Tonga)",
    "Tonga",
    "Turkish (Turkey)",
    "Turkish",
    "Ukrainian (Ukraine)",
    "Ukrainian",
    "Urdu (India)",
    "Urdu (Pakistan)",
    "Urdu",
    "Uzbek (Arabic)",
    "Uzbek (Arabic, Afghanistan)",
    "Uzbek (Cyrillic)",
    "Uzbek (Cyrillic, Uzbekistan)",
    "Uzbek (Latin)",
    "Uzbek (Latin, Uzbekistan)",
    "Uzbek",
    "Vietnamese (Vietnam)",
    "Vietnamese",
    "Vunjo (Tanzania)",
    "Vunjo",
    "Welsh (United Kingdom)",
    "Welsh",
    "Yoruba (Nigeria)",
    "Yoruba",
    "Zulu (South Africa)",
    "Zulu"
]

const defaultOption = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Marathi", value: "Marathi" },
    { label: "Bengali", value: "Bengali" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Urdu", value: "Urdu" },
    { label: "German", value: "German" },
    { label: "Spanish", value: "Spanish" },
    { label: "Kannada", value: "Kannada" },
    { label: "Tamil", value: "Tamil" }
]

const loadOptionsResolveLanguage = (e, Callback) => {
    if (e.length > 1 && e) {
        Callback(_.compact(_.map(language, val => {
            if ((val).toLowerCase().includes((e.toLowerCase().trim()))) {
                return { label: val, value: val }
            }
            else {
                return null;
            }
        }))
        )
    }
}
export { loadOptionsResolveLanguage, defaultOption };
