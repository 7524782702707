
export const config = {
    BASE_URL:"https://api.bollywoodisgood.com",
    AUTH_TOKEN:"",
    REFRESH_TOKEN:"",
    CHAT_URL:"https://api.bollywoodisgood.com",
    FILE_UPLOAD_URL:"https://api.bollywoodisgood.com",
    FILE_URL:"https://api.bollywoodisgood.com/uploads/",
    GOOGLE_MAPS_API_KEY:'AIzaSyC_ej9blQSbdm5LUBX11B5enOuvmoxYePM',
    GOOGLE_OAUTH_CLIENT_ID: '197189787580-r2c0g2p3f38l9vi8j0nulgi6fudtbu0i.apps.googleusercontent.com', // old 952654651197-bjgiue6v6c6kmurqaapb3haob9rqs1e2.apps.googleusercontent.com
    FACEBOOK_OAUTH_CLIENT_ID:'139521922360706'
}

// export const config = {
//  BASE_URL:"http://localhost:8080",
//     AUTH_TOKEN:"",
//     REFRESH_TOKEN:"",
//     CHAT_URL:"https://api.bollywoodisgood.com",
//     FILE_UPLOAD_URL:"http://localhost:8080",
//     FILE_URL:"http://localhost:8080/uploads/",
//     GOOGLE_MAPS_API_KEY:'AIzaSyC_ej9blQSbdm5LUBX11B5enOuvmoxYePM',
//     GOOGLE_OAUTH_CLIENT_ID: '197189787580-r2c0g2p3f38l9vi8j0nulgi6fudtbu0i.apps.googleusercontent.com', // old 952654651197-bjgiue6v6c6kmurqaapb3haob9rqs1e2.apps.googleusercontent.com
//     FACEBOOK_OAUTH_CLIENT_ID:'139521922360706'
// }