import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class InfluencerAd extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>INFLUENCER AD NETWORK</h1>
                  <p align="justify"> BollywoodisGood is one of the top <strong>influencer ad agencies in India</strong> whose goal is to assist brands to get to their target audiences via best social media talents making the world crazy out there. At BollywoodisGood, every partnership is premeditated to achieve utmost growth of the brand/business and engagement. </p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/influencer-ad-network.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/influencer-marketing-platform.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Influencer Marketing Platform</h2>
          </div>
          <p align="justify">The customized influencer ad network campaigns for every brand/business is joined with data determined strategies to assure high Return on Investment for your brand/business.</p>
          <br />
          <p align="justify">Until 2016, in India Influencer Marketing was not at all popular or people use to work for it or take it as a marketing tool, so why suddenly it captured all the popularity, fame and considered as the most effective marketing technique in 2020? Well, if you want to create a buzz in the market about your brand and need an immediate response regarding the same then Influencer Marketing is most effective way available on internet. It offers very much needed online growth and exposure to your brand/business and also enhances its reputation. 
          An <strong>influencer marketing platform</strong> becomes more of a requirement and not just a tool of marketing as major part of the online audience in India is the youth whose pattern for purchasing pattern is extremely influenced by the social media influencers and hence in India it has become a need.
          .</p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>What do We do at BollywoodisGood?</h2>
          </div>
         <p align="justify">We discover or search of Social Media Talent to promote your brand/business With the increasing social media users every day, there is a completely an abundance of the influencers that are shining bright and endorsing brands and making them popular in the market.</p>
          <br />           
          <p align="justify">At BollywoodisGood, the team chooses the best influencer that will promote and endorse your brand/business and will achieve all its marketing objectives.</p>
          <br />           
          <p align="justify">After evaluating a variety of metrics such as social media activity, earlier campaigns and experience and engagement rates with competitor brands, we finalize the influencers. This helps in offering enormous growth and the drive that a campaign requires from an influencer to flourish.</p>
       </div>
          <div class="col-sm-5">
          <img src="images/influencer-ad-campaing.webp"  loading="lazy" width="458" height="400" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">     
          <div class="col-sm-12">
          <div class="text-center inner_head">
          <h2>From the scratch we do Campaign Creation for you</h2>
          </div>
          <p align="justify">From the scratch we do Campaign Creation for you After a huge experience as an influencer ad agency in India, we certainly know how to generate a plan for your brand/business that will assist you to get to the correct audience via the correct people and most prominently with the correct message.</p>
          <br />
          <p align="justify">Our creative team has huge experience and hence assures that the campaign offers sturdy and impactful content which seems genuine and authentic. Our team spend a lot of time in the research process to figure out the best influencer marketing platform depending on the type industry you fall in.</p>
           </div>
          </div>        
          </section> 

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h4>Coordination & Collaboration</h4>
          </div>
         <p align="justify">A good influencer ad agency will always ensure every parts of the campaign is arranged and well coordinated. In creating and executing an effectual influencer marketing strategy a lot of processes are involved. There are not just one or two things to do but a lot of things to consider such as reporting, content making and editing it, coordination between brands and influencer, payments and other related things. To make brand and influencer collaboration a successful one, our team of professionals makes certain that every activities are well taken care of.</p>
          <br /> 
          <h4>From campaign results we measure success</h4>
          <br /> 
          <p align="justify">Our team surrenders to you some reports after the influencer marketing campaign gets over that will showcase what the influencer did for your brand and the best for the campaign in terms of engagement and conversion rate. In terms of conversions or leads or sales it will also provide you an idea regarding the best influencer marketing platform for your business/brand.</p>
       </div>
          <div class="col-sm-5">
          <img src="images/social-media-ad-network.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section> 

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote>
          <p align="justify">
          We handle every brand/business personally and responsibly choose the right influencers for your business/brand as per the goal of the campaign. Be it the finding the correct influencers or suggesting over the marketing strategy or even intensify the campaign to enhance the return on investment, we cover it all here for you. Our team makes certain that the content that is being used and represents the brand/business is of top-notch quality and also aims on setting up relationships with brands and influencers during the whole process. This is the reason why big companies and brands consider us as one of the most trusted company in the Indian Influencer Marketing scene.  <br /><br />
          We have successfully created many brands and campaigns and have also assist in the procedure. 
          </p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>
           
        
           
        <Footer />
      </>
    );
  }
}

export default InfluencerAd;
