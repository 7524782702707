import React from "react";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import {
  profession,
  physique,
  eyeColor,
  hairColor,
  hairLenght,
  voice,
  uniqueTraits,
} from "./registerConst";
import Select from "react-select";
import { vlidateNumberField } from "../../Utilities/validateInputField";

const PhysicalInfo = ({
  handleChangeDropDown,
  handleChangeNestedValue,
  physicalTraits,
  submitForm,
  handleChange,
  nextStep,
  prevStep,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="setup-content row" id="step-2">
        <div className="form-group col-md-6">
          <label className="control-label">Job Type</label>
          <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
            (You can add if not found)
          </small>
          <CreatableSelect
            options={profession}
            //isMulti
            value={{
              label: physicalTraits ?.jobType,
              value: physicalTraits ?.jobType,
            }}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "jobType",
                value: e.label,
                key: "physicalTraits",
              })
            }
          />
        </div>

        <div className="form-group col-md-12">
          <label className="control-label">
            {" "}
            Total Experience Synopsis Throught The Career
          </label>
          <textarea
            type="text"
            name="JobDescription"
            className="form-control"
            placeholder="Enter detail"
            value={physicalTraits ?.JobDescription}
            onChange={(e) =>
              handleChangeNestedValue({
                name: "JobDescription",
                value: e.target.value,
                key: "physicalTraits",
              })
            }
          />
          <ul className="notes">
            <p><strong>Example</strong></p>
            <li>Mention the job description, nature of job, expected task nees to be performed</li>
            <li>Responsibilites</li>
            <li>Skillset Must Have	</li>
          </ul>
        </div>

        {physicalTraits ?.jobType === "Artist or Actor or Talent" && (
          <>
            <div className="form-group col-md-6 double_field">
              <label className="control-label">Height</label>
              <div className="row">
                <div className="col-sm-6">
                  <input
                    type="text"
                    name="hightFt"
                    className="form-control"
                    placeholder="Ft"
                    value={physicalTraits ?.height ?.from ?.feet || ""}
                    onChange={(e) => {
                      vlidateNumberField(e);
                      handleChangeNestedValue({
                        name: "height",
                        twoOrder: "from",
                        thirdKey: "feet",
                        value: e.target.value,
                        key: "physicalTraits",
                      });
                    }}
                  />
                  <input
                    type="text"
                    name="hightIn"
                    className="form-control"
                    placeholder="In"
                    value={physicalTraits ?.height ?.from ?.inch || ""}
                    onChange={(e) => {
                      vlidateNumberField(e) &&
                        handleChangeNestedValue({
                          name: "height",
                          twoOrder: "from",
                          thirdKey: "inch",
                          value: e.target.value,
                          key: "physicalTraits",
                        });
                    }}
                  />
                </div>
                <label className="center_label">To</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    name="hightFt"
                    className="form-control"
                    placeholder="Ft"
                    value={physicalTraits ?.height ?.to ?.feet || ""}
                    onChange={(e) => {
                      vlidateNumberField(e) &&
                        handleChangeNestedValue({
                          name: "height",
                          twoOrder: "to",
                          thirdKey: "feet",
                          value: e.target.value,
                          key: "physicalTraits",
                        });
                    }}
                  />
                  <input
                    type="text"
                    name="hightIn"
                    className="form-control"
                    placeholder="In"
                    value={physicalTraits ?.height ?.to ?.inch || ""}
                    onChange={(e) => {
                      vlidateNumberField(e) &&
                        handleChangeNestedValue({
                          name: "height",
                          twoOrder: "to",
                          thirdKey: "inch",
                          value: e.target.value,
                          key: "physicalTraits",
                        });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group col-md-6">
              <label className="control-label">Weight</label>
              <div className="row">
                <div className="col-sm-6">
                  <input
                    type="text"
                    name="minWeight"
                    className="form-control"
                    placeholder="Min in KG"
                    value={physicalTraits ?.weight ?.min || ""}
                    onChange={(e) => {
                      vlidateNumberField(e) &&
                        handleChangeNestedValue({
                          name: "weight",
                          oneOrder: "min",
                          value: e.target.value,
                          key: "physicalTraits",
                        });
                    }}
                  />
                </div>

                <div className="col-sm-6">
                  <input
                    type="text"
                    name="maxWeight"
                    className="form-control"
                    placeholder="Max in KG"
                    value={physicalTraits ?.weight ?.max || ""}
                    onChange={(e) => {
                      vlidateNumberField(e) &&
                        handleChangeNestedValue({
                          name: "weight",
                          oneOrder: "max",
                          value: e.target.value,
                          key: "physicalTraits",
                        });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group col-md-6">
              <label className="control-label">Physique</label>
              <Select
                options={physique}
                value={{
                  label: physicalTraits ?.physique,
                  value: physicalTraits ?.physique,
                }}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "physique",
                    value: e.label,
                    key: "physicalTraits",
                  });
                }}
              />
              {/* <span style={{ color: "red" }}>
            {this.state.errors["highestQualification"]}
          </span> */}
            </div>

            <div className="form-group col-md-6">
              <label className="control-label">Eye Color</label>
              <Select
                options={eyeColor}
                value={{
                  label: physicalTraits ?.eyeColor,
                  value: physicalTraits ?.eyeColor,
                }}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "eyeColor",
                    value: e.label,
                    key: "physicalTraits",
                  });
                }}
              />
            </div>

            <div className="form-group col-md-6">
              <label className="control-label">Hair color</label>
              <Select
                options={hairColor}
                //isMulti
                value={{
                  label: physicalTraits ?.hairColor,
                  value: physicalTraits ?.hairColor,
                }}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "hairColor",
                    value: e.label,
                    key: "physicalTraits",
                  });
                }}
              />
            </div>

            <div className="form-group col-md-6">
              <label className="control-label">Hair Length</label>
              <Select
                options={hairLenght}
                value={{
                  label: physicalTraits ?.hairLength,
                  value: physicalTraits ?.hairLength,
                }}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "hairLength",
                    value: e.label,
                    key: "physicalTraits",
                  });
                }}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="control-label">Voice Type</label>
              <Select
                options={voice}
                value={{
                  label: physicalTraits ?.voiceType,
                  value: physicalTraits ?.voiceType,
                }}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "voiceType",
                    value: e.label,
                    key: "physicalTraits",
                  });
                }}
              />
            </div>
            <div className="form-group col-md-6">
              <label className="control-label">Unique Traits</label>
              <Select
                options={uniqueTraits}
                value={{
                  label: physicalTraits ?.uniqueTraits,
                  value: physicalTraits ?.uniqueTraits,
                }}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "uniqueTraits",
                    value: e.label,
                    key: "physicalTraits",
                  });
                }}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="control-label">Appearance or D/C </label>
              <input
                type="text"
                name="Appearance"
                className="form-control"
                placeholder="Ethnic Wear, Western Wear, Sports Wear, Salwar Suits, Kurtas, Sarees ...etc"
                value={physicalTraits ?.appearance}
                onChange={(e) => {
                  handleChangeNestedValue({
                    name: "appearance",
                    value: e.target.value,
                    key: "physicalTraits",
                  });
                }}
              />
            </div>

          </>
        )}

        <div className="col-md-12">
          <button
            className="btn btn-primary nextBtn btn-lg stroke_btn"
            onClick={prevStep}
          >
            {t("PrevBtn")}
          </button>
          <button
            className="btn btn-primary nextBtn btn-lg blue_btn  pull-right"
            onClick={nextStep}
          >
            {t("ContinueBtn")}
          </button>

          <button
            className="btn btn-primary nextBtn btn-lg blue_btn pull-right" style={{ background: "#1683f3", marginRight: "20px" }} onClick={submitForm}>
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhysicalInfo;
