import React, { Component } from "react";
import LoginPage from "../../pages/Login/LoginPage";
import swal from 'sweetalert';
import { loginUser } from "../../actions/index";

class LoginPageContainer extends Component {

 

isEmail = (input) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
  }
  state = { emailPhone:"", password: "" };
componentDidMount = ()=>{
  const accessToken =localStorage.getItem('accessToken')
  if (accessToken) {
    this.props.history.push('/home');
  }
}
  LoginSubmit = e => {
    console.log(e, 'sfdd')
    e.preventDefault();
    if(this.state.tnc){
            const loginData = {
        password: this.state.password,
        ...(this.isEmail(this.state.emailPhone) 
          ? { emailId: this.state.emailPhone }
          : { phoneNumber: this.state.emailPhone })
      };
    loginUser(loginData )
      .then(res => {
        if (res && res.data && res.data.data) {
          localStorage.setItem('accessToken', res.data.data.accessToken);
          localStorage.setItem('user', JSON.stringify(res.data.data.user));
          this.props.history.push('/home');
          window.location.reload();
        }

      })

      .catch(e => {
        if (e && e.response ) {
          swal(e.response.data.Error, { buttons: false, timer: 2000, icon: 'error' })
        }
      })
    }
  };

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }


  render() { return <LoginPage {...this.state} handleOnChange={this.handleOnChange} LoginSubmit={this.LoginSubmit} /> }
}


export default LoginPageContainer;















// import React, { Component } from "react";
// import LoginPage from "../../pages/Login/LoginPage";
// import swal from 'sweetalert';
// import { loginUser } from "../../actions/index";

// class LoginPageContainer extends Component {
//   state = { 
//     emailOrPhone: "", 
//     password: "",
//     tnc: false 
//   };

//   componentDidMount = () => {
//     const accessToken = localStorage.getItem('accessToken');
//     if (accessToken) {
//       this.props.history.push('/home');
//     }
//   }

//   LoginSubmit = e => {
//     e.preventDefault();
//     if (this.state.tnc) {
//       const loginData = {
//         password: this.state.password,
//         ...(this.isEmail(this.state.emailOrPhone) 
//           ? { emailId: this.state.emailOrPhone }
//           : { phoneNumber: this.state.emailOrPhone })
//       };

//       loginUser(loginData)
//         .then(res => {
//           if (res && res.data && res.data.data) {
//             localStorage.setItem('accessToken', res.data.data.accessToken);
//             localStorage.setItem('user', JSON.stringify(res.data.data.user));
//             this.props.history.push('/home');
//           }
//         })
//         .catch(e => {
//           if (e && e.response) {
//             swal(e.response.data.Error, { buttons: false, timer: 2000, icon: 'error' });
//           } else {
//             swal("An error occurred. Please try again.", { buttons: false, timer: 2000, icon: 'error' });
//           }
//         });
//     } else {
//       swal("Please accept the terms and conditions", { buttons: false, timer: 2000, icon: 'warning' });
//     }
//   };

//   handleOnChange = (event) => {
//     const { name, value, type, checked } = event.target;
//     const newValue = type === 'checkbox' ? checked : value;
//     this.setState({ [name]: newValue });
//   }

//   isEmail = (input) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(input);
//   }

//   render() {
//     return (
//       <LoginPage 
//         {...this.state} 
//         handleOnChange={this.handleOnChange} 
//         LoginSubmit={this.LoginSubmit} 
//       />
//     );
//   }
// }

// export default LoginPageContainer;