import React, { useEffect, useState } from 'react';
import { FaCopy, FaSort } from 'react-icons/fa';
import Header from '../../components/shared/header/Header';
import SubHeader from '../../components/shared/header/SubHeader';
import Footer from "../../components/shared/footer/Footer";
import { getReferalHistory } from '../../actions';
import { Table } from 'react-bootstrap';
import swal from 'sweetalert';

const Referal = () => {
    const [referalCode, setReferalCode] = useState('');
    const [referalLink, setReferalLink] = useState('');
    const [referalHistory, setReferalHistory] = useState([]); // State to store referral history

    useEffect(() => {
        fetchReferralHistory();

        const user = localStorage.getItem("user");
        if (user) {
            try {
                const userObj = JSON.parse(user);
                if (userObj.refCode) {
                    const code = userObj.refCode;
                    setReferalCode(code);
                    setReferalLink(`https://www.bollywoodisgood.com/#/NewSignup/Invite/${code}`);
                }
            } catch (error) {
                console.error("Error parsing user data:", error);
            }
        }
    }, []);

    const fetchReferralHistory = async () => {
        const user = localStorage.getItem("user");
        if (!user) {
            console.error("User not found in localStorage");
            return;
        }

        try {
            const userObj = JSON.parse(user);
            if (!userObj._id) {
                console.error("User ID not found in user object");
                return;
            }

            const response = await getReferalHistory({
                userId: userObj._id,
                page: 1,
                perPage: 10,
            });

            console.log("Referral History:", response);
            setReferalHistory(response.data.data || []); // Update the state with the fetched data
        } catch (error) {
            console.error("Error fetching referral history:", error);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                swal(`Copied to clipboard!`, { buttons: false, timer: 2000, icon: 'success' });

            })
            .catch((error) => {
                console.error("Failed to copy: ", error);
            });
    };

    return (
        <>
            <div className="top_slide inner_header">
                <Header />
                <SubHeader />

                <section id="user_payment" className="mid_info inner_banner">
                    <div className="container side_space">
                        <div className="row">
                            <div className="col-md-12 inner_banner_text text-center">
                                <h2>Get Free Subscription......!!  By Referring to your 5 friends, 
                                Request friends to click on below referral link and signup.</h2>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div id="payment_mode" className="default_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="payment_area">
                                <div className="form_outer">
                                    <div className="row">

                                        {/* Referral Link Field */}
                                        <div className="col-md-12 form-group">
                                            <label>Referral Link</label>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="..."
                                                    value={referalLink}
                                                    readOnly
                                                    style={{ flex: 1,border:"none" }}

                                                />
                                                <button
                                                    className="btn btn-success"
                                                    onClick={() => copyToClipboard(referalLink)}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <FaCopy />
                                                </button>
                                            </div>
                                        </div>

                                         {/* Referral Code Field */}
                                         <div className="col-md-12 form-group">
                                            <label>Referral Code</label>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <input
                                                    className="form-control"
                                                    placeholder="..."
                                                    value={referalCode}
                                                    readOnly
                                                    style={{ flex: 1,border:"none" }}
                                                />
                                                <button
                                                    className="btn btn-success"
                                                    onClick={() => copyToClipboard(referalCode)}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <FaCopy />
                                                </button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <h4>Referal History</h4>
                <div className='table-box'>
                <Table className='table-striped'>
                    <thead>
                        <tr>
                            <th>Referral Name <FaSort style={{ cursor: 'pointer' }} /></th>
                            <th>Referred Date <FaSort style={{ cursor: 'pointer' }} /></th>
                            <th>Referral Code <FaSort style={{ cursor: 'pointer' }} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {referalHistory.length > 0 ? (
                            referalHistory.map((history, index) => (
                                <tr key={index}>
                                    <td>{history.refferedUserName}</td>
                                    <td>{new Date(history.date).toLocaleDateString()}</td>
                                    <td>{history.refCode}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">No referral history available.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Referal;
