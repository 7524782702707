import { useEffect, useState } from "react";

export const useSearchFilter = (data, keyword)=>{

    const [result, setResult] = useState([]);
    useEffect(() => {
        if (data && keyword) {
            const filtered = data.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().startsWith(keyword.toLowerCase())));
            setResult(filtered);
        }
    }, [data,keyword])

    return keyword ? result : data;
}