import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaRegCreditCard } from "react-icons/fa";
import { config } from "../../actions/config";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";

const QRCodeModal = ({contractId,description,payeeId,paybleAmount , onPaymentDone, isDisabled, amountWithouTax,handleShowModal,showModal, handleClose, disablePaymentOption,milestoneIndex})=> {
  
    const [disableButton, setDisableButton] = useState(false);
    const [txId, setTxId] = useState('');
    const [upiInfo, setUpiInfo] = useState()

    useEffect(() => {
        try {
            axios.get(`${config.BASE_URL}/admin/get/upi`).then((res) => {
                if (res && res.data && res.data && res.data.data) {
                    setUpiInfo(res.data.data)
                }
            })
          } catch (ex) {
            console.log('upload error', ex);
          }
    }, [])

const makepayment = async ()=>{
    setDisableButton(true);
    const userString = localStorage.getItem('user')
    const user = userString? JSON.parse(userString):null

    // const tnxId = (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2);
    const data = {
        "payer": user._id,
        "payee": payeeId,
        "amount": paybleAmount,
        "transactionId": txId,
        "contractId":contractId,
        "status": true,
        "description": description,
        "amountWithouTax":amountWithouTax,
        "paymentMethod" : 'UPI',
        "veryfiedByBig" : false,
        "milestoneIndex": milestoneIndex,
        "veryficationStatus": 'Pending'
    };

    try {
        const result = await axios.post(config.BASE_URL+"/hire/makepayment", data).catch(e =>{});
        // onPaymentDone(result?.data?.data)
        swal("Successful", { buttons: false, timer: 2000, icon: "success" });
         setDisableButton(false);
         handleClose()
    } catch (error) {
          setDisableButton(false);
    }
}
  
    return (
        <>
        <button disabled={disablePaymentOption}
          onClick={handleShowModal}
          className={`milestone-status blue_btn mr-2`}>
          {"Pay using UPI"}
        </button>
        <Modal show={showModal} onHide={handleClose} animation={false} backdrop={false} className="upi_pay">
        <div style={{backgroundColor: '#ffff' }}>
        <Modal.Header closeButton style={{background:"#c62537"}}>
          <Modal.Title>UPI Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="skills_part" >
            <p> <strong>Note :</strong>  <small>Scan below QR or use UPI ID to make payment of <strong> Rs {paybleAmount}</strong> and submit transaction id. </small> </p><br />
            <label className="control-label"> BIG UPI ID : <small>{upiInfo?.upiId}</small> </label>
            <br />
            <img src={`${config.FILE_URL+upiInfo?.qrUrl}`} alt="" />
            <br />
            <div className="form-group">
            <label className="control-label">Transaction ID</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Transaction id here"
              value={txId}
              onChange={(e)=> setTxId(e.target.value)}
            />
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <button disabled={!txId || disableButton} src={process.env.PUBLIC_URL+'icon/razorpay.png'} height={50} className="razorpay-btn" alt="logo" style={{cursor:'pointer'}} onClick={makepayment} >
                    <FaRegCreditCard /> <span style={{marginLeft: 8}}> Finish</span>
                </button>
        </Modal.Footer>
        </div>
      </Modal>
        </>

    );
}

export default QRCodeModal;