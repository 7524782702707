import firebase from 'firebase/app';
import 'firebase/firestore';
// import { getFirestore } from "firebase/firestore";
var firebaseConfig = {
  // apiKey: "AIzaSyAjQZaBYqBnfID5YTQENJQpRAZdyxu1Ygc",
  // authDomain: "bigboll.firebaseapp.com",
  // projectId: "bigboll",
  // storageBucket: "bigboll.appspot.com",
  // messagingSenderId: "842976246575",
  // appId: "1:842976246575:web:b0a5551a9f7091f6babfda",
  // measurementId: "G-K0KXZK5770"
  apiKey: "AIzaSyBq92TwCrdQchLur24nm73KajBDMufpxz4",
  authDomain: "bigbollywod.firebaseapp.com",
  projectId: "bigbollywod",
  storageBucket: "bigbollywod.appspot.com",
  messagingSenderId: "573604067171",
  appId: "1:573604067171:web:c8df46e328dc4e0ab28556",
  measurementId: "G-V3Z52L5JMS"
};
  // Initialize Firebase
 let app =  firebase.initializeApp(firebaseConfig);
 const db =   firebase.firestore();
// const db = getFirestore(app)
 console.log(db , "dkedk");

  export default firebase;