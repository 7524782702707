import React, { Component } from 'react';
import AuditionListPage from '../../pages/AuditionDirectorSide/AuditionListPage';
import { getAuditionList, deleteAudition } from '../../actions/index';
import _ from 'lodash';
let timer = "";
class AuditionListContainer extends Component {
    state = { filters: [{ type: 'visibility', value: 'All' }, { type: 'type', value: 'All' }, { type: 'status', value: 'All' }], search: '' };

    onChangeSearch = search => {
        this.setState({ search });
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.getAuditionList(search);
        }, 300);
    }

    componentDidMount() {
        this.getAuditionList("");
    }

    getAuditionList = search => {
        getAuditionList({ filters: JSON.stringify(_.filter(this.state.filters, val => val.value != 'All')), search: search })
            .then(res => {
                this.setState({ data: res.data ?.data[0] ?.search });
            })
    }

    removePost = (id) => {
        deleteAudition(id)
            .then(res => this.getAuditionList(this.state.search));
    }

    onChangeFilter = e => {
        let { filters, search } = this.state;
        const alreadyPresent = _.find(filters, { ...e });
        if (_.isEmpty(alreadyPresent)) {
            this.setState({ filters: _.uniqBy([e, ...filters], 'type') }, () => this.getAuditionList(search));
        }
        else {
            this.setState({ filters: _.filter(filters, val => val.type != e.type) }, () => this.getAuditionList(search));
        }
    }


    render() {
        return <AuditionListPage
            {...this.state}
            onChangeFilter={this.onChangeFilter}
            onChangeSearch={this.onChangeSearch}
            removePost={this.removePost}
        />
    }
}

export default AuditionListContainer;