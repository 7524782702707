import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { status, workExp, workExpMax, } from "../CreateUserProfile/registerConst";
import Select from "react-select";
import _ from 'lodash';
// import { profession, filmType, filmIndustry, jobNature, roleOption, payout, gender, audition } from '../CreateUserProfile/registerConst';

const UserWalletFilter = ({ onChangeFilter, clearFilter,filters, ...rest }) => {
    return (
      <div
        id="filter-sidebar"
        className="col-xs-12" style={{marginBottom: "20px"}}>     
         <div className='row filter_flex'>         
         
          {/* <div className="filter_points">
            <h6
              data-toggle="collapse"
              data-target="#group-1"
              aria-expanded="true">
              <FaAngleRight />
              Status
            </h6>
            <div id="group-1" className="list-group collapse in">
              {_.map(status, (val) => (
                <p className="list-group-item">
                  <label className="check_bx">
                    <input
                      type="checkbox"
                      name="field"
                      checked={_.find(filters, {
                        type: "auditionType",
                        value: val.label,
                      })}
                      onChange={(e) =>
                        onChangeFilter({
                          type: "auditionType",
                          value: val.label,
                        })
                      }
                    />
                    {val.label}
                    <span className="checkmark" />
                  </label>
                </p>
              ))}
            </div>
          </div> */}
          <div className="filter_points col-md-4">
            <h6>
              Start Date
            </h6>
            <div id="group-2" className="list-group collapse in">
              <input
                className="form-control"
                type={"date"}
                placeholder="Select Start Date"
                value={_.find(filters, val => val.type === "fromDate")?.value || ''}
                onChange={(e) =>
                  onChangeFilter({ type: "fromDate", value: e.target.value })
                }
                
              />
            </div>
          </div>
          <div className="filter_points col-md-4">
            <h6>
              End Date
            </h6>
            <div id="group-3" className="list-group collapse in">
              <input
                className="form-control"
                type={"date"}
                placeholder="Select Start Date"
                value={_.find(filters, val => val.type === "toDate")?.value || ''}
                onChange={(e) =>
                  onChangeFilter({ type: "toDate", value: e.target.value })
                }
                min={_.find(filters, val => val.type === "fromDate")?.value || ''}
              />
            </div>
          </div>
          <div className='btn btn-info col-md-2' style={{padding: "11px 14px", margin: "0 5px 8px"}} onClick={()=>{clearFilter()}}>Clear Filters</div>
      
        </div>
      </div>
    );
}
export default UserWalletFilter;
