import React, { Component } from 'react';
import MakeFilmContactPage from "../../pages/MakeFilmContact/MakeFilmContactPage";
import { getAllFilms } from "../../actions/index";

class MakeFilmContactContainer extends Component {
    state = { AllFilm : [] }

    componentDidMount() {
        getAllFilms().then((res) => {
            this.setState({ AllFilm: res.data.data });
          });
    }

    render() {
        return <MakeFilmContactPage {...this.state} />
    }
}

export default MakeFilmContactContainer;