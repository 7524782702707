import React  from 'react';
import { Link } from 'react-router-dom';
const PostJobBanner = () => (
                <div className="inner_page_head">
                    <div className="row">
                    <div className="col-sm-12 heading header_btn">
        <h2 style={{color:"black"}}>Showcase Your Talent Opportunities!!</h2>    
        <div> 
        {/* <button className="blue_btn" onClick={()=> window.history.back()}>Back</button>
        <Link to="/SearchAudition" className="blue_btn">Audition List</Link> */}
        </div>  
            </div>                    
                    </div>
                </div>
        )
 
export default PostJobBanner;