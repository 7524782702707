import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class FilmTalent extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>Film Talent Management Agency</h1>
                  <p align="justify">BollywoodisGood is an incorporated Film Talent Management Agency and we represent actors, directors, models, singers, writers and music directors. We provide incorporated services in film talent management focusing on showcasing talent in films and complete career development. BollywoodisGood is one of the leading Film Talent Management Company in India with its operations.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/celebrity-talent-management-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/film-talent-management-services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Film Talent Management Services</h2>
          </div>
          <p align="justify">We are the representatives of some of the freshest and most talented artists currently spreading their magic in the industry. We are able to provide the best talent in the film industry. An incorporated approach to shaping opinions, image management for our stars and giving tailored solutions competently in a smart, transparent manner for partners determined out of the creative powerhouse BollywoodisGood. We feel proud that we have given such amazing talent to the film industry and feel very motivated towards our work. The BollywoodisGood follows the process of searching, grooming, introducing and constructing some of the biggest names in the business.</p>
          <br/>
          <p align="justify">With the team of expert professionals, we are able to conduct the process of finding and managing the film talent. Our managers conduct some activities to find out the best talent hidden in the crowd and give them the chance to be on the big screen. We have a huge clientele of successful directors, producers, and managers who help us to showcase the fresh talent in the film industry.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Celebrity Talent Management Services</h2>
          </div>
          <p align="justify">As a <strong>film talent management agency</strong>, we are very well competent to manage the talent that is associated with us and able to provide them the work that suits their personality and talent. BollywoodisGood not only has the freshest faces and talented actors and directors but we make sure that they get the best work as per their skills. Our team manages a portfolio of the clients who are linked with us and when any project knocks our door, we make sure that the best-suited talent we have got the chance to perform. 
<br /><br />We have given some of the best talents to the film industry and they are shining bright. There are so many talents available in the country but searching for them and grooming them is a big task. Therefore, we conduct some camps to reach out to them and make them the next popular face of the film industry. We pride ourselves on having a long term personal relationship with each client and signify only the best of a broad variety of outstanding talent aiming at every artist's individual career requirement.</p>
          <br />
          <p align="justify">There are plenty of modeling agencies but the risk of reputation and work also get attached with the agency, therefore choosing the right one is very important. Any company or models should go through the previous work, portfolio and feedbacks of the agency before getting associated with them. And BollywoodisGood has all these features and is very much reputed in the Industry.</p>
         
          </div>
          <div class="col-sm-5">
          <img src="images/film-talanet-management.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
        <h2>Best Film Talent Management Company</h2>
        </div>
          <p align="justify">The development of every client's career is extremely significant to the connections here at BollywoodisGood film talent management agency. The talent that we have should be utilized in the film industry. Our aim of expanding the career of every talent that we have has proved precious in the ever-rising reputation of the company and ultimately it gives us close working relationships with the best casting directors and best projects for each of our clients.</p>
        <br />
         <p align="justify">Our clients are enjoying success in films in every industry be it Hindi, Bengali, Telegu, Malayalam, Marathi or International touring productions. We feel happy that through our film talent management we are able to fill many talents life with success and glory.</p>
          </div>
          </section>         
        <Footer />
      </>
    );
  }
}

export default FilmTalent;
