import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainSlider from '../../components/HomeSection/MainSlider';
import BubbleContainer from '../../components/shared/bubble/BubbleContainer';
class slider extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const token = localStorage.getItem("accessToken");
    return (
      <div>
        
        {/* slider section start */}
        <section id="slider_part">
        <BubbleContainer />      
          <div className="container side_space">
            <div className="row">             
            <div id="carousel" class="carousel slide carousel-fade" data-ride="carousel"> 
  
            <div class="carousel-inner">
                <div class="active item"> 
                  <div className='row'>
                <div className="inner_text col-md-6 wow slideInLeft">
                  <h1 className='wow slideInLeft'><span>Gateway to OTT, Films & Brands </span>- All in one place</h1>
                  <p className='wow slideInLeft'>From auditions to job opportunities, invoicing to financial tools—find it all here. Your complete solution for success in the entertainment world.</p>
                  <Link to={token ? "/CreateUserProfile" : '/NewSignup'}  className="stroke_btn">Start Your Journey Today!</Link>
                </div>
                <div className="slider_img col-md-6 text-center"> 
             <img src="images/slide1.png" alt="img" />   
             </div>
             </div>
          </div>

           <div class="item"> 
              <div className='row'>
                <div className="inner_text col-md-6 wow slideInLeft">
                  <h1 className='wow slideInLeft'><span>Seize the Spotlight</span> – Discover Talent with Milestone Payments & Tracking!</h1>
                  <p className='wow slideInLeft'>Access exclusive casting calls in Bollywood, OTT, and more. Plus, hire top talent effortlessly with pay-per-milestone options, tracking all payments and invoices.</p>
                  <Link to={token ? "/SearchAudition" : '/NewSignup'} className="stroke_btn">Find Your Next Role or Talent Today!</Link>
                </div>
                <div className="slider_img col-md-6 text-center"> 
             <img src="images/slide2.png" alt="img" />   
             </div>
             </div>
          </div>

          <div class="item"> 
              <div className='row'>
                <div className="inner_text col-md-6 wow slideInLeft">
                  <h1 className='wow slideInLeft'><span>OTT Breakthrough </span>– Get Cast or Hired on the Hottest Streaming Projects!</h1>
                  <p className='wow slideInLeft'>Bollywood is Good connects actors and crew to top OTT platforms, opening doors to movies, web series, and original content.</p>
                  <Link to={token ? "/SearchAudition" : '/NewSignup'} className="stroke_btn">Explore OTT Roles & Crew</Link>
                </div>
                <div className="slider_img col-md-6 text-center"> 
             <img src="images/slide3.png" alt="img" />   
             </div>
             </div>
          </div>

         <div class="item"> 
              <div className='row'>
                <div className="inner_text col-md-6 wow slideInLeft">
                  <h1 className='wow slideInLeft'><span>Dream Jobs & Easy Hiring</span> with Milestone Payments & Tracking!</h1>
                  <p className='wow slideInLeft'>Whether you're on-screen or behind the scenes, find top jobs in the entertainment industry. Hire talent seamlessly with pay-per-milestone and invoice tracking.</p>
                  <Link to={token ? "/SearchJob" : '/NewSignup'} className="stroke_btn">Discover Jobs & Hire Talent Now!</Link>
                </div>
                <div className="slider_img col-md-6 text-center"> 
             <img src="images/slide4.png" alt="img" />   
             </div>
             </div>
          </div>

         <div class="item"> 
              <div className='row'>
                <div className="inner_text col-md-6 wow slideInLeft">
                  <h1 className='wow slideInLeft'><span>Go Digital, Go Global</span> – Unleash the Power of Digital Activations!</h1>
                  <p className='wow slideInLeft'>Connect with brands, influencers, and digital projects that amplify your presence. Elevate your career through strategic activations.</p>
                  <Link to={token ? "/InfliuencersForm" : '/NewSignup'} className="stroke_btn">Join Digital Projects Now!</Link>
                </div>
                <div className="slider_img col-md-6 text-center"> 
             <img src="images/slide5.png" alt="img" />   
             </div>
             </div>
          </div>

         <div class="item"> 
              <div className='row'>
                <div className="inner_text col-md-6 wow slideInLeft">
                  <h1 className='wow slideInLeft'><span>Money at Your Fingertips</span> – Manage Invoices & Wallet with Ease!</h1>
                  <p className='wow slideInLeft'>Handle invoicing, payments, and expenses effortlessly with our all-in-one finance tools. Keep track of your earnings and manage your wallet like a pro.</p>
                  <Link to={token ? "/wallet" : '/NewSignup'} className="stroke_btn">Master Your Finances Today!</Link>
                </div>
                <div className="slider_img col-md-6 text-center"> 
             <img src="images/slide6.png" alt="img" />   
             </div>
             </div>
          </div>
        
    </div>
   
    <a class="carousel-control left" href="#carousel" data-slide="prev">&lsaquo;</a>
    <a class="carousel-control right" href="#carousel" data-slide="next">&rsaquo;</a>
</div>
        
            </div>
          </div>
        </section>
        {/* slider section ends */}

       
      </div>
    );
    
  }
}

export default slider;