import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';

class MainSlider extends Component {     
    state={
        item: [
        {id:1, url:'images/talent.jpg', link: '/NewSignup', btnText:'Join Us', title: 'BiG is your ultimate platform to kickstart your journey to stardom in the Bollywood industry.', about: 'Discover limitless opportunities, connect with industry professionals, and unleash your true potential.'},
        {id:2,url:'images/audition_img.jpg', link:'/SearchAudition', btnText:'Explore Auditions', title: 'Discover Your Bollywood Breakthrough: Join the BiG Community', about: 'Embark on your Bollywood breakthrough journey by joining the vibrant BiG community. Gain exclusive access to auditions, connect with industry professionals, and pave your way to success in the captivating world of Bollywood.'},
        {id:3, url:'images/talent_banner.jpg',link:'/SearchJob', btnText:'Explore Jobs', title: 'One Platform, Infinite Possibilities: The Power of BiG!!', about: 'Experience the unparalleled power of BiG as a crew member in the Bollywood industry, where one platform opens doors to endless possibilities. Harness the platforms potential to showcase your talent, connect with top-notch projects, and thrive in your craft.'},        
        ]        
}

render(){
    const {item} = this.state;
    return(
      <>
      
        <OwlCarousel
        className="owl-theme owl-carousel"
        loop
        items={1}
        style={{
          opacity: 1,
          display: 'block'
        }}
        //  {...options}
        nav={false}
        autoplay={true}
        autospeed="true"
      >
            
             {item.map(item => <div>
              <div className="overlay"></div>           
                  <div className="banner_text col-md-5 col-sm-7 col-xs-7 full">
                  <h1>{item.title}</h1>
                  <p> {item.about}</p>
                  <Link to={localStorage.getItem('user') ? item.link : '/login'} className="stroke_btn">{item.btnText}</Link>
                </div>
                <div className="carousel-inner">        
            <div className="item active">
             <img src={item.url} alt="img" />                
             </div> 
             </div>
             </div>  
              )              
             }        

            </OwlCarousel>
            </>
    )
}

}

export default MainSlider