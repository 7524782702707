import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { workExp, workExpMax, } from "../CreateUserProfile/registerConst";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { loadOptionsResolveLanguage, defaultOption } from "../../Constants.js/languageConst";
import _, { indexOf } from 'lodash';
import cityStateConst from '../CreateUserProfile/cityStateConst';
import filmCourseOptions from "../CreateUserProfile/filmCoursesConst"
import { countries } from '../CreateUserProfile/cityStateConst'
import { profession, filmType, filmIndustry, jobNature, roleOption, payout, gender, read } from '../CreateUserProfile/registerConst';
import { countryState } from '../../Constants.js/countryState'
import moment from 'moment';




const FindTalentFilter = ({ onChangeFilter, filters, defaultProps,resetAllFilters, showFilter, ...props }) => {
    let stateOptions =[];

    const districtArray = _.find(cityStateConst, {
        state: props.stateval,
    }) ?.districts;

    const districtOptions = _.map(districtArray, (val) => ({
        label: val,
        value: val,
    }));

    let countryStateFilter = countryState.filter((value)=>  value.label == filters.find((data)=> data.type == "country" )?.value );
    // console.log('countryState >>>>>',countryState)
    // console.log('countryStateFilter  ',countryStateFilter)

    if (countryStateFilter.length) {
        stateOptions = _.map(countryStateFilter[0].states, (val) => ({
            label: val,
            value: val,
        }));
    } else {
        stateOptions = _.map(cityStateConst, (val) => ({
            label: val.state,
            value: val.state,
        }));
    }

        // console.log( 'districtArray  ',districtArray)
        // console.log('districtOptions  ', districtOptions )
        // console.log('stateOptions  ',stateOptions );
        // console.log('countries  ',countries);
        // console.log('countryState  ',countryState)


    return (
      <div
        id="filter-sidebar"
        className={`col-lg-3 col-md-4 col-sm-4 col-xs-6 sliding-sidebar ${showFilter ? 'px-0 visible-sm visible-md visible-lg collapse' : ''}`}>
        <div className="list_bg clearfix">
          <h3>Filter By</h3>
          {/* <a
            style={{ color: "#2caaff", cursor: "pointer" }}
            onClick={() => resetAllFilters()}>
            Reset all filters
          </a> */}
          <div className="filter_points">
            <h6>Job Category</h6>
            <Select
              options={profession}
              isClearable
              // value={filters.find(e=>e.type === 'category') || null}
              onChange={(e) =>
                onChangeFilter({ type: "category", value: e?.label || null })
              }
            />
          </div>

          <div className="filter_points">
            <h6 data-toggle="collapse" data-target="#group-2">
              <FaAngleRight />
              Country
            </h6>
            <div id="group-2" className="list-group collapse in">
              <p className="list-group-item full_field">
                <Select
                  options={countryState}
                  isClearable
                  onChange={(e) =>
                    onChangeFilter({ type: "country", value: e?.label || null })
                  }
                />
              </p>
            </div>
          </div>

          <div className="filter_points">
            <h6 data-toggle="collapse" data-target="#group-7">
              <FaAngleRight />
              State
            </h6>
            <div id="group-7" className="list-group collapse">
              <p className="list-group-item full_field">
                <Select
                  options={stateOptions}
                  isClearable
                  onChange={(e) => {
                    onChangeFilter({ type: "state", value: e?.label || null });
                  }}
                />
              </p>
            </div>
          </div>

          <div className="filter_points">
            <h6 data-toggle="collapse" data-target="#group-8">
              <FaAngleRight />
              City
            </h6>
            <div id="group-8" className="list-group collapse">
              <p className="list-group-item full_field">
                <Select
                  options={districtOptions}
                  isClearable
                  onChange={(e) =>
                    onChangeFilter({ type: "city", value: e?.label || null })
                  }
                />
              </p>
            </div>
          </div>
          <div className="filter_points">
            <h6
              data-toggle="collapse"
              data-target="#lang-spk"
              aria-expanded="true">
              <FaAngleRight />
              Languages Speak
            </h6>
            <div id="lang-spk" className="list-group collapse">
              <AsyncSelect
                cacheOptions
                isMulti
                defaultOptions={defaultOption}
                isClearable
                loadOptions={loadOptionsResolveLanguage}
                {...defaultProps}
                onChange={(e) => {
                  const val = _.map(e, (v) => v.label);
                  onChangeFilter(
                    {
                      type: "languagesSpeak",
                      value: val,
                    },
                    val.length ? "multiselect" : ""
                  );
                }}
              />
            </div>
          </div>
          <div className="filter_points">
            <h6
              data-toggle="collapse"
              data-target="#lang-rd"
              aria-expanded="true">
              <FaAngleRight />
              Languages Read
            </h6>
            <div id="lang-rd" className="list-group collapse">
              <AsyncSelect
                cacheOptions
                isMulti
                defaultOptions={defaultOption}
                loadOptions={loadOptionsResolveLanguage}
                {...defaultProps}
                onChange={(e) => {
                  const val = _.map(e, (v) => v.label);
                  onChangeFilter(
                    {
                      type: "languagesRead",
                      value: val,
                    },
                    val.length ? "multiselect" : ""
                  );
                }}
              />
            </div>
          </div>
          <div className="filter_points">
            <h6
              data-toggle="collapse"
              data-target="#lang-wr"
              aria-expanded="true">
              <FaAngleRight />
              Languages Write
            </h6>
            <div id="lang-wr" className="list-group collapse">
              <AsyncSelect
                cacheOptions
                isMulti
                defaultOptions={defaultOption}
                loadOptions={loadOptionsResolveLanguage}
                {...defaultProps}
                onChange={(e) => {
                  const val = _.map(e, (v) => v.label);
                  onChangeFilter(
                    {
                      type: "languagesWrite",
                      value: val,
                    },
                    val.length ? "multiselect" : ""
                  );
                }}
              />
            </div>
          </div>
          {/* <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-3" aria-expanded="true">
                        <FaAngleRight />
                        Language
                    </h6>
                    <div id="group-3" className="list-group collapse in">
                        {
                            _.map(read, val => (

                                <p className="list-group-item">
                                    <label className="check_bx">
                                        <input type="checkbox"
                                            name="field"
                                            checked={_.find(filters, { type: 'readType', value: val.label })}
                                            onChange={(e) => onChangeFilter({ type: "readType", value: [val.label] } , "languageMultiselect")}
                                        />
                                        {val.label}
                                        <span className="checkmark" />
                                    </label>
                                </p>
                            ))
                        }
                       
                        <AsyncSelect
                            cacheOptions
                            isMulti
                            defaultOptions={defaultOption}
                            loadOptions={loadOptionsResolveLanguage}
                            {...defaultProps}
                            onChange={(e) => onChangeFilter({ type: "language", value: e[0].label })}

                        />
                      
                    </div>

                    <div className="filter_points">
                        <h6 data-toggle="collapse" data-target="#group-5"> <FaAngleRight /> Film Relevant Institute  </h6>
                        <div id="group-5" className="list-group collapse in">
                            <div className="list-group-item">
                                <Select
                                    options={filmCourseOptions}
                                    onChange={(e) => onChangeFilter({ type: 'instituteType', value: e.label })}
                                />
                            </div>
                        </div>
                    </div>

                </div> */}
          <div className="filter_points">
            <h6 data-toggle="collapse" data-target="#group-4">
              {" "}
              <FaAngleRight /> Experience{" "}
            </h6>
            <div id="group-4" className="list-group collapse">
              <div className="list-group-item">
                <label>Min</label>
                <Select
                  options={workExp}
                  isClearable
                  onChange={(e) =>
                    onChangeFilter({ type: "minExperience", value: e?.label || null })
                  }
                />
              </div>

              <div id="group-4" className="list-group collapse">
                <div className="list-group-item">
                  <label>Max</label>
                  <Select
                    options={workExp}
                    isClearable
                    onChange={(e) =>
                      onChangeFilter({ type: "maxExperience", value: e?.label || null })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="filter_points">
            <h6 data-toggle="collapse" data-target="#group-5">
              {" "}
              <FaAngleRight /> Age{" "}
            </h6>
            <div id="group-5" className="list-group collapse">
              <div className="list-group-item">
                <label>Min</label>
                <input
                  name='minAge'
                  className="form-control"
                  placeholder="Min age"
                  type={'number'}
                  onChange={(e) =>
                    onChangeFilter({ type: "minAge", value:  e?.target.value ? moment().subtract(Number(e?.target.value),'years'): null})
                  }
                />
              </div>

              <div id="group-5" className="list-group collapse">
                <div className="list-group-item">
                  <label>Max</label>
                  <input
                  name='maxAge'
                  className="form-control"
                  placeholder="Max age"
                  type={'number'}
                  onChange={(e) =>
                    onChangeFilter({ type: "maxAge", value: e?.target.value ? moment().subtract(Number(e?.target.value),'years'): null })
                  }
                />
                </div>
              </div>
            </div>
          </div>
          <div className="filter_points">
            <h6 data-toggle="collapse" data-target="#group-6">
              {" "}
              <FaAngleRight /> Film / Production Type
            </h6>
            <div id="group-6" className="list-group collapse">
              <div className="list-group-item">
                {_.map(filmType, (val) => (
                  <p className="list-group-item">
                    <label className="check_bx">
                      <input
                        type="checkbox"
                        name="field"
                        checked={_.find(filters, {
                          type: "filmType",
                          value: val.label,
                        })}
                        onChange={(e) => {
                          let multiselect = "";
                          const isExist = _.find(
                            filters,
                            (e) => e.type === "filmType"
                          );
                          if (isExist) {
                            isExist.value.includes(val.label)
                              ? isExist.value.splice(
                                  isExist.value.indexOf(val.label),
                                  1
                                )
                              : isExist.value.push(val.label);
                            multiselect =
                              isExist.value.length === 0 ? "" : "multiselect";
                          }

                          onChangeFilter(
                            {
                              type: "filmType",
                              value: isExist ? isExist.value : [val.label],
                            },
                            multiselect
                          );
                        }}
                      />{" "}
                      {val.label} <span className="checkmark" />
                    </label>
                  </p>
                ))}
              </div>
            </div>
          </div>

          {/* <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-3" aria-expanded="true">
                        <FaAngleRight />
                        Payout
              </h6>
                    <div id="group-3" className="list-group collapse in">
                        {
                            _.map(payout, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'payType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'payType', value: val.label })} /> {val.label} <span className="checkmark" /></label></p>
                            ))
                        }
                    </div>
                </div> */}
          <div className="filter_points">
            <h6
              data-toggle="collapse"
              data-target="#group-1"
              aria-expanded="true">
              <FaAngleRight />
              Gender
            </h6>
            <div id="group-1" className="list-group collapse in">
              {_.map(gender, (val) => (
                <p className="list-group-item">
                  <label className="check_bx">
                    <input
                      type="checkbox"
                      name="field"
                      checked={_.find(filters, {
                        type: "gender",
                        value: val.label,
                      })}
                      onChange={(e) =>
                        onChangeFilter({ type: "gender", value: val.label })
                      }
                    />
                    {val.label}
                    <span className="checkmark" />
                  </label>
                </p>
              ))}
              {/* <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Male  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Female  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Any Other  <span className="checkmark" /></label></p> */}
            </div>
          </div>

          {/*} <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-3" aria-expanded="true">
                        <FaAngleRight />
                        Physique
                    </h6>
                    <div id="group-3" className="list-group collapse in">
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />All  <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Slim  <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Average  <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Athletic  <span className="checkmark" /></label></p> 
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" />Heavyset  <span className="checkmark" /></label></p> 
                    </div>
                    </div> */}
        </div>
      </div>
    );
}
export default FindTalentFilter;
