export const defaultOptions = [
  "Hindi", "English", "Marathi"]

export const functionalArea = [
  { label: "Cast", value: "Cast" },
  { label: "Creative Team", value: "Creative Team" },
  { label: "Production", value: "Production" },
  { label: "Script", value: "Script" },
  { label: "Location", value: "Location" },
  { label: "Camera", value: "Camera" },
  { label: "Sound", value: "Sound" },
  { label: "Grip", value: "Grip" },
  { label: "Electrical", value: "Electrical" },
  { label: "Art", value: "Art" },
  { label: "Hair and Makeup", value: "Group 2" },
  { label: "wardrob", value: "Group 2" },
  { label: "Post Production", value: "Group 2" },
  { label: "Visual Effects (VFX)", value: "Group 2" },
  { label: "Post Production - Sound/Music", value: "Group 1" },
  { label: "Other Production Crew", value: "Group 2" },
  { label: "Intractive Media", value: "Group 2" },
  { label: "Other", value: "Other" }
];

export const filmIndustry = [
  { label: "Assamese", value: "Assamese" },
  { label: "Bengali", value: "Bengali" },
  { label: "Bhojpuri", value: "Bhojpuri" },
  { label: "Braj Bhasha", value: "Braj Bhasha" },
  { label: "Chakma", value: "Chakma" },
  { label: "Chhattisgarhi", value: "Chhattisgarhi" },
  { label: "English", value: "English" },
  { label: "Gorkha", value: "Gorkha" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Hindi", value: "Hindi" },
  { label: "International", value: "International" },
  { label: "Kannada", value: "Kannada" },
  { label: "Konkani", value: "Konkani" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Marathi", value: "Marathi" },
  { label: "Meitei", value: "Meitei" },
  { label: "Nagpuri", value: "Nagpuri" },
  { label: "Odia", value: "Odia" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Sherdukpen", value: "Sherdukpen" },
  { label: "Sindhi", value: "Sindhi" },
  { label: "Tamil", value: "Tamil" },
  { label: "Telugu", value: "Telugu" },
  { label: "Tulu", value: "Tulu" },
];

export const Genres = [
  { label: "Action", value: "Action" },
  { label: "Adventure", value: "Adventure" },
  { label: "Animated", value: "Animated" },
  { label: "Comedy", value: "Comedy" },
  { label: "Drama", value: "Drama" },
  { label: "Fantasy", value: "Fantasy" },
  { label: "Historical or Biopic", value: "Historical or Biopic" },
  { label: "Horror", value: "Horror" },
  { label: "Science Fiction", value: "Science Fiction" },
  { label: "Suspense", value: "Suspense" },
  { label: "Thriller", value: "Thriller" },
  { label: "Western", value: "Western" },
  { label: "Other", value: "Other" },
];


export const funtionalOption = [
  { label: "Cast", value: "Cast" },
  { label: "Creative Team", value: "Creative Team" },
  { label: "Production", value: "Production" },
  { label: "Script", value: "Script" },
  { label: "Location", value: "Location" },
  { label: "Camera", value: "Camera" },
  { label: "Sound", value: "Sound" },
  { label: "Grip", value: "Grip" },
  { label: "Electrical", value: "Electrical" },
  { label: "Art", value: "Art" },
  { label: "Hair and Makeup", value: "Group 2" },
  { label: "wardrob", value: "Group 2" },
  { label: "Post Production", value: "Group 2" },
  { label: "Visual Effects (VFX)", value: "Group 2" },
  { label: "Post Production - Sound/Music", value: "Group 1" },
  { label: "Other Production Crew", value: "Group 2" },
  { label: "Intractive Media", value: "Group 2" },
  { label: "Other", value: "Other" }
];

export const roleOption = [
  { value: "Lead", label: "Lead" },
  { value: "Supporting", label: "Supporting" },
  { value: "Day Player", label: "Day Player" },
  { value: "Featured", label: "Featured" },
  { value: "Background", label: "Background" },
  { value: "Other", label: "Other" },
]


// export const awardYear1 = [
  //   { label: "2022", value: "2022" },
  //   { label: "2021", value: "2021" },
  //   { label: "2020", value: "2020" },
//   { label: "2019", value: "2019" },
//   { label: "2018", value: "2018" },
//   { label: "2017", value: "2017" },
//   { label: "2016", value: "2016" },
//   { label: "2015", value: "2015" },
//   { label: "2014", value: "2014" },
//   { label: "2013", value: "2013" },
//   { label: "2012", value: "2012" },
//   { label: "2011", value: "2011" },
//   { label: "2010", value: "2010" },
//   { label: "2009", value: "2009" },
//   { label: "2008", value: "2008" },
// ];

// export const passingYear = [
  //   { label: "2022", value: "2022" },
  //   { label: "2021", value: "2021" },
  //   { label: "2020", value: "2020" },
  //   { label: "2019", value: "2019" },
  //   { label: "2018", value: "2018" },
  //   { label: "2017", value: "2017" },
  //   { label: "2016", value: "2016" },
  //   { label: "2015", value: "2015" },
  //   { label: "2014", value: "2014" },
//   { label: "2013", value: "2013" },
//   { label: "2012", value: "2012" },
//   { label: "2011", value: "2011" },
//   { label: "2010", value: "2010" },
//   { label: "2009", value: "2009" },
//   { label: "2008", value: "2008" },
// ];

const thisYear = new Date().getFullYear();
export const awardYear = Array(50).fill(0).map((e,i)=> {
  return({ label: (thisYear-i).toString(), value: (thisYear-i).toString() })  
})
export const passingYear = Array(50).fill(0).map((e,i)=> {
  return({ label: (thisYear-i).toString(), value: (thisYear-i).toString() })  
})

export const HighestQualification = [
  { label: "Select", value: "Select" },
  { label: "Qualification", value: "Qualification" },
  { label: "Doctorate/PhD", value: "Doctorate/PhD" },
  { label: "Masters/Post-Graduation", value: "Masters/Post-Graduation" },
  { label: "Graduation/Diploma", value: "Graduation/Diploma" },
  { label: "12th", value: "12th" },
  { label: "10th", value: "10th" },
  { label: "Other", value: "Other" },
];

export const courses = [
  { label: "Select", value: "Select" },
  { label: "B.A. (Acting)", value: "B.A. (Acting)" },
  { label: "B.A. (Hons) (Drama)", value: "B.A. (Hons) (Drama)" },
  {
    label: "Bachelor of Theatre Arts (B.T.A.)",
    value: "Bachelor of Theatre Arts (B.T.A.)",
  },
  { label: "M.A. (Indian Theatre)", value: "M.A. (Indian Theatre)" },
  {
    label: "M.F.Tech. (Cinematography)",
    value: "M.F.Tech. (Cinematography)",
  },
  {
    label: "M.F.Tech. (Direction &amp; Screenplay Writing)",
    value: "M.F.Tech. (Direction &amp; Screenplay Writing)",
  },
  { label: "Ph.D. (Dramatics)", value: "Ph.D. (Dramatics)" },
  { label: "Ph.D. (Indian Theatre)", value: "Ph.D. (Indian Theatre)" },
  { label: "Other", value: "Other" },
];

export const specialization = [
  { label: "Select", value: "Select" },
  { label: "Film Making", value: "Film Making" },
  { label: "Acting", value: "Acting" },
  { label: "Writing", value: "Writing" },
  { label: "Music", value: "Music" },
  { label: "Special Effects", value: "Special Effects" },
  { label: "Camera Operator", value: "Camera Operator" },
  { label: "Lightning", value: "Lightning" },
  { label: "Film Editing", value: "Film Editing" },
  { label: "Film distribution", value: "Film distribution" },
  { label: "Other", value: "Other" },
];

export const city = [
  { label: "Mumbai", value: "Mumbai" },
  { label: "Delhi", value: "Delhi" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Ahmedabad", value: "Ahmedabad" },
  { label: "Chennai", value: "Chennai" },
  { label: "Kolkata", value: "Kolkata" },
  { label: "Surat", value: "Surat" },
  { label: "Pune", value: "Pune" },
  { label: "Jaipur", value: "Jaipur" },
];

export const state = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Bihar", value: "Bihars" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Film distribution" },
  { label: "Jharkhand", value: "Jharkhand" },
];

export const gender = [
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
  { label: "Child Artist", value: "Child Artist" },
];

export const audition = [
  { label: "All", value: "All" },
  { label: "Online Audition", value: "Online Audition" },
  { label: "Physical Visit Audition", value: "Physical Visit Audition" },
];

export const profession = [
  // { label: "All", value: "All" },
  { label: "3D Artist: Visual Effects", value: "3D Artist: Visual Effects" },
  { label: "Action Director", value: "Action Director" },
  { label: "Additional Sound Mixer", value: "Additional Sound Mixer" },
  { label: "Adr Recordist", value: "Adr Recordist" },
  { label: "Animation Supervisor", value: "Animation Supervisor" },
  { label: "Animator", value: "Animator" },
  { label: "Art Director", value: "Art Director" },
  { label: "Artist or Actor or Talent", value: "Artist or Actor or Talent" },
  { label: "Assistant Art Director", value: "Assistant Art Director" },
  { label: "Assistant Camera Operator", value: "Assistant Camera Operator" },
  { label: "Assistant Choreographer", value: "Assistant Choreographer" },
  { label: "Assistant Cinematographer or 2nd Unit Cinematographer", value: "Assistant Cinematographer or 2nd Unit Cinematographer" },
  { label: "Assistant Director or Second Unit Director", value: "Assistant Director or Second Unit Director" },
  { label: "Assistant Fashion Stylist", value: "Assistant Fashion Stylist" },
  { label: "Assistant Production Manager", value: "Assistant Production Manager" },
  { label: "Assistant Re-recording Mixer", value: "Assistant Re-recording Mixer" },
  { label: "Assistant Sound Editor", value: "Assistant Sound Editor" },
  { label: "Associate Art Director", value: "Associate Art Director" },
  { label: "Assistant Costume Designer", value: "Assistant Costume Designer" },
  { label: "Boom Operator", value: "Boom Operator" },
  { label: "Camera Tracking & Layout Artist", value: "Camera Tracking & Layout Artist" },
  { label: "Cameraman", value: "Cameraman" },
  { label: "Casting Assistant", value: "Casting Assistant" },
  { label: "Casting Director", value: "Casting Director" },
  { label: "Celebrity Manager or Talent Agent or Representative", value: "Celebrity Manager or Talent Agent or Representative" },
  { label: "Cinematographer or Director of Photography", value: "Cinematographer or Director of Photography" },
  { label: "Co-Producer", value: "Co-Producer" },
  { label: "Compere or Anchor", value: "Compere or Anchor" },
  { label: "Composer or Music Director", value: "Composer or Music Director" },
  { label: "Compositor", value: "Compositor" },
  { label: "Costume Designer", value: "Costume Designer" },
  { label: "Dialogue Editor or Sound Editor", value: "Dialogue Editor or Sound Editor" },
  { label: "Digital Compositor", value: "Digital Compositor" },
  { label: "Digital Imaging Technical Department", value: "Digital Imaging Technical Department" },
  { label: "Director", value: "Director" },
  { label: "Dressman", value: "Dressman" },
  { label: "Editor", value: "Editor" },
  { label: "Event Manager", value: "Event Manager" },
  { label: "Executive Producer", value: "Executive Producer" },
  { label: "Fashion Stylist", value: "Fashion Stylist" },
  { label: "Foley Artist", value: "Foley Artist" },
  { label: "Food Vender or Caterer", value: "Food Vender or Caterer" },
  { label: "FX Artist", value: "FX Artist" },
  { label: "Grip or Camera Rigs", value: "Grip or Camera Rigs" },
  { label: "Hair Stylist", value: "Hair Stylist" },
  { label: "Hairdresser", value: "Hairdresser" },
  { label: "Hard driver or Storage Manager", value: "Hard driver or Storage Manager" },
  { label: "Health and Safety Department", value: "Health and Safety Department" },
  { label: "Influencer", value: "Influencer" },
  { label: "Legal Department", value: "Legal Department" },
  { label: "Line Producer", value: "Line Producer" },
  { label: "Location Manager", value: "Location Manager" },
  { label: "Logistic or Transportation Department", value: "Logistic or Transportation Department" },
  { label: "Lyricist", value: "Lyricist" },
  { label: "Makeup Artist", value: "Makeup Artist" },
  { label: "Marketing Manager", value: "Marketing Manager" },
  { label: "Matchmove Artist", value: "Matchmove Artist" },
  { label: "Matte Painter", value: "Matte Painter" },
  { label: "Modeling Artist", value: "Modeling Artist" },
  { label: "Motion Graphics Designer", value: "Motion Graphics Designer" },
  { label: "Other Crew", value: "Other Crew" },
  { label: "Photographer or Videographer", value: "Photographer or Videographer" },
  { label: "Playback Singer", value: "Playback Singer" },
  { label: "Post Production Department", value: "Post Production Department" },
  { label: "Prodcution Designer", value: "Prodcution Designer" },
  { label: "Producer", value: "Producer" },
  { label: "Production Coordinator", value: "Production Coordinator" },
  { label: "Production Department", value: "Production Department" },
  { label: "Production Manager", value: "Production Manager" },
  { label: "Publicist", value: "Publicist" },
  { label: "Re-Recording Mixer", value: "Re-Recording Mixer" },
  { label: "Recording and Dubbing Department", value: "Recording and Dubbing Department" },
  { label: "Rigging Supervisor", value: "Rigging Supervisor" },
  { label: "Roto or Paint Artist", value: "Roto or Paint Artist" },
  { label: "Satellite Network Department", value: "Satellite Network Department" },
  { label: "Script and Continuty Department", value: "Script and Continuty Department" },
  { label: "Script Supervisor", value: "Script Supervisor" },
  { label: "Set Decorator", value: "Set Decorator" },
  { label: "Sound Assistant", value: "Sound Assistant" },
  { label: "Sound Designer", value: "Sound Designer" },
  { label: "Sound Track Designer", value: "Sound Track Designer" },
  { label: "Special Effect Department", value: "Special Effect Department" },
  { label: "Sport Celebrity", value: "Sport Celebrity" },
  { label: "Storyboard Artist", value: "Storyboard Artist" },
  { label: "Stunt Coordinator", value: "Stunt Coordinator" },
  { label: "Stunt Performer", value: "Stunt Performer" },
  { label: "Stunt Rigger", value: "Stunt Rigger" },
  { label: "Sync Sound Recordist", value: "Sync Sound Recordist" },
  { label: "Telecine Reverse Services", value: "Telecine Reverse Services" },
  { label: "VFX Animation and Visual Effect Department", value: "VFX Animation and Visual Effect Department" },
  { label: "VFX Compositor", value: "VFX Compositor" },
  { label: "Video Distributor", value: "Video Distributor" },
  { label: "Visual Effects Artist", value: "Visual Effects Artist" },
  { label: "Visual Effects Lighting Artist", value: "Visual Effects Lighting Artist" },
  { label: "Visual Effects Producer", value: "Visual Effects Producer" },
  { label: "Visual Effects Supervisor", value: "Visual Effects Supervisor" },
  { label: "Wig Maker", value: "Wig Maker" },
  { label: "Writer", value: "Writer" },
  
  { label: "Head of Production", value: "Head of Production" },
  { label: "Production Designer", value: "Production Designer" },
  { label: "Associate Producer", value: "Associate Producer" },
  { label: "Supervising Producer", value: "Supervising Producer" },
  { label: "Makeup and Hair Artist", value: "Makeup and Hair Artist" },
  { label: "Post Production Supervisor", value: "Post Production Supervisor" },
  { label: "Change the text of Additional Sound Mixer to Sound Mixer", value: "Change the text of Additional Sound Mixer to Sound Mixer" },
  { label: "Sound Recordist ", value: "Sound Recordist " },

];

export const workExp = [
  // { label: 0, value: 0 },
  { label: "Less Than 1 Year", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
  { label: "32", value: "32" },
  { label: "33", value: "33" },
  { label: "34", value: "34" },
  { label: "35", value: "35" },
  { label: "36", value: "36" },
  { label: "37", value: "37" },
  { label: "38", value: "38" },
  { label: "39", value: "39" },
  { label: "40", value: "40" },
  { label: "41", value: "41" },
  { label: "42", value: "42" },
  { label: "43", value: "43" },
  { label: "44", value: "44" },
  { label: "45", value: "45" },
  { label: "46", value: "46" },
  { label: "47", value: "47" },
  { label: "48", value: "48" },
  { label: "49", value: "49" },
  { label: "50", value: "50" },
];

export const workExpMax = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
  { label: "32", value: "32" },
  { label: "33", value: "33" },
  { label: "34", value: "34" },
  { label: "35", value: "35" },
  { label: "36", value: "36" },
  { label: "37", value: "37" },
  { label: "38", value: "38" },
  { label: "39", value: "39" },
  { label: "40", value: "40" },
  { label: "41", value: "41" },
  { label: "42", value: "42" },
  { label: "43", value: "43" },
  { label: "44", value: "44" },
  { label: "45", value: "45" },
  { label: "46", value: "46" },
  { label: "47", value: "47" },
  { label: "48", value: "48" },
  { label: "49", value: "49" },
  { label: "50", value: "50" },
];

export const projectDuration = [
  { label: "Less Than 3 Months", value: "Less Than 3 Months" },
  { label: "3-6 Months", value: "3-6 Months" },
  { label: "6-12 Months", value: "6-12 Months" },
  { label: "1 year", value: "1 year" },
  { label: "2 Year", value: "2 Year" },
  { label: "3 Year", value: "3 Year" },
  { label: "4 Year", value: "4 Year" },
  { label: "5 Year", value: "5 Year" },
  { label: "6 Year", value: "6 Year" },
  { label: "7 Year", value: "7 Year" },
  { label: "8 Year", value: "8 Year" },
  { label: "9 Year", value: "9 Year" },
  { label: "10 Year", value: "10 Year" },
];

export const filmType = [
  { label: "Background", value: "Background" },
  { label: "Commercial or Advertisement", value: "Commercial or Advertisement" },
  { label: "Feature Film", value: "Feature Film" },
  { label: "Model", value: "Model" },
  { label: "Podcast", value: "Podcast" },
  { label: "Short Film", value: "Short Film" },
  { label: "Theatre", value: "Theatre" },
  { label: "TV Daily Soaps or Serials", value: "TV Daily Soaps or Serials" },
  { label: "Video Game", value: "Video Game" },
  { label: "Video YouTube / Social Media / Portal", value: "Video YouTube / Social Media / Portal" },
  { label: "Web Series OTT ", value: "Web Series OTT " },
  { label: "Others", value: "Others" },
];

export const physique = [
  { label: "Slim", value: "Slim" },
  { label: "Average", value: "Average" },
  { label: "Athletic", value: "Athletic" },
  { label: "Heavyset", value: "Heavyset" },
];

export const eyeColor = [
  { label: "Blue", value: "Blue" },
  { label: "Brown", value: "Brown" },
  { label: "Green", value: "Green" },
  { label: "Grey", value: "Grey" },
  { label: "Black", value: "Black" },
];

export const hairColor = [
  { label: "Black", value: "Black" },
  { label: "Blonde", value: "Blonde" },
  { label: "Brown", value: "Brown" },
  { label: "Grey", value: "Grey" },
  { label: "Red", value: "Red" },
  { label: "Salt & Papper", value: "Salt & Papper" },
  { label: "Silver", value: "Silver" },
  { label: "White", value: "White" },
  { label: "Other", value: "Other" },
];

export const hairLenght = [
  { label: "Bald", value: "Bald" },
  { label: "Buzz Cut", value: "Buzz Cut" },
  { label: "Chin Length", value: "Chin Length" },
  { label: "Shoulder Length", value: "Shoulder Length" },
  { label: "Long", value: "Long" },
  { label: "Receding", value: "Receding" },
  { label: "Short", value: "Short" },
];

export const voice = [
  { label: "Alto", value: "Alto" },
  { label: "Baritone", value: "Baritone" },
  { label: "Bass", value: "Bass" },
  { label: "Soprano", value: "Soprano" },
  { label: "Tenor", value: "Tenor" },
];

export const uniqueTraits = [
  { label: "None	", value: "None" },
  { label: "Amputee Arm	", value: "Amputee Arm" },
  { label: "Amputee Leg	", value: "Amputee Leg" },
  { label: "Deaf", value: "Deaf" },
  { label: "Little Person	", value: "Little Person" },
  { label: "Twin", value: "Twin" },
  { label: "Cerebral Palsy", value: "Cerebral Palsy" },
  { label: "Hard Of Hearing	", value: "Hard Of Hearing" },
  { label: "Triplet", value: "Triplet" },
  { label: "Visually Impaired	", value: "Visually Impaired" },
  { label: "Walking Impairment", value: "Walking Impairment" },
  { label: "Wheelchair", value: "Wheelchair" },
];

export const workStatus = [
  { label: "Available For New Project", value: "Available For New Project" },
  { label: "Booked Till Date", value: "Booked Till Date" },
  { label: "Enjoying Holiday Till Date", value: "Enjoying Holiday Till Date" },
  { label: "Partially Available ", value: "Partially Available " },
];

export const postGraduation = [
  { label: "SSC", value: "SSC" },
  { label: "HSC/+2", value: "HSC/+2" },
  { label: "Diploma", value: "Diploma" },
  { label: "Graduation", value: "Graduation" },
  { label: "Post Graduation", value: "Post Graduation" },
  { label: "MS/M.Sc(Science)", value: "MS/M.Sc(Science)" },
  { label: " MBA/PGDM", value: " MBA/PGDM" },
  { label: " M.A", value: " M.A" },
  { label: " M.Com", value: " M.Com" },
  { label: "MCA", value: "MCA" },
  { label: " M.Tech", value: " M.Tech" },
  { label: "Integrated PG", value: "Integrated PG" },
  { label: "CS", value: "CS" },
  { label: "DM", value: "DM" },
  { label: "ICWA (CMA)", value: "ICWA (CMA)" },
  { label: "LLM", value: "LLM" },
  { label: "M.Arch", value: "M.Arch" },
  { label: "M.Ch", value: "M.Ch" },
  { label: "M.Des.", value: "M.Des." },
  { label: "M.Ed", value: "M.Ed" },
  { label: "M.Pharma", value: "M.Pharma" },
  { label: "MCM", value: "MCM" },
  { label: "MDS", value: "MDS" },
  { label: "MFA", value: "MFA" },
  { label: "Medical-MS/MD", value: "Medical-MS/MD" },
  { label: "MVSC", value: "MVSC" },
  { label: " PG Diploma", value: " PG Diploma" },
  { label: "Other", value: "Other" },
  { label: "AAFT - Asian Academy of Film and Television", value: "AAFT - Asian Academy of Film and Television" },
  { label: "AAFT University of Media And Arts", value: "AAFT University of Media And Arts" },
  { label: " Actor Prepares, Mumabi", value: " Actor Prepares, Mumabi" },
  { label: "Adarsha Film Institute, Bangalore", value: "Adarsha Film Institute, Bangalore" },
  { label: " Advent School of Communication", value: " Advent School of Communication" },
  { label: " Aimfill International", value: " Aimfill International" },
  { label: "AISFM - Annapurna International School of Film and Media, Hyderabad", value: "AISFM - Annapurna International School of Film and Media, Hyderabad" },
  { label: "Amity University", value: "Amity University" },
  { label: "Amrita School of Arts and Sciences - Amrita University (ASAS Kochi)", value: "Amrita School of Arts and Sciences - Amrita University (ASAS Kochi)" },
  { label: " Apparel Training and Design Centre", value: " Apparel Training and Design Centre" },
  { label: "Aradhna College of Film and Art (ACOFA)", value: "Aradhna College of Film and Art (ACOFA)" },
  { label: "Arena Animation", value: "Arena Animation" },
  { label: "Arena Animation, Fort", value: "Arena Animation, Fort" },
  { label: "Arena Animation, Gwalior", value: "Arena Animation, Gwalior" },
  { label: "'Arena Animation, Vadodara'", value: "'Arena Animation, Vadodara'" },
  { label: "Arena Animation, Vivek Vihar", value: "Arena Animation, Vivek Vihar" },
  { label: "Atharva Institute of Film and Television (AIFT)", value: "Atharva Institute of Film and Television (AIFT)" },
  { label: "Athena Animation Academy", value: "Athena Animation Academy" },
  { label: "Axis Colleges", value: "Axis Colleges" },
  { label: "Bharati Vidyapeeth’s School of Photography", value: "Bharati Vidyapeeth’s School of Photography" },
  { label: "Biju Pattanaik Film & Television Institute of Odisha", value: "Biju Pattanaik Film & Television Institute of Odisha" },
  { label: "Brilliko Institute of Multimedia", value: "Brilliko Institute of Multimedia" },
  { label: "Center for Research in Art of Film and Television, Delhi", value: "Center for Research in Art of Film and Television, Delhi" },
  { label: " Chandigarh University", value: " Chandigarh University" },
  { label: "Charlie Media Institute", value: "Charlie Media Institute" },
  { label: "Clusters Institute of Media and Technology", value: "Clusters Institute of Media and Technology" },
  { label: "CMI - Calcutta Media Institute", value: "CMI - Calcutta Media Institute" },
  { label: "College of Creative Studies, Vidya Jyoti Educational Society", value: "College of Creative Studies, Vidya Jyoti Educational Society" },
  { label: "Creative Mentors Animation and Gaming College", value: "Creative Mentors Animation and Gaming College" },
  { label: "CREO Valley School of Creativity, Design and Management", value: "CREO Valley School of Creativity, Design and Management" },
  { label: "CREO Valley School of Film and Television", value: "CREO Valley School of Film and Television" },
  { label: "Datasoft, Jhansi", value: "Datasoft, Jhansi" },
  { label: " Deccan Education Society’s Institute Of Film & Television", value: " Deccan Education Society’s Institute Of Film & Television" },
  { label: "Design Media and Edutainment Solutions", value: "Design Media and Edutainment Solutions" },
  { label: "Deviprasad Goenka Management College of Media Studies", value: "Deviprasad Goenka Management College of Media Studies" },
  { label: "DICE VFS School of Entertainment Arts", value: "DICE VFS School of Entertainment Arts" },
  { label: "Digiquest Institute of Creative Arts and Design", value: "Digiquest Institute of Creative Arts and Design" },
  { label: "Dream Zone School of Creative Studies, Alambagh", value: "Dream Zone School of Creative Studies, Alambagh" },
  { label: "Dream Zone School of Creative Studies, Indira Nagar", value: "Dream Zone School of Creative Studies, Indira Nagar" },
  { label: "DreamZone School of Creative Studies, Ultadanga", value: "DreamZone School of Creative Studies, Ultadanga" },
  { label: "FA School of Media & Films", value: "FA School of Media & Films" },
  { label: "Faculty of Liberal Arts, MIT World Peace University (MIT WPU)", value: "Faculty of Liberal Arts, MIT World Peace University (MIT WPU)" },
  { label: "Fast Track Photography Institute", value: "Fast Track Photography Institute" },
  { label: "Film and Television Institute (FTI)", value: "Film and Television Institute (FTI)" },
  { label: "FTIH - Film And Television Institute of Hyderabad", value: "FTIH - Film And Television Institute of Hyderabad" },
  { label: "FX School", value: "FX School" },
  { label: "Garware Institute of Career Education and Development, University of Mumbai", value: "Garware Institute of Career Education and Development, University of Mumbai" },
  { label: "Goodness Institute of Film and Television", value: "Goodness Institute of Film and Television" },
  { label: "Government Film and Television Institute, Bangalore", value: "Government Film and Television Institute, Bangalore" },
  { label: "HighFlyer Animation", value: "HighFlyer Animation" },
  { label: "Himgiri Zee University", value: "Himgiri Zee University" },
  { label: "Hue N Rig Institute of Art and Design", value: "Hue N Rig Institute of Art and Design" },
  { label: "ICE Balaji Telefilms - Institute of Creative Excellence", value: "ICE Balaji Telefilms - Institute of Creative Excellence" },
  { label: "IGNTU - Indira Gandhi National Tribal University", value: "IGNTU - Indira Gandhi National Tribal University" },
  { label: "IIFA Multimedia, Bengaluru", value: "IIFA Multimedia, Bengaluru" },
  { label: " iLEAD Institute of Leadership, Entrepreneurship and Development", value: " iLEAD Institute of Leadership, Entrepreneurship and Development" },
  { label: "India Today Media Institute - ITMI", value: "India Today Media Institute - ITMI" },
  { label: "Indore Professional Studies Academy", value: "Indore Professional Studies Academy" },
  { label: "Innovative Film Academy", value: "Innovative Film Academy" },
  { label: "Institute for Creative Excellence", value: "Institute for Creative Excellence" },
  { label: "Institute of Mass Communication Film and Television Studies (IMCFTS)", value: "Institute of Mass Communication Film and Television Studies (IMCFTS)" },
  { label: "Jagran Lakecity University - JLU", value: "Jagran Lakecity University - JLU" },
  { label: "Jahangirabad Institute of Technology", value: "Jahangirabad Institute of Technology" },
  { label: "JNU - Jawaharlal Nehru University", value: "JNU - Jawaharlal Nehru University" },
  { label: "Jyoti Chitraban Film and Television Institute, Assam, India", value: "Jyoti Chitraban Film and Television Institute, Assam, India" },
  { label: "K. R. Narayanan National Institute of Visual Science and Arts (KRNNIVSA)", value: "K. R. Narayanan National Institute of Visual Science and Arts (KRNNIVSA)" },
  { label: "Kalapurnam Institute of Visual effects and Animation MAYA", value: "Kalapurnam Institute of Visual effects and Animation MAYA" },
  { label: "Kerala Chalachitra academy Trivandrum", value: "Kerala Chalachitra academy Trivandrum" },
  { label: "Kishinchand Chellaram College", value: "Kishinchand Chellaram College" },
  { label: "L. J. Institute of Media and Communications", value: "L. J. Institute of Media and Communications" },
  { label: "L.V Prasad Film Institute, Chennai and  Trivandrum", value: "L.V Prasad Film Institute, Chennai and  Trivandrum" },
  { label: "Lovely Professional University", value: "Lovely Professional University" },
  { label: "MAAC Thane", value: "MAAC Thane" },
  { label: "Mahatma Jyoti Rao Phoole University", value: "Mahatma Jyoti Rao Phoole University" },
  { label: "Maya Academy of Advanced Cinematics, Andheri West", value: "Maya Academy of Advanced Cinematics, Andheri West" },
  { label: "Maya Academy of Advanced Cinematics, Bhopal", value: "Maya Academy of Advanced Cinematics, Bhopal" },
  { label: "Maya Academy of Advanced Cinematics, Camp", value: "Maya Academy of Advanced Cinematics, Camp" },
  { label: "Maya Academy of Advanced Cinematics, Dehradun", value: "Maya Academy of Advanced Cinematics, Dehradun" },
  { label: "Maya Academy of Advanced Cinematics, Dunlop", value: "Maya Academy of Advanced Cinematics, Dunlop" },
  { label: " Maya Academy of Advanced Cinematics, Dwarka", value: " Maya Academy of Advanced Cinematics, Dwarka" },
  { label: "Maya Academy of Advanced Cinematics, Gorepeth", value: "Maya Academy of Advanced Cinematics, Gorepeth" },
  { label: " Maya Academy of Advanced Cinematics, Jhansi", value: " Maya Academy of Advanced Cinematics, Jhansi" },
  { label: "Maya Academy of Advanced Cinematics, Nagpur", value: "Maya Academy of Advanced Cinematics, Nagpur" },
  { label: "Maya Academy of Advanced Cinematics, Vadodara (MAAC)", value: "Maya Academy of Advanced Cinematics, Vadodara (MAAC)" },
  { label: "Maya Academy of Advanced Cinematics, Vijayawada", value: "Maya Academy of Advanced Cinematics, Vijayawada" },
  { label: "MGM's College of Journalism and Mass Communication", value: "MGM's College of Journalism and Mass Communication" },
  { label: "MGR Government Film and Television Institute", value: "MGR Government Film and Television Institute" },
  { label: "MIT School of Film and Television", value: "MIT School of Film and Television" },
  { label: "MSE Institute of Film and Media", value: "MSE Institute of Film and Media" },
  { label: " MU - Mewar University", value: " MU - Mewar University" },
  { label: " MV Media Institute", value: " MV Media Institute" },
  { label: "National Academy of Media and Events", value: "National Academy of Media and Events" },
  { label: "National Film Development Corporation of India", value: "National Film Development Corporation of India" },
  { label: "National Institute of Creative Communication", value: "National Institute of Creative Communication" },
  { label: "National School Of Drama (NSD), Agartala", value: "National School Of Drama (NSD), Agartala" },
  { label: "National School Of Drama (NSD), Bangalore", value: "National School Of Drama (NSD), Bangalore" },
  { label: "National School Of Drama (NSD), Delhi", value: "National School Of Drama (NSD), Delhi" },
  { label: "National School Of Drama (NSD), Gangtok", value: "National School Of Drama (NSD), Gangtok" },
  { label: "National School Of Drama (NSD), Varanasi", value: "National School Of Drama (NSD), Varanasi" },
  { label: "New York Film Academy | Mumbai, India", value: "New York Film Academy | Mumbai, India" },
  { label: "NMIMS School of Branding and Advertising", value: "NMIMS School of Branding and Advertising" },
  { label: "NRAI School of Mass Communication", value: "NRAI School of Mass Communication" },
  { label: "NSHM Knowledge Campus, Kolkata", value: "NSHM Knowledge Campus, Kolkata" },
  { label: "Opulent School of Acting and Fine Arts", value: "Opulent School of Acting and Fine Arts" },
  { label: "Padarpan Films and Theatre Institute", value: "Padarpan Films and Theatre Institute" },
  { label: "Pearl Academy, Mumbai", value: "Pearl Academy, Mumbai" },
  { label: "Picasso International Animation – VFX – Gaming College", value: "Picasso International Animation – VFX – Gaming College" },
  { label: "Pondicherry University Community College, Pondicherry University", value: "Pondicherry University Community College, Pondicherry University" },
  { label: "Prime Focus Academy of Media and Entertainment Studies, Goregaon", value: "Prime Focus Academy of Media and Entertainment Studies, Goregaon" },
  { label: "Prime Focus Academy, Delhi", value: "Prime Focus Academy, Delhi" },
  { label: "Punjabi University B.Tech in Filmmaking", value: "Punjabi University B.Tech in Filmmaking" },
  { label: "Rachna Sansad Film Institute", value: "Rachna Sansad Film Institute" },
  { label: "Ramesh Sippy Academy of Cinema and Entertainment", value: "Ramesh Sippy Academy of Cinema and Entertainment" },
  { label: "Ramoji Academy of Film and Television (RAFT), Hyderabad", value: "Ramoji Academy of Film and Television (RAFT), Hyderabad" },
  { label: "Ramoji Krian Universe", value: "Ramoji Krian Universe" },
  { label: "Rayat - Bahra University", value: "Rayat - Bahra University" },
  { label: "Reliance Education : VFX and Animation Academ", value: "Reliance Education : VFX and Animation Academ" },
  { label: "Reliance Education, Bhubaneswar", value: "Reliance Education, Bhubaneswar" },
  { label: "Reliance Education, Brigade Road", value: "Reliance Education, Brigade Road" },
  { label: "Reliance Education, F.C. Road", value: "Reliance Education, F.C. Road" },
  { label: "Reliance Education, Malleswaram", value: "Reliance Education, Malleswaram" },
  { label: "Satyajit Ray Film and Television Institute (SRFTI)", value: "Satyajit Ray Film and Television Institute (SRFTI)" },
  { label: "School of Humanities and Social Science, Jain", value: "School of Humanities and Social Science, Jain" },
  { label: " School of Media Activity Research and Technology", value: " School of Media Activity Research and Technology" },
  { label: "School of Science Studies, CMR University", value: "School of Science Studies, CMR University" },
  { label: "Seamedu - School of Pro-Expressionism", value: "Seamedu - School of Pro-Expressionism" },
  { label: "Seamedu School of Pro-Exprerssionism, Mohali", value: "Seamedu School of Pro-Exprerssionism, Mohali" },
  { label: "SGT University", value: "SGT University" },
  { label: "Shaft Academy of Media Arts", value: "Shaft Academy of Media Arts" },
  { label: "Shemaroo Institute of Film and Technology", value: "Shemaroo Institute of Film and Technology" },
  { label: "Shiv Nadar University", value: "Shiv Nadar University" },
  { label: "Sixteen By Nine Media", value: "Sixteen By Nine Media" },
  { label: "Sri Aurobindo Centre for Arts and Communication", value: "Sri Aurobindo Centre for Arts and Communication" },
  { label: "Srishti Institute of Art, Design and Technology", value: "Srishti Institute of Art, Design and Technology" },
  { label: "SRM University Ramapuram - SRM Institute of Science and Technology", value: "SRM University Ramapuram - SRM Institute of Science and Technology" },
  { label: "SSS - Symbiosis Summer School", value: "SSS - Symbiosis Summer School" },
  { label: " St. Pauls Institute of Communication Education", value: " St. Pauls Institute of Communication Education" },
  { label: "Talsdo - The Film Institute", value: "Talsdo - The Film Institute" },
  { label: "TGC Animation and Multimedia", value: "TGC Animation and Multimedia" },
  { label: "The Arohan Media School", value: "The Arohan Media School" },
  { label: "The Department of Film Studies at Jadavpur University", value: "The Department of Film Studies at Jadavpur University" },
  { label: " The National Institute  of  Design (NID) Ahmadabad", value: " The National Institute  of  Design (NID) Ahmadabad" },
  { label: "The One School Goa", value: "The One School Goa" },
  { label: "Trytoon Academy", value: "Trytoon Academy" },
  { label: "Udaan School of Photography", value: "Udaan School of Photography" },
  { label: "VCD College of Designing", value: "VCD College of Designing" },
  { label: "Whistling Woods International Institute of Film", value: "Whistling Woods International Institute of Film" },
  { label: "WWI Virtual Academy", value: "WWI Virtual Academy" },
  { label: "X-Gen College of Visual Art", value: "X-Gen College of Visual Art" },
  { label: "YMCA New Delhi ", value: "YMCA New Delhi " },
  { label: "YWCA Women’s Training institute", value: "YWCA Women’s Training institute" },
  { label: "Zee Institute of Creative Art (ZICA), Indore", value: "Zee Institute of Creative Art (ZICA), Indore" },
  { label: " Zee Institute of Creative Art, Chandigarh", value: " Zee Institute of Creative Art, Chandigarh" },
  { label: "Others", value: "Others" }

];



export const graduation = [
  { label: "B.Tech/B.E.", value: "B.Tech/B.E." },
  { label: "B.Com", value: "B.Com" },
  { label: "B.Sc", value: "B.Sc" },
  { label: "B.A", value: "B.A" },
  { label: "Diploma", value: "Diploma" },
  { label: "B.B.A/ B.M.S", value: "B.B.A/ B.M.S" },
  { label: "BCA", value: "BCA" },
  { label: "B.Arch", value: "B.Arch" },
  { label: " B.Des.", value: " B.Des." },
  { label: "B.Ed ", value: "B.Ed " },
  { label: "B.El.Ed ", value: "B.El.Ed " },
  { label: " B.P.Ed", value: " B.P.Ed" },
  { label: " B.Pharma", value: " B.Pharma" },
  { label: "B.U.M.S", value: "B.U.M.S" },
  { label: "BAMS", value: "BAMS" },
  { label: "BDS", value: "BDS" },
  { label: "BFA", value: "BFA" },
  { label: "BHM", value: "BHM" },
  { label: "BHMS", value: "BHMS" },
  { label: "BVSC", value: "BVSC" },
  { label: "LLB", value: "LLB" },
  { label: "MBBS", value: "MBBS" },
  { label: "Other", value: "Other" },
];

export const jobNature = [
  { label: "Full Time", value: "Full Time" },
  { label: "Part TIme", value: "Part TIme" },
  { label: "Other", value: "Other" },
];

export const payout = [
  { label: "Per Hour", value: "Per Hour" },
  { label: "Per Day", value: "Per Day" },
  { label: "Per Month", value: "Per Month" },
  { label: "Fixed Price", value: "Fixed Price" },
  { label: "Disclose Latter", value: "Disclose Latter" },
];


export const workDuration = [
  { label: "Hour", value: "Hour" },
  { label: "Day", value: "Day" },
  { label: "Month", value: "Month" },
  { label: "Year", value: "Year" },
  { label: "Disclose Latter", value: "Disclose Latter" },
];


export const Lang = [
  { label: "Hindi", value: "Hindi" },
  { label: "English", value: "English" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "French", value: "French" },
  { label: "Tamil", value: "Tamil" },
];

export const read = [
  { label: "Speak", value: "Speak" },
  { label: "Read", value: "Read" },
  { label: "Write", value: "Write" }
]
export const langType = [
  { label: "Read", value: "languagesRead" },
  { label: "Speak", value: "languagesSpeak" },
  { label: "Write", value: "languagesWrite" }
]
export const status = [
  { label: "All", value: "All" },
  { label: "In Progress", value: "In Pprogress" },
  { label: "Completed", value: "completed" },
  { label: "Started", value: "Started" },
];
export const plateformOfRelease = [
  { label: "OTT", value: "OTT" },
  { label: "Theatrical", value: "Theatrical" },
];
  export  const workingStyle=[
  { label: "Hybrid", value: "Hybrid" },
  { label: "Work from Office", value: "Work from Office" },
  { label: "Work from Home", value: "Work from Home" },
  ]
  export  const schedule=[
    { label: "Day Shift", value: "Day Shift" },
    { label: "Fixed Shift", value: "Fixed Shift" },
    { label: "Morning Shift", value: "Morning Shift" },
    ] 
    export  const supplementalPayTypes=[
      { label: "Performance bonus", value: "Performance bonus" },
      { label: "Yearly Bonus", value: "Yearly Bonus" },
      ]

      export  const abilityToCommut=[
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
        ]