import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class NewsGallery extends Component {     
    state={
        item: [
            {url:'images/dropdown_img1.jpg', title: 'Sed ut perspiciatis unde', views: '50 views', time: '10 months ago'},
            {url:'images/dropdown_img1.jpg', title: 'Sed ut perspiciatis unde', views: '72 views', time: '1 months ago'},
            {url:'images/dropdown_img1.jpg', title: 'Sed ut perspiciatis unde', views: '10 views', time: '2 months ago'},
            {url:'images/dropdown_img1.jpg', title: 'Sed ut perspiciatis unde', views: '80 views', time: '3 months ago'},
            {url:'images/dropdown_img1.jpg', title: 'Sed ut perspiciatis unde', views: '105 views', time: '6 months ago'},
            {url:'images/dropdown_img1.jpg', title: 'Sed ut perspiciatis unde', views: '2 views', time: '2 days ago'},        
        ]        
}

render(){
    const {item} = this.state;
    return(
        <div >  
             {item.map(item => 
             <div className="col-sm-4 col-xs-6 small_img">
                 <Link to="">
                    <div className="hover_img">
                    <img src={item.url} alt="img" />
                    </div>
                </Link>                 
                <Link to=""><h5>{item.title}</h5></Link>
                <small>{item.views} &nbsp;   |   &nbsp; {item.time}</small>
             </div>        
              )              
             }        
        </div>
           
    )
}

}

export default NewsGallery


