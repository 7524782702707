import React from "react";
import { useTranslation } from "react-i18next";
import {awardYear} from './registerConst';
import Select from 'react-select';

const Award = ({handleChangeDropDown , handleChange , nextStep , prevStep ,...props}) => {

  const { t } = useTranslation();

  return (
    <div className="setup-content" id="step-3">
      <div className="form-group">
        <label className="control-label">{t("SignUpAwardNameLabel")}</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Url"
          name="awardName"
          value={props.awardName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label className="control-label">{t("SignUpAwardForLabel")}</label>
        <input
          type="text"
          className="form-control"
          placeholder
          value={props.awardFor}
          name="awardFor"
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label className="control-label">
          {t("SignUpAwardNominateLabel")}{" "}
        </label>
        <input
          type="text"
          className="form-control"
          placeholder
          value={props.awardNominateFor}
          name="awardNominateFor"
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label className="control-label">{t("SignUpAwardYrLabel")}</label>

        <Select
              options={awardYear}
              //isMulti
              value = {props.awardYear}
              onChange = {e => handleChangeDropDown({name : 'awardYear' , value : e}) }
           />
      </div>
      <div className="form-group text-right">
        <a href="#">
          <i className="fa fa-plus" /> {t("SignupAddMoreAward")}
        </a>
      </div>
      <div>
        <label className="control-label">
          {t("SignUpAwardImgLabel")} &nbsp;{" "}
          <small>
            {" "}
            <a href="#">
              <i className="fa fa-plus"></i> {t("SignupAddMore")}
            </a>{" "}
          </small>
        </label>
        <input
          type="file"
          name="awardImage"
          className="form-control"
          value={props.awardImage}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label className="control-label">
          {t("SignUpAwardVidLabel")} &nbsp;{" "}
          <small>
            {" "}
            <a href="#">
              <i className="fa fa-plus"></i> {t("SignupAddMore")}
            </a>{" "}
          </small>
        </label>
        <input
          type="text"
          name="awardVedio"
          className="form-control"
          placeholder="Enter Url"
          value={props.awardVedio}
          onChange={handleChange}
        />
      </div>
      <button
        className="btn btn-primary nextBtn btn-lg blue_btn"
        onClick={nextStep}
      >
        {t("ContinueBtn")}
      </button>
      <button
        className="btn btn-primary nextBtn btn-lg stroke_btn pull-right"
        onClick={prevStep}
      >
        {t("PrevBtn")}
      </button>
    </div>
  );
}


export default Award;