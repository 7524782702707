import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class AnimatedVideoProduction extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>ANIMATED VIDEO PRODUCTION COMPANY</h1>
                  <p align="justify">BollywoodisGood was launched with the goal to work together with clients and to break the silos that make the brands from offering significant customer experiences. In this continually change on world, we as Animated video production company have expertise narratives with the use of data, art and pure gut feeling.
CONTACT US</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/animated-video-production-company.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/animated-video-production-services.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Animated Video Production Services</h2>
          </div>
          <p align="justify">We understand the importance of being a leader, and over the years, we’ve build a group of cautiously recruited video creation specialists to support the achievement of each of our customers in a professional and positive way.</p>
         <br />
          <p align="justify">The company’s aims and vision rotates in the region of assisting your business develops! We follow our core culture always. We wish to assist companies across every industries attain their target audience and develop their business. To make it more clear, we are your ideal digital partners! Are you looking for an influential method to talk your brand story, process or products?</p>
          <br/>
          <p align="justify">BollywoodisGood makes convincing animations, explainer videos and motion graphics that converse the complex messages smoothly. We are zealous regarding conveying your story in the most appealing and effectual method feasible, and assisting you to attain your objective. Making animations by which your target audience gets motivated and assist your business expand. So get in tyuch if you are looking for the explainer vidio services.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>For effective animation our time-tested process</h2>
          </div>
          <p align="justify">BollywoodisGood is experts in 3D / 2D motion graphics, live action, character, animated interaction, hand-drawn animation and typographic.BollywoodisGood is experts in 3D / 2D motion graphics, live action, character, animated interaction, hand-drawn animation and typographic. BollywoodisGood is beyond an animation video production agency, our objective is to build mutual partnerships that permit us to send the company’s messages in the most appealing way.</p>
          <br />
          <p align="justify">We don’t rush to create a video for you but we conduct our research and understand the goal of the company and the team. If you are looking for animated or motion graphics content we are here to assist you make video that tells the tale to your audience & share experiences that reverberate with your audiences.</p>
          <br />
          <p align="justify">BiG’s animations shares effectual messages that assist you to attain more with the videos.</p>
          
          </div>
          <div class="col-sm-5">
          <img src="images/explainer-video-production.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  

          <section id="inner_services">
          <div class="container">
          <div class="text-center inner_head">
<h2>Do You Know That Animation Is A Reasonable Choice For Delivering The Video Message? Yes it is.</h2>
</div>
<p align="justify">BiG’s expert team is competent to generate effective visual content with no requirement to employ locations, actors, production team or props. Every work can be executed in time limit and budget by our internal animated video production professionals.</p>

          </div>
          </section>     
          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/explainer-video-makers.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>To Increase Sales The Best Method Is To Add An Animated Explainer Video!</h2>
          </div>
          <p align="justify">We recognize the significance of your video marketing deliver outcomes. That’s the cause you ought to believe the advantages of animated video production. In a current survey after watching a video the potential customers were 64% more probable to take action.
In case you have a complex plan or product that you wish to enlighten in simple, trouble-free to recognize way then animated videos are perfect. Watching animated videos are very entertaining and can deliver the message rapidly and in an effectual way.</p>
          <br />
          <p align="justify">Animation video production can appear intimidating; TokyoTechie internal animated video production experts have abundance of knowledge and experience in a lot of dissimilar sorts of animation. To bring your animation idea to life, we work all the way to modeling and compositing, Cinema4D / Maya 3D rendering. If you are looking for increasing the sales through andimated video then you should hire animated explainer prduction agency in India.</p>
           </div>         
        
          </div>
          </div>
          </section>  

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">          
          <div class="inner_head">
          <h2>Explainer Video Production Services process</h2>
          </div>
          <ul>
          <li>We ask question</li>
          <li> Script writing</li>
          <li>Style frames</li>
          <li>Story boarding</li>
          <li>Feedback</li>
          <li>Animation</li>
          <li>Finishing Touches</li>
          <li>Video Promotion</li>
          </ul>
          <p align="justify">We feel happy that we as one of the best Animated video production company we have worked with numerous popular brands of the industry and when they give positive feedback to us, that becomes the moment of pride for us. We have very good clientele as every client who has hired us has experienced complete satisfaction regarding the services we offer.</p>
          </div>
          <div class="col-sm-5">
          <img src="images/animated-video-makers.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>  
        
         
        <Footer />
      </>
    );
  }
}

export default AnimatedVideoProduction;
