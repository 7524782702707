import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { vlidateNumberField } from "../../Utilities/validateInputField";
import { loadOptionsResolveLanguage, defaultOption } from "../../Constants.js/languageConst";
import AsyncSelect from "react-select/async";
import { ActorType, auditionType, Compensations, Ethnicity } from "../JobPost/jobPostConst";
import { roleOption, filmIndustry, filmType } from "../CreateUserProfile/registerConst";
import moment from 'moment';
import { config } from "../../actions/config";
// import { usePlacesWidget } from "react-google-autocomplete";
import Autocomplete from "react-google-autocomplete";
import { Gmap } from "../Gmap";
import FileInput from "../FileCropper/FileInput";
import ImageCropper from "../FileCropper/FileCropper";

const AuditionAndRole = ({ defaultProps, handleChangeDropDown, handleChangeSingle, fileSelectedHandler, nextStep, bannerHandler, PostAuditionSubmit, ...props }) => {
    // const [selectedFile, setSelectedFile] = useState();
    const [location, setLocation] = useState({lat:'', lng:''});
   useEffect(() => {
   if(+props?.auditionLatLng?.lat) setLocation({lat: +props?.auditionLatLng?.lat || '',lng: +props?.auditionLatLng?.lng || ''})
   }, [+props?.auditionLatLng?.lat]);
    // const { AutocompleteRef } = usePlacesWidget({
    //   apiKey: config.GOOGLE_MAPS_API_KEY,
    //   onPlaceSelected: (place) =>  setLocation(
    //     {
    //       lat:place.geometry.location.lat(),
    //       lng:place.geometry.location.lng()
    //     })
    // })
    // const [preview, setPreview] = useState(props.banner)

    
    // useEffect(() => {
    //     if (!selectedFile) {
    //         setPreview(undefined)
    //         return
    //     }
    //     const objectUrl = URL.createObjectURL(selectedFile)
    //     setPreview(objectUrl)
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [selectedFile])

    const [image, setImage] = useState("");
    const [imageName, setImageName] = useState("");
    const [currentPage, setCurrentPage] = useState("choose-img");
  
    // Invoked when new image file is selected
    const onImageSelected = (selectedImg,name) => {
      setImage(selectedImg);
      setCurrentPage("crop-img");
      setImageName(name)
    };
    // Handle Cancel Button Click
    const onCropCancel = () => {
      setCurrentPage("choose-img");
      setImage("");
    };
    const onCropDone = (img) => {
      fileSelectedHandler({target:{name: imageName, files:[img]}})
      setCurrentPage("choose-img");
    }
    return (
      <>
        <div>
          <div className="tab_head">
            <h4>Audition Details</h4>
            <hr />
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="form-group col-md-6">
                  <label>Project Title* </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Project Title"
                    name="title"
                    value={props.title}
                    onChange={handleChangeSingle}
                    required={true}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Audition Type* </label>
                  <Select
                    options={auditionType}
                    value={{
                      value: props.auditionType,
                      label: props.auditionType,
                    }}
                    onChange={(e) =>
                      handleChangeDropDown({
                        name: "auditionType",
                        value: e.label,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-group col-sm-12">
              <label>Audition Date</label>
              <div className="row">
                {!props.discloseLaterAuditionDate && (
                  <>
                    <div className="col-md-4 col-sm-6 col-xs-6">
                      <input
                        type="date"
                        className={`form-control ${
                          props.auditionStartDate ? "" : "date-time-picker"
                        }`}
                        onFocus="(this.type='date')"
                        placeholder="From"
                        name="auditionStartDate"
                        value={
                          moment(props.auditionStartDate).format(
                            "YYYY-MM-DD"
                          ) || " "
                        }
                        onChange={handleChangeSingle}
                      />
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-6">
                      <input
                        type="date"
                        className={`form-control ${
                          props.auditionEndDate ? "" : "date-time-picker"
                        }`}
                        placeholder="To"
                        name="auditionEndDate"
                        min={moment(props.auditionStartDate).format("YYYY-MM-DD")}
                        value={
                          moment(props.auditionEndDate).format("YYYY-MM-DD") ||
                          " "
                        }
                        onChange={handleChangeSingle}
                      />
                    </div>
                  </>
                )}

                <div className="col-md-4 col-sm-12 col-xs-12">
                  <label className="check_bx">
                    <input
                      type="checkbox"
                      value="checkbx"
                      name="checkField"
                      onClick={() =>
                        handleChangeSingle({
                          target: {
                            value: !props.discloseLaterAuditionDate,
                            name: "discloseLaterAuditionDate",
                          },
                        })
                      }
                    />
                    Disclose Latter
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            {!props.discloseLaterAuditionDate && (
              <div className="form-group col-md-6 col-sm-12">
                <label>Audition Time</label>
                <div className="row">
                  <div className="col-sm-6 col-xs-6">
                    <input
                      type="time"
                      className={`form-control ${
                        props.auditionStartTime ? "" : "time-picker"
                      }`}
                      //placeholder="From"
                      name="auditionStartTime"
                      value={props.auditionStartTime || "HH:MM"}
                      onChange={handleChangeSingle}
                    />
                  </div>
                  <div className="col-sm-6 col-xs-6">
                    <input
                      type="time"
                      className={`form-control ${
                        props.auditionEndTime ? "" : "time-picker"
                      }`}
                      placeholder="To"
                      name="auditionEndTime"
                      value={props.auditionEndTime || ""}
                      onChange={handleChangeSingle}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="form-group col-sm-6">
              <label>Audition Location City</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter city"
                name="auditionLocationCity"
                value={props.auditionLocationCity || ""}
                onChange={handleChangeSingle}
              />
            </div>

            {/* <div className="form-group col-sm-6">
              <label>Audition Address </label>
              <input
              ref ={AutocompleteRef}
                type="text"
                className="form-control"
                placeholder="Enter address"
                name="auditionAddress"
                value={props.auditionAddress || ""}
                onChange={handleChangeSingle}
              />
            </div> */}
            <div className="form-group col-sm-6">
              <label>Audition Address </label>
              <Autocomplete
                    apiKey={config.GOOGLE_MAPS_API_KEY}
                    className="form-control"
                    placeholder= {props?.auditionAddress }
                    onPlaceSelected={(place) => {
                      let address = '';
                      place.address_components.forEach((e,i) => {
                        address += e.long_name ;
                        if (i< place.address_components.length-1) {
                          address += ', '
                        }
                      });
                      console.log(address)

                      setLocation(
                        {
                          lat:place.geometry.location.lat(),
                          lng:place.geometry.location.lng()
                        })
                        handleChangeSingle({target:{name:'auditionAddress',value:address}})
                        handleChangeSingle({
                          target:{name:'auditionLatLng',
                          value:{
                            lat:place.geometry.location.lat(),
                            lng:place.geometry.location.lng(),
                          }
                        }
                        })
                      }}
                  />
                  {location?.lat && <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div>lat: {location.lat}</div>
                    <div>lng: {location.lng}</div>
                  </div>}
            </div>
           {location && location.lat && <div className="form-group col-md-6 col-sm-12">
              <Gmap location={location} />
            </div>}

            <div className="form-group col-md-6 col-sm-12">
              <label>
                Actor
                <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                  (You can add if not found)
                </small>
              </label>
              <CreatableSelect
                options={ActorType}
                isMulti
                value={_.map(props.actorType, (val) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(e) =>
                  handleChangeDropDown({
                    name: "actorType",
                    value: _.map(e, (val) => val.label),
                  })
                }
              />
            </div>

            <div className="form-group col-sm-6 col-sm-5">
              <label>Age</label>
              <div className="row">
                <div className="col-sm-6 col-xs-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Min"
                    name="minAge"
                    maxLength={3}
                    value={props.minAge || ""}
                    onChange={(e) => {
                      vlidateNumberField(e) && handleChangeSingle(e);
                    }}
                  />
                </div>
                <div className="col-sm-6 col-xs-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Max"
                    name="maxAge"
                    maxLength={3}
                    value={props.maxAge || ""}
                    onChange={(e) => {
                      vlidateNumberField(e) && handleChangeSingle(e);
                    }}
                  />
                </div>
              </div>
              {props.maxAge < props.minAge && <p style={{ color: 'red',fontSize:'12px',marginLeft:"30px" }}>MaxAge is not less than MinAge</p>}

            </div>

            <div className="form-group col-md-6 col-sm-7">
              <label>
                Role Type
                <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                  (You can add if not found)
                </small>
              </label>
              <Select
                options={roleOption}
                isMulti
                value={_.map(props.roleType, (val) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(e) =>
                  handleChangeDropDown({
                    name: "roleType",
                    value: _.map(e, (val) => val.label),
                  })
                }
              />
            </div>

            <div className="form-group col-md-6 col-sm-12">
              <label>
                Film / Production Type
                <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                  (You can add if not found)
                </small>
              </label>

              <CreatableSelect
                options={filmType}
                isMulti
                value={_.map(props.filmType, (val) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(e) =>
                  handleChangeDropDown({
                    name: "filmType",
                    value: _.map(e, (val) => val.label),
                  })
                }
              />
            </div>
            <div className="form-group col-md-6 col-sm-12">
              <label>
                Film Industry
                <small style={{ fontSize: 10, color: "green", marginLeft: 12 }}>
                  (You can add if not found)
                </small>
              </label>
              <CreatableSelect
                options={filmIndustry}
                isMulti
                value={_.map(props.filmIndustry, (val) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(e) =>
                  handleChangeDropDown({
                    name: "filmIndustry",
                    value: _.map(e, (val) => val.label),
                  })
                }
              />
            </div>
            <div className="form-group col-sm-6">
              <label>Compensations </label>
              <Select
                options={Compensations}
                value={{ value: props.compensation, label: props.compensation }}
                onChange={(e) =>
                  handleChangeDropDown({ name: "compensation", value: e.label })
                }
              />
            </div>
          
            <div className="form-group col-sm-12">
              <label>Skills </label>
              <input
                type="text"
                className="form-control"
                placeholder="Skills"
                name="skills"
                value={props.skills || ""}
                onChange={handleChangeSingle}
              />
            </div>
            <div className="form-group col-sm-6">
              <label>Ethnicity </label>
              <Select
                options={Ethnicity}
                value={{ value: props.ethnicity, label: props.ethnicity }}
                onChange={(e) =>
                  handleChangeDropDown({ name: "ethnicity", value: e.label })
                }
              />
            </div>
            <div className="form-group col-sm-6">
              <label>Based in (Hometown) </label>
              <input
                type="text"
                className="form-control"
                placeholder="hometown"
                name="hometown"
                value={props.hometown || ""}
                onChange={handleChangeSingle}
              />
            </div>
            {/* <div className="form-group col-sm-12">
              <label>Character Brief / Sketch </label>
              <textarea
                className="form-control"
                placeholder="Enter here"
                value={props.characterDescription}
                name="characterDescription"
                onChange={handleChangeSingle}
              />
              <small>Minimum 100 Character</small>
            </div> */}
            <div className="form-group col-sm-12">
              <p>
                <strong>Lanugages</strong>
              </p>
              <div className="row">
                <div className="col-md-4">
                  <label>Read</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={defaultOption}
                    loadOptions={loadOptionsResolveLanguage}
                    {...defaultProps}
                    isMulti
                    value={_.map(props.languagesRead, (val) => ({
                      label: val,
                      value: val,
                    }))}
                    onChange={(e) =>
                      handleChangeDropDown({
                        name: "languagesRead",
                        value: _.map(e, (val) => val.label),
                      })
                    }
                  />
                </div>

                <div className="col-md-4">
                  <label>Write</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={defaultOption}
                    loadOptions={loadOptionsResolveLanguage}
                    {...defaultProps}
                    isMulti
                    value={_.map(props.languagesSpeak, (val) => ({
                      label: val,
                      value: val,
                    }))}
                    onChange={(e) =>
                      handleChangeDropDown({
                        name: "languagesSpeak",
                        value: _.map(e, (val) => val.label),
                      })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <label>Speak</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={defaultOption}
                    loadOptions={loadOptionsResolveLanguage}
                    {...defaultProps}
                    isMulti
                    value={_.map(props.languagesWrite, (val) => ({
                      label: val,
                      value: val,
                    }))}
                    onChange={(e) =>
                      handleChangeDropDown({
                        name: "languagesWrite",
                        value: _.map(e, (val) => val.label),
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-sm-12">
              <label> Instructions to Candidates </label>
              <textarea
                className="form-control"
                placeholder="Enter here"
                rows={4}
                value={props.instructionsToCandidates}
                name="instructionsToCandidates"
                onChange={handleChangeSingle}
              />
              <small>Minimum 100 Character</small>
            </div>
            <div className="col-sm-12">
              {props.banner && (
                <>
                  <a href={config.FILE_URL + props.banner} target="_blank">
                    <img
                      src={config.FILE_URL + props.banner}
                      style={{ height: 100, borderRadius: 10 }}
                    />
                    <br />
                  </a>
                </>
              )}
              <div className="position-relative">
                <button className="blue_btn " style={{ marginTop: "5px" }}>
                {/* <input
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translate(0px, -50%)",
                    opacity: 0,
                    width: 135,
                    height: '100%'
                  }}
                  type="file"
                  name="banner"
                  onChange={(e) => {
                    fileSelectedHandler(e);
                    onSelectFile(e);
                  }}
                /> */}
                {currentPage === "choose-img" ? (
                <FileInput
                  setImage={setImage}
                  onImageSelected={onImageSelected}
                  label={" Upload Banner"}
                  name={"banner"}
                />
              ) : currentPage === "crop-img" ? (
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                  
                />
              ) : (
                ""
              )}
                 
                </button>
              </div>
            </div>

            <div className="col-sm-12">
              <hr />
             
              <button
                className="blue_btn pull-right"
                onClick={() => nextStep()}>
                Next
              </button>
              <button
                className="blue_btn pull-right"
                style={{ marginRight: "20px" }}
                onClick={(e) => PostAuditionSubmit(e, false)}>
                Save as Draft
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default AuditionAndRole;