import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class KitchenAppliancesInfluencer extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>KITCHEN APPLIANCES INFLUENCER MARKETING</h1>
                  <p align="justify">Home decor, kitchen storage, dining and pets accessories and necessities can become popular through influencer marketing. This range of products is necessities and people need the best quality and best brand to shop for these things, hence when any popular influencer will endorse your range of products or brand it will automatically get the attention of the audience and will increase your sale and ROI.</p>
                  <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/kitchen-appliances-influencer-marketing.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/kitchen-brand-promotions.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
            <div className="col-sm-7">
            <div class="inner_head">
          <h2>Kitchen Products Marketing</h2>
          </div>
          <p align="justify">Finding the correct influencer who has the popularity regarding this product range and have the target audience that is looking for such products and brands then that will enhance your growth then the brand becomes easily popular. BollywoodisGood as a popular and trusted influencer marketing agency will help you with the process.</p>
          <br />
          <p align="justify">The team of BollywoodisGood has years of experience and knows what sort of influencer will be right for your brand/business. There are plenty of influencers available in the market of influencer marketing but choosing the right one, coordinating with them and collaboration is a hard job and BollywoodisGood will do it for you. Our experienced team not only finds the correct influencer nut also create a successful campaign for your brand/business and ensure the success of the same. After a deep understanding of your goals, audience, potential customers and what your brand if offering, our experienced team creates the influencer marketing campaign and that campaign with the influencer will surely going to give your positive results.</p>
          </div>          
        
          </div>
          </div>
          </section>

          <section id="last_info">
          <div class="container">
          <div class="row">
          <div class="col-xs-12">
          <blockquote>
          <p align="justify">A detailed report will be shared with you that will give you an idea that how beneficial was the campaign and the influencer for your brand/business promotion. 
          <br /><br />
          With the increasing demand and increasing competition of the product range you are offering, it’s vital to go for influencer marketing for instant results and growth of the business/brand. The return on investment, sales and brand awareness will surely be going to increase through influencer marketing and with the right influencer. 
          </p>
          </blockquote>
          </div>
          </div>
          </div>
          </section>
        <Footer />
      </>
    );
  }
}

export default KitchenAppliancesInfluencer;
