import React from "react";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import UserWalletFilter from '../../components/UserWallet/UserWalletFilter'
import UserWalletList from '../../components/UserWallet/UserWalletList'
const UserWalletPage = ({...props }) => {
  return (
    <>
    <div className="top_slide inner_header">
      <Header {...props} />
      <SubHeader />
       {/* CTA section start*/}
     <section id="wallet_banner" className="mid_info inner_banner">
          <div className="container side_space">         
            <div className="row">   
            <div className="col-md-6 inner_banner_text">
            <h2>Money at Your Fingertips – Manage Invoices & Pocket with Ease!</h2>
            <p>Handle invoicing, payments, and expenses effortlessly with our all-in-one finance tools. Keep track of your earnings and manage your pocket like a pro.</p>
            </div>
            <div className="col-md-6 inner_img">
            <img src="images/slide6.png" alt="img" />   
           </div>
            </div>            
          </div>
      </section>
      {/* CTA section ends */}
      </div>
      <section id="people_page" className="default_bg wallet_page">
        <div className="container side_space">
        <div className="row">
        {/* <UserWalletFilter {...props} /> */}
        <UserWalletList {...props} />
        </div>

        </div>
      </section>
      <Footer />
    </>
  );
};

export default UserWalletPage;
