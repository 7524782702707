import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import BrandInformation from "./BrandInformation";
import BrandIntegration from "./BrandIntegration";
import BrandMoreDetail from "./BrandMoreDetail";

const BrandIntegrationsTabs = ({ step, ...props }) => {
  console.log(step, "STEP COUNT");
  return (
    <>
      <div className="col-md-3 col-sm-4 right_space sidebar_part"> 
        <ul className="nav nav-tabs">
          <li className={step === 1 ? 'active' : ''} >
            <a href="javascript:void" onClick={() => props.nextStep(1)} className="active"> Brand Information</a>
          </li>
          <li className={step === 2 ? 'active' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(2)}>Brand Integration{" "}</a>
          </li>
          <li className={step === 3 ? 'active' : ''}>
            <a href="javascript:void" onClick={() => props.nextStep(3)}> More Details{" "}</a>
          </li>         
        </ul>
      </div>
      <div className="col-md-9 col-sm-8">
        <div className="tab-content shadow_bg">
          {
            step === 1 ? <BrandInformation {...props} /> :
              step === 2 ? <BrandIntegration {...props} /> :
                <BrandMoreDetail {...props} /> 
          }
        </div>
      </div>
    </>
  );
};

export default BrandIntegrationsTabs;
