import React, { Component, useState } from "react";
import { Link } from 'react-router-dom';
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import FindTalentFilter from "../../components/FindTalent/FindTalentFilter";
import FindTalentList from "../../components/FindTalent/FindTalentList";
import { FaSlidersH } from "react-icons/fa";

const FindTalentPage = (props)  => {
  const [showFilter, setToggleFilter] = useState(true);

  return(  
  <div>
          <div className="top_slide inner_header">
    <Header {...props} />
    <SubHeader />
     {/* CTA section start*/}

     <section id="audition_banner" className="mid_info inner_banner">
          <div className="container side_space">         
            <div className="row">   
            <div className="col-md-6 inner_banner_text">
            <h2>Need to hire talent for your next project?</h2>
            <p>Access the largest network of Producers, Directors, Writers, Artists, models, freelancers, and crew members to find the perfect creative talent for your next project.</p>
            <button className="blue_btn" onClick={()=> window.history.back()}>Back</button>       
            <Link to="/hiredContracts" className="blue_btn">Hired List</Link> 
            </div>
            <div className="col-md-6 inner_img">
            <img src="images/slide6.png" alt="img" />   
           </div>
            </div>            
          </div>
      </section>
      </div>

     {/* <section id="cta" className="mid_info dark_clr inner_banner">
          <div className="container side_space">
          <div class="home_grey_bx clearfix"><img src="images/hire.png" alt="img" class="right_pattern" />
            <div className="row">   
            <div className="col-md-9 cta_info">
            <h2>Need to hire talent for your next project?</h2>
            <p>Access the largest network of Producers, Directors, Writers, Artists, models, freelancers, and crew members to find the perfect creative talent for your next project.</p>
                 </div>
            </div>
            </div>
          </div>
      </section> */}


      {/* CTA section ends */}

    {/* <section id="breakdown_list" className="default_bg audition_people_list">
      <div className="container">
        <div className="heading header_btn">
        <h2>Find the Perfect Fit for Your Projects</h2>
       <div>
       <button className="blue_btn" onClick={()=> window.history.back()}>Back</button> 
        <Link to="/hiredContracts" className="blue_btn">Hired List</Link> 
       </div> 
        </div>
        <div className="row">
        <div className="filter-toggler" onClick={()=>{setToggleFilter(!showFilter)}}><button className="blue_btn"><FaSlidersH /> Filters </button></div>
          {!props.resetFilters && <FindTalentFilter {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}}/>}
          <FindTalentList {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}} />
        </div>
      </div>
    </section> */}

    <section id="breakdown_list" className="audition_list">
      <div className="container">      
        <div className="row">      
        <div className="filter-toggler" onClick={()=>{setToggleFilter(!showFilter)}}><button className="blue_btn"><FaSlidersH /> Filters </button></div>
         <FindTalentFilter {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}}/>
          <FindTalentList {...props} {...{showFilter: showFilter, setToggleFilter: setToggleFilter}}/>
        </div>
      </div>
    </section>
    <Footer />
  </div>
)};
export default FindTalentPage;
