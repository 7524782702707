import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import '../../scss/celebrityManagement.scss'
import _ from "lodash";

class CelebritySocialMedia extends Component {

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
          <section id="inner_celeb_banner" className="dark_clr">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-xs-8 inner_celebrity_info">
                  <h1>Celebrity Social Media Management Company</h1>
                  <p align="justify">In the digital era there are platforms to be more famous, get connected with your audience and become a celebrity. Where you can get recognized instantly, know immediate reactions from fans, stay in news and get in touch with people.</p>
                
              <Link to="/ReachUs" class="stroke_btn">Contact US</Link>
                </div>
                <div class="col-sm-6 inner_celebrity_img">
                <img src="images/social-media-marketing-actors.webp" alt="BollywoodisGood is best Celebrity Management Company in India" width="699" height="450" class="wow fadeInDown" data-wow-delay="0.3s" />	 
                </div>
              </div>
            </div>
          </section>

          <section id="inner_about">
          <div class="container">
          <div class="row">
          <div class="col-sm-5">
          <img src="images/celebrity-social-media-marketing.webp"  width="458" height="458" alt="Celebrity Management Services in India" />
          </div>
          <div class="col-sm-7 inner_about_info">
          <div class="inner_head">
          <h2>Celebrity Social Media Marketing Services</h2>
          </div>
          <p align="justify">Yes, we are talking about the most popular and effective way to get famous is Social Media Marketing. Today, if you are not active on social media, you are nearly dead for your followers! Celebrity Social Media Management, gives you a preference in higher revenues, PR coverage, demand among masses. And for all this you need a popular, reliable and successful Celebrity Social Media Management Company.</p>
          <br/>
          <p align="justify">Before getting into more details, lets discuss What Does Celebrity Social Media Management Means? BiG is a Celebrity Social Media Optimization Company, which states that a company that handles your online life. Guide you in building an online positive image among your fans and masses. It needs 24*7 availability and attention to capture trends, share some valid opinions, involve in conversation with fans and followers online. All this to advantageously enhance your public value. In short, we are the best Celebrity Social Media Marketing Company to makes sure you are a STAR everywhere”.</p>
          </div>
          </div>
          </div>
          </section>

          <section id="last_info">           
        <div class="container">
       
        <div class="row">
        <div id="inner_services" className="clearfix">
        <div class="col-xs-12">        
          <div className="inner_head">
            <h2>Why BollywoodisGood for Celebrity Social Media?</h2>
            </div>
        <blockquote>
        <p align="justify">You require BollywoodisGood to make you famous online. We have expert team of social media managers with different expertise in numerous social media platforms like Youtube, Instagram, Facebook, Twitter, Tumblr and more. We design a customised plan as per your niche genre like sports, music, fashion, bollywood, targeting to position you as a BRAND and increase your brand value by giving you an extremely realistic, vocal and spontaneous VOICE on Internet. so if you are looking for social media marketing for actors tokyo techie is the best choice for you.</p>
        </blockquote>
        </div>
        </div>
        </div>
        </div>
        </section>

          <section id="our_role">
          <div class="container">
          <div class="row">
          <div class="col-sm-7 role_info">
          <div class="inner_head">
          <h2>Why require to use social media?</h2>
          </div>
          <p align="justify">From past few years’ social media has become a big part of our daily lives, particularly with celebrities/public figure. Facebook, Instagram and Twitter are those platforms, which offersbig opportunity, to fans as well as celebrities/Public figures, to communicate with each other and get updates about them with their concerts, latest news, tour dates, and general thoughts. But it is also considered to bet the best option for charity and self-promotion.</p>
          <br />
          <p align="justify">These days, most of the celebrities/ Public figures quickly gaining followers and fans on numerous social media platforms because their fans want to stay updated with their lives, which means those celebs have sufficient opportunities to market to a fascinated audience. Occasionally, this is done so well, that even fans don’t understand it’s a part of an advertising campaign!</p>
          <br />
          <ul>
            <li>Engage with audience</li>
            <li>Promote Brands</li>
            <li>Share Live updates</li>
            <li>Share events they are going to</li>
          </ul>
          </div>
          <div class="col-sm-5">
          <img src="images/our_role_img.webp"  loading="lazy" width="458" height="459" alt="Artist Management Services" />
          </div>
          </div>
          </div>
          </section>          

          <section id="inner_about">
            <div class="container">
            <div class="row">
            <div class="col-sm-5">
            <img src="images/celebrity-social-media-marketing.webp" alt="img" />
            </div>
            <div class="col-sm-7 inner_about_info">
            <div class="inner_head">
            <h2>Advantage from social media</h2>
            </div>
            <ul>
            <li>Higher fan following.</li>
            <li>Appreciation from fans.</li>
            <li>Traffic in theatres.</li>
            <li>Publicity.</li>
            <li>Getting more projects.</li>
            <li>Better fan engagements.</li>
            </ul>
           <br />

            <div class="inner_head">
            <h2>Our approach</h2>
            </div>
            <p align="justify">
                </p><ul> <li>Fix the goals and objectives.</li>
            <li>Identifying target audience.</li>
            <li>Develop a customized strategy.</li>
            <li>Coordinate with client’s brand manager to implement the plan.</li>
            <li>Prepare content, graphics, and videos.</li>
            <li>Key performance reporting.</li></ul>
            <p></p>
            </div>
            </div>
            </div>
          </section>

          <section id="inner_services">
          <div class="container">
          <p align="justify">So these are the things what a Celebrity social media management company do. BollywoodisGood the best option if you want a company which can handle your social media presence and reputation. What you do, how you do, what’s your daily routine, where are you going, your fans want to know everything. Social media is the platform which is used by every sort of fan. From high class to middle class, everyone is active on social media these days. And maybe that’s the reason why it has gained so much popularity.</p>
          <br />
          <p align="justify">The ultimate way to get fame as well as communicate with your audience is the social media platform. So, why not hire BollywoodisGood to do it for you. We have experienced and skilled social media managers, who knows how to increase your online presence as well as multiply your fame on internet. Get in touch with us through call, e-mail, skype as well as WhatsApp.</p>
          </div>
          </section>              

        <section id="last_info">
        <div class="container">
        <div class="row">
        <div class="col-xs-12">
        <blockquote>
        <p align="justify">We have opened every medium of communication with you, so that you don’t have to wait or search a lot to get connected with us. Now you must have got the idea that Celebrity Social Media Marketing is the way to gain love and fame from your fans, so why to wait, get in touch with us now and become a STAR.</p>
        </blockquote>
        </div>
        </div>
        </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default CelebritySocialMedia;
