import React from 'react';
import moment from 'moment';
import { FaMoneyBill, FaCalendarCheck, FaSuitcase } from 'react-icons/fa';
import _, { isArray } from 'lodash';

const JobDetailData = ({ jobPostDetail }) => (
  <div className="col-md-9">
    <h5><strong>{jobPostDetail.title}</strong></h5>
    <hr />
    <div className='job_cat row' >
      {/* <h6>{jobPostDetail.category}</h6> */}
      <div className='col-md-6'><strong>Job Category: </strong> <small>{jobPostDetail.category}</small></div>
      {jobPostDetail.jobTitle && <div className='col-md-6'><strong>Job Title: </strong> <small>{jobPostDetail.jobTitle}</small></div>}
      <div className='col-md-6'><strong>Posted on: </strong> <small>{moment(jobPostDetail.createdAt).format('ll')}</small></div>
    </div>
    <hr />
    <div className="job_list job_des_info">
      <ul>
        {jobPostDetail.talentType  && jobPostDetail.talentType.filter(e=> e.length).length> 0 ? <li>
          <span>Character :</span> {jobPostDetail.talentType ?.join(', ')}
        </li> :''}
        {jobPostDetail.roleType && <li>
          <span>Role Type:</span> {jobPostDetail.roleType}
        </li>}
      {jobPostDetail.filmType && jobPostDetail.filmType.length ? <li>
          <span>Film / Production Type:</span>  {jobPostDetail.filmType ?.join(', ')}
        </li>:''}
       {jobPostDetail.filmIndustry && jobPostDetail.filmIndustry.length ? <li>
          <span>Film Industry:</span>  {jobPostDetail.filmIndustry ?.join(', ')}
        </li> :''}
        {jobPostDetail.productionHouse && <li>
          <span>Production House:</span> {jobPostDetail.productionHouse}
        </li>}
        {jobPostDetail.mustHave && <li>
          <span>Must Have:</span> {jobPostDetail.mustHave}
        </li>}
      </ul>
    </div>
    { jobPostDetail.description &&
    <>
    <strong>Description</strong>
    <p>{jobPostDetail.description}</p>
    </>
    }
    { jobPostDetail.jobDescription &&
    <>
    <strong>Job Description</strong>
    <p>{jobPostDetail.jobDescription}</p>
    </>
    }
    <hr />
    <div className="job_list mid_job_info clearfix">
      <ul>
        <li>
          <div className="side_icon text-center">
            <FaMoneyBill />
          </div>
          <div className="job_info">
           {jobPostDetail.minAmount ? <span><strong>{jobPostDetail.minAmount} - {jobPostDetail.maxAmount}</strong></span> :'  Yet to Submit'}
            <small>{jobPostDetail.payType}</small>
          </div>
        </li>
        <li>
          <div className="side_icon text-center">
            <FaSuitcase />
          </div>
          <div className="job_info">
           {jobPostDetail.minExperience ? <span><strong>{jobPostDetail.minExperience} - {jobPostDetail.maxExperience}</strong></span> : ' Yet to Submit'}
            <small>Experience</small>
          </div>
        </li>
        <li>
          <div className="side_icon text-center">
            <FaCalendarCheck />
          </div>
          <div className="job_info">
            {jobPostDetail.shootStartDate ? <span><strong>{jobPostDetail.shootStartDate} to {jobPostDetail.shootEndDate}</strong></span> : ' Yet to Submit'}
            <small>Shoot / Task Dates</small>
          </div>
        </li>
      </ul>
    </div>
    <hr />
    <div class="row">
    {jobPostDetail.category === 'Artist or Actor or Talent' && <div className="job_list job_list_info col-md-6">
        <h6>Physical Information</h6>
        <ul>
          {jobPostDetail.minAge &&<li>
            <span>Age:</span>
            {jobPostDetail.minAge} yr to {jobPostDetail.maxAge} yr
                      </li>}
         { jobPostDetail.minHeightInch && <li>
            <span>Height:</span>
            {jobPostDetail.minHeightFeet} ft  {jobPostDetail.minHeightInch} in
                        to  {jobPostDetail.maxHeightFeet} ft  {jobPostDetail.maxHeightInch} in
                      </li>}
        {jobPostDetail.maxWeight &&  <li>
            <span>Weight:</span>
            {jobPostDetail.minWeight} kg to {jobPostDetail.maxWeight} kg
                      </li>}
         {jobPostDetail.physique && <li>
            <span>Physique:</span> {jobPostDetail.physique}
          </li>}
         {jobPostDetail.uniqueTraits && <li>
            <span>Unique Traits:</span> {jobPostDetail.uniqueTraits}
          </li>}
         {jobPostDetail.appearance && <li>
            <span>Appearance or D/C:</span>
            <small>{jobPostDetail.appearance}</small>
          </li>}
        </ul>
      </div>}
      {jobPostDetail.category !== 'Artist or Actor or Talent' && <div className="job_list job_list_info col-md-6">
      <h6>Basic Information</h6>
       <ul>
      {jobPostDetail.workStyle && <li>
            <span>Work Style:</span>
            {jobPostDetail.workStyle}
        </li>}
        { jobPostDetail.schedule && <li>
            <span>Schedule:</span>
            {jobPostDetail.schedule}
        </li>}
       {jobPostDetail.abilityToCommut && <li>
            <span>Ability To Commut:</span>
            {jobPostDetail.abilityToCommut}
        </li>}
      { isArray(jobPostDetail.benefits) && jobPostDetail.benefits.length ? <li>
            <span>Benefits:</span>
            {jobPostDetail.benefits?.join(', ')}
        </li> :''}
       </ul>
      </div>}
      <div className="job_list job_list_info col-md-6">
        <h6>Other Information</h6>
        <ul>
        {jobPostDetail.mandatoryConditions &&  <li>
            <span>Mandatory Conditions:</span> {jobPostDetail.mandatoryConditions}
          </li>}
          {jobPostDetail.category === 'Artist or Actor or Talent' && jobPostDetail.specificRoleCriteria && <li>
            <span>Specific Role Criteria:</span> {jobPostDetail.specificRoleCriteria}
          </li>}
          {jobPostDetail.shootLocations && <li>
            <span>Shoot Locations:</span> {jobPostDetail.shootLocations}
          </li>}
          {/* <li>
            <span>Passport:</span> Not required
                      </li> */}
         {jobPostDetail.jobNature && <li>
            <span>Job Nature:</span> {jobPostDetail.jobNature}
          </li>}
          {/* <li>
            <span>Introduction Video Needed:</span> Yes
                      </li> */}
        </ul>
      </div>
    </div>
    <hr />
    <div className="job_list">
      <h6>Screening Questions</h6>
      <ul>
        {
          _.map(jobPostDetail.screeningQuestions, (val, i) => (
            <li>{i + 1}. {val}</li>
          ))
        }

      </ul>
    </div>
    <hr />
    <div class="row">
      <div className="job_list col-md-6">
        <h6>Other Details</h6>
        <ul>
          { jobPostDetail.genre &&<li><strong>Genre:</strong> {jobPostDetail.genre}</li>}
        {jobPostDetail.languagesRead && jobPostDetail.languagesRead.length ?<li><strong>Languages Asked While Job Creation:</strong> {jobPostDetail.languagesRead}</li>:''}
          {jobPostDetail.visibility && <li><strong>Job Visibility:</strong> {jobPostDetail.visibility}</li>}
         {jobPostDetail.highestQualification && <li><strong>Highest Qualification:</strong> {jobPostDetail.highestQualification}</li>}
          { jobPostDetail.talentCount && <li><strong>Total Number of Vacancy:</strong> {jobPostDetail.talentCount}</li>}
          {jobPostDetail.currentCountry && <li><strong>Country:</strong>{jobPostDetail.currentCountry}</li>}
        </ul>
      </div>
      <div className="job_list col-md-6">
        <h6>Job Status</h6>
        <ul>
          <li><strong>Applied Users:</strong>{Number(jobPostDetail.appliedUsers ?.length)}</li>
          <li><strong>Interviewing:</strong>{Number(jobPostDetail.shortListedUsers ?.length)}</li>
          <li><strong>Invite Sent:</strong>{Number(jobPostDetail.invites ?.length)}</li>
          {/* <li><strong>Unanswered Invites:</strong> 0</li> */}
        </ul>
      </div>
    </div>
  </div>
);

export default JobDetailData;