import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FaArrowRight } from 'react-icons/fa';
import moment from 'moment';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class AuditionPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'tab1',
      isLoading: true,
      error: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({ isLoading: true, error: null });
    // Simulate API call with setTimeout
    setTimeout(() => {
      if (this.props.jobs && this.props.data) {
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false, error: 'Failed to load data' });
      }
    }, 1000);
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  }

  renderCarousel = (items) => {
    const userStr = localStorage.getItem("user");
    const user = userStr ? JSON.parse(userStr) : null;

    return (
      <OwlCarousel
        className="owl-theme owl-carousel"
        loop
        items={3}
        style={{ opacity: 1, display: 'block' }}
        nav={true}
        autoplay={true}
        autoSpeed={true}
      >
        {_.map(items, (val, i) => (
          <div key={i} className="item">
            <div className="white_bx">
              <h4>{val.title}</h4>
              <span>{val.productionHouse || "Unknown Production"}</span>
              <p>{val.description || val.characterDescription || "No description available."}</p>
              <p>
                <small>Posted : {moment(val.createdAt).format('MMM DD, YYYY')}</small>
              </p>
              <Link
                to={user ? `/${this.state.activeTab === 'tab1' ? 'UserSideJob' : 'AuditionDetail'}/${val._id}` : '/login'}
                className="blue_btn"
              >
                <FaArrowRight />
              </Link>
            </div>
          </div>
        ))}
      </OwlCarousel>
    );
  }

  render() {
    const { activeTab, isLoading, error } = this.state;

    return (
      <div>
        <section id="feature_oppertunity">
          <div className="container">
            <div className="row">
              <div className="col-md-4 head_part wow fadeInDownBig">
                <div className="tiltle">Hiring</div>
                <h2>Feature <br /> Opportunities</h2>
              </div>
              <div className='col-md-8 feature_tab'>
                <ul className="nav nav-tabs text-right">
                  <li className={activeTab === 'tab1' ? 'active' : ''}>
                    <a onClick={() => this.setActiveTab('tab1')} style={{cursor: "pointer"}}>Jobs</a>
                  </li>
                  <li className={activeTab === 'tab2' ? 'active' : ''}>
                    <a onClick={() => this.setActiveTab('tab2')} style={{cursor: "pointer"}}>Auditions</a>
                  </li>
                </ul>
                <div className="tab-content">
                  {isLoading ? (
                    <div></div>
                  ) : error ? (
                    <div>Error: {error}</div>
                  ) : (
                    <>
                      <div id="tab1" className={`tab-pane fade ${activeTab === 'tab1' ? 'in active' : ''}`}>
                        {this.props.jobs && this.props.jobs.length > 0 ? 
                          this.renderCarousel(this.props.jobs) : 
                          <div>No jobs available at the moment.</div>
                        }
                      </div>
                      <div id="tab2" className={`tab-pane fade ${activeTab === 'tab2' ? 'in active' : ''}`}>
                        {this.props.data && this.props.data.length > 0 ? 
                          this.renderCarousel(this.props.data) : 
                          <div>No auditions available at the moment.</div>
                        }
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AuditionPart;















// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import _ from 'lodash';
// import { FaArrowRight } from 'react-icons/fa';
// import moment from 'moment';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

// class AuditionPart extends Component {


//   render() {
//     const userStr = localStorage.getItem("user");
//     const user = userStr ? JSON.parse(userStr) : null
//     return (
//       <div>
//         <section id="feature_oppertunity">
//           <div className="container">
//             <div className="row">
//               <div class="col-md-4 head_part wow fadeInDownBig">
//                 <div className="tiltle">Hiring</div>
//                 <h2>Feature <br /> Oppertunities</h2>
//               </div>
//               <div className='col-md-8 feature_tab'>
//                 <ul class="nav nav-tabs text-right">
//                   <li class="active"><a data-toggle="tab" href="#tab1">Jobs</a></li>
//                   <li><a data-toggle="tab" href="#tab2">Auditions</a></li>
//                 </ul>
//                 <div class="tab-content">
//                   <div id="tab1" class="tab-pane fade in active">

//                     {/* <OwlCarousel
//                       className="owl-theme owl-carousel"
//                       loop
//                       items={3}
//                       style={{
//                         opacity: 1,
//                         display: 'block'
//                       }}
//                       //  {...options}
//                       nav={true}
//                       autoplay={true}
//                       autospeed="true"
//                     >
//                       <div className="item">
//                         <div className='white_bx'>
//                           <h4>Cinematographer for Feature Film</h4>
//                           <span>Dharma Productions</span>
//                           <p>Hiring an experienced cinematographer for a feature film. The role involves working closely with the director to capture the visual essence of the film. Must have a strong portfolio and experience in feature films.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a>
//                         </div>
//                       </div>
//                       <div className="item">
//                         <div className='white_bx'>
//                           <h4>Editor for Independent Film Project</h4>
//                           <span>Balaji Motion Pictures</span>
//                           <p>Looking for an editor to work on an independent film project. Experience with film editing software and a keen eye for detail are required.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a>
//                         </div>
//                       </div>
//                       <div className="item">
//                         <div className='white_bx'>
//                           <h4>Production Designer for TV Series</h4>
//                           <span>Yash Raj Studios</span>
//                           <p>Seeking a production designer to create compelling sets and environments for a new TV series. Strong design skills and previous experience in television production are necessary.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a>
//                         </div>
//                       </div>
//                       <div className="item">
//                         <div className='white_bx'>
//                           <h4>Sound Engineer for Music Video</h4>
//                           <span>T-Series</span>
//                           <p>Hiring a sound engineer for a music video production. Responsibilities include capturing and mixing high-quality sound for a visually dynamic project.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a>
//                         </div>
//                       </div>
//                     </OwlCarousel> */}

//                     <OwlCarousel
//                       className="owl-theme owl-carousel"
//                       loop
//                       items={3}
//                       style={{
//                         opacity: 1,
//                         display: 'block',
//                       }}
//                       nav={true}
//                       autoplay={true}
//                       autoSpeed={true}
//                     >
//                       {_.map(this.props.jobs, (val, i) => (
//                         <div key={i} className="item">
//                           <div className="white_bx">
//                             {/* <h4>Production Designer for TV Series</h4>
//                           <span>Yash Raj Studios</span>
//                           <p>Seeking a production designer to create compelling sets and environments for a new TV series. Strong design skills and previous experience in television production are necessary.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a> */}
//                             {/* Display job type */}
//                             {val.title && (
//                               <Link to="">
//                                 <h4>{val.title}</h4>
//                               </Link>
//                             )}
//                             {/* Display talent type */}
//                             {/* {val.talentType && val.talentType.map((tt, index) => (
//                               <span key={index}>{tt}</span>
//                             ))} */}
//                             {/* Display location */}
//                             {/* {val.currentCity && (
//                               <span>Location: {val.currentCity || 'Yet to Submit'}</span>
//                             )} */}
//                             {/* Display job title */}
//                             {val.title && (
//                               <Link to="">
//                                 <span>{val.productionHouse}</span>
//                               </Link>
//                             )}
//                             {/* Display job description */}
//                             <p>{val.description}</p>
//                             {/* Display posted date */}
//                             <p>
//                               <small>Posted : {moment(val.createdAt).format('MMM DD, YYYY')}</small>
//                             </p>
//                             {/* View details link */}
//                             <Link
//                               to={user ? `/UserSideJob/${val._id}` : '/login'}
//                               className="blue_btn"
//                             >
//                               <FaArrowRight />
//                             </Link>
//                           </div>
//                         </div>
//                       ))}
//                     </OwlCarousel>

//                   </div>

//                   <div id="tab2" class="tab-pane fade">
//                     {/* <OwlCarousel
//                       className="owl-theme owl-carousel"
//                       loop
//                       items={3}
//                       style={{
//                         opacity: 1,
//                         display: 'block'
//                       }}
//                       //  {...options}
//                       nav={true}
//                       autoplay={true}
//                       autospeed="true"
//                     >
//                       <div className="item">
//                         <div className='white_bx'>
//                           <h4>Lead Role in Upcoming OTT Drama</h4>
//                           <span>Disney Hotstar</span>
//                           <p>Seeking a versatile actor for the lead role in a new drama series. Ideal candidates should have strong acting skills and experience in dramatic roles. The role involves intense character development and key scenes.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a>
//                         </div>
//                       </div>
//                       <div className="item">
//                         <div className='white_bx'>
//                           <h4>Supporting Actor for Bollywood Film</h4>
//                           <span>Balaji Motion Pictures</span>
//                           <p>Looking for a supporting actor to play a critical role in an upcoming Bollywood film. Experience in Bollywood cinema and the ability to work well in a collaborative environment is essential.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a>
//                         </div>
//                       </div>
//                       <div className="item">
//                         <div className='white_bx'>
//                           <h4>Voice Actor for Animated Series</h4>
//                           <span>Maya Digital Studios</span>
//                           <p>Casting call for a voice actor to bring characters to life in a new animated series. Experience in voice work and a strong vocal range are required.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a>
//                         </div>
//                       </div>
//                       <div className="item">
//                         <div className='white_bx'>
//                           <h4>Featured Extra in High-Profile Movie</h4>
//                           <span>Dharma Productions</span>
//                           <p>Open casting for featured extras in a major film. This is an excellent chance to gain experience and be part of a high-profile project.</p>
//                           <p><small>Posted : Jul 24, 2023</small></p>
//                           <a href="#" className='blue_btn'><FaArrowRight /></a>
//                         </div>
//                       </div>
//                     </OwlCarousel> */}



//                     <OwlCarousel
//                       className="owl-theme owl-carousel"
//                       loop
//                       items={3}
//                       style={{
//                         opacity: 1,
//                         display: 'block'
//                       }}
//                       nav={true}
//                       autoplay={true}
//                       autoSpeed="true"
//                     >
//                       {/* Map through the data array */}
//                       {_.map(this.props.data, (val, i) => (
//                         <div className="item" key={i + 'carousel-item'}>
//                           <div className='white_bx'>
//                             {/* Display the dynamic content */}
//                             <h4>{val.title}</h4>
//                             <span>{val.productionHouse || "Unknown Production"}</span>
//                             <p>{val.characterDescription || "No description available."}</p>
                            
//                               {/* Check if roleType exists and map through it */}
//                               {/* {val?.roleType && val.roleType.length > 0 && (
//                                 <span>
//                                   Role: {val.roleType.map((rt, idx) => (
//                                     <span key={idx}>{rt}{idx < val.roleType.length - 1 ? ', ' : ''}</span>
//                                   ))}
//                                 </span>
//                               )} */}
//                               {/* Display location if available */}
//                               {/* {val?.shootLocations && <span>Location: {val.shootLocations}</span>} */}
//                               {/* Display posted date */}
//                               <span>Posted : {moment(val.createdAt).format('MMM DD, YYYY')}</span>
                            
//                             {/* Link to details or login */}
//                             <Link to={user ? `/AuditionDetail/${val._id}` : '/login'} className="blue_btn">
//                               <FaArrowRight />
//                             </Link>
                            
//                           </div>
//                         </div>
//                       ))}
//                     </OwlCarousel>

//                   </div>

//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         {/* <section id="auditions">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-12 head_part">
//                     <h2>Featured jobs</h2>
//                </div>
//             </div>
//             <div className='row flex-row'>
//               { _.map(this.props.jobs, (val, i) => (<div key={i+'djdbd'} className='col-lg-4 col-md-6 col-sm-6 info_bx'>
//               <div className='bg_bx'>
//               <div className='list_part'>
//               {val.filmType.map(( ft,index) =>(index===0 ? <span>{ft}</span>:''))} 
//               {_.map(val.talentType, tt =>(<span>{tt}</span>))}  
//                {val?.currentCity && <span>Location : {val.currentCity ? val.currentCity : ' Yet to Submit' }</span>}
//               <span>Posted :  {moment(val.createdAt).format('MMM DD, YYYY')}</span>
//               </div>    
//               {val.title && <Link to=""><h6>{val.title}</h6></Link>}
//               <p>{val.description}</p>
//              <Link to={user ? "/UserSideJob/"+val._id: '/login'} className="link_clr">View Details</Link>
//               </div>
//               </div>
//               ))
//               }

//               {/* <div className='col-md-4'>
//               <div className='bg_bx'>
//               <div className='list_part'>
//               <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
//               </div>    
//               <Link to=""><h6>Assistant Director</h6></Link>
//               <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
//              <Link to="" className="link_clr">View Details</Link>
//               </div>
//               </div>

//               <div className='col-md-4'>
//               <div className='bg_bx'>
//               <div className='list_part'>
//               <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
//               </div>    
//               <Link to=""><h6>Assistant Director</h6></Link>
//               <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
//              <Link to="" className="link_clr">View Details</Link>
//               </div>
//               </div>

//               <div className='col-md-4'>
//               <div className='bg_bx'>
//               <div className='list_part'>
//               <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
//               </div>    
//               <Link to=""><h6>Assistant Director</h6></Link>
//               <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
//              <Link to="" className="link_clr">View Details</Link>
//               </div>
//               </div>

//               <div className='col-md-4'>
//               <div className='bg_bx'>
//               <div className='list_part'>
//               <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
//               </div>    
//               <Link to=""><h6>Assistant Director</h6></Link>
//               <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
//              <Link to="" className="link_clr">View Details</Link>
//               </div>
//               </div>

//               <div className='col-md-4'>
//               <div className='bg_bx'>
//               <div className='list_part'>
//               <span>Feature Film</span> <span>Female</span>  <span>Location : Chandigarh</span><span>Posted : Dec 12, 2022</span>
//               </div>    
//               <Link to=""><h6>Assistant Director</h6></Link>
//               <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
//              <Link to="" className="link_clr">View Details</Link>
//               </div>
//               </div> 
//             </div>

//             <div className='row'>
//               <div className='col-xs-12 text-center'>
//               <Link to="/SearchJob" className="stroke_btn">Explore Jobs</Link>
//               </div>
//             </div>
//          </div>
//          </section> */}

//       </div>
//     );
//   }
// }

// export default AuditionPart;