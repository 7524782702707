import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { workExp, workExpMax, } from "../../components/CreateUserProfile/registerConst";
import Select from "react-select";
import _ from 'lodash';
import { profession, filmType, filmIndustry, jobNature, roleOption, payout } from '../../components/CreateUserProfile/registerConst';
import cityStateConst from '../CreateUserProfile/cityStateConst';
import { countries } from '../CreateUserProfile/cityStateConst';
import { countryState } from '../../Constants.js/countryState'


// const stateOptions = _.map(cityStateConst, (val) => ({
//     label: val.state, value: val.state
// }));


const SearchJobFilter = ({ onChangeFilter, resetAllFilters, filters, showFilter, ...props }) => {
    let stateOptions =[];

    const districtArray = _.find(cityStateConst, {
        state: props.stateval,
    }) ?.districts;


    const districtOptions = _.map(districtArray, (val) => ({
        label: val, value: val
    }));

    let countryStateFilter = countryState.filter((value)=>  value.label == filters.find((data)=> data.type == "country" )?.value );
    // console.log('countryState >>>>>',countryState)
    // console.log('countryStateFilter  ',countryStateFilter)

    if (countryStateFilter.length) {
        stateOptions = _.map(countryStateFilter[0].states, (val) => ({
            label: val,
            value: val,
        }));
    } else {
        stateOptions = _.map(cityStateConst, (val) => ({
            label: val.state,
            value: val.state,
        }));
    }


    let maxAgeValue ;
let minAgeValue ;


filters.forEach(filter => {
    if (filter.type === "maxAge") {
        maxAgeValue = parseInt(filter.value); 
    } else if (filter.type === "minAge") {
        minAgeValue = parseInt(filter.value); 
    }
});

let maxExperienceValue ;
let minExperienceValue ;

filters.forEach(filter => {
    if (filter.type === "maxExperience") {
        maxExperienceValue = parseInt(filter.value); 
    } else if (filter.type === "minExperience") {
        minExperienceValue = parseInt(filter.value); 
    }
});


    return (
        <div id="filter-sidebar" className={`col-md-3 col-sm-4 sliding-sidebar ${showFilter ? 'px-0 visible-sm visible-md visible-lg collapse' : ''}`}>
            <div className="list_bg clearfix">
                <h3>Filter By</h3>
                {/* <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => resetAllFilters()}>Reset all filters</a> */}
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-0">
                        <FaAngleRight />
                        Job Category</h6>
                    <div id="group-0" className="list-group collapse">
                        {
                            _.map(profession, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'professionType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'professionType', value: [val.label] } ,"multiselect")} /> {val.label} <span className="checkmark" /></label></p>
                            ))
                        }
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-1">
                        <FaAngleRight />
                        Age
                </h6>
                    <div id="group-1" className="list-group collapse in">
                        <div className="list-group-item no_padding row">
                            <div className="col-md-6">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Min"
                                    name="minAge"

                                    onChange={e => onChangeFilter({ type: 'minAge', value: e.target.value })}
                                />
                            </div>
                            <div className="col-md-6">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Max"
                                    name="maxAge"

                                    onChange={e => onChangeFilter({ type: 'maxAge', value: e.target.value })}
                                />
                            </div>
                            {maxAgeValue < minAgeValue && <p style={{ color: 'red',fontSize:'12px',marginLeft:"30px" }}>MaxAge is not less than MinAge</p>}

                        </div>
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-2">
                        <FaAngleRight />
                        Country
                </h6>
                    <div id="group-2" className="list-group collapse">
                        <Select
                            options={countryState}

                            onChange={(e) => onChangeFilter({ type: "country", value: e.label })}
                        />
                    </div>
                </div>

                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-12">
                        <FaAngleRight />
                        State
                </h6>
                    <div id="group-12" className="list-group collapse">
                        <Select
                            options={stateOptions}

                            onChange={(e) => onChangeFilter({ type: "state", value: e.label })}
                        />
                    </div>
                </div>

                <div className="filter_points">
                    <h6 data-toggle="collapse" className="#group-13">
                        <FaAngleRight />
                        City
                    </h6>
                    <div id="group-13" className="list-group collapse in">
                        <p className="list-group-item full_field">
                            <Select
                                options={districtOptions}

                                onChange={(e) => onChangeFilter({ type: "city", value: e.label })}
                            />
                        </p>
                    </div>
                </div>
                {/*<div className="filter_points">
                <h6 data-toggle="collapse" data-target="#group-1" aria-expanded="true">
                    <FaAngleRight />
                Applicant needed
            </h6>
                <div id="group-1" className="list-group collapse in">
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'talentCount', value: [0,1] })} onChange={(e) => onChangeFilter({ type: 'talentCount', value: [0,1] })} />1  <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'talentCount', value: [2,5] })} onChange={(e) => onChangeFilter({ type: 'talentCount', value: [2,5] })} />2 to 5  <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'talentCount', value: [5,100000] })} onChange={(e) => onChangeFilter({ type: 'talentCount', value: [5,100000] })} />More than 5  <span className="checkmark" /></label></p>

                </div>
    </div> */}
                {/* <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-3" aria-expanded="true">
                        <FaAngleRight />
                        Payout
              </h6>
                    <div id="group-3" className="list-group collapse in">
                        {
                            _.map(payout, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'payType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'payType', value: val.label })} /> {val.label} <span className="checkmark" /></label></p>
                            ))
                        }
                    </div>
                </div> */}
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-4"> <FaAngleRight />  Experience  </h6>
                    <div id="group-4" className="list-group collapse in">
                        <div className="list-group-item no_padding row">
                            <div className="col-md-12">
                                <label>Min</label>
                                <Select
                                    isClearable
                                    options={workExp}

                                    onChange={e => onChangeFilter({ type: 'minExperience', value: e ?.label })}
                                />
                            </div>
                            <div className="col-md-12">
                                <label>Max</label>
                                <Select
                                    isClearable
                                    options={workExpMax}

                                    onChange={e => onChangeFilter({ type: 'maxExperience', value: e ?.label })}
                                />
                            </div>
                            {maxExperienceValue < minExperienceValue && <p style={{ color: 'red',fontSize:'12px',marginLeft:"30px" }}>MaxExperience is not less than MinExperience</p>}

                        </div>
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-5">
                        <FaAngleRight />
                        Job Posted
                </h6>
                    <div id="group-5" className="list-group collapse in">
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'jobPosted', value: [0, 1] })} onChange={(e) => onChangeFilter({ type: 'jobPosted', value: [0, 1] })} /> Less than 1 month  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'jobPosted', value: [1, 3] })} onChange={(e) => onChangeFilter({ type: 'jobPosted', value: [1, 3] })} /> 1 to 3 months  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'jobPosted', value: [3, 6] })} onChange={(e) => onChangeFilter({ type: 'jobPosted', value: [3, 6] })} /> 3 to 6 months  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'jobPosted', value: [6, 1000] })} onChange={(e) => onChangeFilter({ type: 'jobPosted', value: [6, 1000] })} /> More than 6 months  <span className="checkmark" /></label></p>
                    </div>
                </div>

                {/* <div className="filter_points">
                <h6 data-toggle="collapse" data-target="#group-4">
                    <FaAngleRight />
                Number of Applications
</h6>
                <div id="group-4" className="list-group collapse in">
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'applications', value: [0,5] })} onChange={(e) => onChangeFilter({ type: 'applications', value: [0,5] })} /> Less than 5 <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'applications', value: [5,10] })} onChange={(e) => onChangeFilter({ type: 'applications', value: [5,10] })} /> 5 to 10 <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'applications', value: [10,15] })} onChange={(e) => onChangeFilter({ type: 'applications', value: [10,15] })} /> 10 to 15 <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'applications', value: [15,20] })} onChange={(e) => onChangeFilter({ type: 'applications', value: [15,20] })} /> 15 to 20 <span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'applications', value: [20, 50] })} onChange={(e) => onChangeFilter({ type: 'applications', value: [20, 50] })} /> 20 to 50 <span className="checkmark" /></label></p>
                </div>
</div> */}
                {/* <div className="filter_points">
                <h6 data-toggle="collapse" data-target="#group-5">
                    <FaAngleRight />
                COVID-19 Related Jobs
              </h6>
                <div id="group-5" className="list-group collapse in">
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" defaultChecked /> COVID-19 <span className="checkmark" /></label></p>
                </div>
            </div> */}
                {/* <div className="filter_points">
                <h6 data-toggle="collapse" data-target="#group-6">
                    <FaAngleRight />
                Client info
                 </h6>
                <div id="group-6" className="list-group collapse in">
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" defaultChecked />My Previous Clients<span className="checkmark" /></label></p>
                    <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" defaultChecked />Payment Verified <span className="checkmark" /></label></p>
                </div>
            </div> */}
                {/* <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-6">
                        <FaAngleRight />
                        Client History
                </h6>
                    <div id="group-6" className="list-group collapse">
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'clientHistory', value: 'No hires' })} onChange={(e) => onChangeFilter({ type: 'clientHistory', value: 'No hires' })} /> No hires  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'clientHistory', value: '1 to 9 hires' })} onChange={(e) => onChangeFilter({ type: 'clientHistory', value: '1 to 9 hires' })} /> 1 to 9 hires  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'clientHistory', value: '10+ hires' })} onChange={(e) => onChangeFilter({ type: 'clientHistory', value: '10+ hires' })} /> 10+ hires  <span className="checkmark" /></label></p>
                    </div>
                </div> */}
                {/* 
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-7">
                        <FaAngleRight />
                        Budget
                </h6>
                    <div id="group-7" className="list-group collapse">
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Less than Rs 5000 ' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [0, 5000] })} /> Less than Rs 5000  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Rs 5k to Rs 20k' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [5000, 20000] })} /> Rs 5k to Rs 20k  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Rs 20k to Rs 35k' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [20000, 35000] })} /> Rs 20k to Rs 35k <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Rs 35k to Rs 50k' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [35000, 50000] })} /> Rs 35k to Rs 50k  <span className="checkmark" /></label></p>
                        <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'totalBudget', value: 'Rs 50k+' })} onChange={(e) => onChangeFilter({ type: 'totalBudget', value: [35000, 10000000000000] })} /> Rs 50k+ <span className="checkmark" /></label></p>
                        {/* <p className="list-group-item half_fields"><input type="text" placeholder="Min" /> to <input type="text" placeholder="Max" /> <input type="button" className="stroke_btn" defaultValue="Go" /></p> */}
                {/* </div>
                </div>  */}
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-8">
                        <FaAngleRight />
                        Role Type
                </h6>
                    <div id="group-8" className="list-group collapse">
                        {
                            _.map(roleOption, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'roleType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'roleType', value: val.label })} />  {val.label}  <span className="checkmark" /></label></p>
                            ))
                        }

                    </div>
                </div>

                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-9">
                        <FaAngleRight />
                        Film / Production Type
                </h6>
                    <div id="group-9" className="list-group collapse">
                        {
                            _.map(filmType, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'filmType', value: val.label })} onChange={(e) => onChangeFilter({ type: 'filmType', value: val.label })} /> {val.label}  <span className="checkmark" /></label></p>
                            ))
                        }


                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-10">
                        <FaAngleRight />
                        Film Industry
                </h6>
                    <div id="group-10" className="list-group collapse">
                        {
                            _.map(filmIndustry, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'filmIndustry', value: val.label })} onChange={(e) => onChangeFilter({ type: 'filmIndustry', value: val.label })} /> {val.label}  <span className="checkmark" /></label></p>
                            ))
                        }
                    </div>
                </div>
                <div className="filter_points">
                    <h6 data-toggle="collapse" data-target="#group-11">
                        <FaAngleRight />
                        Job Nature
                </h6>
                    <div id="group-11" className="list-group collapse">
                        {
                            _.map(jobNature, val => (
                                <p className="list-group-item"><label className="check_bx"><input type="checkbox" name="field" checked={_.find(filters, { type: 'jobNature', value: val.label })} onChange={(e) => onChangeFilter({ type: 'jobNature', value: val.label })} /> {val.label}  <span className="checkmark" /></label></p>
                            ))
                        }

                    </div>
                </div>

            </div>
        </div>
    )
}
export default SearchJobFilter;
