import React from 'react';
import { Link } from 'react-router-dom';
import { FaMapMarker, FaStarHalf, FaStar, FaUser, } from 'react-icons/fa';
import { config } from '../../actions/config';

const UserTopHeader = ({ jobApplication }) => {
  console.log(jobApplication)
  return (
    <div className="clearfix people_detail_banner your_page col-md-3">
      <div className="row">
        <div className="col-md-12">
          <div className="applicant_img">
          <img src={jobApplication ?.userId?.profilePic ? config.FILE_URL+jobApplication ?.userId?.profilePic : 'images/fill_user.png'} alt="img" />
          {/* <span class="online"><div class="circle_icon"></div> </span> */}
          </div>
        </div>
        <div className="col-md-12 people_center_info">
          <div className="add_list">
            <div className="clearfix people_header">
              <h3>{jobApplication ?.userId ?.userName}
                <div className="rating">
                  <div className="star">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStarHalf />
                  </div>
                </div>
              </h3>
              <br />
              <small><FaMapMarker /> {jobApplication ?.userId ?.city}</small>
              <div className="bottom_desc">
                <div class="progress default_progress">
                  <div class="progress-bar" role="progressbar" aria-valuenow="70"
                    aria-valuemin="0" aria-valuemax="100" style={{ width: "70%" }}>
                    {/* <span>70% Job Success</span> */}
                  </div>
                </div>
              </div>
              {/* <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam</p> */}
            </div>
          </div>
        </div>
        <div className="col-md-12 text-right profile_right">
          {/* <Link to="" className="blue_btn">Hire {jobApplication?.userId?.userType}</Link> */}
          <Link to={`/UserProfile/${jobApplication ?.userId ?._id}`} className="stroke_btn"> View Profile</Link>
          {/* <Link to="" className="stroke_btn"><FaUser /> Invite</Link> */}
        </div>
      </div>
    </div>
  )
}

export default UserTopHeader;