import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios";
import {config} from '../actions/config'
// import { BASE_URL, CHAT_LIST, SAVE_CHAT } from "../../Utils/serviceURLConfig";

const getChatlist = async (auditionId, fromUserId) => {
    const { data } = await axios({
        method: 'get',
        url: `${config.BASE_URL}/get/auditionChat?auditionId=${auditionId}&fromUserId=${fromUserId}`
    });
    return data
}

export const useGetEventChat = (auditionId,fromUserId) => {
    return useQuery(['getEventChatList', auditionId], () => getChatlist(auditionId,fromUserId),
        {
            refetchOnWindowFocus: true
        })
}


// const saveChat = async (data) => {
//     const { data } = await axios({
//         method: 'post',
//         url: `${BASE_URL}/${SAVE_CHAT}}`,
//         body:datpa
//     });
//     return data
// }

// export const useSaveChat = (data) => {
//     return useQuery(['saveChat', data], () => saveChat(data))
// }

const saveChat = (eventReqJson) => {
    return axios({
        method: 'post',
        url: `${config.BASE_URL}/save/auditionChat`,
        data: eventReqJson
    });
}

export const useSaveChat = () => {
    return useMutation(saveChat)
}