import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import JobDetailData from './JobDetailData';
import JobDetailSidebar from './JobDetailSidebar'
import InvitePeopleTab from './InvitePeopleTab'
import ReviewApplicationsTab from './ReviewApplicationsTab'
import HireTab from './HireTab'

const PostjobTabInfo = props => (
    <section id="postjob_detail" className="default_bg">
        <img src="images/post_job_bg.png" alt="img" className="bg_img" />
        <div className="container">
            <div className="row">
                <div className="col-xs-12 jobdetail_tab">
                    <h3 className="main_head">Job Details</h3>
                    <div className="postjob_tab">
                        <ul className="nav nav-tabs">
                            <li className="active"><Link to="#tab_default_1" data-toggle="tab">View Post Job</Link></li>
                            <li><Link to="#tab_default_2" data-toggle="tab">Invite Peoples</Link></li>
                            <li><Link to="#tab_default_3" data-toggle="tab">Review Applications </Link></li>
                            <li><Link to="#tab_default_4" data-toggle="tab">Hire </Link> </li>
                        </ul>
                    </div>

                    <div className="tab-content">
                        <div className="tab-pane active" id="tab_default_1">
                            <div className="shadow_bg clearfix">
                                <div className="row">
                                    <JobDetailData {...props} />
                                    <JobDetailSidebar {...props} />
                                </div>
                            </div>
                        </div>
                        <InvitePeopleTab {...props} />
                        <ReviewApplicationsTab {...props} />
                        <HireTab {...props} />
                    </div>
                </div>
            </div>
        </div>
    </section>
)
export default PostjobTabInfo;