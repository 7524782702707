import React from "react";
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaRegEdit, FaSearch, FaTimes, FaAngleLeft, FaAngleRight, } from 'react-icons/fa';
import _ from 'lodash';
import moment from 'moment';
import Pagination from "../Pagination/Pagination";
import { useEffect } from "react";
import { useState } from "react";

const AuditionList = ({ onChangeSearch, removePost, data, ...props }) => {
    let pageSize = 10;
    const user = localStorage.getItem('user');
      const myId = user ? JSON.parse(user)?._id:'';
    const [auditioList, setauditioList] = useState([])
    const [pageIndex, setPageIndex] = useState(1);

    
  useEffect(() => {
    if(data){
      let d = data.slice(0,pageSize)
      setauditioList(d)
    }
    }, [data])
    const pageChangeHandler = (index) => {
      console.log(index)
      const slicedList = data.slice((index - 1) * pageSize, (index * pageSize))
      setauditioList(slicedList)
      setPageIndex(index)
    
    }
    return(
    <div className="col-sm-9 post_result">
        <div className="form-group search_field dark_search">
            <input type="search" placeholder="Search for auditions" className="form-control" onChange={e => onChangeSearch(e.target.value)} />
            <button type="button" className="default_btn"><FaSearch /></button>
        </div>
        <div className="shadow_bg clearfix">
            <ul>

                {
                    _.map(auditioList, val=> (
                        <>
                            <li >
                                <div className="audition_date">
                                    <span>{val.auditionStartDate && moment(val.auditionStartDate).format('ll') || 'Disclose later'}</span>

                                </div>
                                <div className="breakdown_casting_info audition_info">
                                    <div className="casting_list_head clearfix">
                                        <h3>{val.title}</h3>
                                        <p><span><strong>Type :</strong>  {val.auditionType}</span>
                                            <span><strong> Actor : </strong> {val.actorType ?.join()} </span>
                                            <span><strong>Location: </strong> {val.auditionLocationCity}</span>
                                            <span><strong>Age: </strong> {val.minAge} - {val.maxAge}</span>
                                            <span><strong>Post on: </strong> {moment(val.createdAt).format('ll')}</span></p>
                                    </div>

                                    <p className="brief_intro">{val.characterDescription}</p>

                                    <div className="casting_list_btn clearfix">
                                        <Link to={`/AuditionDetail/${val._id}`}>More Details</Link>
                                        <div className="action_btn">
                                            <div className="job_action">
                                                <ul>
                                                    <li><strong>0</strong> Applications</li>
                                                    <li><strong>0</strong> Messaged</li>
                                                </ul>
                                            </div >
                                            <Link className="message" onClick={() => removePost(val._id)}><FaTimes />
                                                <p className="editmessage"><small>Delete</small></p>
                                            </Link>
                                            <Link className="message" to={{ pathname: "/CreateAudition", state: { id: val._id } }}><FaRegEdit />  <p className="editmessage"><small>Edit</small></p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <hr />
                        </>
                    ))
                }

            </ul>

        </div>
        <div className="pagination_part clearfix text-right">
            <br />
            <Pagination totalCount={data?.length || 0} pageSize={pageSize} onPageChange={pageChangeHandler} currentPage={pageIndex} />
        </div>
    </div>)
};

export default AuditionList;