import React from 'react';
import _ from 'lodash';
const CoverLetter = ({ jobApplication, jobPostDetail, currentUser }) => {
    return (
        <>
            <div className="shadow_bg clearfix message_part">
                <h5><strong>Cover letter</strong></h5>
                <hr />
                <h2>Meessege to client</h2>
                <p>{jobApplication ?.messegeToClient}</p>
                <br />
                <h1>Screening questions</h1>
                {
                    _.map(jobPostDetail ?.job ?.screeningQuestions , (val, index) => (
                        <>
                            <p><strong>{val}</strong></p>
                            <p>{jobApplication ?.answers ?.[index]}</p>
                        </>
                    ))
                }
                <p>Thanks</p>
                <p>{currentUser ?.userName}</p>
            </div>
        </>
    );
}

export default CoverLetter;