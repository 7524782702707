import React from 'react';
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import PostjobTabInfo from '../../components/PostJobDetail/PostjobTabInfo';

const PostjobDetailPage = props => (
    <>
        <Header {...props} />
        <SubHeader />
        <PostjobTabInfo {...props} />
        <Footer />
    </>
);

export default PostjobDetailPage;