import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Header from "../../components/shared/header/Header";
import SubHeader from "../../components/shared/header/SubHeader";
import Footer from "../../components/shared/footer/Footer";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import _ from "lodash";
import moment from "moment";
class NotificationPage extends Component {
  redirectPage = (val) => {
    if (this.props.history) {
      if (val.mainCategory === "jobPost") {
        switch (val.subCategory) {
          case "jobInvitation":
            this.props.history.push(`./UserSideJob/${val.elementId}`);
            break;
          case "shortlisted":
            this.props.history.push(`./UserSideJob/${val.elementId}`);
            break;
          case "hired":
            this.props.history.push(`./UserSideJob/${val.elementId}`);
            break;
          case "jobApplication":
            this.props.history.push(`./PostJobDetail/${val.elementId}`);
            break;
        }
      }
      if (val.mainCategory === "makeMyFilm") {
        switch (
          val.subCategory
          //case 'filmInvitation': this.props.history.push(`./UserSideJob/${val.elementId}`); break;
        ) {
        }
      }
      if (val.mainCategory === "Audition") {
        switch (val.subCategory) {
          case "AuditionApplication":
            this.props.history.push(`./AuditionDetail/${val.elementId}`);
            break;
          case "shortlisted":
            this.props.history.push(`./AuditionDetail/${val.elementId}`);
            break;
          case "hired":
            this.props.history.push(`./UserSideJob/${val.elementId}`);
            break;
          case "jobApplication":
            this.props.history.push(`./PostJobDetail/${val.elementId}`);
            break;
        }
      }
    }
  };

  render() {
    return (
      <>
        <Header />
        <SubHeader /> 
        <section id="breakdown_list" className="default_bg notification_list">
          <img src="images/post_job_bg.png" alt="img" className="bg_img" />
          <div className="container">
            <div className="row">
              <div className="col-sm-12 post_result">
                <h2>All Notifications</h2>
                <div className="shadow_bg clearfix">
                  
                  <ul>
                    {_.map(
                      this.props.notifications,
                      (val) => (
                        <li key={val.id}
                          onClick={() => this.redirectPage(val)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="breakdown_casting_info">
                            <div className="casting_list_head">
                              <div className="notification_img">
                                {" "}
                                <img src="images/fill_user.png" alt="img" />
                              </div>
                              <div className="noti_detail">
                                {/* <h3>Akshay Kumar <small>Actor</small></h3> */}
                                <span>
                                  {moment(
                                    new Date(val.createdAt.seconds * 1000)
                                  ).format("ll HH:MM A")}
                                </span>
                                <p>{val.content}</p>
                              </div>
                            </div>
                            {/* <div className="side_action_btn">
                                    <Link to="" className="stroke_btn">Ignore</Link>
                                        <Link to="" className="stroke_btn">Accept</Link>
                                        <div className="icon">
                                        <Link to=""><FaTimes /></Link>
                                        </div>
                                    </div> */}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                  {/* <ul>
                    <label className="notification_label">Auditions</label>
                    {_.map(
                      _.filter(
                        this.props.notifications,
                        (val) => val.mainCategory === "Audition"
                      ),
                      (val) => (
                        <li
                          onClick={() => this.redirectPage(val)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="breakdown_casting_info">
                            <div className="casting_list_head">
                              <div className="notification_img">
                                {" "}
                                <img src="images/akshay.jpg" alt="img" />
                              </div>
                              <div className="noti_detail">
                                <span>
                                  {moment(
                                    new Date(val.createdAt.seconds * 1000)
                                  ).format("ll HH:MM A")}
                                </span>
                                <p>{val.content}</p>
                              </div>
                            </div>
                            <div className="side_action_btn">
                                    <Link to="" className="stroke_btn">Ignore</Link>
                                        <Link to="" className="stroke_btn">Accept</Link>
                                        <div className="icon">
                                        <Link to=""><FaTimes /></Link>
                                        </div>
                                    </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul> */}
                  <div class="pagination_part clearfix text-right">
                    <hr />
                    {/* <ul class="pagination">
                      <li>
                        <Link to="#">
                          <FaAngleLeft /> Prev
                        </Link>
                      </li>
                      <li>
                        <Link to="#">1</Link>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">3</Link>
                      </li>
                      <li>
                        <Link to="#">4</Link>
                      </li>
                      <li>
                        <Link to="#">5</Link>
                      </li>
                      <li>
                        <Link to="#">
                          {" "}
                          Next <FaAngleRight />
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let loggedInUserStr = localStorage.getItem('user');
  const loggedInUser = loggedInUserStr ? JSON.parse(loggedInUserStr): {}
  const allowNotifications = loggedInUser?.additionalInfo?.allowPushNotification;
  let filters = [];
  filters = loggedInUser?.additionalInfo?.pushNotificationFilter || [];
  let notificationList =[]
  const noti_list = []
  if(!allowNotifications){
    notificationList =[]
  }else{
    let initialList = state.firestore.ordered.notifications || []
    initialList = initialList.filter(e=> e.userId === loggedInUser?._id || e.userId === '')
    initialList.forEach((d)=>{
      if(d.mainCategory === "Audition" && d.subCategory === 'AuditionApplication' && d.filter){
        let allow = false
        d.filter.forEach((fltr)=>{
          if(filters.includes(fltr)){
            allow = true;    
          }
        })
        if(allow)noti_list.push(d)
      }else{
        noti_list.push(d)
      }
    })
    notificationList = noti_list
  }
  console.log(noti_list,notificationList,'Notifications')
  return {
    notifications: notificationList
  }
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "notifications",
      limit: 100,
      // where: [
      //   ["userId", "==", `${JSON.parse(localStorage.getItem("user"))?._id}`],
      //   ["userId", "==", '']
      // ], /// [[] , [] ,[]] // can give multiple conditions
      orderBy: ["createdAt", "desc"], // order by read we will do //
    },
  ])
)(NotificationPage);
