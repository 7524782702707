export default(
    {
        national_school_of_drama : [
            {label : 'Acting' , value : 'Acting'},
            {label : 'Direction' , value : 'Direction'},
            {label : 'Design' , value : 'Design'} ,    
        ],
        film_and_television_institute : [
            {label : 'Direction & Screenplay Writing' , value : 'Direction & Screenplay Writing'},
            {label : 'Cenematography' , value : 'Cenematography'},
            {label : 'Editing' , value : 'Editing'},
            {label : 'Sound Recording & Sound Desing' , value : 'Sound Recording & Sound Desing'},
            {label : 'Screen Acting' , value : 'Screen Acting'},
            {label : 'Screen Writing' , value : 'Screen Writing'},
            {label : 'Electronic Cenematography' , value : 'Electronic Cenematography'},
            {label : 'Video Editing' , value : 'Video Editing'},
            {label : 'Mention Other (Blank Box)' , value : 'Mention Other (Blank Box)'},
        ]
    }
)