import React from 'react';
import { Link } from 'react-router-dom';

const MidSection = (props) => {
    return (
        <>
            <section id="create_talent" className="mid_info dark_clr">
                <div className="container-fluid side_space">
                    <div className="home_grey_bx clearfix">
                        <img src="images/right_pattern.png" alt="img" className="right_pattern" />
                        <div className="box_align">
                            <div className="col-md-6 section_info">
                                <h2>Create your profile and take your career to the next level</h2>
                                <p>Looking for acting projects, auditions and casting calls for Bollywood, movies, tv serials, ad films, reality shows and short films in India? Create your profile here now! </p>
                                <Link to="/CreateUserProfile" className="stroke_btn">Create Profile</Link>
                            </div>
                            <div className="col-md-6 section_img text-center">
                                <img src="images/create_profile.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="post_audition" className="mid_info dark_clr">
                <div className="container-fluid side_space">
                    <div className="home_grey_bx clearfix">
                        <img src="images/left_pattern.png" alt="img" className="left_pattern" />
                        <div className="row box_align">
                            <div className="col-md-6 section_img text-center">
                                <img src="images/audition.png" alt="img" />
                            </div>
                            <div className="col-md-6 section_info">
                                <h2>Post Ad and Search Auditions Near you</h2>
                                <p>You can post your auditions as per your project requirements and expectation. </p>
                                <Link to="/CreateAudition" className="stroke_btn">Post Audition</Link>
                                &nbsp;&nbsp;&nbsp;
                            <Link to="/SearchAudition" className="stroke_btn">Search Audition</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="create_talent" className="mid_info dark_clr">
                <div className="container-fluid side_space">
                    <div className="home_grey_bx clearfix">
                        <img src="images/right_pattern.png" alt="img" className="right_pattern" />
                        <div className="row box_align">
                            <div className="col-md-6 section_info">
                                <h2>Let us help you find the right talent</h2>
                                <p>We bring to you some talent search basics to focus on while hiring people for your project </p>
                                <Link to="/PostJob" className="stroke_btn">Post a Job</Link>
                            </div>
                            <div className="col-md-6 section_img text-center">
                                <img src="images/find_talent.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="find_work_part" className="mid_info dark_clr">
                <div className="container-fluid side_space">
                    <div className="home_grey_bx clearfix">
                        <img src="images/left_pattern.png" alt="img" className="left_pattern" />
                        <div className="row box_align">
                            <div className="col-md-6 section_img text-center">
                                <img src="images/search_job_infographic.png" alt="img" />
                            </div>
                            <div className="col-md-6 section_info">
                                <h2>Find Great Work according to your Skills</h2>
                                <p>Explore the best-acting jobs on Bollywood is Good (BiG). </p>
                                <Link to="/SearchJob" className="stroke_btn">Search Job</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="make_film_part" className="mid_info dark_clr">
                <div className="container-fluid side_space">
                    <div className="home_grey_bx clearfix">
                        <img src="images/right_pattern.png" alt="img" className="right_pattern" />
                        <div className="row box_align">
                            <div className="col-md-6 section_info">
                                <h2>We help you to Make your own Team/Film</h2>
                                <p>We help to gather your entire team such as artists, production houses, casting directors & casting Agencies, crew members, etc in one place. </p>
                                <Link to="/MakeFilm" className="stroke_btn">Make My Team/Film</Link>
                                {/* <p><br /><small>For Director Only*</small></p> */}
                            </div>
                            <div className="col-md-6 section_img text-center">
                                <img src="images/film.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MidSection;